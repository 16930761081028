
import { InitialGeneralSettings } from 'src/state/api-models/setting';
import { InitialSettingDataState } from './model';

const INITIAL_SETTINGS_KEY = 'initial_settings';

export const getInitialSettings = (): InitialSettingDataState => {
  const data = window.localStorage.getItem(INITIAL_SETTINGS_KEY);
  return data && JSON.parse(data);
};



export const getInitialGeneralSettings = (): InitialGeneralSettings => {
  const data = getInitialSettings();
  return data && data.general;
};

export const getCountryCode = () => {
  const initial_settings = getInitialGeneralSettings();
  return initial_settings && initial_settings.country_code;
};

export const setInitialSettings = (data: InitialSettingDataState) => {
  if (data) {
    window.localStorage.setItem(INITIAL_SETTINGS_KEY, JSON.stringify(data));
  } else {
    window.localStorage.removeItem(INITIAL_SETTINGS_KEY);
  }
};

export const clearInitialSettings = () => {
  window.localStorage.removeItem(INITIAL_SETTINGS_KEY);
};
