import { put, call, all, takeLatest } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';

function* getCheckedInEmployeeList(action: ReturnType<typeof actions.getCheckedInEmployeeList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;

    const response = yield call(axiosInstance.get, API_ENDPOINTS.EMPLOYEE.CHECKED_IN_LIST, {
      params: { sort, ...filter, ...pagination },
    });

    let responseData = (response as any).data;
    /*
    let key_axios = generateSingleKey(API_ENDPOINTS.EMPLOYEE.CHECKED_IN_LIST, {
      store_id: filter.store_id || '',
      page: pagination.page || '',
      per_page: pagination.per_page || '',
    });

    let responseData = null;

    let cacheObject = window.localStorage.getItem(key_axios);
    if (cacheObject) {
      responseData = JSON.parse(cacheObject);
    } else {
      const response = yield call(axiosInstance.get, API_ENDPOINTS.EMPLOYEE.CHECKED_IN_LIST, {
        params: { sort, ...filter, ...pagination },
      });

      responseData = (response as any).data;
      if (responseData.success) {
        window.localStorage.setItem(key_axios, JSON.stringify(responseData));
      }
    }*/

    yield put(actions.getCheckedInEmployeeList.success(responseData));
  } catch (err) {
    yield put(actions.getCheckedInEmployeeList.failure(err));
  }
}

function* clockInOutEmployee(action: ReturnType<typeof actions.clockInOutEmployee.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.EMPLOYEE.CHECK_IN_OUT, action.payload);

    yield put(actions.clockInOutEmployee.success((response as any).data));

    //remove caching employee and walk-in
    //removeCacheEmployees(null);

    yield put(actions.reloadCheckedInEmployeeList());
  } catch (err) {
    yield put(actions.clockInOutEmployee.failure(err));
  }
}

function* getSummaryEmployeeInOutList(
  action: ReturnType<typeof actions.getSummaryEmployeeInOutList.request>
): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.EMPLOYEE_WORKING_TIME.SUMMARY, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getSummaryEmployeeInOutList.success((response as any).data));
  } catch (err) {
    yield put(actions.getSummaryEmployeeInOutList.failure(err));
  }
}

function* getReportSummaryEmployeeInOutList(
  action: ReturnType<typeof actions.getReportSummaryEmployeeInOutList.request>
): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.EMPLOYEE_WORKING_TIME.REPORT_SUMMARY, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getReportSummaryEmployeeInOutList.success((response as any).data));
  } catch (err) {
    yield put(actions.getReportSummaryEmployeeInOutList.failure(err));
  }
}

function* getEmployeeInOutTimesInDateDetail(
  action: ReturnType<typeof actions.getEmployeeInOutTimesInDateDetail.request>
): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.EMPLOYEE_WORKING_TIME.DETAIL_WKT_IN_DATE, {
      params: action.payload,
    });

    yield put(actions.getEmployeeInOutTimesInDateDetail.success((response as any).data));
  } catch (err) {
    yield put(actions.getEmployeeInOutTimesInDateDetail.failure(err));
  }
}

function* deleteEmployeeInOutTimesInDate(
  action: ReturnType<typeof actions.deleteEmployeeInOutTimesInDate.request>
): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.delete(
        API_ENDPOINTS.EMPLOYEE_WORKING_TIME.DELETE_WKT_IN_DATE.replace('{user_id}', action.payload.user_id).replace(
          '{date_work}',
          action.payload.date_work
        )
      )
    );

    yield put(actions.deleteEmployeeInOutTimesInDate.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteEmployeeInOutTimesInDate.failure(err));
  }
}

function* updateEmployeeInOutTimesInDate(
  action: ReturnType<typeof actions.updateEmployeeInOutTimesInDate.request>
): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.EMPLOYEE_WORKING_TIME.UPDATE_WKT_IN_DATE, action.payload)
    );

    yield put(actions.updateEmployeeInOutTimesInDate.success((response as any).data));
  } catch (err) {
    yield put(actions.updateEmployeeInOutTimesInDate.failure(err));
  }
}

// Main saga
export default function* employeeSaga() {
  yield all([
    takeLatest(actions.getCheckedInEmployeeList.request, getCheckedInEmployeeList),
    takeLatest(actions.clockInOutEmployee.request, clockInOutEmployee),
    takeLatest(actions.getSummaryEmployeeInOutList.request, getSummaryEmployeeInOutList),
    takeLatest(actions.getReportSummaryEmployeeInOutList.request, getReportSummaryEmployeeInOutList),
    takeLatest(actions.getEmployeeInOutTimesInDateDetail.request, getEmployeeInOutTimesInDateDetail),
    takeLatest(actions.deleteEmployeeInOutTimesInDate.request, deleteEmployeeInOutTimesInDate),
    takeLatest(actions.updateEmployeeInOutTimesInDate.request, updateEmployeeInOutTimesInDate),
  ]);
}
