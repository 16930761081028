export const GIFTCARD = {
  List: '/giftcards',
  Create: '/giftcards',
  Details: '/giftcards/{id}',
  Histories: '/giftcards/{id}/histories',
  Update: '/giftcards/{id}',
  Delete: '/giftcards/{id}',
  Bulk: '/giftcards/bulk',

  PosDetails: 'pos/giftcards/{id}',
  PosHistories: 'pos/giftcards/{id}/histories',
};
