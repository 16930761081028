export const FETCH_DETAILS = 'giftcard-report/details_summary';
export const FETCH_DETAILS_SUCCESS = 'giftcard-report/details_summary_success';
export const FETCH_DETAILS_FAILED = 'giftcard-report/details_summary_failed';

export const CLEAR_DATA = 'giftcard-report/clear_data';

export type Actions =
  | typeof FETCH_DETAILS
  | typeof FETCH_DETAILS_FAILED
  | typeof FETCH_DETAILS_SUCCESS
  | typeof CLEAR_DATA;
