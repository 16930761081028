export const FETCH_SUMMARY = 'emarketing/sent/summary';
export const FETCH_SUMMARY_SUCCESS = 'emarketing/sent/summary_success';
export const FETCH_SUMMARY_FAILED = 'emarketing/sent/summary_failed';

export const FETCH_DETAILS = 'emarketing/sent/details';
export const FETCH_DETAILS_SUCCESS = 'emarketing/sent/details_success';
export const FETCH_DETAILS_FAILED = 'emarketing/sent/details_failed';

export const DELETE = 'emarketing/sent/detele';
export const DELETE_SUCCESS = 'emarketing/sent/detele_success';
export const DELETE_FAILED = 'emarketing/sent/detele_failed';

export const BULK = 'emarketing/sent/bulk';
export const BULK_SUCCESS = 'emarketing/sent/bulk_success';
export const BULK_FAILED = 'emarketing/sent/bulk_failed';

export const CLEAR_DATA = 'emarketing/sent/clear_data';

export type EmarketingSentAction =
  | typeof FETCH_SUMMARY
  | typeof FETCH_SUMMARY_SUCCESS
  | typeof FETCH_SUMMARY_FAILED
  | typeof FETCH_DETAILS
  | typeof FETCH_DETAILS_FAILED
  | typeof FETCH_DETAILS_SUCCESS
  | typeof DELETE
  | typeof DELETE_SUCCESS
  | typeof DELETE_FAILED
  | typeof BULK
  | typeof BULK_SUCCESS
  | typeof BULK_FAILED
  | typeof CLEAR_DATA;
