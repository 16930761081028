import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { API_ENDPOINTS } from '../../../config/api-endpoints';
import { axiosInstance } from '../../../utils/axios/axios-instance';

// Handle request saga

function* getWalkinList(action: ReturnType<typeof actions.getWalkinList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.WALKIN.List, {
      params: action.payload,
    });

    yield put(actions.getWalkinList.success((response as any).data));
  } catch (err) {
    yield put(actions.getWalkinList.failure(err));
  }
}

function* getWalkinListGroupByEmployee(
  action: ReturnType<typeof actions.getWalkinListGroupByEmployee.request>
): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.WALKIN.ListGroupByEmployee, {
      params: action.payload,
    });

    yield put(actions.getWalkinListGroupByEmployee.success((response as any).data));
  } catch (err) {
    yield put(actions.getWalkinListGroupByEmployee.failure(err));
  }
}

function* getWalkinEmloyeeList(action: ReturnType<typeof actions.getWalkinEmloyeeList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.EMPLOYEE.ENABLE_WALKIN_LIST, {
      params: action.payload,
      skipLoadingHandler: true,
    });

    yield put(actions.getWalkinEmloyeeList.success((response as any).data));
  } catch (err) {
    yield put(actions.getWalkinEmloyeeList.failure(err));
  }
}

function* checkinCustomer(action: ReturnType<typeof actions.checkinCustomer.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.CUSTOMER.Checkin, {
      params: action.payload,
      skipLoadingHandler: true,
    });

    yield put(actions.checkinCustomer.success((response as any).data));
  } catch (err) {
    yield put(actions.checkinCustomer.failure(err));
  }
}

function* getServicesOfEmployee(action: ReturnType<typeof actions.getServicesOfEmployee.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.SERVICE.PosWalkinServices, {
      params: action.payload,
    });

    yield put(actions.getServicesOfEmployee.success((response as any).data));
  } catch (err) {
    yield put(actions.getServicesOfEmployee.failure(err));
  }
}

// Main saga
export default function* walkinSaga() {
  yield all([
    takeLatest(actions.getWalkinList.request, getWalkinList),
    takeLatest(actions.getWalkinListGroupByEmployee.request, getWalkinListGroupByEmployee),
    takeLatest(actions.getWalkinEmloyeeList.request, getWalkinEmloyeeList),
    takeLatest(actions.checkinCustomer.request, checkinCustomer),
    takeLatest(actions.getServicesOfEmployee.request, getServicesOfEmployee),
  ]);
}
