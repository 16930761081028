import { put, call, all, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from 'src/utils/axios/axios-instance';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { convertToFormData } from 'src/utils/converter';
import * as actions from './actions';

// Handle request saga
function* getEmployeeList(action: ReturnType<typeof actions.getEmployeeList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.USER.List, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getEmployeeList.success((response as any).data));
  } catch (err) {
    yield put(actions.getEmployeeList.failure(err));
  }
}

// Handle request saga
function* getEmployeeTotal(action: ReturnType<typeof actions.getEmployeeTotal.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.USER.Total, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getEmployeeTotal.success((response as any).data));
  } catch (err) {
    yield put(actions.getEmployeeTotal.failure(err));
  }
}

function* getEmployeeDetails(action: ReturnType<typeof actions.getEmployeeDetails.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.USER.Details.replace('{id}', action.payload));

    yield put(actions.getEmployeeDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getEmployeeDetails.failure(err));
  }
}

function* createEmployee(action: ReturnType<typeof actions.createEmployee.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.USER.Create, action.payload, config));

    yield put(actions.createEmployee.success((response as any).data));

    //yield removeCacheEmployees((action.payload as any).stores);
  } catch (err) {
    yield put(actions.createEmployee.failure(err));
  }
}

function* updateEmployee(action: ReturnType<typeof actions.updateEmployee.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.USER.Update.replace('{id}', action.payload.id), action.payload, config)
    );

    yield put(actions.updateEmployee.success((response as any).data));

    //remove caching employee checked-in
    //yield removeCacheEmployees((action.payload as any).stores);
  } catch (err) {
    yield put(actions.updateEmployee.failure(err));
  }
}

function* deleteEmployee(action: ReturnType<typeof actions.deleteEmployee.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.delete(API_ENDPOINTS.USER.Delete.replace('{id}', action.payload)));
    yield put(actions.deleteEmployee.success((response as any).data));

    //remove caching employee checked-in
    //yield removeCacheEmployees(null);
  } catch (err) {
    yield put(actions.deleteEmployee.failure(err));
  }
}

function* changePass(action: ReturnType<typeof actions.changePass.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.USER.ChangePass.replace('{id}', action.payload.id), action.payload)
    );

    yield put(actions.changePass.success((response as any).data));
  } catch (err) {
    yield put(actions.changePass.failure(err));
  }
}

function* bulkEmployees(action: ReturnType<typeof actions.bulkEmployees.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.USER.Bulk, action.payload);

    yield put(actions.bulkEmployees.success((response as any).data));
    //remove caching employee checked-in
    //yield removeCacheEmployees(null);
  } catch (err) {
    yield put(actions.bulkEmployees.failure(err));
  }
}

function* importEmployee(action: ReturnType<typeof actions.importEmployee.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };

    const response = yield call(axiosInstance.post, API_ENDPOINTS.USER.Import, action.payload, config);

    yield put(actions.importEmployee.success((response as any).data));

    //remove caching employee checked-in
    //yield removeCacheEmployees(null);
  } catch (err) {
    yield put(actions.importEmployee.failure(err));
  }
}

// Main saga
export default function* employeeSaga() {
  yield all([
    takeLatest(actions.getEmployeeList.request, getEmployeeList),
    takeLatest(actions.getEmployeeTotal.request, getEmployeeTotal),
    takeLatest(actions.getEmployeeDetails.request, getEmployeeDetails),
    takeLatest(actions.createEmployee.request, createEmployee),
    takeLatest(actions.updateEmployee.request, updateEmployee),
    takeLatest(actions.deleteEmployee.request, deleteEmployee),
    takeLatest(actions.changePass.request, changePass),
    takeLatest(actions.bulkEmployees.request, bulkEmployees),
    takeLatest(actions.importEmployee.request, importEmployee),
  ]);
}
