import { SaleOrderData } from 'src/state/models/order';
import { createAction, createAsyncAction } from 'typesafe-actions';
import * as types from './types';

// Create the set of async actions
export const getWaitingSaleList = createAsyncAction(
  types.GET_WAITING_SALE_LIST_REQUEST,
  types.GET_WAITING_SALE_LIST_SUCCESS,
  types.GET_WAITING_SALE_LIST_FAILED
)<{ store_id: string; workstation_id: string }, SaleOrderData[], any>();

export const saveWaitingSale = createAction(
  types.SAVE_WAITING_SALE,
  (ticket: SaleOrderData, store_id: string, workstation_id: string) => ({ ticket, store_id, workstation_id })
)();

export const deleteWaitingSale = createAction(
  types.DELETE_WAITING_SALE,
  (ticket_id: string, store_id: string, workstation_id: string) => ({ ticket_id, store_id, workstation_id })
)();

export const mergeWaitingSale = createAction(
  types.MERGE_WAITING_SALE,
  (ticket_ids: string[], store_id: string, workstation_id: string) => ({ ticket_ids, store_id, workstation_id })
)();

export const clearData = createAction(types.CLEAR_DATA)();
