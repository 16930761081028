export const API_ENDPOINTS = {
  PRODUCTKIT: {
    List: '/productkits',
    Create: '/productkits',
    Details: '/productkits/{id}',
    Update: '/productkits/{id}',
    Delete: '/productkits/{id}',
    Bulk: '/productkits/bulk',
    Import: '/productkits/import',
  },
};
