import { CalculateData } from "./calculate";

export type DiscountData = CalculateData;

export enum DiscountType {
  Item = '0',
  Employee = '1',
  Ticket = '2',
  Membership = '3',
  Manager = '4',
  Other = '5',
}

export type DiscountSetting = {
  type: DiscountType;
  discount: DiscountData;
};

