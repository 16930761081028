import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';
import {
  ExpenseListRequest,
  ExpenseListResponse,
  ExpenseCreateRequest,
  ExpenseCreateResponse,
  ExpenseDetailRequest,
  ExpenseDetailResponse,
  ExpenseUpdateRequest,
  ExpenseUpdateResponse,
  ExpenseDeleteRequest,
  ExpenseDeleteResponse,
  ExpenseBulkRequest,
  ExpenseBulkResponse,
} from '../../api-models/expense';

export const getExpenseList = createAsyncAction(types.FETCH_LIST, types.FETCH_LIST_SUCCESS, types.FETCH_LIST_FAILED)<
  ExpenseListRequest,
  ExpenseListResponse,
  any
>();

export const createExpense = createAsyncAction(types.CREATE, types.CREATE_SUCCESS, types.CREATE_FAILED)<
  ExpenseCreateRequest,
  ExpenseCreateResponse,
  any
>();

export const getExpenseDetails = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<ExpenseDetailRequest, ExpenseDetailResponse, any>();

export const updateExpense = createAsyncAction(types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILED)<
  ExpenseUpdateRequest,
  ExpenseUpdateResponse,
  any
>();

export const deleteExpense = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  ExpenseDeleteRequest,
  ExpenseDeleteResponse,
  any
>();

export const bulkExpense = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  ExpenseBulkRequest,
  ExpenseBulkResponse,
  any
>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
