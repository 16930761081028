import { all, call, put, takeEvery } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { axiosInstance } from '../../../../utils/axios/axios-instance';
import { generateSingleKey } from '../../../../utils/axios/axios-fecth';

import * as actions from './actions';
import { getAuthData, setAuthData } from '../auth/storage';
import { updateInitialSettings } from '../auth/actions';
import { setInitialSettings } from './storage';

function* getInitialGeneralSettings(action: ReturnType<typeof actions.getInitialGeneralSettings.request>): Generator {
  try {
    let key_axios = generateSingleKey(API_ENDPOINTS.SETTING.Initial, action.payload);

    let responseData = null;
    let cacheObject = localStorage.getItem(key_axios);
    if (cacheObject) {
      responseData = JSON.parse(cacheObject);
    } else {
      const response = yield call(axiosInstance.get, API_ENDPOINTS.SETTING.Initial, {
        params: action.payload,
      });
      responseData = (response as any).data;
      if (responseData.success) {
        window.localStorage.setItem(key_axios, JSON.stringify(responseData));
      }
    }

    yield put(actions.getInitialGeneralSettings.success(responseData));

    const initialSettings = responseData.data;
    if (initialSettings) {
      yield put(actions.setInitialGeneralSettingData(initialSettings));

      // update setting of login store
      const loginData = getAuthData();
      loginData.initial_settings.generalSettings = initialSettings;

      yield put(updateInitialSettings(initialSettings));
      yield call(() => setInitialSettings(initialSettings));
      yield call(() => setAuthData(loginData));
    }
  } catch (err) {
    yield put(actions.getInitialGeneralSettings.failure(err));
  }
}

function* getInitialStoreSettings(action: ReturnType<typeof actions.getInitialStoreSettings.request>): Generator {
  try {
    const { store_id } = action.payload;

    let key_axios = generateSingleKey(API_ENDPOINTS.STORE_SETTING.Initial, action.payload);
    let responseData = null;

    let cacheObject = localStorage.getItem(key_axios);
    if (cacheObject) {
      responseData = JSON.parse(cacheObject);
    } else {
      const response = yield call(axiosInstance.get, API_ENDPOINTS.STORE_SETTING.Initial, {
        params: action.payload,
      });

      responseData = (response as any).data;
      if (responseData.success) {
        window.localStorage.setItem(key_axios, JSON.stringify(responseData));
      }
    }

    yield put(actions.getInitialStoreSettings.success(responseData));

    const initialSettings = responseData.data;
    if (store_id && initialSettings) {
      yield put(actions.setInitialStoreSettingData({ storeId: store_id, data: initialSettings }));

      // update setting of login store
      const loginData = getAuthData();
      if (loginData.store_id === store_id) {
        loginData.initial_settings.storeSettings = initialSettings;

        yield put(updateInitialSettings(initialSettings));
        yield call(() => setInitialSettings(initialSettings));
        yield call(() => setAuthData(loginData));
      }
    }
  } catch (err) {
    yield put(actions.getInitialStoreSettings.failure(err));
  }
}

// Main saga
export default function* initialSettingSaga() {
  yield all([takeEvery(actions.getInitialGeneralSettings.request, getInitialGeneralSettings)]);
  yield all([takeEvery(actions.getInitialStoreSettings.request, getInitialStoreSettings)]);
}
