import { SettingGroup } from 'src/state/api-models/setting';
import { TaxSetting } from 'src/state/models/common';
import {
  EmployeeWithOrderItems,
  initOrderTotal,
  OrderDiscount,
  OrderItemData,
  OrderItemType,
  OrderTotalAmount,
  PaymentData,
  SaleOrderData,
} from 'src/state/models/order';
import { PaymentType } from 'src/types/sale';
import { DiscountSetting, DiscountType } from 'src/types/discount';
import { calculateDiscountAmount, calculateTaxAmount, roundValue } from './calculate';
import { toNumber } from './converter';

export const getTaxSetting = (settings: SettingGroup, taxes: string) => {
  if (taxes) {
    const taxOnTaxes = parseInt(settings.config_tax_on_tax);
    const indianTax = settings.config_indian_tax;
    return { taxOnTaxes, indianTax, taxes };
  }
};

export const getOrderEmployees = (state: SaleOrderData) => {
  const { orderEmployees } = state;

  // get all order items has a discount
  return orderEmployees.map((group: EmployeeWithOrderItems) => {
    let service_amount = 0;
    const orderItems = group.orderItems.map((item: OrderItemData) => {
      const price = toNumber(item.price);
      if (item.discounts && item.discounts.length > 0) {
        // price = calculateAmountWithMultiDiscounts(price, item.discounts);
      }
      if (item.item_type === OrderItemType.Service) {
        service_amount += item.quantity * price;
      }

      return { ...item, total: item.quantity * price };
    });
    const { employee, tips } = group;

    return { orderItems, employee, tips, service_amount };
  });
};

export const getAmountTotal = (orderEmployees: EmployeeWithOrderItems[], settings?: SettingGroup) => {
  let totalAmount = 0;
  let totalTipAmount = 0;
  let totalTaxAmount = 0;
  let totalDiscountItem = 0;
  let totalDiscountEmployee = 0;

  orderEmployees.forEach((group) => {
    group.orderItems.forEach((item) => {
      const price = toNumber(item.price);
      totalAmount += item.quantity * price;

      if (
        item.item_type === OrderItemType.Product ||
        item.item_type === OrderItemType.Service ||
        item.item_type === OrderItemType.ProductKit
      ) {
        // update by tax setting of product/service/productkit
        let taxSetting: TaxSetting = { taxOnTaxes: item.tax_on, taxes: item.taxes, indianTax: item.indian_tax };
        if (settings) {
          taxSetting = getTaxSetting(settings, item.taxes);
        }

        const taxAmountItem = calculateTaxAmount(price, taxSetting);
        totalTaxAmount += item.quantity * taxAmountItem;
      }

      if (item.discounts) {
        item.discounts.forEach((discountItem: DiscountSetting) => {
          if (String(discountItem.type) === DiscountType.Item) {
            totalDiscountItem += item.quantity * calculateDiscountAmount(price, discountItem.discount);
          }
          if (String(discountItem.type) === DiscountType.Employee) {
            totalDiscountEmployee += item.quantity * calculateDiscountAmount(price, discountItem.discount);
          }
        });
      }
    });

    if (group.tips) {
      const tip = toNumber(group.tips.tip_amount);
      totalTipAmount += tip;
    }
  });

  return {
    totalAmount: roundValue(totalAmount),
    totalTaxAmount: roundValue(totalTaxAmount),
    totalTipAmount: roundValue(totalTipAmount),
    totalDiscountEmployee: roundValue(totalDiscountEmployee),
    totalDiscountItem: roundValue(totalDiscountItem),
  };
};

export const getDiscountTotal = (discount: OrderDiscount, amountTotal: OrderTotalAmount) => {
  const orderDiscount = { ...discount };
  const { totalAmount, totalDiscountEmployee, totalDiscountItem } = amountTotal;

  orderDiscount.discount_item = totalDiscountItem;
  orderDiscount.discount_employee = totalDiscountEmployee;

  let discount_ticket = 0;
  let discount_manager = 0;
  let discount_membership = 0;

  const totalAmoutAfterDiscEmpAndItem = Math.max(totalAmount - totalDiscountEmployee - totalDiscountItem, 0);
  if (orderDiscount.discount_ticket_info) {
    discount_ticket = calculateDiscountAmount(totalAmoutAfterDiscEmpAndItem, orderDiscount.discount_ticket_info);
  }
  if (orderDiscount.discount_manager_info) {
    discount_manager = calculateDiscountAmount(totalAmoutAfterDiscEmpAndItem, orderDiscount.discount_manager_info);
  }
  if (orderDiscount.discount_membership_info) {
    discount_membership = calculateDiscountAmount(
      totalAmoutAfterDiscEmpAndItem,
      orderDiscount.discount_membership_info
    );
  }
  orderDiscount.discount_ticket = discount_ticket;
  orderDiscount.discount_manager = discount_manager;
  orderDiscount.discount_membership = discount_membership;

  return orderDiscount;
};

export const getPaymentTotal = (payments: PaymentData[]) => {
  let paymentTotal = 0;
  payments.forEach((item) => {
    paymentTotal += toNumber(item.payment_amount);
  });

  return roundValue(paymentTotal);
};

export const getPaymentTotalWithoutCredit = (payments: PaymentData[]) => {
  let paymentTotal = 0;
  payments.forEach((item) => {
    if (item.payment_type !== PaymentType.Credit) {
      paymentTotal += toNumber(item.payment_amount);
    }
  });

  return roundValue(paymentTotal);
};

export const getOrderTotal = (amountTotal: OrderTotalAmount, paymentTotal: number, discountTotal: OrderDiscount) => {
  const orderTotal = { ...initOrderTotal };
  const { totalAmount, totalTipAmount, totalTaxAmount } = amountTotal;

  orderTotal.paid_customer_total = roundValue(paymentTotal);

  const { discount_membership, discount_other, discount_item, discount_employee, discount_ticket, discount_manager } =
    discountTotal;

  orderTotal.tip_amount = roundValue(totalTipAmount);
  orderTotal.taxes = roundValue(totalTaxAmount);
  // because ttotalAmount is discounted by emp/item discount
  orderTotal.sub_total = roundValue(totalAmount);

  orderTotal.discount_amount = roundValue(
    discount_item + discount_employee + discount_ticket + discount_manager + discount_membership + discount_other
  );
  orderTotal.total_price = roundValue(
    orderTotal.sub_total + orderTotal.taxes + orderTotal.tip_amount - orderTotal.discount_amount
  );
  orderTotal.paid_customer_change_amount_total = roundValue(orderTotal.paid_customer_total - orderTotal.total_price);

  return { ...orderTotal, ...discountTotal, discount_ticket, discount_manager };
};

export const getPaymentAmount = (payments: PaymentData[], type: PaymentType) => {
  const paymentItem = payments.find((item) => item.payment_type === type);

  if (paymentItem) return paymentItem.payment_amount;
  return 0;
};

export const getPaymentInfo = (payments: PaymentData[], type: PaymentType) => {
  const paymentItem = payments.find((item) => item.payment_type === type);

  return paymentItem;
};

export const getOrderPaymentInTypes = (payments: PaymentData[]) => {
  const vnpay = getPaymentInfo(payments, PaymentType.VnPay);
  const momo = getPaymentInfo(payments, PaymentType.Momo);
  const cash = getPaymentInfo(payments, PaymentType.Cash);
  const credit = getPaymentInfo(payments, PaymentType.Credit);
  const giftcard = getPaymentInfo(payments, PaymentType.Giftcard);
  const check = getPaymentInfo(payments, PaymentType.Check);
  const point = getPaymentInfo(payments, PaymentType.Point);
  const coupon = getPaymentInfo(payments, PaymentType.Coupon);
  const venmo = getPaymentInfo(payments, PaymentType.Venmo);
  const vietqr = getPaymentInfo(payments, PaymentType.VietQr);

  const paymentInTypes = {
    vnpay: vnpay ? vnpay.payment_amount : 0,
    vnpay_info: cash ? cash.payment_info : 0,
    momo: momo ? momo.payment_amount : 0,
    momo_info: momo ? momo.payment_info : 0,
    vietqr: vietqr ? vietqr.payment_amount : 0,
    vietqr_info: vietqr ? vietqr.payment_info : 0,
    cash: cash ? cash.payment_amount : 0,
    cash_info: cash ? cash.payment_info : undefined,
    credit: credit ? credit.payment_amount : 0,
    credit_info: credit ? credit.payment_info : undefined,
    giftcard: giftcard ? giftcard.payment_amount : 0,
    giftcard_info: giftcard ? giftcard.payment_info : undefined,
    check: check ? check.payment_amount : 0,
    check_info: check ? check.payment_info : undefined,
    point: point ? point.payment_amount : 0,
    point_info: point ? point.payment_info : undefined,
    coupon: coupon ? coupon.payment_amount : 0,
    coupon_info: coupon ? coupon.payment_info : undefined,
    venmo: venmo ? venmo.payment_amount : 0,
    venmo_info: venmo ? venmo.payment_info : undefined,
  };

  return paymentInTypes;
};

export const getSaleOrderData = (order: SaleOrderData) => {
  const { id, store_id, ticket_no, invoice_no, order_at, customer, customer_id, customer_name } = order;
  const orderEmployees = getOrderEmployees(order);
  const amountTotal = getAmountTotal(orderEmployees);
  const discountTotal = getDiscountTotal(order.discount, amountTotal);
  const paymentTotal = getPaymentTotal(order.payments);
  const orderTotal = getOrderTotal(amountTotal, paymentTotal, discountTotal);
  return {
    id,
    store_id,
    ticket_no,
    invoice_no,
    order_at,
    customer,
    customer_id,
    customer_name,
    payments: order.payments,
    orderEmployees,
    discount: discountTotal,
    orderTotal,
  };
};

export const mergeSaleTickets = (ticketList: SaleOrderData[], mergedTicktIds: string[]) => {
  const mergedTicketList = ticketList.filter((item) => mergedTicktIds.indexOf(item.id) >= 0);

  if (mergedTicketList.length > 0) {
    let mergedTicket: SaleOrderData = {
      orderEmployees: [],
      payments: [],
    };

    mergedTicketList.forEach((ticket, index) => {
      if (index === 0) {
        mergedTicket = { ...ticket };
      } else {
        ticket.orderEmployees.forEach((ticketEmp) => {
          const empIndex = mergedTicket.orderEmployees.findIndex(
            (mergedEmp) => mergedEmp.employee.id === ticketEmp.employee.id
          );
          if (empIndex >= 0) {
            // merged order items
            ticketEmp.orderItems.forEach((ticketOrderItem) => {
              const orderItemIndex = mergedTicket.orderEmployees[empIndex].orderItems.findIndex(
                (item) => item.item_id === ticketOrderItem.item_id
              );
              if (orderItemIndex >= 0) {
                const meredOrderItem = mergedTicket.orderEmployees[empIndex].orderItems[orderItemIndex];
                meredOrderItem.quantity += ticketOrderItem.quantity;
                // merged discount

                mergedTicket.orderEmployees[empIndex].orderItems[orderItemIndex] = meredOrderItem;
              } else {
                mergedTicket.orderEmployees[empIndex].orderItems.push(ticketOrderItem);
              }
            });

            // merged tickets
          } else {
            mergedTicket.orderEmployees.push(ticketEmp);
          }

          // merged payments, discounts
        });
      }
    });

    mergedTicketList.forEach((ticket, index) => {
      const removedIndex = ticketList.findIndex((item) => item.id === ticket.id);
      if (removedIndex >= 0) {
        if (index === 0) {
          ticketList.splice(removedIndex, 1, mergedTicket);
        } else {
          ticketList.splice(removedIndex, 1);
        }
      }
    });
  }
  return ticketList;
};

export const getEmployeeIdsOfTickets = (tickets: SaleOrderData[]) => {
  // map<id, count>
  const employeeIds = new Map<string, number>();
  tickets.forEach((item) => {
    item.orderEmployees.forEach((emp) => {
      if (employeeIds.has(emp.employee.id)) {
        const count = employeeIds.get(emp.employee.id) + 1;
        employeeIds.set(emp.employee.id, count);
      } else {
        employeeIds.set(emp.employee.id, 1);
      }
    });
  });
  return employeeIds;
};

export const isExistSamleEmployeeInTickets = (tickets: SaleOrderData[]) => {
  const employeeIds = getEmployeeIdsOfTickets(tickets);

  const index = Array.from(employeeIds.values()).findIndex((item) => item > 1);
  return index >= 0;
};

export const calcTotalRefundAmount = (orderItems: OrderItemData[]) => {
  let totalAmount = 0;
  let totalTaxAmount = 0;
  let totalDiscountItem = 0;
  let totalDiscountEmployee = 0;

  orderItems.forEach((item) => {
    const price = toNumber(item.price);
    totalAmount += item.quantity * price;

    if (
      item.item_type === OrderItemType.Product ||
      item.item_type === OrderItemType.Service ||
      item.item_type === OrderItemType.ProductKit
    ) {
      // update by tax setting of product/service/productkit
      const taxSetting: TaxSetting = { taxOnTaxes: item.tax_on, taxes: item.taxes };
      const taxAmountItem = calculateTaxAmount(price, taxSetting);
      totalTaxAmount += item.quantity * taxAmountItem;
    }

    if (item.discounts) {
      item.discounts.forEach((discountItem: DiscountSetting) => {
        if (String(discountItem.type) === DiscountType.Item) {
          totalDiscountItem += item.quantity * calculateDiscountAmount(price, discountItem.discount);
        }
        if (String(discountItem.type) === DiscountType.Employee) {
          totalDiscountEmployee += item.quantity * calculateDiscountAmount(price, discountItem.discount);
        }
      });
    }
  });

  const totalRefundAmount = totalAmount + totalTaxAmount - totalDiscountItem - totalDiscountEmployee;

  return {
    totalAmount,
    totalTaxAmount,
    totalDiscountAmount: totalDiscountItem + totalDiscountEmployee,
    refundAmount: roundValue(totalRefundAmount),
  };
};

export const getPaymentLabel = (type: PaymentType) => {
  const paymentLabels = {};

  paymentLabels[PaymentType.Cash] = 'Cash';
  paymentLabels[PaymentType.Credit] = 'Credit';
  paymentLabels[PaymentType.Giftcard] = 'Giftcard';
  paymentLabels[PaymentType.Check] = 'Check';
  paymentLabels[PaymentType.Point] = 'Point';
  paymentLabels[PaymentType.Coupon] = 'Coupon';
  paymentLabels[PaymentType.Voucher] = 'Voucher';
  paymentLabels[PaymentType.Venmo] = 'Venmo';
  paymentLabels[PaymentType.Momo] = 'Cash';
  paymentLabels[PaymentType.VnPay] = 'Cash';
  paymentLabels[PaymentType.VietQr] = 'Cash';
  paymentLabels[PaymentType.Others] = 'Others';

  return paymentLabels[type];
};
