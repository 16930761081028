import { Draft } from 'immer';
import { BookingAppt } from 'src/state/models/bookingAppt';
import { Employee } from 'src/state/models/employee';
import { EmployeeTurnSystem } from 'src/state/models/employee-turn-system';
import {
  EmployeeWithOrderItems,
  initOrderDiscount,
  OrderItem,
  OrderItemData,
  OrderItemType,
  PaymentData,
  SaleOrderData,
  TipData,
} from 'src/state/models/order';
import { CalculateData } from 'src/types';
import { toNumber } from 'src/utils/converter';
import { initialOrderState, SaleOrderState } from './model';
import { DiscountSetting, DiscountType } from 'src/types/discount';

export const findGroudIndex = (orderEmployees: EmployeeWithOrderItems[], id: string) =>
  orderEmployees.findIndex((group) => group.employee.id === id);

export const findOrderItemIndex = (orderItems: OrderItemData[], id: string) =>
  orderItems.findIndex((item) => item.item_id === id);

export const addEmployee = (draft: Draft<SaleOrderState>, payload: Employee) => {
  const employeeIndex = findGroudIndex(draft.orderEmployees, payload.id);
  if (employeeIndex < 0) {
    draft.orderEmployees.push({ employee: payload, orderItems: [] });
  }
  draft.activeEmployeeIndex = draft.orderEmployees.length - 1;
  draft.activeOrderItemIndex = -1;

  if (!draft.employee) {
    draft.employee = payload;
  }

  return draft;
};

export const changeEmployee = (draft: Draft<SaleOrderState>, payload: Employee) => {
  const employeeIndex = findGroudIndex(draft.orderEmployees, payload.id);

  //change to an employee already existed, but not a first employee
  if (employeeIndex > 0) {
  } else {
    if (draft.orderEmployees && draft.orderEmployees[0]) {
      draft.orderEmployees[0].employee = payload;
      draft.employee = payload;
    }
  }

  return draft;
};

// eslint-disable-next-line max-len
export const addOrderItem = (
  draft: Draft<SaleOrderState>,
  payload: {
    type: number;
    item: OrderItem;
    employee?: Employee;
    employeeIndex?: number;
  }
) => {
  const { item, type, employee, employeeIndex } = payload;

  const activeEmployeeIndex = employeeIndex !== undefined ? employeeIndex : draft.activeEmployeeIndex;

  if (activeEmployeeIndex >= 0) {
    const orderItemIndex = findOrderItemIndex(draft.orderEmployees[activeEmployeeIndex].orderItems, item.id);
    if (orderItemIndex >= 0) {
      const persistedOrderItem = draft.orderEmployees[activeEmployeeIndex].orderItems[orderItemIndex];

      draft.orderEmployees[activeEmployeeIndex].orderItems[orderItemIndex].quantity = persistedOrderItem.quantity + 1;

      draft.activeEmployeeIndex = activeEmployeeIndex;
      draft.activeOrderItemIndex = orderItemIndex;
    } else {
      draft.orderEmployees[activeEmployeeIndex].orderItems.push({
        // ...item,
        item_id: item.id,
        item_type: type,
        name: item.name,
        taxes: item.taxes,
        unit_price: toNumber(item.unit_price),
        price: toNumber(item.unit_price),
        quantity: 1,
        giftcard_number: item.giftcard_number,
        info: item.info,
      });

      draft.activeEmployeeIndex = activeEmployeeIndex;
      draft.activeOrderItemIndex = draft.orderEmployees[activeEmployeeIndex].orderItems.length - 1;
    }
  } else if (employee) {
    draft.orderEmployees.push({
      employee,
      orderItems: [
        {
          // ...item,
          item_id: item.id,
          item_type: type,
          name: item.name,
          taxes: item.taxes,
          unit_price: toNumber(item.unit_price),
          price: toNumber(item.unit_price),
          quantity: 1,
          giftcard_number: item.giftcard_number,
          info: item.info,
        },
      ],
    });

    draft.activeEmployeeIndex = draft.orderEmployees.length - 1;
    draft.activeOrderItemIndex = 0;
  }

  return draft;
};

// eslint-disable-next-line max-len
export const deleteOrderItem = (
  draft: Draft<SaleOrderState>,
  payload: { itemIndex: number; employeeIndex?: number }
) => {
  const { employeeIndex, itemIndex } = payload;

  const activeEmployeeIndex = employeeIndex !== undefined ? employeeIndex : draft.activeEmployeeIndex;

  if (activeEmployeeIndex >= 0 && draft.orderEmployees[activeEmployeeIndex]) {
    draft.orderEmployees[activeEmployeeIndex].orderItems.splice(itemIndex, 1);
  }

  if (employeeIndex === draft.activeEmployeeIndex) {
    if (itemIndex === 0 && draft.orderEmployees[activeEmployeeIndex].orderItems.length > 0) {
      draft.activeOrderItemIndex = 0;
    } else if (itemIndex <= draft.activeOrderItemIndex) {
      draft.activeOrderItemIndex -= 1;
    }
  }

  return draft;
};

export const updateOrderItem = (
  draft: Draft<SaleOrderState>,
  payload: { itemIndex: number; price: number; employeeIndex?: number }
) => {
  const { employeeIndex, itemIndex, price } = payload;

  const activeEmployeeIndex = employeeIndex !== undefined ? employeeIndex : draft.activeEmployeeIndex;
  if (
    activeEmployeeIndex >= 0 &&
    draft.orderEmployees[activeEmployeeIndex] &&
    draft.orderEmployees[activeEmployeeIndex].orderItems &&
    draft.orderEmployees[activeEmployeeIndex].orderItems[itemIndex]
  ) {
    // here update
    draft.orderEmployees[activeEmployeeIndex].orderItems[itemIndex].price = toNumber(price);
  }

  return draft;
};

export const updateQtyOrderItem = (
  draft: Draft<SaleOrderState>,
  payload: { itemIndex: number; employeeIndex?: number; quantity: number }
) => {
  const { employeeIndex, itemIndex, quantity } = payload;

  const activeEmployeeIndex = employeeIndex !== undefined ? employeeIndex : draft.activeEmployeeIndex;
  if (
    activeEmployeeIndex >= 0 &&
    draft.orderEmployees[activeEmployeeIndex] &&
    draft.orderEmployees[activeEmployeeIndex].orderItems &&
    draft.orderEmployees[activeEmployeeIndex].orderItems[itemIndex]
  ) {
    // here update
    draft.orderEmployees[activeEmployeeIndex].orderItems[itemIndex].quantity += toNumber(quantity);
    if (draft.orderEmployees[activeEmployeeIndex].orderItems[itemIndex].quantity === 0) {
      return deleteOrderItem(draft, payload);
    }
  }

  return draft;
};

export const discountOrderItem = (
  draft: Draft<SaleOrderState>,
  payload: {
    itemIndex: number;
    employeeIndex?: number;
    discount: DiscountSetting;
  }
) => {
  const { employeeIndex, itemIndex, discount } = payload;

  const activeEmployeeIndex = employeeIndex !== undefined ? employeeIndex : draft.activeEmployeeIndex;
  if (
    activeEmployeeIndex >= 0 &&
    draft.orderEmployees[activeEmployeeIndex] &&
    draft.orderEmployees[activeEmployeeIndex].orderItems &&
    draft.orderEmployees[activeEmployeeIndex].orderItems[itemIndex]
  ) {
    const updateOrderItem = draft.orderEmployees[activeEmployeeIndex].orderItems[itemIndex];

    updateOrderItem.discounts = updateOrderItem.discounts || [];
    const discountIndex = updateOrderItem.discounts.findIndex((item) => item.type === discount.type);

    if (discountIndex >= 0) {
      if (discount.discount.value > 0) {
        updateOrderItem.discounts[discountIndex] = discount;
      } else {
        updateOrderItem.discounts.splice(discountIndex, 1);
      }
    } else if (discount.discount.value > 0) {
      updateOrderItem.discounts.push(discount);
    }

    // updateOrderItem.price = calculateAmountWithMultiDiscounts(updateOrderItem.unit_price, updateOrderItem.discounts);

    draft.orderEmployees[activeEmployeeIndex].orderItems[itemIndex] = updateOrderItem;
  }

  return draft;
};

export const discountOrder = (
  draft: Draft<SaleOrderState>,
  payload: { type: DiscountType; discount: CalculateData }
) => {
  const { type, discount } = payload;

  if (!draft.orderTotal) draft.orderTotal = initialOrderState.orderTotal;

  if (!draft.discount) {
    draft.discount = initOrderDiscount;
  }

  // const { total_price } = draft.orderTotal;

  // const discountAmount = calculateDiscountAmount(total_price, discount);

  switch (String(type)) {
    case DiscountType.Manager:
      // draft.discount.discount_manager = discountAmount;
      if (toNumber(discount.value) === 0) {
        draft.discount.discount_manager_info = undefined;
      } else {
        draft.discount.discount_manager_info = discount;
      }
      break;
    case DiscountType.Ticket:
      // draft.discount.discount_ticket = discountAmount;
      if (toNumber(discount.value) === 0) {
        draft.discount.discount_ticket_info = undefined;
      } else {
        draft.discount.discount_ticket_info = discount;
      }
      break;
    // case DiscountType.Employee:
    //   draft.discount.discount_employee = discountAmount;
    //   break;

    case DiscountType.Membership:
      // draft.discount.discount_membership = discountAmount;
      if (toNumber(discount.value) === 0) {
        draft.discount.discount_membership_info = undefined;
      } else {
        draft.discount.discount_membership_info = discount;
      }
      break;

    case DiscountType.Other:
      // draft.discount.discount_other = discountAmount;
      break;
  }

  return draft;
};

export const removeDiscountOrder = (draft: Draft<SaleOrderState>, payload: DiscountType) => {
  const type = payload;

  if (!draft.orderTotal) draft.orderTotal = initialOrderState.orderTotal;

  if (!draft.discount) {
    draft.discount = initOrderDiscount;
  }

  // const { total_price } = draft.orderTotal;

  // const discountAmount = calculateDiscountAmount(total_price, discount);

  switch (String(type)) {
    case DiscountType.Manager:
      // draft.discount.discount_manager = discountAmount;
      draft.discount.discount_manager_info = undefined;
      break;
    case DiscountType.Ticket:
      // draft.discount.discount_ticket = discountAmount;
      draft.discount.discount_ticket_info = undefined;
      break;
    // case DiscountType.Employee:
    //   draft.discount.discount_employee = discountAmount;
    //   break;

    case DiscountType.Membership:
      // draft.discount.discount_membership = discountAmount;
      draft.discount.discount_membership_info = undefined;
      break;

    case DiscountType.Other:
      // draft.discount.discount_other = discountAmount;
      break;
  }

  return draft;
};

export const addPayment = (draft: Draft<SaleOrderState>, payload: PaymentData) => {
  const { payment_type, payment_amount, payment_info } = payload;

  if (!draft.payments) draft.payments = [];

  const index = draft.payments.findIndex((item) => item.payment_type === payment_type);
  if (index < 0) {
    if (payment_amount > 0) {
      draft.payments.push({ payment_type, payment_amount, payment_info });
    }
  } else if (payment_amount > 0) {
    draft.payments[index] = payload;
  } else {
    draft.payments.splice(index, 1);
  }

  return draft;
};

export const addTipEmployee = (draft: Draft<SaleOrderState>, payload: TipData[]) => {
  payload.forEach((tip) => {
    const index = draft.orderEmployees.findIndex((employee) => employee.employee.id === tip.user_id);
    if (index >= 0) {
      draft.orderEmployees[index].tips = tip;
    }
  });

  return draft;
};

export const updateOrderByBooking = (draft: Draft<SaleOrderState>, payload: BookingAppt) => {
  if (payload.booking_appt_service) {
    payload.booking_appt_service.forEach((element) => {
      const orderItem = {
        id: element.service_id,
        type: OrderItemType.Service,
        name: element.service_name,
        taxes: element.taxes,
        price: element.unit_price,
        unit_price: element.unit_price,
      };
      addOrderItem(draft, {
        type: OrderItemType.Service,
        item: orderItem,
      });
    });
    // draft = calcOrderTotal(draft);
  }
  return draft;
};

export const updateOrderByTurn = (draft: Draft<SaleOrderState>, payload: EmployeeTurnSystem) => {
  if (payload.service) {
    const { service } = payload;
    const orderItem = {
      id: service.id,
      type: OrderItemType.Service,
      name: service.service_name,
      taxes: service.taxes,
      price: service.unit_price,
      unit_price: service.unit_price,
    };
    addOrderItem(draft, {
      type: OrderItemType.Service,
      item: orderItem,
    });

    // draft = calcOrderTotal(draft);
  }
  return draft;
};

export const setOrderData = (draft: Draft<SaleOrderState>, payload: SaleOrderData) => {
  draft.id = payload.id;
  draft.order_at = payload.order_at;
  draft.booking = payload.booking;
  draft.customer = payload.customer;
  draft.orderEmployees = payload.orderEmployees;
  draft.discount = payload.discount;
  draft.payments = payload.payments;

  if (payload.orderEmployees && payload.orderEmployees.length > 0) {
    draft.activeEmployeeIndex = 0;
    draft.employee = payload.orderEmployees[0].employee;
  }
  return draft;
};
