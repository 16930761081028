import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { PaymentState, initialPaymentState } from './model';

const payment = produce((draft: Draft<PaymentState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getPaymentList.request): {
      draft.list.loading = true;
      return draft;
    }
    case getType(actions.getPaymentList.success): {
      draft.list.loading = true;
      draft.list.response = action.payload;
      return draft;
    }
    case getType(actions.getPaymentList.failure): {
      draft.list.loading = false;
      draft.list.errors = action.payload;
      return draft;
    }
    case getType(actions.getPaymentStatus.request): {
      draft.status.loading = true;
      draft.status.response = undefined;
      draft.status.errors = undefined;
      return draft;
    }
    case getType(actions.getPaymentStatus.success): {
      draft.status.loading = true;
      draft.status.response = action.payload;
      return draft;
    }
    case getType(actions.getPaymentStatus.failure): {
      draft.status.loading = false;
      draft.status.errors = action.payload;
      return draft;
    }
    case getType(actions.salePayment.request): {
      draft.sale = initialPaymentState.sale;
      return draft;
    }
    case getType(actions.salePayment.success): {
      draft.sale.loading = false;
      draft.sale.response = action.payload;
      return draft;
    }
    case getType(actions.salePayment.failure): {
      draft.sale.loading = false;
      draft.sale.errors = action.payload;
      return draft;
    }
    case getType(actions.refundPayment.request): {
      draft.return.loading = true;
      return draft;
    }
    case getType(actions.refundPayment.success): {
      draft.return.loading = false;
      draft.return.response = action.payload;
      return draft;
    }
    case getType(actions.refundPayment.failure): {
      draft.return.loading = false;
      draft.return.errors = action.payload;
      return draft;
    }
    case getType(actions.voidPayment.request): {
      draft.void.loading = true;
      return draft;
    }
    case getType(actions.voidPayment.success): {
      draft.void.loading = false;
      draft.void.response = action.payload;
      return draft;
    }
    case getType(actions.voidPayment.failure): {
      draft.void.loading = false;
      draft.void.errors = action.payload;
      return draft;
    }
    case getType(actions.tipAdjustPayment.request): {
      draft.tipAdjust.loading = true;
      return draft;
    }
    case getType(actions.tipAdjustPayment.success): {
      draft.tipAdjust.loading = false;
      draft.tipAdjust.response = action.payload;
      return draft;
    }
    case getType(actions.tipAdjustPayment.failure): {
      draft.tipAdjust.loading = false;
      draft.tipAdjust.errors = action.payload;
      return draft;
    }
    case getType(actions.bulkPayments.request): {
      draft.bulk.loading = true;
      return draft;
    }
    case getType(actions.bulkPayments.success): {
      draft.bulk.loading = false;
      draft.bulk.response = action.payload;
      return draft;
    }
    case getType(actions.bulkPayments.failure): {
      draft.bulk.loading = false;
      draft.bulk.errors = action.payload;
      return draft;
    }
    case getType(actions.paymentMomo.request): {
      draft.momo.loading = true;
      return draft;
    }
    case getType(actions.paymentMomo.success): {
      draft.momo.loading = false;
      draft.momo.response = action.payload;
      return draft;
    }
    case getType(actions.paymentMomo.failure): {
      draft.momo.loading = false;
      draft.momo.errors = action.payload;
      return draft;
    }
    case getType(actions.paymentVietQr.request): {
      draft.vietqr.loading = true;
      return draft;
    }
    case getType(actions.paymentVietQr.success): {
      draft.vietqr.loading = false;
      draft.vietqr.response = action.payload;
      return draft;
    }
    case getType(actions.paymentVietQr.failure): {
      draft.vietqr.loading = false;
      draft.vietqr.errors = action.payload;
      return draft;
    }
    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialPaymentState;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialPaymentState);

export default payment;
