import Axios from 'axios';
import { API_TIMEOUT, API_URL } from 'src/config';
import {
  requestAuthHandler,
  requestPaginationHandler,
  requestLoadingHandler,
  responseLoadingHandler,
  errorLoadingHandler,
  successHandler,
  errorHandler,
  // requestConvertFormDataHandler,
} from './axios-interceptors';

declare module 'axios' {
  interface AxiosRequestConfig {
    apiRequestId?: string;
    skipLoadingHandler?: boolean;
  }
}

export const axiosInstance = Axios.create({
  baseURL: API_URL,
  timeout: API_TIMEOUT,
  withCredentials: false,
  headers: {
    //'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
    // 'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export const registerAxiosInterceptors = (store) => {
  axiosInstance.interceptors.request.use((request) => requestLoadingHandler(store)(request));
  axiosInstance.interceptors.request.use((request) => requestAuthHandler(store)(request));
  axiosInstance.interceptors.request.use((request) => requestPaginationHandler(store)(request));
  // axiosInstance.interceptors.request.use((request) => requestConvertFormDataHandler(store)(request));

  axiosInstance.interceptors.response.use(
    (response) => successHandler(store)(response),
    (error) => errorHandler(store)(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => responseLoadingHandler(store)(response),
    (error) => errorLoadingHandler(store)(error)
  );
};
