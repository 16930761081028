export const PAYMENT = {
  List: '/payments',
  Sale: '/payments/sale',
  Status: '/payments/status',
  TipAdjust: '/payments/tipAdjust',
  Void: '/payments/void',
  Refund: '/payments/refund',
  Bulk: '/payments/bulk',
  Momo: '/payments/momo/create',
  MomoStatus: 'payments/momo/{id}/status',
  MomoConfirm: 'payments/momo/{id}/confirm',
  VietQr: '/payments/vietqr/create',
};
