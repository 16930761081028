import { put, call, all, takeLatest } from 'redux-saga/effects';
import { API_SALE_TIMEOUT } from 'src/config';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { convertToFormData } from 'src/utils/converter';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';
import { generateSingleKey } from 'src/utils/axios/axios-fecth';

// Handle request saga
function* getSaleServiceProductSetting(
  action: ReturnType<typeof actions.getSaleServiceProductSetting.request>
): Generator {
  try {
    let key_axios = generateSingleKey(API_ENDPOINTS.SALE.ServiceProductSetting, action.payload);

    let responseData = null;
    let cacheObject = window.localStorage.getItem(key_axios);
    if (cacheObject) {
      responseData = JSON.parse(cacheObject);
    } else {
      const response = yield call(axiosInstance.get, API_ENDPOINTS.SALE.ServiceProductSetting, {
        params: action.payload,
      });

      responseData = (response as any).data;
      if (responseData.success) {
        window.localStorage.setItem(key_axios, JSON.stringify(responseData));
      }
    }

    yield put(actions.getSaleServiceProductSetting.success(responseData));

    const { settings, categories } = responseData.data;
    if (settings) {
      yield put(actions.setSettings(settings));
    }

    if (categories && categories[0]) {
      yield put(actions.setActiveCategory(categories[0]));
    }
  } catch (err) {
    yield put(actions.getSaleServiceProductSetting.failure(err));
  }
}

function* getSaleInit(action: ReturnType<typeof actions.getSaleInit.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.SALE.Request, {
      params: action.payload,
    });

    let responseData = (response as any).data;
    yield put(actions.getSaleInit.success(responseData));

    const { settings, employee, customer, booking, turn } = responseData.data;
    if (settings) {
      yield put(actions.setSettings(settings));
    }
    if (employee) {
      yield put(actions.addEmployee(employee));
    }
    if (customer) {
      yield put(actions.addCustomer(customer));
    }
    if (booking) {
      yield put(actions.addBooking(booking));
    }
    if (turn) {
      yield put(actions.addTurn(turn));
    }
  } catch (err) {
    yield put(actions.getSaleInit.failure(err));
  }
}

function* getGiftcardDetails(action: ReturnType<typeof actions.getGiftcardDetails.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.GIFTCARD.PosDetails.replace('{id}', action.payload));

    yield put(actions.getGiftcardDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getGiftcardDetails.failure(err));
  }
}

function* getGiftcardHistories(action: ReturnType<typeof actions.getGiftcardHistories.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.get,
      API_ENDPOINTS.GIFTCARD.PosHistories.replace('{id}', action.payload.giftcard_id),
      {
        params: action.payload,
      }
    );

    yield put(actions.getGiftcardHistories.success((response as any).data));
  } catch (err) {
    yield put(actions.getGiftcardHistories.failure(err));
  }
}

function* getCheckedInEmployeeList(action: ReturnType<typeof actions.getCheckedInEmployeeList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;

    let key_axios = generateSingleKey(API_ENDPOINTS.EMPLOYEE.CHECKED_IN_LIST, {
      store_id: filter.store_id || '',
      page: pagination.page || '',
      per_page: pagination.per_page || '',
      date_range: filter.date_range || '',
    });

    let responseData = null;

    let cacheObject = window.localStorage.getItem(key_axios);
    if (cacheObject) {
      responseData = JSON.parse(cacheObject);
    } else {
      const response = yield call(axiosInstance.get, API_ENDPOINTS.EMPLOYEE.CHECKED_IN_LIST, {
        params: { sort, ...filter, ...pagination },
      });

      responseData = (response as any).data;
      if (responseData.success) {
        window.localStorage.setItem(key_axios, JSON.stringify(responseData));
      }
    }
    yield put(actions.getCheckedInEmployeeList.success(responseData));
  } catch (err) {
    yield put(actions.getCheckedInEmployeeList.failure(err));
  }
}

function* getEmployeeList(action: ReturnType<typeof actions.getEmployeeList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.EMPLOYEE.LIST, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getEmployeeList.success((response as any).data));
  } catch (err) {
    yield put(actions.getEmployeeList.failure(err));
  }
}

function* createSale(action: ReturnType<typeof actions.createSale.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.SALE.Create, action.payload, {
        timeout: API_SALE_TIMEOUT,
        skipLoadingHandler: true,
      })
    );

    const { data } = response as any;
    if (data.data) {
      yield put(actions.setOrderMetaData(data.data));
    }
    yield put(actions.createSale.success(data));
  } catch (err) {
    yield put(actions.createSale.failure(err));
  }
}

function* getSale(action: ReturnType<typeof actions.getSale.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.get(API_ENDPOINTS.SALE.Get.replace('{id}', action.payload)));

    const { data } = response as any;

    yield put(actions.getSale.success(data));

    if (data.data) {
      yield put(actions.setOrderData(data.data));
    }
  } catch (err) {
    yield put(actions.getSale.failure(err));
  }
}

function* updateSale(action: ReturnType<typeof actions.updateSale.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.SALE.Get.replace('{id}', action.payload.id), action.payload, {
        skipLoadingHandler: true,
      })
    );
    yield put(actions.updateSale.success((response as any).data));
  } catch (err) {
    yield put(actions.updateSale.failure(err));
  }
}

function* getCustomerList(action: ReturnType<typeof actions.getCustomerList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.CUSTOMER.PosActiveList, {
      params: { sort, ...filter, ...pagination },
      skipLoadingHandler: true,
    });

    yield put(actions.getCustomerList.success((response as any).data));
  } catch (err) {
    yield put(actions.getCustomerList.failure(err));
  }
}

function* getCustomerDetails(action: ReturnType<typeof actions.getCustomerDetails.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.get,
      API_ENDPOINTS.CUSTOMER.PostDetails.replace('{id}', (action.payload as any).id),
      {
        params: action.payload,
      }
    );

    yield put(actions.getCustomerDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getCustomerDetails.failure(err));
  }
}

function* createCustomer(action: ReturnType<typeof actions.createCustomer.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.CUSTOMER.PosCreate, action.payload, config));

    yield put(actions.createCustomer.success((response as any).data));
  } catch (err) {
    yield put(actions.createCustomer.failure(err));
  }
}

function* updateCustomer(action: ReturnType<typeof actions.updateCustomer.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const id = action.payload.id;
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.CUSTOMER.PosUpdate.replace('{id}', id), action.payload, config)
    );

    yield put(actions.updateCustomer.success((response as any).data));
  } catch (err) {
    yield put(actions.updateCustomer.failure(err));
  }
}

function* clearData(action: ReturnType<typeof actions.clearData>): Generator {
  yield put(actions.clearOrderData({ clearAll: true }));
  yield put(actions.clearSaleData());
  yield put(actions.clearGiftcard());
  yield put(actions.clearCustomer());
}

// Main saga
export default function* saleSaga() {
  yield all([
    takeLatest(actions.getSaleServiceProductSetting.request, getSaleServiceProductSetting),
    takeLatest(actions.getSaleInit.request, getSaleInit),
    takeLatest(actions.getSale.request, getSale),
    takeLatest(actions.createSale.request, createSale),
    takeLatest(actions.updateSale.request, updateSale),

    takeLatest(actions.getGiftcardDetails.request, getGiftcardDetails),
    takeLatest(actions.getGiftcardHistories.request, getGiftcardHistories),

    takeLatest(actions.getCheckedInEmployeeList.request, getCheckedInEmployeeList),
    takeLatest(actions.getEmployeeList.request, getEmployeeList),

    takeLatest(actions.getCustomerList.request, getCustomerList),

    takeLatest(actions.getCustomerDetails.request, getCustomerDetails),
    takeLatest(actions.createCustomer.request, createCustomer),
    takeLatest(actions.updateCustomer.request, updateCustomer),
    takeLatest(actions.clearData, clearData),
  ]);
}
