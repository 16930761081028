import produce, { Draft } from 'immer';
import { mergeSaleTickets } from 'src/utils/sale';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { initialWaitingSaleState, WaitingSaleState } from './model';

const waitingSale = produce((draft: Draft<WaitingSaleState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getWaitingSaleList.request): {
      return draft;
    }
    case getType(actions.getWaitingSaleList.success): {
      draft = action.payload;
      return draft;
    }
    case getType(actions.getWaitingSaleList.failure): {
      return draft;
    }
    case getType(actions.saveWaitingSale): {
      const index = draft.findIndex((item) => item.id === action.payload.ticket.id);
      if (index >= 0) {
        draft[index] = action.payload.ticket;
      } else {
        draft.push(action.payload.ticket);
      }
      return draft;
    }
    case getType(actions.deleteWaitingSale): {
      const index = draft.findIndex((item) => item.id === action.payload.ticket_id);
      if (index >= 0) {
        draft.splice(index, 1);
      }
      return draft;
    }
    case getType(actions.mergeWaitingSale): {
      draft = mergeSaleTickets(draft, action.payload.ticket_ids);
      return draft;
    }

    case getType(actions.clearData): {
      draft = [];
      return draft;
    }

    default:
      return draft;
  }
}, initialWaitingSaleState);

export default waitingSale;
