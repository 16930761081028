import { PAGE_PATH } from './route-path';

export type NavigationItem = {
  name: string;
  module: string;
  screen: string;
  url: string;
  icon: string;
  class?: string;
  badge?: {
    variant: string;
    text: string;
  };
  children?: NavigationItem[];
};

// eslint-disable-next-line no-underscore-dangle
const navigation: { items: NavigationItem[] } = {
  items: [
    {
      name: 'dashboard',
      module: 'dashboard',
      screen: 'dashboard',
      url: PAGE_PATH.Dashboard,
      icon: 'cil-view-module font-3xl',
      badge: {
        variant: 'info',
        text: 'Dashboard',
      },
    },
    {
      name: 'employeeCheckIn',
      module: 'sale',
      screen: 'sale',
      url: PAGE_PATH.EmployeeCheckIn,
      icon: 'cil-employee font-3xl',
      badge: {
        variant: 'info',
        text: 'Employees',
      },
    },
    {
      name: 'quickSale',
      module: 'sale',
      screen: 'sale',
      url: PAGE_PATH.EmployeeSales,
      icon: 'cil-pos font-3xl',
      badge: {
        variant: 'info',
        text: 'POS',
      },
    },
    {
      name: 'customerCheckIn',
      module: 'walkin',
      screen: 'walkin',
      url: PAGE_PATH.Walkins,
      icon: 'cil-check-in font-3xl',
      badge: {
        variant: 'info',
        text: 'Check-In',
      },
    },
    {
      name: 'turnSystem',
      module: 'turn_system',
      screen: 'turn_system',
      url: PAGE_PATH.TurnSystem,
      icon: 'cil-aperture font-3xl',
      badge: {
        variant: 'info',
        text: 'Turn System',
      },
    },
    {
      name: 'schedule',
      module: 'schedule',
      screen: 'schedule',
      url: PAGE_PATH.Schedule,
      icon: 'cil-calendar font-3xl',
      badge: {
        variant: 'info',
        text: 'Schedules',
      },
    },
    {
      name: 'report',
      module: 'report',
      screen: 'report',
      url: PAGE_PATH.SummaryReport,
      icon: 'cil-money font-3xl',
      class: 'nav-report',
      children: [
        {
          name: 'dailyReport',
          module: 'report',
          screen: 'daily_report',
          url: PAGE_PATH.DailyReport,
          icon: 'cil-calendar font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'summaryReport',
          module: 'report',
          screen: 'summary_report',
          url: PAGE_PATH.SummaryReport,
          icon: 'cil-diamond font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'storeReport',
          module: 'report',
          screen: 'store_report',
          url: PAGE_PATH.StoreReport,
          icon: 'go-to-store font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        // {
        //   name: 'Payment Analysis',
        //   url: PAGE_PATH.AnalysisPayment,
        //   icon: 'cib-cc-visa font-1xl',
        //   badge: {
        //     variant: 'info',
        //     text: '',
        //   },
        // },
        {
          name: 'employeeIncomeReport',
          module: 'report',
          screen: 'employee_income_report',
          url: PAGE_PATH.IncomeReport,
          icon: 'cil-income font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'employeeIncomeHrReport',
          module: 'report',
          screen: 'employee_income_hr_report',
          url: PAGE_PATH.IncomeWorkingTimeReport,
          icon: 'cil-briefcase-time font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'customerReport',
          module: 'report',
          screen: 'customer_report',
          url: PAGE_PATH.CustomerReport,
          icon: 'cil-people font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'giftcardReport',
          module: 'report',
          screen: 'giftcard_report',
          url: PAGE_PATH.GiftcardReport,
          icon: 'cil-gift font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'employeeCheckInoutReport',
          module: 'report',
          screen: 'employee_check_inout_report',
          url: PAGE_PATH.EmployeeCheckInOutReport,
          icon: 'cil-alarm font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'payrollReport',
          module: 'report',
          screen: 'payroll_report',
          url: PAGE_PATH.PayrollReport,
          icon: 'cil-management font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'analysisItem',
          module: 'report',
          screen: 'service_product_report',
          url: PAGE_PATH.ServiceProductReport,
          icon: 'cil-view-module font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        // {
        //   name: 'Customer Analysis',
        //   url: PAGE_PATH.AnalysisCustomer,
        //   icon: 'cil-bar-chart font-3xl',
        //   badge: {
        //     variant: 'info',
        //     text: '',
        //   },
        // },
      ],
      badge: {
        variant: 'info',
        text: 'Reports',
      },
    },
    {
      name: 'emarketing',
      module: 'emarketing',
      screen: 'emarketing',
      url: PAGE_PATH.EmarketingCampaign,
      icon: 'cil-at font-3xl',
      class: 'nav-emarketing',
      children: [
        {
          name: 'emarketingCampaign',
          module: 'emarketing',
          screen: 'emarketing_campain',
          url: PAGE_PATH.EmarketingCampaign,
          icon: 'cil-alarm font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'emarketingTemplate',
          module: 'emarketing',
          screen: 'emarketing_template',
          url: PAGE_PATH.EmarketingTemplate,
          icon: 'cil-browser font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'emarketingReport',
          module: 'emarketing',
          screen: 'emarketing_report',
          url: PAGE_PATH.EmarketingSentSummary,
          icon: 'cil-envelope-letter font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
      ],
      badge: {
        variant: 'info',
        text: 'eMarketing',
      },
    },
    {
      name: 'sms',
      module: 'sms',
      screen: 'sms',
      url: PAGE_PATH.SmsReminder,
      icon: 'cil-comment-bubble font-3xl',
      class: 'nav-sms',
      children: [
        {
          name: 'smsReminder',
          module: 'sms',
          screen: 'sms_reminder',
          url: PAGE_PATH.SmsReminder,
          icon: 'cil-alarm font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'smsTemplate',
          module: 'sms',
          screen: 'sms_template',
          url: PAGE_PATH.SmsTemplate,
          icon: 'cil-browser font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'smsReport',
          module: 'sms',
          screen: 'sms_report',
          url: PAGE_PATH.SmsReportSummary,
          icon: 'cil-chat-bubble font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
      ],
      badge: {
        variant: 'info',
        text: 'SMS',
      },
    },
    {
      name: 'backOffice',
      module: 'back_office',
      screen: 'back_office',
      url: '/admin',
      icon: 'cil-back-end font-3xl',
      class: 'nav-backend',
      children: [
        {
          name: 'member',
          module: 'member',
          screen: 'member',
          url: PAGE_PATH.Members,
          icon: 'cil-people font-1xl',
          class: 'be-member',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'category',
          module: 'category',
          screen: 'category',
          url: PAGE_PATH.CategoryList,
          icon: 'cil-list-rich font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'service',
          module: 'service',
          screen: 'service',
          url: PAGE_PATH.ServiceList,
          icon: 'cil-view-module font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'product',
          module: 'product',
          screen: 'product',
          url: PAGE_PATH.ProductList,
          icon: 'cil-view-module font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },

        {
          name: 'package',
          module: 'package',
          screen: 'package',
          url: PAGE_PATH.PackageList,
          icon: 'cil-3d font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'supplier',
          module: 'supplier',
          screen: 'supplier',
          url: PAGE_PATH.Suppliers,
          icon: 'cil-home font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'unit',
          module: 'unit',
          screen: 'unit',
          url: PAGE_PATH.UnitsList,
          icon: 'cil-menu font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'expense',
          module: 'expense',
          screen: 'expense',
          url: PAGE_PATH.ExpenseList,
          icon: 'cil-money font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
      ],
      badge: {
        variant: 'info',
        text: 'CMS',
      },
    },
    {
      name: 'customer',
      module: 'customer',
      screen: 'customer',
      url: PAGE_PATH.CustomerList,
      icon: 'cil-people font-3xl',
      class: 'nav-customer',
      children: [
        {
          name: 'customer',
          module: 'customer',
          screen: 'customer',
          url: PAGE_PATH.CustomerList,
          icon: 'cil-people font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'customerGroup',
          module: 'customer_group',
          screen: 'customer_group',
          url: PAGE_PATH.CustomerGroup,
          icon: 'cil-people font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'membership',
          module: 'membership',
          screen: 'membership',
          url: PAGE_PATH.Memberships,
          icon: 'cil-heart font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
      ],
      badge: {
        variant: 'info',
        text: 'Customers',
      },
    },
    {
      name: 'voucher',
      module: 'voucher',
      screen: 'voucher',
      url: PAGE_PATH.Giftcardlist,
      icon: 'cil-gift font-3xl',
      class: 'nav-giftcard',
      children: [
        {
          name: 'giftcard',
          module: 'giftcard',
          screen: 'giftcard',
          url: PAGE_PATH.Giftcardlist,
          icon: 'cil-gift font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
        {
          name: 'coupon',
          module: 'coupon',
          screen: 'coupon',
          url: PAGE_PATH.Couponlist,
          icon: 'cil-spa font-1xl',
          badge: {
            variant: 'info',
            text: '',
          },
        },
      ],
      badge: {
        variant: 'info',
        text: 'Vouchers',
      },
    },
    {
      name: 'setting',
      module: 'setting',
      screen: 'setting',
      url: PAGE_PATH.Settings,
      icon: 'cil-settings font-3xl',
      class: 'nav-setting',
      badge: {
        variant: 'info',
        text: 'Settings',
      },
    },
  ],
};

export default navigation;
