import { BaseState } from '../models';
import {
  ProductBulkResponse,
  ProductCreateResponse,
  ProductDeleteResponse,
  ProductDetailResponse,
  ProductListResponse,
  ProductUpdateResponse,
  ProductImportResponse,
  InventoryCreateResponse,
  InventoryResponse,
} from '../../api-models/product';

export type ProductDetails = BaseState<ProductDetailResponse>;
export type ProductList = BaseState<ProductListResponse>;
export type ProductCreate = BaseState<ProductCreateResponse>;
export type ProductUpdate = BaseState<ProductUpdateResponse>;
export type ProductDelete = BaseState<ProductDeleteResponse>;
export type ProductBulk = BaseState<ProductBulkResponse>;
export type ProductImport = BaseState<ProductImportResponse>;
export type InventoryList = BaseState<InventoryResponse>;
export type InventoryCreate = BaseState<InventoryCreateResponse>;

export type ProductState = {
  list: ProductList;
  details: ProductDetails;
  create: ProductCreate;
  update: ProductUpdate;
  delete: ProductDelete;
  bulk: ProductBulk;
  import: ProductImport;
  inventoryList: InventoryList;
  inventoryCreate: InventoryCreate;
  ordering: ProductUpdate;
};

export const initialProductState = {
  list: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
  import: { loading: false, response: undefined },
  inventoryList: { loading: false, response: undefined },
  inventoryCreate: { loading: false, response: undefined },
  ordering: { loading: false, response: undefined },
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    // match to reducer name, each reducer will be one field in state
    product: ProductState;
  }
}
