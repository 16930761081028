import produce, { Draft } from 'immer';
import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import {
  InitialGeneralSettingsState,
  initialSettingState,
  InitialStoreSettingsState,
  InitialSettingDataState,
} from './model';

const data = produce((draft: Draft<InitialSettingDataState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.setInitialStoreSettingData): {
      const { storeId, data } = action.payload;
      draft[storeId] = data;
      return draft;
    }
    case getType(actions.setInitialGeneralSettingData): {
      draft.general = action.payload;
      return draft;
    }
    case getType(actions.clearSettingData): {
      const { storeId } = action.payload;

      if (draft[storeId] && draft[storeId]) {
        draft[storeId] = {};
      }
      return draft;
    }
    case getType(actions.clearData): {
      draft = {};

      return draft;
    }
    default:
      return draft;
  }
}, initialSettingState.data);

const generalSetting = produce((draft: Draft<InitialGeneralSettingsState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getInitialGeneralSettings.request): {
      draft.loading = true;
      return draft;
    }
    case getType(actions.getInitialGeneralSettings.success): {
      draft.loading = false;
      draft.response = action.payload;
      return draft;
    }
    case getType(actions.getInitialGeneralSettings.failure): {
      draft.loading = false;
      draft.errors = action.payload;
      return draft;
    }
    default:
      return draft;
  }
}, initialSettingState.generalSetting);

const storeSetting = produce((draft: Draft<InitialStoreSettingsState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getInitialStoreSettings.request): {
      draft.loading = true;
      return draft;
    }
    case getType(actions.getInitialStoreSettings.success): {
      draft.loading = false;
      draft.response = action.payload;
      return draft;
    }
    case getType(actions.getInitialStoreSettings.failure): {
      draft.loading = false;
      draft.errors = action.payload;
      return draft;
    }
    default:
      return draft;
  }
}, initialSettingState.storeSetting);

export default combineReducers({
  generalSetting,
  storeSetting,
  data,
});
