export const FETCH_DETAILS = 'units/details';
export const FETCH_DETAILS_SUCCESS = 'units/details_success';
export const FETCH_DETAILS_FAILED = 'units/details_failed';

export const FETCH_LIST = 'units/list';
export const FETCH_LIST_SUCCESS = 'units/list_success';
export const FETCH_LIST_FAILED = 'units/list_failed';

export const CREATE = 'units/create';
export const CREATE_SUCCESS = 'units/create_success';
export const CREATE_FAILED = 'units/create_failed';

export const UPDATE = 'units/update';
export const UPDATE_SUCCESS = 'units/update_success';
export const UPDATE_FAILED = 'units/update_failed';

export const DELETE = 'units/detele';
export const DELETE_SUCCESS = 'units/detele_success';
export const DELETE_FAILED = 'units/detele_failed';

export const BULK = 'units/bulk';
export const BULK_SUCCESS = 'units/bulk_success';
export const BULK_FAILED = 'units/bulk_failed';

export const GET_GIFTCARD_NUMBER = 'units/get_unit_type_number';
export const GET_GIFTCARD_NUMBER_SUCCESS = 'units/get_unit_type_number_success';
export const GET_GIFTCARD_NUMBER_FAILED = 'units/get_unit_type_number_failed';

export const CLEAR_DATA = 'units/clear_data';

export type UnitsAction =
  | typeof FETCH_DETAILS
  | typeof FETCH_DETAILS_FAILED
  | typeof FETCH_DETAILS_SUCCESS
  | typeof FETCH_LIST
  | typeof FETCH_LIST_FAILED
  | typeof FETCH_LIST_SUCCESS
  | typeof CREATE
  | typeof CREATE_FAILED
  | typeof CREATE_SUCCESS
  | typeof UPDATE
  | typeof UPDATE_FAILED
  | typeof UPDATE_SUCCESS
  | typeof DELETE
  | typeof DELETE_FAILED
  | typeof DELETE_SUCCESS
  | typeof BULK
  | typeof BULK_FAILED
  | typeof BULK_SUCCESS
  | typeof CLEAR_DATA;
