import { SaleOrderData } from 'src/state/models/order';
import { mergeSaleTickets } from 'src/utils/sale';

const KEY = 'waiting_sale_tickets__{store_id}__{workstation_id}';

export const getStorageKey = (store_id, workstation_id) => {
  return KEY.replace('{store_id}', store_id || 'none').replace('{workstation_id}', workstation_id || 'none');
};

export const getWaitingSaleTickets = (store_id, workstationId): SaleOrderData[] => {
  const savedData = localStorage.getItem(getStorageKey(store_id, workstationId));
  let savedDataObj: Array<any> = [];
  if (savedData) {
    savedDataObj = JSON.parse(String(savedData));
  }
  return savedDataObj || [];
};

export const saveWaitingSale = (ticket: SaleOrderData, store_id, workstationId) => {
  const savedDataObj = getWaitingSaleTickets(store_id, workstationId);
  const index = savedDataObj.findIndex((item) => item.id === ticket.id);
  if (index >= 0) {
    savedDataObj[index] = ticket;
  } else {
    savedDataObj.push(ticket);
  }
  localStorage.setItem(getStorageKey(store_id, workstationId), JSON.stringify(savedDataObj));
};

export const delteWaitingSale = (ticketID: string, store_id, workstationId) => {
  const savedDataObj = getWaitingSaleTickets(store_id, workstationId);
  const index = savedDataObj.findIndex((item) => item.id === ticketID);
  if (index >= 0) {
    savedDataObj.splice(index, 1);
  }
  localStorage.setItem(getStorageKey(store_id, workstationId), JSON.stringify(savedDataObj));
};

export const mergeWaitingSale = (mergedticketId: string[], store_id, workstationId) => {
  const savedDataObj = getWaitingSaleTickets(store_id, workstationId);
  const meredData = mergeSaleTickets(savedDataObj, mergedticketId);
  localStorage.setItem(getStorageKey(store_id, workstationId), JSON.stringify(meredData));
};
