export const GET_SALE_SERVICE_PRODUCT_SETTING = 'sale/get_sale_service_product_setting';
export const GET_SALE_SERVICE_PRODUCT_SETTING_SUCCESS = 'sale/get_sale_service_product_setting_success';
export const GET_SALE_SERVICE_PRODUCT_SETTING_FAILED = 'sale/get_sale_service_product_setting_failed';

export const FETCH_INIT_DATA = 'sale/init_data';
export const FETCH_INIT_DATA_SUCCESS = 'sale/init_data_success';
export const FETCH_INIT_DATA_FAILED = 'sale/init_data_failed';

export const GET_ORDER = 'sale/get';
export const GET_ORDER_SUCCESS = 'sale/get_success';
export const GET_ORDER_FAILED = 'sale/get_failed';
export const CLEAR_GET_ORDER_DATA = 'sale/get_clear';

export const CREATE_ORDER = 'sale/create';
export const CREATE_ORDER_SUCCESS = 'sale/create_success';
export const CREATE_ORDER_FAILED = 'sale/create_failed';
export const CLEAR_CREATE_ORDER_DATA = 'sale/create_clear';

export const UPDATE_ORDER = 'sale/update';
export const UPDATE_ORDER_SUCCESS = 'sale/update_success';
export const UPDATE_ORDER_FAILED = 'sale/update_failed';
export const CLEAR_UPDATE_ORDER_DATA = 'sale/update_clear';

export const FETCH_GIFTCARD_DETAILS = 'sale/giftcard_details';
export const FETCH_GIFTCARD_DETAILS_SUCCESS = 'sale/giftcard_details_success';
export const FETCH_GIFTCARD_DETAILS_FAILED = 'sale/giftcard_details_failed';
export const CLEAR_GIFTCARD = 'sale/giftcard_clear';

export const FETCH_GIFTCARD_HISTORIES = 'sale/giftcard_histories';
export const FETCH_GIFTCARD_HISTORIES_SUCCESS = 'sale/giftcard_histories_success';
export const FETCH_GIFTCARD_HISTORIES_FAILED = 'sale/giftcard_histories_failed';

export const CHECKED_IN_EMPLOYEE_LIST = 'sale/checked_in_employee_list';
export const CHECKED_IN_EMPLOYEE_LIST_SUCCESS = 'sale/checked_in_employee_list_success';
export const CHECKED_IN_EMPLOYEE_LIST_FAILED = 'sale/checked_in_employee_list_failed';

export const FETCH_EMPLOYEE_LIST = 'sale/employee_list';
export const FETCH_EMPLOYEE_LIST_SUCCESS = 'sale/employee_list_success';
export const FETCH_EMPLOYEE_LIST_FAILED = 'sale/employee_list_failed';

export const FETCH_CUSTOMER_LIST = 'sale/customer_list';
export const FETCH_CUSTOMER_LIST_SUCCESS = 'sale/customer_list_success';
export const FETCH_CUSTOMER_LIST_FAILED = 'sale/customer_list_failed';

export const CREATE_CUSTOMER = 'sale/create_customer';
export const CREATE_CUSTOMER_SUCCESS = 'sale/create_customer_success';
export const CREATE_CUSTOMER_FAILED = 'sale/create_customer_failed';

export const UPDATE_CUSTOMER = 'sale/update_customer';
export const UPDATE_CUSTOMER_SUCCESS = 'sale/update_customer_success';
export const UPDATE_CUSTOMER_FAILED = 'sale/update_customer_failed';

export const DETAIL_CUSTOMER = 'sale/detail_customer';
export const DETAIL_CUSTOMER_SUCCESS = 'sale/detail_customer_success';
export const DETAIL_CUSTOMER_FAILED = 'sale/detail_customer_failed';

export const CLEAR_CUSTOMER = 'sale/customer_clear';

export const SET_SETTINGS = 'sale/set_settings';
export const SET_ACTIVE_CATEGORY = 'sale/set_active_category';
export const ADD_EMPLOYEE = 'sale/add_employee';
export const CHANGE_EMPLOYEE = 'sale/change_employee';
export const SELECT_ORDER_ITEM = 'sale/select_order_item';
export const ADD_ORDER_ITEM = 'sale/add_order_item';
export const DELETE_ORDER_ITEM = 'sale/delete_order_item';
export const UPDATE_ORDER_ITEM = 'sale/update_order_item';
export const UPDATE_QTY_ORDER_ITEM = 'sale/update_qty_order_item';

export const ADD_TIP = 'sale/add_employee_tip';
export const DISCOUNT_ORDER = 'sale/discount_order';
export const REMOVE_DISCOUNT_ORDER = 'sale/remove_discount_order';
export const DISCOUNT_ORDER_ITEM = 'sale/discount_order_item';

export const ADD_PAYMENT = 'sale/add_payment';

export const ADD_CUSTOMER = 'sale/add_customer';

export const ADD_BOOKING = 'sale/add_booking';

export const ADD_TURN = 'sale/add_turn';

export const SET_ORDER_META_DATA = 'sale/set_order_meta_data';
export const SET_ORDER_DATA = 'sale/set_order_data';
export const CLEAR_ORDER_DATA = 'sale/clear_order_data';
export const CLEAR_DATA = 'sale/clear_data';

export type SaleAction =
  | typeof FETCH_INIT_DATA
  | typeof FETCH_INIT_DATA_FAILED
  | typeof FETCH_INIT_DATA_SUCCESS
  | typeof SET_ACTIVE_CATEGORY
  | typeof ADD_EMPLOYEE
  | typeof SELECT_ORDER_ITEM
  | typeof ADD_ORDER_ITEM
  | typeof DELETE_ORDER_ITEM
  | typeof UPDATE_ORDER_ITEM
  | typeof UPDATE_QTY_ORDER_ITEM
  | typeof DISCOUNT_ORDER_ITEM
  | typeof REMOVE_DISCOUNT_ORDER
  | typeof ADD_TIP
  | typeof ADD_PAYMENT
  | typeof ADD_CUSTOMER
  | typeof SET_ORDER_META_DATA
  | typeof SET_ORDER_DATA
  | typeof CLEAR_ORDER_DATA
  | typeof FETCH_GIFTCARD_DETAILS
  | typeof FETCH_GIFTCARD_DETAILS_FAILED
  | typeof FETCH_GIFTCARD_DETAILS_SUCCESS
  | typeof FETCH_GIFTCARD_HISTORIES
  | typeof FETCH_GIFTCARD_HISTORIES_FAILED
  | typeof FETCH_GIFTCARD_HISTORIES_SUCCESS
  | typeof FETCH_CUSTOMER_LIST
  | typeof FETCH_CUSTOMER_LIST_FAILED
  | typeof FETCH_CUSTOMER_LIST_SUCCESS
  | typeof CREATE_CUSTOMER
  | typeof CREATE_CUSTOMER_FAILED
  | typeof CREATE_CUSTOMER_SUCCESS
  | typeof CLEAR_DATA;
