export const PAYROLL_LIST = 'payroll/sget_payroll_list';
export const PAYROLL_LIST_SUCCESS = 'payroll/sget_payroll_list_success';
export const PAYROLL_LIST_FAILED = 'payroll/sget_payroll_list_failed';


export const PAYROLL_DETAIL = 'payroll/get_payroll_detail';
export const PAYROLL_DETAIL_SUCCESS = 'payroll/get_payroll_detail_success';
export const PAYROLL_DETAIL_FAILED = 'payroll/get_payroll_detail_failed';

export const CREATE_PAYROLL = 'payroll/create_payroll';
export const CREATE_PAYROLL_SUCCESS = 'payroll/create_payroll_success';
export const CREATE_PAYROLL_FAILED = 'payroll/create_payroll_failed';

export const UPDATE_PAYROLL = 'payroll/update_payroll';
export const UPDATE_PAYROLL_SUCCESS = 'payroll/update_payroll_success';
export const UPDATE_PAYROLL_FAILED = 'payroll/update_payroll_failed';

export const DELTE_PAYROLL = 'payroll/delete_payroll';
export const DELTE_PAYROLL_SUCCESS = 'payroll/delete_payroll_success';
export const DELTE_PAYROLL_FAILED = 'payroll/delete_payroll_failed';

export const BULK_PAYROLL = 'payroll/bulk_payroll';
export const BULK_PAYROLL_SUCCESS = 'payroll/bulk_payroll_success';
export const BULK_PAYROLL_FAILED = 'payroll/bulk_payroll_failed';

export const CALCULATE_PAYOLL = 'payroll/calculate_payoll';
export const CALCULATE_PAYOLL_SUCCESS = 'payroll/calculate_payoll_success';
export const CALCULATE_PAYOLL_FAILED = 'payroll/calculate_payoll_failed';

export const FETCH_PAYROLL_COMMISSION = 'payroll/report_payroll';
export const FETCH_PAYROLL_COMMISSION_SUCCESS = 'payroll/report_payroll_success';
export const FETCH_PAYROLL_COMMISSION_FAILED = 'payroll/report_payroll_failed';

export const FETCH_PAYROLL_COMMISSION_SUMMARY = 'payroll/report_payroll_summary';
export const FETCH_PAYROLL_COMMISSION_SUMMARY_SUCCESS = 'payroll/report_payroll_summary_success';
export const FETCH_PAYROLL_COMMISSION_SUMMARY_FAILED = 'payroll/report_payroll_summary_failed';

export const FETCH_PAYROLL_COMMISSION_DETAILS = 'payroll/report_payroll_details';
export const FETCH_PAYROLL_COMMISSION_DETAILS_SUCCESS = 'payroll/report_payroll_details_success';
export const FETCH_PAYROLL_COMMISSION_DETAILS_FAILED = 'payroll/report_payroll_details_failed';

export const CLEAR_DATA = 'payroll/clear_data';

export type Actions =
  | typeof PAYROLL_DETAIL
  | typeof PAYROLL_DETAIL_FAILED
  | typeof PAYROLL_DETAIL_SUCCESS
  | typeof DELTE_PAYROLL
  | typeof DELTE_PAYROLL_SUCCESS
  | typeof DELTE_PAYROLL_FAILED
  | typeof UPDATE_PAYROLL
  | typeof UPDATE_PAYROLL_SUCCESS
  | typeof UPDATE_PAYROLL_FAILED
  | typeof CREATE_PAYROLL
  | typeof CREATE_PAYROLL_SUCCESS
  | typeof CREATE_PAYROLL_FAILED
  | typeof CALCULATE_PAYOLL
  | typeof CALCULATE_PAYOLL_SUCCESS
  | typeof CALCULATE_PAYOLL_FAILED
  | typeof FETCH_PAYROLL_COMMISSION
  | typeof FETCH_PAYROLL_COMMISSION_SUCCESS
  | typeof FETCH_PAYROLL_COMMISSION_FAILED
  | typeof FETCH_PAYROLL_COMMISSION_SUMMARY
  | typeof FETCH_PAYROLL_COMMISSION_SUMMARY_FAILED
  | typeof FETCH_PAYROLL_COMMISSION_SUMMARY_SUCCESS
  | typeof FETCH_PAYROLL_COMMISSION_DETAILS
  | typeof FETCH_PAYROLL_COMMISSION_DETAILS_FAILED
  | typeof FETCH_PAYROLL_COMMISSION_DETAILS_SUCCESS
  | typeof CLEAR_DATA;
