import { put, call, all, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';
import { EXPENSE } from '../../../config/api-endpoints/expense';

// Handle request saga
function* getExpenseList(action: ReturnType<typeof actions.getExpenseList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, EXPENSE.List, {
      params: action.payload,
    });

    yield put(actions.getExpenseList.success((response as any).data));
  } catch (err) {
    yield put(actions.getExpenseList.failure(err));
  }
}

function* createExpense(action: ReturnType<typeof actions.createExpense.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.post(EXPENSE.Create, action.payload));

    yield put(actions.createExpense.success((response as any).data));
  } catch (err) {
    yield put(actions.createExpense.failure(err));
  }
}

function* getExpenseDetails(action: ReturnType<typeof actions.getExpenseDetails.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, EXPENSE.Detail.replace('{id}', action.payload));

    yield put(actions.getExpenseDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getExpenseDetails.failure(err));
  }
}

function* updateExpense(action: ReturnType<typeof actions.updateExpense.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.put(EXPENSE.Update.replace('{id}', action.payload.id), action.payload)
    );

    yield put(actions.updateExpense.success((response as any).data));
  } catch (err) {
    yield put(actions.updateExpense.failure(err));
  }
}

function* deleteExpense(action: ReturnType<typeof actions.deleteExpense.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.delete(EXPENSE.Delete.replace('{id}', action.payload)));

    yield put(actions.deleteExpense.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteExpense.failure(err));
  }
}

function* bulkExpense(action: ReturnType<typeof actions.bulkExpense.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, EXPENSE.Bulk, action.payload);

    yield put(actions.bulkExpense.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkExpense.failure(err));
  }
}

// Main saga
export default function* expenseSaga() {
  yield all([
    takeLatest(actions.getExpenseList.request, getExpenseList),
    takeLatest(actions.createExpense.request, createExpense),
    takeLatest(actions.getExpenseDetails.request, getExpenseDetails),
    takeLatest(actions.updateExpense.request, updateExpense),
    takeLatest(actions.deleteExpense.request, deleteExpense),
    takeLatest(actions.bulkExpense.request, bulkExpense),
  ]);
}
