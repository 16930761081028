import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { initialSaleState, SaleMgntStateAll } from './model';

const saleMgnt = produce((draft: Draft<SaleMgntStateAll>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getSaleDetail.request): {
      draft.details.loading = true;
      return draft;
    }
    case getType(actions.getSaleDetail.success): {
      draft.details.loading = true;
      draft.details.response = action.payload;
      return draft;
    }
    case getType(actions.getSaleDetail.failure): {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    case getType(actions.getSaleInvoice.request): {
      draft.invoice.loading = true;
      return draft;
    }
    case getType(actions.getSaleInvoice.success): {
      draft.invoice.loading = true;
      draft.invoice.response = action.payload;
      return draft;
    }
    case getType(actions.getSaleInvoice.failure): {
      draft.invoice.loading = false;
      draft.invoice.errors = action.payload;
      return draft;
    }
    case getType(actions.updateSaleStatus.request): {
      draft.status.loading = true;
      return draft;
    }
    case getType(actions.updateSaleStatus.success): {
      draft.status.loading = true;
      draft.status.response = action.payload;
      return draft;
    }
    case getType(actions.updateSaleStatus.failure): {
      draft.status.loading = false;
      draft.status.errors = action.payload;
      return draft;
    }

    case getType(actions.updateSaleTip.request): {
      draft.tip.loading = true;
      draft.tip.response = undefined;
      draft.tip.errors = undefined;
      return draft;
    }
    case getType(actions.updateSaleTip.success): {
      draft.tip.loading = true;
      draft.tip.response = action.payload;

      return draft;
    }
    case getType(actions.updateSaleTip.failure): {
      draft.tip.loading = false;
      draft.tip.errors = action.payload;
      return draft;
    }
    case getType(actions.changeEmployee.request): {
      draft.changeEmployee.loading = true;
      draft.changeEmployee.response = undefined;
      draft.changeEmployee.errors = undefined;
      return draft;
    }
    case getType(actions.changeEmployee.success): {
      draft.changeEmployee.loading = true;
      draft.changeEmployee.response = action.payload;

      return draft;
    }
    case getType(actions.changeEmployee.failure): {
      draft.changeEmployee.loading = false;
      draft.changeEmployee.errors = action.payload;
      return draft;
    }
    case getType(actions.changeOrderAt.request): {
      draft.changeOrderAt.loading = true;
      draft.changeOrderAt.response = undefined;
      draft.changeOrderAt.errors = undefined;
      return draft;
    }
    case getType(actions.changeOrderAt.success): {
      draft.changeOrderAt.loading = true;
      draft.changeOrderAt.response = action.payload;

      return draft;
    }
    case getType(actions.changeOrderAt.failure): {
      draft.changeOrderAt.loading = false;
      draft.changeOrderAt.errors = action.payload;
      return draft;
    }
    case getType(actions.getSaleRefund.request): {
      draft.refundDetail.loading = true;
      draft.refundDetail.response = undefined;
      draft.refundDetail.errors = undefined;
      return draft;
    }
    case getType(actions.getSaleRefund.success): {
      draft.refundDetail.loading = true;
      draft.refundDetail.response = action.payload;

      return draft;
    }
    case getType(actions.getSaleRefund.failure): {
      draft.refundDetail.loading = false;
      draft.refundDetail.errors = action.payload;
      return draft;
    }
    case getType(actions.refundSale.request): {
      draft.refund.loading = true;
      draft.refund.response = undefined;
      draft.refund.errors = undefined;
      return draft;
    }
    case getType(actions.refundSale.success): {
      draft.refund.loading = true;
      draft.refund.response = action.payload;

      return draft;
    }
    case getType(actions.refundSale.failure): {
      draft.refund.loading = false;
      draft.refund.errors = action.payload;
      return draft;
    }
    case getType(actions.voidSale.request): {
      draft.void.loading = true;
      draft.void.response = undefined;
      draft.void.errors = undefined;
      return draft;
    }
    case getType(actions.voidSale.success): {
      draft.void.loading = true;
      draft.void.response = action.payload;

      return draft;
    }
    case getType(actions.voidSale.failure): {
      draft.void.loading = false;
      draft.void.errors = action.payload;
      return draft;
    }
    case getType(actions.deleteSale.request): {
      draft.delete.loading = true;
      draft.delete.response = undefined;
      draft.delete.errors = undefined;
      return draft;
    }
    case getType(actions.deleteSale.success): {
      draft.delete.loading = true;
      draft.delete.response = action.payload;

      return draft;
    }
    case getType(actions.deleteSale.failure): {
      draft.delete.loading = false;
      draft.delete.errors = action.payload;
      return draft;
    }
    case getType(actions.bulkSale.request): {
      draft.bulk.loading = true;
      draft.bulk.response = undefined;
      draft.bulk.errors = undefined;
      return draft;
    }
    case getType(actions.bulkSale.success): {
      draft.bulk.loading = true;
      draft.bulk.response = action.payload;

      return draft;
    }
    case getType(actions.bulkSale.failure): {
      draft.bulk.loading = false;
      draft.bulk.errors = action.payload;
      return draft;
    }

    case getType(actions.saveAllChanges.request): {
      draft.saveAllChanges.loading = true;
      draft.saveAllChanges.response = undefined;
      draft.saveAllChanges.errors = undefined;
      return draft;
    }
    case getType(actions.saveAllChanges.success): {
      draft.saveAllChanges.loading = true;
      draft.saveAllChanges.response = action.payload;

      return draft;
    }
    case getType(actions.saveAllChanges.failure): {
      draft.saveAllChanges.loading = false;
      draft.saveAllChanges.errors = action.payload;
      return draft;
    }

    case getType(actions.restoreSale.request): {
      draft.restore.loading = true;
      draft.restore.response = undefined;
      draft.restore.errors = undefined;
      return draft;
    }
    case getType(actions.restoreSale.success): {
      draft.restore.loading = true;
      draft.restore.response = action.payload;

      return draft;
    }
    case getType(actions.restoreSale.failure): {
      draft.restore.loading = false;
      draft.restore.errors = action.payload;
      return draft;
    }
    case getType(actions.clearTrashedSale.request): {
      draft.clearTrashed.loading = true;
      draft.clearTrashed.response = undefined;
      draft.clearTrashed.errors = undefined;
      return draft;
    }
    case getType(actions.clearTrashedSale.success): {
      draft.clearTrashed.loading = true;
      draft.clearTrashed.response = action.payload;

      return draft;
    }
    case getType(actions.clearTrashedSale.failure): {
      draft.clearTrashed.loading = false;
      draft.clearTrashed.errors = action.payload;
      return draft;
    }
    case getType(actions.clearAllTrashedSales.request): {
      draft.clearAllTrashed.loading = true;
      draft.clearAllTrashed.response = undefined;
      draft.clearAllTrashed.errors = undefined;
      return draft;
    }
    case getType(actions.clearAllTrashedSales.success): {
      draft.clearAllTrashed.loading = true;
      draft.clearAllTrashed.response = action.payload;

      return draft;
    }
    case getType(actions.clearAllTrashedSales.failure): {
      draft.clearAllTrashed.loading = false;
      draft.clearAllTrashed.errors = action.payload;
      return draft;
    }

    case getType(actions.clearData): {
      if (action.payload === 'all') {
        draft = initialSaleState;
      } else if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialSaleState;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialSaleState);

export default saleMgnt;
