import {
  SettingData,
  SettingListResponse,
  SettingUpdateManyResponse,
  SettingUpdateResponse,
} from 'src/state/api-models/setting';
import { BaseState } from '../../models';

export type SettingDataState = { updatedAt?: string } & SettingData;
export type SettingListState = BaseState<SettingListResponse>;
export type SettingUpdateState = BaseState<SettingUpdateResponse> & {};
export type SettingUpdateManyState = BaseState<SettingUpdateManyResponse>;

export type SettingState = {
  data: SettingDataState;
  list: SettingListState;
  update: SettingUpdateState;
  updateMany: SettingUpdateManyState;
};

export const initialSettingState: SettingState = {
  data: {},
  list: { loading: false },
  update: { loading: false },
  updateMany: { loading: false },
};

declare module './../../models' {
  interface StateAll {
    // match to a duck folder
    'common/setting': SettingState;
  }
}
