import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { IncomeStateAll, initialStateAll } from './model';

const storeReport = produce((draft: Draft<IncomeStateAll>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getSummaryList.request): {
      draft.summary.loading = true;
      return draft;
    }
    case getType(actions.getSummaryList.success): {
      draft.summary.loading = true;
      draft.summary.response = action.payload;
      return draft;
    }
    case getType(actions.getSummaryList.failure): {
      draft.summary.loading = false;
      draft.summary.errors = action.payload;
      return draft;
    }
    case getType(actions.getDetailList.request): {
      draft.details.loading = true;
      return draft;
    }
    case getType(actions.getDetailList.success): {
      draft.details.loading = true;
      draft.details.response = action.payload;

      return draft;
    }
    case getType(actions.getDetailList.failure): {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }

    case getType(actions.getReportSummary.request): {
      draft.reportSummary.loading = true;
      draft.reportSummary.response = undefined;
      draft.reportSummary.errors = undefined;
      return draft;
    }
    case getType(actions.getReportSummary.success): {
      draft.reportSummary.loading = true;
      draft.reportSummary.response = action.payload;

      return draft;
    }
    case getType(actions.getReportSummary.failure): {
      draft.reportSummary.loading = false;
      draft.reportSummary.errors = action.payload;
      return draft;
    }
    case getType(actions.getReportDetails.request): {
      draft.reportDetails.loading = true;
      draft.reportDetails.response = undefined;
      draft.reportDetails.errors = undefined;
      return draft;
    }
    case getType(actions.getReportDetails.success): {
      draft.reportDetails.loading = true;
      draft.reportDetails.response = action.payload;

      return draft;
    }
    case getType(actions.getReportDetails.failure): {
      draft.reportDetails.loading = false;
      draft.reportDetails.errors = action.payload;
      return draft;
    }

    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialStateAll;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialStateAll);

export default storeReport;
