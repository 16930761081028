import { BaseState } from '../models';
import { IncomeListResponse, IncomeDetailResponse } from '../../api-models/income';

export type ReportDetails = BaseState<IncomeDetailResponse>;
export type ReportList = BaseState<IncomeListResponse>;
export type PrintReportDetails = BaseState<IncomeDetailResponse>;
export type PrintReportList = BaseState<IncomeListResponse>;

export type ReportState = {
  summary: ReportList;
  summaryDetails: ReportList;
  printSummary: ReportList;
  printSummaryDetails: ReportList;
};

export type ReportStateAll = ReportState;

export const initialReportState: ReportState = {
  summary: { loading: false, response: undefined },
  summaryDetails: { loading: false, response: undefined },
  printSummary: { loading: false, response: undefined },
  printSummaryDetails: { loading: false, response: undefined },
};

export const initialStateAll: ReportStateAll = initialReportState;

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    dailyReport: ReportStateAll;
  }
}
