import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';
import {
  CustomerListRequest,
  CustomerListResponse,
  CustomerDeleteResponse,
  CustomerUpdateResponse,
  CustomerCreateResponse,
  CustomerDetailResponse,
  CustomerBulkResponse,
  CustomerBulkRequest,
  CustomerImportRequest,
  CustomerImportResponse,
  CheckMembershipRequest,
  CheckMembershipResponse,
  CustomerReviewRequest,
  CustomerReviewResponse,
} from '../../api-models/customer';
import {
  InputSearchCustomer,
  InputSearchCustomerDetail,
  CustomerReview,
  CustomersHistoryPoint,
  Customer,
} from '../../models/customer';

// Create the set of async actions
export const getCustomerList = createAsyncAction(types.FETCH_LIST, types.FETCH_LIST_SUCCESS, types.FETCH_LIST_FAILED)<
  CustomerListRequest,
  CustomerListResponse,
  any
>();

// Create the set of async actions
export const getCustomerDetails = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<string, CustomerDetailResponse, any>();

// Create the set of async actions
export const createCustomer = createAsyncAction(types.CREATE, types.CREATE_SUCCESS, types.CREATE_FAILED)<
  Customer,
  CustomerCreateResponse,
  any
>();

// Create the set of async actions
export const createCustomerInPos = createAsyncAction(
  types.CREATE_IN_POS,
  types.CREATE_IN_POS_SUCCESS,
  types.CREATE_IN_POS_FAILED
)<Customer, CustomerCreateResponse, any>();

// Create the set of async actions
export const updateCustomer = createAsyncAction(types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILED)<
  Customer,
  CustomerUpdateResponse,
  any
>();

// Create the set of async actions
export const deleteCustomer = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  string,
  CustomerDeleteResponse,
  any
>();

// Create the set of async actions
export const bulkCustomer = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  CustomerBulkRequest,
  CustomerBulkResponse,
  any
>();

// Create the set of async actions
export const importCustomer = createAsyncAction(types.IMPORT, types.IMPORT_SUCCESS, types.IMPORT_FAILED)<
  CustomerImportRequest,
  CustomerImportResponse,
  any
>();

// Create the set actions
export const inputSearchCustomer = createAction(types.INPUTSEARCH)<InputSearchCustomer>();

export const historyPointAction = createAsyncAction(
  types.HISTORYPOINT,
  types.HISTORYPOINT_SUCCESS,
  types.HISTORYPOINT_FAILED
)<InputSearchCustomerDetail, CustomersHistoryPoint, any>();

export const customerReviewList = createAsyncAction(
  types.LIST_REVIEW,
  types.LIST_REVIEW_SUCCESS,
  types.LIST_REVIEW_FAILED
)<InputSearchCustomerDetail, CustomerReview, any>();

export const customerReview = createAsyncAction(types.REVIEW, types.REVIEW_SUCCESS, types.REVIEW_FAILED)<
  CustomerReviewRequest,
  CustomerReviewResponse,
  any
>();

// Create the set of async actions
export const checkMembership = createAsyncAction(
  types.CHECK_MEMBERSHIP,
  types.CHECK_MEMBERSHIP_SUCCESS,
  types.CHECK_MEMBERSHIP_FAILED
)<CheckMembershipRequest, CheckMembershipResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
