import { BaseState } from 'src/state/ducks/models';
import {
  EmarketingTemplateListResponse,
  EmarketingTemplateDetailResponse,
  EmarketingTemplateDeleteResponse,
  EmarketingTemplateUpdateResponse,
  EmarketingTemplateCreateResponse,
  EmarketingTemplateBulkResponse,
} from 'src/state/api-models/emarketing-template';

export type EmarketingTemplateDetails = BaseState<EmarketingTemplateDetailResponse>;
export type EmarketingTemplateList = BaseState<EmarketingTemplateListResponse>;
export type EmarketingTemplateCreate = BaseState<EmarketingTemplateCreateResponse>;
export type EmarketingTemplateUpdate = BaseState<EmarketingTemplateUpdateResponse>;
export type EmarketingTemplateDelete = BaseState<EmarketingTemplateDeleteResponse>;
export type EmarketingTemplateBulk = BaseState<EmarketingTemplateBulkResponse>;

export type EmarketingTemplateState = {
  list: EmarketingTemplateList;
  details: EmarketingTemplateDetails;
  create: EmarketingTemplateCreate;
  update: EmarketingTemplateUpdate;
  delete: EmarketingTemplateDelete;
  bulk: EmarketingTemplateBulk;
};

export const initialState = {
  list: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
};

declare module 'src/state/ducks/models' {
  interface StateAll {
    // match to a duck folder
    'emarketing/template': EmarketingTemplateState;
  }
}
