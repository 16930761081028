import { put, call, all, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from 'src/utils/axios/axios-instance';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { convertToFormData } from 'src/utils/converter';
import * as actions from './actions';

// Handle request saga
function* getPayrollList(action: ReturnType<typeof actions.getPayrollList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.PAYROLL.List, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getPayrollList.success((response as any).data));
  } catch (err) {
    yield put(actions.getPayrollList.failure(err));
  }
}

function* calculatePayoll(action: ReturnType<typeof actions.calculatePayoll.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.PAYROLL.Calculate, {
      params: action.payload,
    });

    yield put(actions.calculatePayoll.success((response as any).data));
  } catch (err) {
    yield put(actions.calculatePayoll.failure(err));
  }
}

function* getPayrollDetails(action: ReturnType<typeof actions.getPayrollDetails.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.PAYROLL.Details.replace('{id}', action.payload.id));

    yield put(actions.getPayrollDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getPayrollDetails.failure(err));
  }
}

function* createPayroll(action: ReturnType<typeof actions.createPayroll.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.PAYROLL.Create, action.payload, config));

    yield put(actions.createPayroll.success((response as any).data));
  } catch (err) {
    yield put(actions.createPayroll.failure(err));
  }
}

function* updatePayroll(action: ReturnType<typeof actions.updatePayroll.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const id = action.payload.id;
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.PAYROLL.Update.replace('{id}', id), action.payload, config)
    );

    yield put(actions.updatePayroll.success((response as any).data));
  } catch (err) {
    yield put(actions.updatePayroll.failure(err));
  }
}

function* deletePayroll(action: ReturnType<typeof actions.deletePayroll.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.delete(API_ENDPOINTS.PAYROLL.Delete.replace('{id}', action.payload.id))
    );

    yield put(actions.deletePayroll.success((response as any).data));
  } catch (err) {
    yield put(actions.deletePayroll.failure(err));
  }
}

function* bulkPayrolls(action: ReturnType<typeof actions.bulkPayrolls.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.PAYROLL.Bulk, action.payload));

    yield put(actions.bulkPayrolls.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkPayrolls.failure(err));
  }
}

function* getPayrollCommissionReport(action: ReturnType<typeof actions.getPayrollCommissionReport.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.PAYROLL.PayrollCommissionReport, {
      params: action.payload,
    });

    yield put(actions.getPayrollCommissionReport.success((response as any).data));
  } catch (err) {
    yield put(actions.getPayrollCommissionReport.failure(err));
  }
}

function* getPayrollCommissionSummary(
  action: ReturnType<typeof actions.getPayrollCommissionSummary.request>
): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.PAYROLL.PayrollCommissionSummary, {
      params: action.payload,
    });

    yield put(actions.getPayrollCommissionSummary.success((response as any).data));
  } catch (err) {
    yield put(actions.getPayrollCommissionSummary.failure(err));
  }
}

function* getPayrollCommissionDetails(
  action: ReturnType<typeof actions.getPayrollCommissionDetails.request>
): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.PAYROLL.PayrollCommissionDetails, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getPayrollCommissionDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getPayrollCommissionDetails.failure(err));
  }
}

// Main saga
export default function* payrollSaga() {
  yield all([
    takeLatest(actions.getPayrollList.request, getPayrollList),
    takeLatest(actions.getPayrollDetails.request, getPayrollDetails),
    takeLatest(actions.calculatePayoll.request, calculatePayoll),
    takeLatest(actions.createPayroll.request, createPayroll),
    takeLatest(actions.updatePayroll.request, updatePayroll),
    takeLatest(actions.deletePayroll.request, deletePayroll),
    takeLatest(actions.bulkPayrolls.request, bulkPayrolls),
    takeLatest(actions.getPayrollCommissionReport.request, getPayrollCommissionReport),
    takeLatest(actions.getPayrollCommissionSummary.request, getPayrollCommissionSummary),
    takeLatest(actions.getPayrollCommissionDetails.request, getPayrollCommissionDetails),
  ]);
}
