export enum QuickDate {
  All = 'all',
  Today = 'today',
  ThisWeek = 'this_week',
  ThisMonth = 'this_month',
  ThisYear = 'this_year',
  LastWeek = 'last_week',
  Last2Weeks = 'last_2_weeks',
  Last3Weeks = 'last_3_weeks',
  LastMonth = 'last_month',
  Last2Months = 'last_2_months',
  Last3Months = 'last_3_months',
  Last6Months = 'last_6_months',
  LastYear = 'last_year',
  Other = 'other',
}

export type DateTimeDuration = {
  time_type?: QuickDate;
  effective_start?: string;
  effective_end?: string;
};
