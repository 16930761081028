import { LoginUserData } from 'src/state/api-models/auth';
import { InitialSettings } from 'src/state/api-models/setting';
import { User } from 'src/state/models/user';

const AUTH_DATA_KEY = 'auth_data';

export const getAuthData = (): LoginUserData => {
  const data = window.localStorage.getItem(AUTH_DATA_KEY);
  return data && JSON.parse(data);
};

export const getStoreId = (): string => {
  const data = getAuthData();
  return data && data.store_id;
};

export const getWorkstationId = (): string => {
  const data = getAuthData();
  return data && data.workstation_id;
};

export const getCurrentUser = (): User => {
  const data = getAuthData();
  return data && data.user;
};

export const getInitialSettings = (): InitialSettings => {
  const data = getAuthData();
  return data && data.initial_settings;
};

export const getCountryCode = () => {
  const initial_settings = getInitialSettings();
  return initial_settings && initial_settings.generalSettings && initial_settings.generalSettings.country_code;
};

export const getTokenValue = (): string => {
  const data = getAuthData();
  return data && data.token;
};

export const setAuthData = (data: LoginUserData) => {
  if (data) {
    window.localStorage.setItem(AUTH_DATA_KEY, JSON.stringify(data));
  } else {
    window.localStorage.removeItem(AUTH_DATA_KEY);
  }
};

export const clearAuthData = () => {
  window.localStorage.removeItem(AUTH_DATA_KEY);
};

export const clearAuthlocalStorage = () => {
  //clear all local storage
  if (window.localStorage.getItem('initial_settings') !== null) {
    let notInList = [
      'listingPrinters',
      'printer_demand',
      'app_version',
      'rememberUser',
      'loglevel',
      'i18next_res_en',
      'user_uuid',
    ];
    Object.entries(window.localStorage).forEach(([cKey, v]) => {
      if (notInList.indexOf(cKey) === -1) {
        if (cKey.indexOf('waiting_sale_tickets') === -1) {
          window.localStorage.removeItem(cKey);
        }
      }
    });
  }
};

export const getCurrentUserData = () => {
  const auth_data = getAuthData();

  return auth_data;
};

export const setCurrentUserData = (data: LoginUserData) => {
  setAuthData(data);
};

export const isAuthenticated = () => {
  const auth_data = getAuthData();
  return !!auth_data;
};

export const getRouteDefaultHomePage = () => {
  const auth_data = getAuthData();
  return (auth_data && auth_data.initial_settings.storeSettings['default_home']) || 'dashboard';
};
