export const FETCH_SUMMARY = 'customer_report/summary';
export const FETCH_SUMMARY_SUCCESS = 'customer_report/summary_success';
export const FETCH_SUMMARY_FAILED = 'customer_report/summary_failed';

export const FETCH_SUMMARY_DETAILS = 'customer_report/details_summary';
export const FETCH_SUMMARY_DETAILS_SUCCESS = 'customer_report/details_summary_success';
export const FETCH_SUMMARY_DETAILS_FAILED = 'customer_report/details_summary_failed';

export const CLEAR_DATA = 'customer_report/clear_data';

export type IncomeAction =
  | typeof FETCH_SUMMARY
  | typeof FETCH_SUMMARY_FAILED
  | typeof FETCH_SUMMARY_SUCCESS
  | typeof FETCH_SUMMARY_DETAILS
  | typeof FETCH_SUMMARY_DETAILS_FAILED
  | typeof FETCH_SUMMARY_DETAILS_SUCCESS
  | typeof CLEAR_DATA;
