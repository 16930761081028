import { put, call, all, takeLatest } from 'redux-saga/effects';
import { API_SALE_TIMEOUT } from 'src/config';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';

function* getSaleDetail(action: ReturnType<typeof actions.getSaleDetail.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.get(API_ENDPOINTS.SALE.Detail.replace('{id}', action.payload)));

    yield put(actions.getSaleDetail.success((response as any).data));
  } catch (err) {
    yield put(actions.getSaleDetail.failure(err));
  }
}

function* getSaleInvoice(action: ReturnType<typeof actions.getSaleInvoice.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.get(API_ENDPOINTS.SALE.Invoice.replace('{id}', action.payload)));

    yield put(actions.getSaleInvoice.success((response as any).data));
  } catch (err) {
    yield put(actions.getSaleInvoice.failure(err));
  }
}

function* updateSaleStatus(action: ReturnType<typeof actions.updateSaleStatus.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.SALE.UpdateStatus.replace('{id}', action.payload.id), action.payload)
    );

    yield put(actions.updateSaleStatus.success((response as any).data));
  } catch (err) {
    yield put(actions.updateSaleStatus.failure(err));
  }
}

function* updateSaleTip(action: ReturnType<typeof actions.updateSaleTip.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.SALE.UpdateTip.replace('{id}', action.payload.id), action.payload, {
        timeout: API_SALE_TIMEOUT,
      })
    );

    yield put(actions.updateSaleTip.success((response as any).data));
  } catch (err) {
    yield put(actions.updateSaleTip.failure(err));
  }
}

function* changeEmployee(action: ReturnType<typeof actions.changeEmployee.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.SALE.ChangeEmployee.replace('{id}', action.payload.id), action.payload)
    );

    yield put(actions.changeEmployee.success((response as any).data));
  } catch (err) {
    yield put(actions.changeEmployee.failure(err));
  }
}

function* changeOrderAt(action: ReturnType<typeof actions.changeOrderAt.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.SALE.ChangeOrderAt.replace('{id}', action.payload.id), action.payload)
    );

    yield put(actions.changeOrderAt.success((response as any).data));
  } catch (err) {
    yield put(actions.changeOrderAt.failure(err));
  }
}

function* getSaleRefund(action: ReturnType<typeof actions.getSaleRefund.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.get(API_ENDPOINTS.SALE.Refund.replace('{id}', action.payload)));

    yield put(actions.getSaleRefund.success((response as any).data));
  } catch (err) {
    yield put(actions.getSaleRefund.failure(err));
  }
}

function* refundSale(action: ReturnType<typeof actions.refundSale.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.SALE.Refund.replace('{id}', action.payload.id), action.payload, {
        timeout: API_SALE_TIMEOUT,
      })
    );

    yield put(actions.refundSale.success((response as any).data));
  } catch (err) {
    yield put(actions.refundSale.failure(err));
  }
}

function* voidSale(action: ReturnType<typeof actions.voidSale.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.SALE.Void.replace('{id}', action.payload.id), action.payload, {
        timeout: API_SALE_TIMEOUT,
      })
    );

    yield put(actions.voidSale.success((response as any).data));
  } catch (err) {
    yield put(actions.voidSale.failure(err));
  }
}

function* deleteSale(action: ReturnType<typeof actions.deleteSale.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.delete(API_ENDPOINTS.SALE.Delete.replace('{id}', action.payload)));

    yield put(actions.deleteSale.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteSale.failure(err));
  }
}

function* clearTrashedSale(action: ReturnType<typeof actions.clearTrashedSale.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.delete(API_ENDPOINTS.SALE.ClearTrashed.replace('{id}', action.payload))
    );

    yield put(actions.clearTrashedSale.success((response as any).data));
  } catch (err) {
    yield put(actions.clearTrashedSale.failure(err));
  }
}

function* clearAllTrashedSales(action: ReturnType<typeof actions.clearAllTrashedSales.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.delete(API_ENDPOINTS.SALE.ClearAllTrashed));

    yield put(actions.clearAllTrashedSales.success((response as any).data));
  } catch (err) {
    yield put(actions.clearAllTrashedSales.failure(err));
  }
}

function* restoreSale(action: ReturnType<typeof actions.restoreSale.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.SALE.Restore.replace('{id}', action.payload));

    yield put(actions.restoreSale.success((response as any).data));
  } catch (err) {
    yield put(actions.restoreSale.failure(err));
  }
}

function* bulkSale(action: ReturnType<typeof actions.bulkSale.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.SALE.Bulk, action.payload);

    yield put(actions.bulkSale.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkSale.failure(err));
  }
}

function* saveAllChanges(action: ReturnType<typeof actions.saveAllChanges.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.SALE.SaveAllChanges, action.payload, {
      timeout: API_SALE_TIMEOUT,
    });

    yield put(actions.saveAllChanges.success((response as any).data));
  } catch (err) {
    yield put(actions.saveAllChanges.failure(err));
  }
}

// Main saga
export default function* saleMgntSaga() {
  yield all([
    takeLatest(actions.getSaleInvoice.request, getSaleInvoice),
    takeLatest(actions.getSaleDetail.request, getSaleDetail),
    takeLatest(actions.updateSaleStatus.request, updateSaleStatus),
    takeLatest(actions.updateSaleTip.request, updateSaleTip),
    takeLatest(actions.changeEmployee.request, changeEmployee),
    takeLatest(actions.changeOrderAt.request, changeOrderAt),
    takeLatest(actions.getSaleRefund.request, getSaleRefund),
    takeLatest(actions.refundSale.request, refundSale),
    takeLatest(actions.voidSale.request, voidSale),
    takeLatest(actions.restoreSale.request, restoreSale),
    takeLatest(actions.deleteSale.request, deleteSale),
    takeLatest(actions.clearTrashedSale.request, clearTrashedSale),
    takeLatest(actions.clearAllTrashedSales.request, clearAllTrashedSales),
    takeLatest(actions.bulkSale.request, bulkSale),
    takeLatest(actions.saveAllChanges.request, saveAllChanges),
  ]);
}
