export const API_ENDPOINTS = {
  PRODUCT: {
    List: '/products',
    Create: '/products',
    Details: '/products/{id}',
    Update: '/products/{id}',
    Ordering: '/products/{id}/ordering',
    Delete: '/products/{id}',
    Bulk: '/products/bulk',
    IMPORT: 'products/import',
    CreateInventory: 'products/inventory',
    inventoriesDetail: 'products/inventoriesDetail',
  },
};
