export const FETCH_LIST = 'setting/list';
export const FETCH_LIST_SUCCESS = 'setting/list_success';
export const FETCH_LIST_FAILED = 'setting/list_failed';

export const UPDATE = 'setting/update';
export const UPDATE_SUCCESS = 'setting/update_success';
export const UPDATE_FAILED = 'setting/update_failed';

export const UPDATE_MANY = 'setting/update_many';
export const UPDATE_MANY_SUCCESS = 'setting/update_many_success';
export const UPDATE_MANY_FAILED = 'setting/update_many_failed';

export const HAS_UPDATED_DATA = 'setting/has_updated_data';
export const SET_DATA = 'setting/set_data';
export const CLEAR_GROUP_DATA = 'setting/clear_group_data';
export const CLEAR_DATA = 'setting/clear_data';

export type SettingAction =
  | typeof FETCH_LIST
  | typeof FETCH_LIST_FAILED
  | typeof FETCH_LIST_SUCCESS
  | typeof UPDATE
  | typeof UPDATE_FAILED
  | typeof UPDATE_SUCCESS
  | typeof UPDATE_MANY
  | typeof UPDATE_MANY_FAILED
  | typeof UPDATE_MANY_SUCCESS
  | typeof HAS_UPDATED_DATA
  | typeof SET_DATA
  | typeof CLEAR_DATA;
