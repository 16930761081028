import { all, call, put, takeLatest } from 'redux-saga/effects';
import { convertToFormData } from 'src/utils/converter';
import { API_ENDPOINTS } from 'src/config/api-endpoints/sms/reminder';
import { axiosInstance } from '../../../../utils/axios/axios-instance';
import * as actions from './actions';

// Handle request saga
function* getSmsReminderList(action: ReturnType<typeof actions.getSmsReminderList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.SMS_REMINDER.List, {
      params: action.payload,
    });

    yield put(actions.getSmsReminderList.success((response as any).data));
  } catch (err) {
    yield put(actions.getSmsReminderList.failure(err));
  }
}

function* getSmsReminderDetails(action: ReturnType<typeof actions.getSmsReminderDetails.request>): Generator {
  try {
    let response: any = {};
    if (action.payload)
      response = yield call(axiosInstance.get, API_ENDPOINTS.SMS_REMINDER.Details.replace('{id}', `${action.payload}`));

    yield put(actions.getSmsReminderDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getSmsReminderDetails.failure(err));
  }
}

function* createSmsReminder(action: ReturnType<typeof actions.createSmsReminder.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const response = yield call(axiosInstance.post, API_ENDPOINTS.SMS_REMINDER.Create, action.payload, config);

    yield put(actions.createSmsReminder.success((response as any).data));
  } catch (err) {
    yield put(actions.createSmsReminder.failure(err));
  }
}

function* updateSmsReminder(action: ReturnType<typeof actions.updateSmsReminder.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const response = yield call(
      axiosInstance.post,
      API_ENDPOINTS.SMS_REMINDER.Update.replace('{id}', `${action.payload.id}`),
      action.payload,
      config
    );

    yield put(actions.updateSmsReminder.success((response as any).data));
  } catch (err) {
    yield put(actions.updateSmsReminder.failure(err));
  }
}

function* updateStatus(action: ReturnType<typeof actions.updateStatus.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.post,
      API_ENDPOINTS.SMS_REMINDER.UpdateStatus.replace('{id}', `${action.payload.id}`),
      action.payload
    );

    yield put(actions.updateStatus.success((response as any).data));
  } catch (err) {
    yield put(actions.updateStatus.failure(err));
  }
}

function* deleteSmsReminder(action: ReturnType<typeof actions.deleteSmsReminder.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.delete,
      API_ENDPOINTS.SMS_REMINDER.Delete.replace('{id}', `${action.payload}`)
    );

    yield put(actions.deleteSmsReminder.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteSmsReminder.failure(err));
  }
}

function* bulkSmsReminder(action: ReturnType<typeof actions.bulkSmsReminder.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.SMS_REMINDER.Bulk, action.payload);

    yield put(actions.bulkSmsReminder.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkSmsReminder.failure(err));
  }
}

// Main saga
export default function* smsSaga() {
  yield all([
    takeLatest(actions.getSmsReminderList.request, getSmsReminderList),
    takeLatest(actions.getSmsReminderDetails.request, getSmsReminderDetails),
    takeLatest(actions.createSmsReminder.request, createSmsReminder),
    takeLatest(actions.updateSmsReminder.request, updateSmsReminder),
    takeLatest(actions.updateStatus.request, updateStatus),
    takeLatest(actions.deleteSmsReminder.request, deleteSmsReminder),
    takeLatest(actions.bulkSmsReminder.request, bulkSmsReminder),
  ]);
}
