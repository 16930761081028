import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';
import {
  EmployeeInOutTimeRequest,
  EmployeeInOutTimeResponse,
  ClockInOutTimeRequest,
  ClockInOutTimeResponse,
  EmployeeInOutTimesSummaryRequest,
  EmployeeInOutTimesSummaryResponse,
  EmployeeInOutTimesInDateDeleteResponse,
  EmployeeInOutTimesInDateDetailResponse,
  EmployeeInOutTimesInDateUpdateRequest,
  EmployeeInOutTimesInDateUpdateResponse,
  EmployeeInOutTimesInDateDetailRequest,
  EmployeeInOutTimesInDateDeleteRequest,
  ReportEmployeeInOutTimesSummaryRequest,
  ReportEmployeeInOutTimesSummaryResponse,
} from '../../api-models/employee-in-out-time';
export const reloadCheckedInEmployeeList = createAction(types.RELOAD_CHECKED_IN_TIME)();

// Create the set of async actions
export const getCheckedInEmployeeList = createAsyncAction(
  types.CHECKED_IN_TIME,
  types.CHECKED_IN_TIME_SUCCESS,
  types.CHECKED_IN_TIME_FAILED
)<EmployeeInOutTimeRequest, EmployeeInOutTimeResponse, any>();

// Create the set of async actions
export const clockInOutEmployee = createAsyncAction(
  types.CLOCK_IN_OUT_TIME,
  types.CLOCK_IN_OUT_TIME_SUCCESS,
  types.CLOCK_IN_OUT_TIME_FAILED
)<ClockInOutTimeRequest, ClockInOutTimeResponse, any>();

export const getSummaryEmployeeInOutList = createAsyncAction(
  types.SUMMARY_EMPLOYEE_IN_OUT_LIST,
  types.SUMMARY_EMPLOYEE_IN_OUT_LIST_SUCCESS,
  types.SUMMARY_EMPLOYEE_IN_OUT_LIST_FAILED
)<EmployeeInOutTimesSummaryRequest, EmployeeInOutTimesSummaryResponse, any>();

export const getReportSummaryEmployeeInOutList = createAsyncAction(
  types.REPORT_SUMMARY_EMPLOYEE_IN_OUT_LIST,
  types.REPORT_SUMMARY_EMPLOYEE_IN_OUT_LIST_SUCCESS,
  types.REPORT_SUMMARY_EMPLOYEE_IN_OUT_LIST_FAILED
)<ReportEmployeeInOutTimesSummaryRequest, ReportEmployeeInOutTimesSummaryResponse, any>();

export const getEmployeeInOutTimesInDateDetail = createAsyncAction(
  types.EMPLOYEE_IN_OUT_DETAIL,
  types.EMPLOYEE_IN_OUT_DETAIL_SUCCESS,
  types.EMPLOYEE_IN_OUT_DETAIL_FAILED
)<EmployeeInOutTimesInDateDetailRequest, EmployeeInOutTimesInDateDetailResponse, any>();

export const deleteEmployeeInOutTimesInDate = createAsyncAction(
  types.DELETE_EMPLOYEE_IN_OUT,
  types.DELETE_EMPLOYEE_IN_OUT_SUCCESS,
  types.DELETE_EMPLOYEE_IN_OUT_FAILED
)<EmployeeInOutTimesInDateDeleteRequest, EmployeeInOutTimesInDateDeleteResponse, any>();

export const updateEmployeeInOutTimesInDate = createAsyncAction(
  types.UPDATE_EMPLOYEE_IN_OUT,
  types.UPDATE_EMPLOYEE_IN_OUT_SUCCESS,
  types.UPDATE_EMPLOYEE_IN_OUT_FAILED
)<EmployeeInOutTimesInDateUpdateRequest, EmployeeInOutTimesInDateUpdateResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
