import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { ReportState, initialReportState } from './model';

const dailyReport = produce((draft: Draft<ReportState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getSummaryList.request): {
      draft.summary.loading = true;
      return draft;
    }
    case getType(actions.getSummaryList.success): {
      draft.summary.loading = true;
      draft.summary.response = action.payload;
      return draft;
    }
    case getType(actions.getSummaryList.failure): {
      draft.summary.loading = false;
      draft.summary.errors = action.payload;
      return draft;
    }
    case getType(actions.printSummaryList.request): {
      draft.summary.loading = true;
      return draft;
    }
    case getType(actions.printSummaryList.success): {
      draft.summary.loading = true;
      draft.summary.response = action.payload;
      return draft;
    }
    case getType(actions.printSummaryList.failure): {
      draft.summary.loading = false;
      draft.summary.errors = action.payload;
      return draft;
    }
    case getType(actions.getDetailList.request): {
      draft.summaryDetails.loading = true;
      return draft;
    }
    case getType(actions.getDetailList.success): {
      draft.summaryDetails.loading = true;
      draft.summaryDetails.response = action.payload;

      return draft;
    }
    case getType(actions.getDetailList.failure): {
      draft.summaryDetails.loading = false;
      draft.summaryDetails.errors = action.payload;
      return draft;
    }
    case getType(actions.printDetailList.request): {
      draft.summaryDetails.loading = true;
      return draft;
    }
    case getType(actions.printDetailList.success): {
      draft.summaryDetails.loading = true;
      draft.summaryDetails.response = action.payload;

      return draft;
    }
    case getType(actions.printDetailList.failure): {
      draft.summaryDetails.loading = false;
      draft.summaryDetails.errors = action.payload;
      return draft;
    }

    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialReportState;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialReportState);

export default dailyReport;
