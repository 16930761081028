import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';

import { initialGlobalState, GlobalState } from './model';

const global = produce((draft: Draft<GlobalState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getGlobalVariables.request): {
      return draft;
    }
    case getType(actions.getGlobalVariables.success): {
      draft = action.payload;
      return draft;
    }
    case getType(actions.getGlobalVariables.failure): {
      return draft;
    }

    case getType(actions.setItem): {
      draft[action.payload.key] = action.payload.value;
      return draft;
    }

    case getType(actions.removeItem): {
      draft[action.payload.key] = undefined;
      return draft;
    }

    case getType(actions.clearData): {
      draft = initialGlobalState;
      return draft;
    }
    default:
      return draft;
  }
}, initialGlobalState);

export default global;
