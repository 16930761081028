export const BOOKINGAPPT = {
  List: '/pos/bookingAppt',
  UnassignedList: '/pos/bookingAppt/unassigned',
  CountInDays: '/pos/bookingAppt/count-in-days',
  TopServices: '/pos/bookingAppt/top-services',
  Assign: '/pos/bookingAppt/assign',
  Create: '/pos/bookingAppt',
  Details: '/pos/bookingAppt/{id}',
  Update: '/pos/bookingAppt/{id}',
  UpdateStatus: '/pos/bookingAppt/{id}/status',
  Delete: '/pos/bookingAppt/{id}',
  Bulk: '/pos/bookingAppt/bulk',
  Import: '/pos/bookingAppt/import',
  SendSms: '/pos/bookingAppt/send-message',
  UpdateColumn: '/pos/bookingAppt/{id}/attribute',
};
