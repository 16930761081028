export const FETCH_DETAILS = 'customer/details';
export const FETCH_DETAILS_SUCCESS = 'customer/details_success';
export const FETCH_DETAILS_FAILED = 'customer/details_failed';

export const FETCH_LIST = 'customer/list';
export const FETCH_LIST_SUCCESS = 'customer/list_success';
export const FETCH_LIST_FAILED = 'customer/list_failed';

export const CREATE = 'customer/create';
export const CREATE_SUCCESS = 'customer/create_success';
export const CREATE_FAILED = 'customer/create_failed';

export const CREATE_IN_POS = 'customer/create_in_pos';
export const CREATE_IN_POS_SUCCESS = 'customer/create_create_in_possuccess';
export const CREATE_IN_POS_FAILED = 'customer/create_create_in_pos_failed';

export const UPDATE = 'customer/update';
export const UPDATE_SUCCESS = 'customer/update_success';
export const UPDATE_FAILED = 'customer/update_failed';

export const DELETE = 'customer/detele';
export const DELETE_SUCCESS = 'customer/detele_success';
export const DELETE_FAILED = 'customer/detele_failed';

export const BULK = 'customer/bulk';
export const BULK_SUCCESS = 'customer/bulk_success';
export const BULK_FAILED = 'customer/bulk_failed';

export const IMPORT = 'customer/import';
export const IMPORT_SUCCESS = 'customer/import_success';
export const IMPORT_FAILED = 'customer/import_failed';

export const INPUTSEARCH = 'customer/search';

export const HISTORYPOINT = 'customer/historyPoint';
export const HISTORYPOINT_SUCCESS = 'customer/historyPoint_success';
export const HISTORYPOINT_FAILED = 'customer/historyPoint_failed';

export const REVIEW = 'customer/review';
export const REVIEW_SUCCESS = 'customer/review_success';
export const REVIEW_FAILED = 'customer/review_failed';

export const LIST_REVIEW = 'customer/reviews';
export const LIST_REVIEW_SUCCESS = 'customer/reviews_success';
export const LIST_REVIEW_FAILED = 'customer/reviews_failed';

export const CHECK_MEMBERSHIP = 'customer/check_membership';
export const CHECK_MEMBERSHIP_SUCCESS = 'customer/check_membership_success';
export const CHECK_MEMBERSHIP_FAILED = 'customer/check_membership_failed';

export const CLEAR_DATA = 'customer/clear_data';
