import { CustomerListResponse } from 'src/state/api-models/customer';
import { DashboardPaymentAnalysisResponse } from 'src/state/api-models/payment-analysis';
import { BaseState } from '../models';
import { DashboardDetailsResponse, DashboardSummaryResponse } from '../../api-models/dashboard';
import { QuickDate } from 'src/types/date-time';
import { getTimezone } from 'src/utils/date-time';

export type IncomeSummary = BaseState<DashboardSummaryResponse>;
export type IncomeDetails = BaseState<DashboardDetailsResponse>;
export type BirthayCustomers = BaseState<CustomerListResponse>;
export type PaymentAnalysis = BaseState<DashboardPaymentAnalysisResponse>;

export type SearchCondition = {
  user_id?: string;
  cashier_id?: string;
  time_type: string;
  effective_start?: string;
  effective_end?: string;
  timezone?: string;
};

export type ReportState = {
  searchCondition: SearchCondition;
  summary: IncomeSummary;
  summaryDetails: IncomeDetails;
  paymentAnalysis: PaymentAnalysis;
  birthayCustomers: BirthayCustomers;
};

export type ReportStateAll = ReportState;

export const initialReportState: ReportState = {
  searchCondition: {
    time_type: QuickDate.Today,
    user_id: null,
    cashier_id: null,
    effective_start: null,
    effective_end: null,
    timezone: getTimezone(),
  },
  summary: { loading: false, response: undefined },
  summaryDetails: { loading: false, response: undefined },
  paymentAnalysis: { loading: false, response: undefined },
  birthayCustomers: { loading: false, response: undefined },
};

export const initialStateAll: ReportStateAll = initialReportState;

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    dashboard: ReportStateAll;
  }
}
