import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  LoginRequest,
  LoginResponse,
  CheckPinCodeRequest,
  CheckPinCodeResponse,
  GetInitialSettingsRequest,
  GetInitialSettingsResponse,
  UpdateProfleRequest,
  UpdateProfleResponse,
} from 'src/state/api-models/auth';
import * as types from './types';
import { InitialSettings } from 'src/state/api-models/setting';

// Create the set of async actions
export const login = createAsyncAction(types.LOGIN, types.LOGIN_SUCCESS, types.LOGIN_FAILED)<
  LoginRequest,
  LoginResponse,
  any
>();

export const loginToken = createAsyncAction(types.LOGIN_TOKEN, types.LOGIN_TOKEN_SUCCESS, types.LOGIN_TOKEN_FAILED)<
  LoginRequest,
  LoginResponse,
  any
>();

export const logout = createAsyncAction(types.LOGOUT, types.LOGOUT_SUCCESS, types.LOGOUT_FAILED)<void, void, any>();

export const checkPinCode = createAsyncAction(
  types.CHECK_PIN_CODE_ROLE,
  types.CHECK_PIN_CODE_ROLE_SUCCESS,
  types.CHECK_PIN_CODE_ROLE_FAILED
)<CheckPinCodeRequest, CheckPinCodeResponse, any>();

export const getInitialSettings = createAsyncAction(
  types.GET_INITIAL_SETTINGS,
  types.GET_INITIAL_SETTINGS_SUCCESS,
  types.GET_INITIAL_SETTINGS_FAILED
)<GetInitialSettingsRequest, GetInitialSettingsResponse, any>();

export const updateInitialSettings = createAction(types.UPDATE_INITIAL_SETTINGS)<InitialSettings>();

export const updateProfile = createAsyncAction(
  types.UPDATE_PROFILE,
  types.UPDATE_PROFILE_SUCCESS,
  types.UPDATE_PROFILE_FAILED
)<UpdateProfleRequest, UpdateProfleResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)();
export const clearPinCodeData = createAction(types.CLEAR_DATA_CHECK_PIN_CODE)();
export const clearProfileData = createAction(types.CLEAR_DATA_PROFILE)();
