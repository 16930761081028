import React from 'react';
import { PAGE_PATH } from 'src/routers/route-path';
import { AppRouteProps } from './types';

const Test = React.lazy(() => import('src/screens/test'));

const Plan = React.lazy(() => import('src/screens/auth/Plan'));

const Login = React.lazy(() => import('src/screens/auth/Login'));
const Logout = React.lazy(() => import('src/screens/auth/Logout'));

const Receipt = React.lazy(() => import('src/screens/report/Receipt'));

const Page403 = React.lazy(() => import('src/screens/error/403'));
const Page404 = React.lazy(() => import('src/screens/error/404'));
const Page500 = React.lazy(() => import('src/screens/error/500'));

const Dashboard = React.lazy(() => import('src/screens/dashboard'));
const EmployeeList = React.lazy(() => import('src/screens/admin/Member'));
const ServiceList = React.lazy(() => import('src/screens/admin/Service'));
const CustomerList = React.lazy(() => import('src/screens/admin/Customer'));
const CustomerGroup = React.lazy(() => import('src/screens/admin/CustomerGroup'));
const Memberships = React.lazy(() => import('src/screens/admin/Memberships'));

const Giftcardlist = React.lazy(() => import('src/screens/admin/Giftcard'));
const Couponlist = React.lazy(() => import('src/screens/admin/Coupon'));
const Suppliers = React.lazy(() => import('src/screens/admin/Suppliers'));
const EmployeeSales = React.lazy(() => import('src/screens/pos/Sales'));

const IncomeReport = React.lazy(() => import('src/screens/report/IncomeReport/summary'));
const IncomeReportDetail = React.lazy(() => import('src/screens/report/IncomeReport/detail'));

const IncomeWorkingTimeReport = React.lazy(() => import('src/screens/report/IncomeWorkingTimeReport/summary'));
const EmployeeCheckInOutReport = React.lazy(() => import('src/screens/report/EmployeeCheckInOutReport/summary'));
const PayrollReport = React.lazy(() => import('src/screens/report/Payroll/list'));

const SummaryReport = React.lazy(() => import('src/screens/report/SummaryReport/summary'));
const SummaryReportDetail = React.lazy(() => import('src/screens/report/SummaryReport/detail'));

const StoreReport = React.lazy(() => import('src/screens/report/StoreReport/summary'));
const StoreReportDetail = React.lazy(() => import('src/screens/report/StoreReport/detail'));

const CustomerReport = React.lazy(() => import('src/screens/report/CustomerReport/summary'));
const CustomerReportDetail = React.lazy(() => import('src/screens/report/CustomerReport/detail'));

const DailyReport = React.lazy(() => import('src/screens/report/DailyReport/summary'));
const DailyReportDetail = React.lazy(() => import('src/screens/report/DailyReport/detail'));

const GiftcardReport = React.lazy(() => import('src/screens/report/GiftcardReport/report'));

const ServiceProductReport = React.lazy(() => import('src/screens/report/ServiceProductReport/summary'));
const ServiceProductReportDetail = React.lazy(() => import('src/screens/report/ServiceProductReport/detail'));

const BookingAppt = React.lazy(() => import('src/screens/pos/BookingAppt/list'));

const Employees = React.lazy(() => import('src/screens/pos/Employees'));

const Settings = React.lazy(() => import('src/screens/admin/Settings'));

// WALK IN
const Walkins = React.lazy(() => import('src/screens/pos/Walkin'));
const TvWalkins = React.lazy(() => import('src/screens/pos/Walkin/list-tv'));
const WalkinSignIn = React.lazy(() => import('src/screens/pos/Walkin/signin'));
const WalkinSignUp = React.lazy(() => import('src/screens/pos/Walkin/signup'));
const WalkinQuickEmployees = React.lazy(() => import('src/screens/pos/Walkin/quick-employee'));
const WalkinQuickServices = React.lazy(() => import('src/screens/pos/Walkin/quick-service'));

const CustomerReview = React.lazy(() => import('src/screens/pos/Walkin/review'));
const TurnSystem = React.lazy(() => import('src/screens/pos/TurnSystem'));

// SEND SMS
const SmsTemplate = React.lazy(() => import('src/screens/admin/Sms/Templates'));
const SmsReminder = React.lazy(() => import('src/screens/admin/Sms/Reminder'));
const SmsReportSummary = React.lazy(() => import('src/screens/admin/Sms/Report/summary'));
const SmsReportDetails = React.lazy(() => import('src/screens/admin/Sms/Report/detail'));

// EMARKETING
const EmarketingTemplate = React.lazy(() => import('src/screens/admin/Emarketing/Templates'));
const EmarketingCampaign = React.lazy(() => import('src/screens/admin/Emarketing/Campaigns'));
const EmarketingSentSummary = React.lazy(() => import('src/screens/admin/Emarketing/Report/summary'));
const EmarketingSentDetails = React.lazy(() => import('src/screens/admin/Emarketing/Report/detail'));

const ProductList = React.lazy(() => import('src/screens/admin/Product'));
const CategoryList = React.lazy(() => import('src/screens/admin/Category'));
const UnitsList = React.lazy(() => import('src/screens/admin/Units'));
const PackageList = React.lazy(() => import('src/screens/admin/ProductPackage'));
const ExpenseList = React.lazy(() => import('src/screens/admin/Expense'));

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes: AppRouteProps[] = [
  // { path: '/', name: 'Home' },
  {
    path: '/test',
    component: Test,
  },
  {
    path: PAGE_PATH.Plan,
    exact: true,
    requireAuth: false,
    layout: 'plan',
    name: 'Plan',
    screen: 'plan',
    module: 'plan',
    component: Plan,
  },
  {
    path: PAGE_PATH.Login,
    exact: true,
    requireAuth: false,
    layout: 'login',
    name: 'Login',
    screen: 'login',
    module: 'auth',
    component: Login,
  },
  {
    path: PAGE_PATH.Logout,
    exact: true,
    requireAuth: false,
    layout: 'logout',
    name: 'Logout',
    screen: 'logout',
    module: 'auth',
    component: Logout,
  },

  {
    path: PAGE_PATH.ReceiptById,
    exact: true,
    requireAuth: false,
    layout: 'receipt',
    name: 'Receipt',
    screen: 'receipt',
    module: 'sale',
    component: Receipt,
  },
  {
    path: PAGE_PATH.Error_403,
    exact: true,
    requireAuth: false,
    layout: 'error',
    name: '403',
    screen: '403',
    module: 'error',
    component: Page403,
  },
  {
    path: PAGE_PATH.Error_404,
    exact: true,
    requireAuth: false,
    layout: 'error',
    name: '404',
    screen: '404',
    module: 'error',
    component: Page404,
  },
  {
    path: PAGE_PATH.Error_500,
    exact: true,
    requireAuth: false,
    layout: 'error',
    name: '500',
    screen: '500',
    module: '500',
    component: Page500,
  },
  {
    path: PAGE_PATH.Dashboard,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'dashboard',
    screen: 'dashboard',
    module: 'dashboard',
    component: Dashboard,
  },
  {
    path: PAGE_PATH.EmployeeCheckIn,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'employeeCheckIn',
    screen: 'employee_checkin',
    module: 'sale',
    component: Employees,
  },
  {
    path: PAGE_PATH.EmployeeSales,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'quickSale',
    screen: 'pos',
    module: 'sale',
    component: EmployeeSales,
  },
  {
    path: PAGE_PATH.EmployeeSalesParams,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'sale',
    screen: 'quick_pos',
    module: 'sale',
    component: EmployeeSales,
  },
  {
    path: PAGE_PATH.Walkins,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'customerCheckIn',
    screen: 'walkin',
    module: 'walkin',
    component: Walkins,
  },

  {
    path: PAGE_PATH.TurnSystem,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'turnSystem',
    screen: 'turn_system',
    module: 'turn_system',
    component: TurnSystem,
  },
  {
    path: PAGE_PATH.Schedule,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'schedule',
    screen: 'schedule',
    module: 'schedule',
    component: BookingAppt,
  },

  {
    path: PAGE_PATH.WalkinsTv,
    exact: true,
    requireAuth: true,
    layout: 'walkin_tv',
    name: 'customerCheckIn',
    screen: 'walkin_tv',
    module: 'walkin',
    component: TvWalkins,
  },
  {
    path: PAGE_PATH.WalkinSignIn,
    exact: true,
    requireAuth: false,
    layout: 'walkin',
    name: 'customerCheckIn',
    screen: 'walkin_signin',
    module: 'walkin',
    component: WalkinSignIn,
  },
  {
    path: PAGE_PATH.WalkinSignUp,
    exact: true,
    requireAuth: false,
    layout: 'walkin',
    name: 'customerSignup',
    screen: 'walkin_signup',
    module: 'walkin',
    component: WalkinSignUp,
  },
  {
    path: PAGE_PATH.WalkinQuickEmployees,
    exact: true,
    requireAuth: false,
    layout: 'walkin',
    name: 'walkinSigninEmployee',
    screen: 'walkin_signin_employee',
    module: 'walkin',
    component: WalkinQuickEmployees,
  },
  {
    path: PAGE_PATH.WalkinQuickServices,
    exact: true,
    requireAuth: false,
    layout: 'walkin',
    name: 'walkinSigninService',
    screen: 'walkin_signin_service',
    module: 'walkin',
    component: WalkinQuickServices,
  },
  {
    path: PAGE_PATH.CustomerReview,
    exact: true,
    requireAuth: false,
    layout: 'walkin',
    name: 'customerReview',
    screen: 'customer_review',
    module: 'walkin',
    component: CustomerReview,
  },
  {
    path: PAGE_PATH.CustomerList,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'customer',
    screen: 'customer',
    module: 'customer',
    component: CustomerList,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.CustomerGroup,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'customerGroup',
    screen: 'customer_group',
    module: 'customer_group',
    component: CustomerGroup,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.Memberships,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'membership',
    screen: 'membership',
    module: 'membership',
    component: Memberships,
    auth: {
      roles: ['admin'],
    },
  },

  {
    path: PAGE_PATH.EmarketingCampaign,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'emarketingCampaign',
    screen: 'emarketing_campaign',
    module: 'emarketing',
    component: EmarketingCampaign,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.EmarketingTemplate,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'emarketingTemplate',
    screen: 'emarketing_template',
    module: 'emarketing',
    component: EmarketingTemplate,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.EmarketingSentSummary,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'emarketingReport',
    screen: 'emarketing_report_summary',
    module: 'emarketing',
    component: EmarketingSentSummary,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.EmarketingSentDetails,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'emarketingReportDetails',
    screen: 'emarketing_report_Details',
    module: 'emarketing',
    component: EmarketingSentDetails,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.SmsReminder,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'smsReminder',
    screen: 'sms_reminder',
    module: 'sms',
    component: SmsReminder,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.SmsTemplate,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'smsTemplate',
    screen: 'sms_template',
    module: 'sms',
    component: SmsTemplate,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.SmsReportSummary,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'smsReport',
    screen: 'sms_report_summary',
    component: SmsReportSummary,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.SmsReportDetails,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'smsReportDetail',
    screen: 'sms_report_details',
    component: SmsReportDetails,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.Giftcardlist,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'giftcard',
    screen: 'giftcard',
    module: 'giftcard',
    component: Giftcardlist,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.Couponlist,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'coupon',
    screen: 'coupon',
    module: 'coupon',
    component: Couponlist,
    auth: {
      roles: ['admin'],
    },
  },

  // report
  {
    path: PAGE_PATH.IncomeReport,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'employeeIncomeReport',
    screen: 'employee_income_report',
    module: 'report',
    component: IncomeReport,
    auth: {
      requirePinCode: true,
    },
  },
  {
    path: PAGE_PATH.IncomeReportDetail,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'employeeIncomeReportDetail',
    screen: 'employee_income_report_detail',
    module: 'report',
    component: IncomeReportDetail,
    auth: {
      requirePinCode: true,
    },
  },
  {
    path: PAGE_PATH.IncomeWorkingTimeReport,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'employeeIncomeHrReport',
    screen: 'employee_income_hr_report',
    module: 'report',
    component: IncomeWorkingTimeReport,
    auth: {
      requirePinCode: true,
    },
  },
  {
    path: PAGE_PATH.SummaryReport,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'summaryReport',
    screen: 'summary_report',
    module: 'report',
    component: SummaryReport,
    auth: {
      requirePinCode: true,
    },
  },
  {
    path: PAGE_PATH.SummaryReportDetail,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'summaryReportDetail',
    screen: 'summary_report_detail',
    module: 'report',
    component: SummaryReportDetail,
    auth: {
      requirePinCode: true,
    },
  },
  {
    path: PAGE_PATH.StoreReport,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'storeReport',
    screen: 'store_report',
    module: 'report',
    component: StoreReport,
    auth: {
      requirePinCode: true,
    },
  },
  {
    path: PAGE_PATH.StoreReportDetail,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'storeReportDetail',
    screen: 'store_report_detail',
    module: 'report',
    component: StoreReportDetail,
    auth: {
      requirePinCode: true,
    },
  },
  {
    path: PAGE_PATH.DailyReport,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'dailyReport',
    screen: 'daily_report',
    module: 'report',
    component: DailyReport,
    auth: {
      requirePinCode: true,
    },
  },
  {
    path: PAGE_PATH.DailyReportDetail,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'dailyReportDetail',
    screen: 'daily_report_detail',
    module: 'report',
    component: DailyReportDetail,
    auth: {
      requirePinCode: true,
    },
  },
  {
    path: PAGE_PATH.CustomerReport,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'customerReport',
    screen: 'customer_report',
    module: 'report',
    component: CustomerReport,
    auth: {
      requirePinCode: true,
    },
  },
  {
    path: PAGE_PATH.CustomerReportDetail,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'customerReportDetail',
    screen: 'customer_report_detail',
    module: 'report',
    component: CustomerReportDetail,
    auth: {
      requirePinCode: true,
    },
  },
  {
    path: PAGE_PATH.GiftcardReport,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'giftcardReport',
    screen: 'giftcard_report',
    module: 'report',
    component: GiftcardReport,
    auth: {
      requirePinCode: true,
    },
  },
  {
    path: PAGE_PATH.EmployeeCheckInOutReport,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'employeeCheckInoutReport',
    screen: 'employee_check_inout_report',
    module: 'report',
    component: EmployeeCheckInOutReport,
  },
  {
    path: PAGE_PATH.PayrollReport,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'payrollReport',
    screen: 'payroll_report',
    module: 'report',
    component: PayrollReport,
  },
  {
    path: PAGE_PATH.ServiceProductReport,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'analysisItem',
    screen: 'service_product_report',
    module: 'report',
    component: ServiceProductReport,
    auth: {
      requirePinCode: true,
    },
  },
  {
    path: PAGE_PATH.ServiceProductReportDetail,
    exact: true,
    requireAuth: true,
    layout: 'pos',
    name: 'analysisItemDetail',
    screen: 'service_product_report_detail',
    module: 'report',
    component: ServiceProductReportDetail,
    auth: {
      requirePinCode: true,
    },
  },

  // back-office
  {
    path: PAGE_PATH.Members,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'member',
    screen: 'member',
    module: 'member',
    component: EmployeeList,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.Suppliers,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'supplier',
    screen: 'supplier',
    module: 'supplier',
    component: Suppliers,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.CategoryList,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'category',
    screen: 'category',
    module: 'category',
    component: CategoryList,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.UnitsList,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'unit',
    screen: 'unit',
    module: 'unit',
    component: UnitsList,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.ExpenseList,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'expense',
    screen: 'expense',
    module: 'expense',
    component: ExpenseList,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.ServiceList,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'service',
    screen: 'service',
    module: 'service',
    component: ServiceList,
    auth: {
      roles: ['admin'],
    },
  },

  {
    path: PAGE_PATH.PackageList,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'package',
    screen: 'package',
    module: 'package',
    component: PackageList,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.ProductList,
    exact: true,
    requireAuth: true,
    name: 'product',
    screen: 'product',
    module: 'product',
    layout: 'default',
    component: ProductList,
    auth: {
      roles: ['admin'],
    },
  },
  {
    path: PAGE_PATH.Settings,
    exact: true,
    requireAuth: true,
    layout: 'default',
    name: 'setting',
    screen: 'setting',
    module: 'setting',
    component: Settings,
    auth: {
      roles: ['admin', 'owner'],
    },
  },
];

export default routes;
