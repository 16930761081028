import { all, call, put, takeEvery } from 'redux-saga/effects';
import { API_ENDPOINTS } from '../../../../config/api-endpoints/support';
import { axiosInstance } from '../../../../utils/axios/axios-instance';
import * as actions from './actions';

// Handle request saga
function* getOrdering(action: ReturnType<typeof actions.getOrdering.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, `${API_ENDPOINTS.SUPPORT.Ordering}`, {
      params: action.payload,
    });
    yield put(actions.getOrdering.success((response as any).data));
  } catch (err) {
    yield put(actions.getOrdering.failure(err));
  }
}

// Main saga
export default function* supportSaga() {
  yield all([takeEvery(actions.getOrdering.request, getOrdering)]);
}
