import { createAsyncAction, createAction } from 'typesafe-actions';
import { SmsReminder } from 'src/state/models/sms';
import {
  SmsReminderListResponse,
  SmsReminderDetailResponse,
  SmsReminderDeleteResponse,
  SmsReminderUpdateResponse,
  SmsReminderCreateResponse,
  SmsReminderBulkResponse,
  SmsReminderListRequest,
  SmsReminderBulkRequest,
  SmsReminderUpdateStatusRequest,
  SmsReminderUpdateStatusResponse,
  SmsReminderUpdateRequest,
  SmsReminderCreateRequest,
} from 'src/state/api-models/sms-reminder';
import * as types from './types';

// Create the set of async actions
export const getSmsReminderList = createAsyncAction(
  types.FETCH_LIST,
  types.FETCH_LIST_SUCCESS,
  types.FETCH_LIST_FAILED
)<SmsReminderListRequest, SmsReminderListResponse, any>();

// Create the set of async actions
export const getSmsReminderDetails = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<SmsReminder['id'] | undefined, SmsReminderDetailResponse, any>();

// Create the set of async actions
export const createSmsReminder = createAsyncAction(types.CREATE, types.CREATE_SUCCESS, types.CREATE_FAILED)<
  SmsReminderCreateRequest,
  SmsReminderCreateResponse,
  any
>();

// Create the set of async actions
export const updateSmsReminder = createAsyncAction(types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILED)<
  SmsReminderUpdateRequest,
  SmsReminderUpdateResponse,
  any
>();

// Create the set of async actions
export const updateStatus = createAsyncAction(
  types.UPDATE_STATUS,
  types.UPDATE_STATUS_SUCCESS,
  types.UPDATE_STATUS_FAILED
)<SmsReminderUpdateStatusRequest, SmsReminderUpdateStatusResponse, any>();

// Create the set of async actions
export const deleteSmsReminder = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  SmsReminder['id'],
  SmsReminderDeleteResponse,
  any
>();

export const bulkSmsReminder = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  SmsReminderBulkRequest,
  SmsReminderBulkResponse,
  any
>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
