export const API_ENDPOINTS = {
  EMARKETING_CAMPAIGN: {
    List: '/emarketing/campaigns',
    Create: '/emarketing/campaigns',
    Details: '/emarketing/campaigns/{id}',
    Update: '/emarketing/campaigns/{id}',
    UpdateStatus: '/emarketing/campaigns/{id}/status',
    Delete: '/emarketing/campaigns/{id}',
    Bulk: '/emarketing/campaigns/bulk',
  },
};
