export const API_ENDPOINTS = {
  TURN_SYSTEM: {
    GetTurnList: '/pos/turn-system',
    GetTurnServices: '/pos/turn-system/services',
    CreateTurnDetail: '/pos/turn-system',
    GetTurnDetail: '/pos/turn-system/{id}',
    UpdateTurnDetail: '/pos/turn-system/{id}',
    DeleteTurnDetail: '/pos/turn-system/{id}',
    GetReorderList: '/pos/turn-system/reorder',
    ChangeReorder: '/pos/turn-system/reorder',
  },
};
