import { PAGE_PATH } from 'src/routers/route-path';
import { Screens } from './screens';
import { Modules } from './modules';
import { Policy } from './types';

export const getDenyAllPolicies = (): Policy[] =>
  Modules.map((item) => ({
    ...item,
    is_readable: false,
    is_writable: false,
    enable: false,
    can_set_readable: false,
    can_set_writable: false,
    can_set_enable: false,
  }));

export const getAllowAllPolicies = (): Policy[] =>
  Modules.map((item) => ({
    ...item,
    enable: true,
    is_readable: true,
    is_writable: true,
    can_set_readable: true,
    can_set_writable: true,
    can_set_enable: true,
  }));

export const getDefaultPoliciesWithAllowed = (allowedPolicies: Policy[]) => {
  const denyAll = getDenyAllPolicies();
  return denyAll.map((item) => {
    const allowItem = allowedPolicies.find((policy) => policy.value === item.value);
    if (allowItem) return { ...item, ...allowItem };
    return { ...item };
  });
};

export const getDefaultPoliciesWithDenied = (deniedPolicies: Policy[]) => {
  const allowAll = getAllowAllPolicies();
  return allowAll.map((item) => {
    const denyItem = deniedPolicies.find((policy) => policy.value === item.value);
    if (denyItem) return { ...item, ...denyItem };
    return { ...item };
  });
};

export const getAllScreens = () => Screens;

export const getScreensWithDenied = (deniedScreens: Screen[]) => {
  const allowAll = getAllScreens();
  return allowAll.map((item) => {
    const denyItem = deniedScreens.find((deny) => String(deny) === item.value);
    if (denyItem) return { ...item, ...denyItem };
    return { ...item };
  });
};

//   { value: 'employee_checkin', label: 'Employees check-in dashboard' },
//   { value: 'schedule', label: 'Schedule dashboard' },
//   { value: 'turn_system', label: 'Take turn dashboard' },
//   { value: 'walkin', label: 'Walk-in dashboard' },
//   { value: 'dashboard', label: 'Dashboard' },
export const getPageUrl = (page: string) => {
  switch (page) {
    case 'employee_checkin':
      return PAGE_PATH.EmployeeCheckIn;
    case 'schedule':
      return PAGE_PATH.Schedule;
    case 'turn_system':
      return PAGE_PATH.TurnSystem;
    case 'walkin':
      return PAGE_PATH.Walkins;
    case 'dashboard':
      return PAGE_PATH.Dashboard;
    case 'print_receipt':
      return PAGE_PATH.ReceiptById;
    default:
      return PAGE_PATH.Dashboard;
  }
};
