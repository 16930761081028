import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { IncomeStateAll, initialStateAll } from './model';

const incomeWorkingTime = produce((draft: Draft<IncomeStateAll>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getIncomeSummaryList.request): {
      draft.incomeSummary.loading = true;
      return draft;
    }
    case getType(actions.getIncomeSummaryList.success): {
      draft.incomeSummary.loading = true;
      draft.incomeSummary.response = action.payload;
      return draft;
    }
    case getType(actions.getIncomeSummaryList.failure): {
      draft.incomeSummary.loading = false;
      draft.incomeSummary.errors = action.payload;
      return draft;
    }

    case getType(actions.getReportSummary.request): {
      draft.reportSummary.loading = true;
      draft.reportSummary.response = undefined;
      draft.reportSummary.errors = undefined;
      return draft;
    }
    case getType(actions.getReportSummary.success): {
      draft.reportSummary.loading = true;
      draft.reportSummary.response = action.payload;

      return draft;
    }
    case getType(actions.getReportSummary.failure): {
      draft.reportSummary.loading = false;
      draft.reportSummary.errors = action.payload;
      return draft;
    }

    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialStateAll;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialStateAll);

export default incomeWorkingTime;
