import moment, { Moment, unitOfTime } from 'moment';
import { DateTimeDuration, QuickDate } from 'src/types/date-time';
import { toUTCDateTime } from './format/date-time-format';

export const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const isValidDateTime = (value: string | Date | Moment) => moment(value).isValid();

export const getStartEndDateByQuickType = (code: QuickDate) => {
  let fromDate;
  let toDate;
  const curr = new Date(); // get current date

  const lastSunday = curr.getDate() - curr.getDay(); // Last sunday
  const lastMonday = lastSunday - 6; // Last monday

  switch (code) {
    // Today
    case QuickDate.Today:
      fromDate = getDateWithStartTime();
      toDate = new Date();
      break;

    // This Week
    case QuickDate.ThisWeek:
      fromDate = getDateWithStartTime();
      if (lastSunday === curr.getDate()) {
        fromDate.setDate(lastSunday - 6); // Last Monday
      } else {
        fromDate.setDate(lastSunday + 1); // Monday
      }

      toDate = new Date(); // Today
      break;

    // ThisMonth
    case QuickDate.ThisMonth:
      fromDate = getDateWithStartTime();
      fromDate.setDate(1); // first day in month

      toDate = new Date(); // Today
      break;

    // ThisYear
    case QuickDate.ThisYear:
      fromDate = getDateWithStartTime();
      fromDate.setMonth(0);
      fromDate.setDate(1); //first day in year

      toDate = new Date(); // Today
      break;

    // Last Week
    case QuickDate.LastWeek:
      fromDate = getDateWithStartTime();
      fromDate.setDate(lastMonday); // Last monday

      toDate = getDateWithEndTime();
      toDate.setDate(lastMonday + 6); // Last sunday
      break;

    // Last 2 Week
    case QuickDate.Last2Weeks:
      fromDate = getDateWithStartTime();
      fromDate.setDate(lastMonday - 7); // The monday before last

      toDate = getDateWithEndTime();
      toDate.setDate(lastMonday + 6); // The sunday before last
      break;

    // Last 3 Week
    case QuickDate.Last3Weeks:
      fromDate = getDateWithStartTime();
      fromDate.setDate(lastMonday - 14); // The monday before before last

      toDate = getDateWithEndTime();
      toDate.setDate(lastMonday + 6); // The sunday before before last
      break;

    // Last 1 Month
    case QuickDate.LastMonth:
      fromDate = getDateWithStartTime();
      fromDate.setMonth(curr.getMonth() - 1);
      fromDate.setDate(1); //first day in last month

      toDate = getDateWithEndTime();
      toDate.setMonth(fromDate.getMonth() + 1);
      toDate.setDate(0); //last day in last month
      break;

    // Last 2 Month
    case QuickDate.Last2Months:
      fromDate = getDateWithStartTime();
      fromDate.setMonth(curr.getMonth() - 2);
      fromDate.setDate(1); //first day in last month

      toDate = getDateWithEndTime();
      toDate.setMonth(fromDate.getMonth() + 2);
      toDate.setDate(0); //last day in last month
      break;

    // Last 3 Month
    case QuickDate.Last3Months:
      fromDate = getDateWithStartTime();
      fromDate.setMonth(curr.getMonth() - 3);
      fromDate.setDate(1); //first day in last month

      toDate = getDateWithEndTime();
      toDate.setMonth(fromDate.getMonth() + 3);
      toDate.setDate(0); //last day in last month
      break;

    // Last 6 Month
    case QuickDate.Last6Months:
      fromDate = getDateWithStartTime();
      fromDate.setMonth(curr.getMonth() - 6);
      fromDate.setDate(1); //first day in last month

      toDate = getDateWithEndTime();
      toDate.setMonth(fromDate.getMonth() + 6);
      toDate.setDate(0); //last day in last month
      break;

    // Last 1 Year'
    case QuickDate.LastYear:
      fromDate = getDateWithStartTime();
      fromDate.setFullYear(curr.getFullYear() - 2);
      fromDate.setDate(1); //first day in last year

      toDate = getDateWithEndTime();
      toDate.setFullYear(curr.getFullYear() - 1);
      toDate.setDate(0); //last day in last year
      break;

    default:
      fromDate = new Date('1900-01-01');
      toDate = new Date();
      break;
  }

  return {
    startDate: fromDate.toISOString(),
    endDate: toDate.toISOString(),
  };
};

export const getDateWithStartTime = (date?: Date) => {
  const startDate = date ? new Date(date) : new Date();
  startDate.setHours(0, 0, 0, 0);
  return startDate;
};

export const getDateWithStartTime1AM = (date?: Date) => {
  const startDate = date ? new Date(date) : new Date();
  startDate.setHours(1, 0, 0, 0);
  return startDate;
};

export const getDateWithEndTime = (date?: Date) => {
  const endDate = date ? new Date(date) : new Date();
  endDate.setHours(23, 59, 59, 999);
  return endDate;
};

export function getDateWorkingStartTime(date?: Date) {
  const newDate = date ? new Date(date) : new Date();

  newDate.setHours(8, 0, 0, 0);
  return moment(newDate).toDate();
}

export function getDateWorkingEndTime(date?: Date) {
  const newDate = date ? new Date(date) : new Date();

  newDate.setHours(21, 0, 0, 0);
  return moment(newDate).toDate();
}

export const getDate = () => {
  return moment().format('YYYY-MM-DD');
};

export const getEndDate = () => {
  return moment().add(5, 'years').format('YYYY-MM-DD');
};

export const getDateTime = (isEnd = false, time = 5, format = 'years') => {
  return moment().format('YYYY-MM-DDTHH:mm:ss');
};

export const getEndDateTime = (time = 5, format = 'years') => {
  const duration = moment.duration({ [format]: time });

  return moment().add(duration).format('YYYY-MM-DDTHH:mm:ss');
};

export const getWaitingTime = (startTime: string | Date | Moment, currentTime?: string | Date | Moment) => {
  const mStart = moment(startTime);
  const mCurrent = moment(currentTime);
  const diff = mCurrent.diff(mStart);

  if (diff <= 0) return '00:00';

  const duration = moment.duration(diff);
  const hours = duration.hours() >= 10 ? duration.hours() : `0${duration.hours()}`;
  const minutes = duration.minutes() >= 10 ? duration.minutes() : `0${duration.minutes()}`;
  return `${hours}:${minutes}`;
};

export const getDurationMinutes = (startTime: string, endTime: string) => {
  const startDateTime = moment(startTime);
  const endDateTime = moment(endTime);
  return endDateTime.diff(startDateTime, 'minutes');
};

export const combineDateTime = (date: string, time: string) => {
  const effectiveMoment = moment(`${date}T${time}`);
  return effectiveMoment.toDate();
};

export const getDayInNextWeek = (date: Date, week: number) =>
  moment(date)
    .add(week * 7, 'day')
    .toDate();

export const getUnitsInRange = (start: Date | Moment, end: Date | Moment, unit: unitOfTime.Diff = 'day') => {
  let current = moment(start);
  const endDate = moment(end);
  const days = [];
  while (current.diff(endDate, unit) <= 0) {
    days.push(current.toDate());
    current = current.add(1, unit);
  }
  return days;
};

export const startOf = (given: string | Date | Moment, unit: unitOfTime.StartOf) => {
  return moment(given).startOf(unit);
};

export const endOf = (given: string | Date | Moment, unit: unitOfTime.StartOf) => {
  return moment(given).endOf(unit);
};

export const minutesOfDay = (m) => {
  const date = new Date(m);
  return date.getMinutes() + date.getHours() * 60;
};

export const isBefore = (
  given: string | Date | Moment,
  compared?: string | Date | Moment,
  unit?: unitOfTime.StartOf
) => {
  const givenDate = moment(given);
  const comparedDate = moment(compared);
  return givenDate.isBefore(comparedDate, unit);
};

export const isBeforeMinute = (given: string | Date | Moment, compared?: string | Date | Moment) => {
  return minutesOfDay(given) > minutesOfDay(compared);
};

export const isSameOrBefore = (
  given: string | Date | Moment,
  compared?: string | Date | Moment,
  unit?: unitOfTime.StartOf
) => {
  const givenDate = moment(given);
  const comparedDate = moment(compared);

  return givenDate.isSameOrBefore(comparedDate, unit);
};

export const isAfter = (
  given: string | Date | Moment,
  compared?: string | Date | Moment,
  unit?: unitOfTime.StartOf
) => {
  const givenDate = moment(given);
  const comparedDate = moment(compared);
  return givenDate.isAfter(comparedDate, unit);
};

export const isAfterMinute = (given: string | Date | Moment, compared?: string | Date | Moment) => {
  return minutesOfDay(given) < minutesOfDay(compared);
};

export const isSameOrAfter = (
  given: string | Date | Moment,
  compared?: string | Date | Moment,
  unit?: unitOfTime.StartOf
) => {
  const givenDate = moment(given);
  const comparedDate = moment(compared);

  return givenDate.isSameOrAfter(comparedDate, unit);
};

export const isSame = (given: string | Date | Moment, compared?: string | Date | Moment, unit?: unitOfTime.StartOf) => {
  const givenDate = moment(given);
  const comparedDate = moment(compared);

  return givenDate.isSame(comparedDate, unit);
};

export const isDiffDays = (given: string | Date | Moment, compared?: string | Date | Moment) => {
  const givenDate = moment(given);
  const comparedDate = moment(compared);

  // Difference in number of days
  return moment.duration(givenDate.diff(comparedDate)).asDays();
};

export const isDiffHours = (given: string | Date | Moment, compared?: string | Date | Moment) => {
  const givenDate = moment(given);
  const comparedDate = moment(compared);

  // Difference in number of hours
  return moment.duration(givenDate.diff(comparedDate)).asHours();
};

export const isDiffMinutes = (given: string | Date | Moment, compared?: string | Date | Moment) => {
  const givenDate = moment(given);
  const comparedDate = moment(compared);

  // Difference in number of hours
  return moment.duration(givenDate.diff(comparedDate)).asMinutes();
};

export const isDateInPast = (given: string | Date | Moment) => isDiffDays(given) <= -1;

export const addDateTime = (given: string | Date | Moment, amount: number, unit: unitOfTime.DurationConstructor) => {
  const givenDate = moment(given);

  return moment(givenDate).add(amount, unit);
};

export const getDateTimeDuration = (values: DateTimeDuration) => {
  if (values.time_type === QuickDate.Other) {
    let _effective_start = values.effective_start || toUTCDateTime(getDateWithStartTime(new Date()));
    let _effective_end = values.effective_end || toUTCDateTime(getDateWithEndTime(new Date()));

    if (_effective_start.indexOf('T') === -1) {
      _effective_start = toUTCDateTime(getDateWithStartTime(new Date(values.effective_start + ' 00:00:00')));
      _effective_end = toUTCDateTime(getDateWithEndTime(new Date(values.effective_end + ' 23:59:59')));
    }

    return {
      effective_start: _effective_start,
      effective_end: _effective_end,
      time_type: values.time_type,
    };
  }

  if (!values.time_type || values.time_type === QuickDate.All) {
    return {
      time_type: values.time_type,
      effective_start: undefined,
      effective_end: undefined,
    };
  }

  const duration = getStartEndDateByQuickType(values.time_type);
  return {
    effective_start: toUTCDateTime(getDateWithStartTime(new Date(duration.startDate))),
    effective_end: toUTCDateTime(getDateWithEndTime(new Date(duration.endDate))),
    time_type: values.time_type,
  };
};

export const getLocalTime = (dateOfValue, isTime = false) => {
  if (isTime) {
    return moment.utc(dateOfValue).local().format('YYYY-MM-DD HH:mm:ss');
  } else {
    return moment.utc(dateOfValue).local().format('YYYY-MM-DD');
  }
};

export const isAfterTime = (given: string | Date | Moment, compared?: string | Date | Moment) => {
  return moment(moment(given).format('HH:mm:ss a')).isAfter(moment(moment(compared).format('HH:mm:ss a')));
};

export const isBeforeTime = (given: string | Date | Moment, compared?: string | Date | Moment) => {
  return moment(moment(given).format('HH:mm:ss a')).isBefore(moment(moment(compared).format('HH:mm:ss a')));
};

export const displayDateTimeFormat = (value: string | Date | Moment, format: string, isLocal?: boolean | false) => {
  if (isValidDateTime(value)) {
    return isLocal ? moment.utc(value).local().format(format) : moment(value).format(format);
  }
  return '';
};

export const getBlockDateFromWeek = function (week, year) {
  let returnData = {
    start: null,
    end: null,
  };
  if (week && year) {
    let startOfDate = moment().day('Monday').year(year).week(week).toDate();
    returnData['start'] = startOfDate;
    returnData['end'] = moment(startOfDate).add(6, 'days').toDate();
  }
  return returnData;
};

export const displayFormatDuration = (totalMinute) => {
  let minuteNum = parseInt(totalMinute);
  let hours = Math.floor(minuteNum / 60);
  let minutes = Math.floor(minuteNum - hours * 60);
  let shours = hours.toString();
  let sminutes = minutes.toString();
  if (hours < 10) {
    shours = '0' + hours.toString();
  }
  if (minutes < 10) {
    sminutes = '0' + minutes.toString();
  }
  return shours + ':' + sminutes + '"';
};
