import { createAction, createAsyncAction } from 'typesafe-actions';
import { OrderItem, OrderMeta, PaymentData, SaleOrderData, TipData } from 'src/state/models/order';
import { Employee } from 'src/state/models/employee';
import { Customer } from 'src/state/models/customer';
import {
  GiftcardDetailResponse,
  GiftcardHistoriesRequest,
  GiftcardHistoriesResponse,
} from 'src/state/api-models/giftcard';
import { EmployeeListRequest, EmployeeListResponse } from 'src/state/api-models/employee';
import {
  CustomerCreateResponse,
  CustomerDetailResponse,
  CustomerListRequest,
  CustomerListResponse,
  CustomerUpdateResponse,
} from 'src/state/api-models/customer';
import { SettingGroup } from 'src/state/api-models/setting';
import { Category } from 'src/state/models/category';
import { BookingAppt } from 'src/state/models/bookingAppt';
import * as types from './types';
import {
  SaleCreateResponse,
  SaleDetailResponse,
  SaleFirstDataRequest,
  SaleFirstDataResponse,
  SaleServiceProductSettingRequest,
  SaleServiceProductSettingResponse,
  SaleUpdateResponse,
} from '../../api-models/sale';
import { EmployeeTurnSystem } from 'src/state/models/employee-turn-system';
import { EmployeeInOutTimeRequest, EmployeeInOutTimeResponse } from 'src/state/api-models/employee-in-out-time';
import { DiscountSetting, DiscountType } from 'src/types/discount';

// Create the set of async actions
export const getSaleServiceProductSetting = createAsyncAction(
  types.GET_SALE_SERVICE_PRODUCT_SETTING,
  types.GET_SALE_SERVICE_PRODUCT_SETTING_SUCCESS,
  types.GET_SALE_SERVICE_PRODUCT_SETTING_FAILED
)<SaleServiceProductSettingRequest, SaleServiceProductSettingResponse, any>();

// Create the set of async actions
export const getSaleInit = createAsyncAction(
  types.FETCH_INIT_DATA,
  types.FETCH_INIT_DATA_SUCCESS,
  types.FETCH_INIT_DATA_FAILED
)<SaleFirstDataRequest, SaleFirstDataResponse, any>();

// Create the set of async actions
export const getGiftcardDetails = createAsyncAction(
  types.FETCH_GIFTCARD_DETAILS,
  types.FETCH_GIFTCARD_DETAILS_SUCCESS,
  types.FETCH_GIFTCARD_DETAILS_FAILED
)<string, GiftcardDetailResponse, any>();

// Create the set of async actions
export const getGiftcardHistories = createAsyncAction(
  types.FETCH_GIFTCARD_HISTORIES,
  types.FETCH_GIFTCARD_HISTORIES_SUCCESS,
  types.FETCH_GIFTCARD_HISTORIES_FAILED
)<GiftcardHistoriesRequest, GiftcardHistoriesResponse, any>();

export const clearGiftcard = createAction(types.CLEAR_GIFTCARD)();

export const getCheckedInEmployeeList = createAsyncAction(
  types.CHECKED_IN_EMPLOYEE_LIST,
  types.CHECKED_IN_EMPLOYEE_LIST_SUCCESS,
  types.CHECKED_IN_EMPLOYEE_LIST_FAILED
)<EmployeeInOutTimeRequest, EmployeeInOutTimeResponse, any>();

// Create the set of async actions
export const getEmployeeList = createAsyncAction(
  types.FETCH_EMPLOYEE_LIST,
  types.FETCH_EMPLOYEE_LIST_SUCCESS,
  types.FETCH_EMPLOYEE_LIST_FAILED
)<EmployeeListRequest, EmployeeListResponse, any>();

// Create the set of async actions
export const getCustomerList = createAsyncAction(
  types.FETCH_CUSTOMER_LIST,
  types.FETCH_CUSTOMER_LIST_SUCCESS,
  types.FETCH_CUSTOMER_LIST_FAILED
)<CustomerListRequest, CustomerListResponse, any>();

// Create the set of async actions
export const getCustomerDetails = createAsyncAction(
  types.DETAIL_CUSTOMER,
  types.DETAIL_CUSTOMER_SUCCESS,
  types.DETAIL_CUSTOMER_FAILED
)<string, CustomerDetailResponse, any>();

export const createCustomer = createAsyncAction(
  types.CREATE_CUSTOMER,
  types.CREATE_CUSTOMER_SUCCESS,
  types.CREATE_CUSTOMER_FAILED
)<Customer, CustomerCreateResponse, any>();

export const updateCustomer = createAsyncAction(
  types.UPDATE_CUSTOMER,
  types.UPDATE_CUSTOMER_SUCCESS,
  types.UPDATE_CUSTOMER_FAILED
)<Customer, CustomerUpdateResponse, any>();

export const clearCustomer = createAction(types.CLEAR_CUSTOMER)();

// Create the set of async actions
export const createSale = createAsyncAction(types.CREATE_ORDER, types.CREATE_ORDER_SUCCESS, types.CREATE_ORDER_FAILED)<
  SaleOrderData,
  SaleCreateResponse,
  any
>();

export const updateSale = createAsyncAction(types.UPDATE_ORDER, types.UPDATE_ORDER_SUCCESS, types.UPDATE_ORDER_FAILED)<
  SaleOrderData,
  SaleUpdateResponse,
  any
>();

export const getSale = createAsyncAction(types.GET_ORDER, types.GET_ORDER_SUCCESS, types.GET_ORDER_FAILED)<
  string,
  SaleDetailResponse,
  any
>();

export const clearSaleData = createAction(types.CLEAR_CREATE_ORDER_DATA)();

// Create the set of async actions
export const setSettings = createAction(types.SET_SETTINGS)<SettingGroup>();
export const addEmployee = createAction(types.ADD_EMPLOYEE)<Employee>();
export const changeEmployee = createAction(types.CHANGE_EMPLOYEE)<Employee>();
export const setActiveCategory = createAction(types.SET_ACTIVE_CATEGORY)<Category>();

export const addOrderItem = createAction(types.ADD_ORDER_ITEM)<{
  type: number;
  item: OrderItem;
  employee?: Employee;
  employeeIndex?: number;
}>();

export const deleteOrderItem = createAction(types.DELETE_ORDER_ITEM)<{
  itemIndex: number;
  employee?: Employee;
  employeeIndex?: number;
}>();

export const updateOrderItem = createAction(types.UPDATE_ORDER_ITEM)<{
  price: number;
  itemIndex: number;
  employee?: Employee;
  employeeIndex?: number;
}>();

export const updateQtyOrderItem = createAction(types.UPDATE_QTY_ORDER_ITEM)<{
  quantity: number;
  itemIndex: number;
  employee?: Employee;
  employeeIndex?: number;
}>();

export const selectOrderItem = createAction(types.SELECT_ORDER_ITEM)<{
  orderItemIndex: number;
  employeeIndex?: number;
}>();

export const discountOrderItem = createAction(types.DISCOUNT_ORDER_ITEM)<{
  itemIndex: number;
  discount: DiscountSetting;
  employee?: Employee;
  employeeIndex?: number;
}>();

export const discountOrder = createAction(types.DISCOUNT_ORDER)<DiscountSetting>();
export const removeDiscountOrder = createAction(types.REMOVE_DISCOUNT_ORDER)<DiscountType>();

export const addTip = createAction(types.ADD_TIP)<TipData[]>();

export const addPayment = createAction(types.ADD_PAYMENT)<PaymentData>();

export const addCustomer = createAction(types.ADD_CUSTOMER)<Customer | undefined>();

export const addBooking = createAction(types.ADD_BOOKING)<BookingAppt>();

export const addTurn = createAction(types.ADD_TURN)<EmployeeTurnSystem>();

export const setOrderData = createAction(types.SET_ORDER_DATA)<SaleOrderData>();

export const setOrderMetaData = createAction(types.SET_ORDER_META_DATA)<OrderMeta>();

export const clearOrderData = createAction(types.CLEAR_ORDER_DATA)<{ clearAll?: boolean } | undefined>();

export const clearData = createAction(types.CLEAR_DATA)();
