import { createAsyncAction, createAction } from 'typesafe-actions';
import { EmployeeListRequest, EmployeeListResponse } from 'src/state/api-models/employee';
import * as types from './types';
import {
  BookingApptListRequest,
  BookingApptListResponse,
  BookingApptDeleteResponse,
  BookingApptUpdateResponse,
  BookingApptCreateResponse,
  BookingApptDetailResponse,
  BookingApptBulkResponse,
  BookingApptBulkRequest,
  BookingApptCreateRequest,
  BookingApptUpdateRequest,
  BookingApptAssignRequest,
  BookingApptAssignResponse,
  BookingApptUpdateStatusRequest,
  BookingApptUpdateStatusResponse,
  BookingApptTopServicesRequest,
  BookingApptTopServicesResponse,
  BookingApptSendSmsRequest,
  BookingApptSendSmsResponse,
  CountBookingApptInDaysResponse,
  CountBookingApptInDaysRequest,
  BookingApptUpdateColumnRequest,
  BookingApptUpdateColumnResponse,
} from '../../api-models/bookingAppt';
import {
  InputSearchBookingAppt,
  InputSearchBookingApptDetail,
  BookingApptService,
  BookingApptServiceItem,
} from '../../models/bookingAppt';

export const getEmployeeList = createAsyncAction(
  types.FETCH_EMPLOYEE_LIST,
  types.FETCH_EMPLOYEE_LIST_SUCCESS,
  types.FETCH_EMPLOYEE_LIST_FAILED
)<EmployeeListRequest, EmployeeListResponse, any>();

// Create the set of async actions
export const getBookingApptList = createAsyncAction(
  types.FETCH_LIST,
  types.FETCH_LIST_SUCCESS,
  types.FETCH_LIST_FAILED
)<BookingApptListRequest, BookingApptListResponse, any>();

// Create the set of async actions
export const getUnassignedBookingApptList = createAsyncAction(
  types.FETCH_LIST_UNASSIGNED,
  types.FETCH_LIST_UNASSIGNED_SUCCESS,
  types.FETCH_LIST_UNASSIGNED_FAILED
)<BookingApptListRequest, BookingApptListResponse, any>();

export const getNumberOfBookingsInDays = createAsyncAction(
  types.FETCH_NUMBER_OF_BOOKINGS_IN_DAYS,
  types.FETCH_NUMBER_OF_BOOKINGS_IN_DAYS_SUCCESS,
  types.FETCH_NUMBER_OF_BOOKINGS_IN_DAYS_FAILED
)<CountBookingApptInDaysRequest, CountBookingApptInDaysResponse, any>();

export const getTopBookingServices = createAsyncAction(
  types.FETCH_TOP_BOOKING_SERVICES,
  types.FETCH_TOP_BOOKING_SERVICES_SUCCESS,
  types.FETCH_TOP_BOOKING_SERVICES_FAILED
)<BookingApptTopServicesRequest, BookingApptTopServicesResponse, any>();

// Create the set of async actions
export const assingBookingAppt = createAsyncAction(types.ASSIGN, types.ASSIGN_SUCCESS, types.ASSIGN_FAILED)<
  BookingApptAssignRequest,
  BookingApptAssignResponse,
  any
>();

// Create the set of async actions
export const getBookingApptDetails = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<string, BookingApptDetailResponse, any>();

// Create the set of async actions
export const createBookingAppt = createAsyncAction(types.CREATE, types.CREATE_SUCCESS, types.CREATE_FAILED)<
  BookingApptCreateRequest,
  BookingApptCreateResponse,
  any
>();

// Create the set of async actions
export const updateBookingAppt = createAsyncAction(types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILED)<
  BookingApptUpdateRequest,
  BookingApptUpdateResponse,
  any
>();

// Create the set of async actions
export const updateBookingApptStatus = createAsyncAction(
  types.UPDATE_STATUS,
  types.UPDATE_STATUS_SUCCESS,
  types.UPDATE_STATUS_FAILED
)<BookingApptUpdateStatusRequest, BookingApptUpdateStatusResponse, any>();

// Create the set of async actions
export const deleteBookingAppt = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  string,
  BookingApptDeleteResponse,
  any
>();

// Create the set of async actions
export const bulkBookingAppt = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  BookingApptBulkRequest,
  BookingApptBulkResponse,
  any
>();

// Create the set actions
export const inputSearchBookingAppt = createAction(types.INPUTSEARCH)<InputSearchBookingAppt>();

export const setServiceBooking = createAction(types.SET_SERVICEBOOKING)<BookingApptServiceItem[]>();

export const removeServiceBooking = createAction(types.REMOVE_SERVICEBOOKING)<BookingApptServiceItem>();

export const bookingApptServiceAction = createAsyncAction(
  types.BOOKINGAPPT_SERVICE,
  types.BOOKINGAPPT_SERVICE_SUCCESS,
  types.BOOKINGAPPT_SERVICE_FAILED
)<InputSearchBookingApptDetail, BookingApptService, any>();

export const bookingApptSendSms = createAsyncAction(
  types.BOOKINGAPPT_SEND_SMS,
  types.BOOKINGAPPT_SEND_SMS_SUCCESS,
  types.BOOKINGAPPT_SEND_SMS_FAILED
)<BookingApptSendSmsRequest, BookingApptSendSmsResponse, any>();

// Create the set of async actions
export const updateBookingApptColumn = createAsyncAction(
  types.UPDATE_COLUMN,
  types.UPDATE_COLUMN_SUCCESS,
  types.UPDATE_COLUMN_FAILED
)<BookingApptUpdateColumnRequest, BookingApptUpdateColumnResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
