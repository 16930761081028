import { put, call, all, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';
import { API_ENDPOINTS } from '../../../config/api-endpoints/coupon';
import { convertToFormData } from 'src/utils/converter';

// Handle request saga
function* getCouponList(action: ReturnType<typeof actions.getCouponList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.COUPON.List, {
      params: action.payload,
    });

    yield put(actions.getCouponList.success((response as any).data));
  } catch (err) {
    yield put(actions.getCouponList.failure(err));
  }
}

function* getCouponDetails(action: ReturnType<typeof actions.getCouponDetails.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.COUPON.Details.replace('{id}', action.payload));

    yield put(actions.getCouponDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getCouponDetails.failure(err));
  }
}

function* createCoupon(action: ReturnType<typeof actions.createCoupon.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.COUPON.Create, action.payload, config));

    yield put(actions.createCoupon.success((response as any).data));
  } catch (err) {
    yield put(actions.createCoupon.failure(err));
  }
}

function* updateCoupon(action: ReturnType<typeof actions.updateCoupon.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const id = action.payload.id;
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.COUPON.Update.replace('{id}', id), action.payload, config)
    );

    yield put(actions.updateCoupon.success((response as any).data));
  } catch (err) {
    yield put(actions.updateCoupon.failure(err));
  }
}

function* deleteCoupon(action: ReturnType<typeof actions.deleteCoupon.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.delete(API_ENDPOINTS.COUPON.Delete.replace('{id}', action.payload))
    );

    yield put(actions.deleteCoupon.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteCoupon.failure(err));
  }
}

function* bulkCoupons(action: ReturnType<typeof actions.bulkCoupons.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.COUPON.Bulk, action.payload);

    yield put(actions.bulkCoupons.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkCoupons.failure(err));
  }
}

function* getHistories(action: ReturnType<typeof actions.getHistories.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.get,
      API_ENDPOINTS.COUPON.Histories.replace('{id}', action.payload.coupon_id),
      {
        params: { ...action.payload, coupon_id: undefined },
      }
    );

    yield put(actions.getHistories.success((response as any).data));
  } catch (err) {
    yield put(actions.getHistories.failure(err));
  }
}

// Main saga
export default function* couponSaga() {
  yield all([
    takeLatest(actions.getCouponList.request, getCouponList),
    takeLatest(actions.getCouponDetails.request, getCouponDetails),
    takeLatest(actions.getHistories.request, getHistories),
    takeLatest(actions.createCoupon.request, createCoupon),
    takeLatest(actions.updateCoupon.request, updateCoupon),
    takeLatest(actions.deleteCoupon.request, deleteCoupon),
    takeLatest(actions.bulkCoupons.request, bulkCoupons),
  ]);
}
