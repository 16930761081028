import { BaseState } from 'src/state/ducks/models';
import {
  EmarketingSentSummaryResponse,
  EmarketingSentDetailResponse,
  EmarketingSentDeleteResponse,
  EmarketingSentBulkResponse,
} from 'src/state/api-models/emarketing-sent';

export type EmarketingSentSummary = BaseState<EmarketingSentSummaryResponse>;
export type EmarketingSentDetails = BaseState<EmarketingSentDetailResponse>;
export type EmarketingSentDelete = BaseState<EmarketingSentDeleteResponse>;
export type EmarketingSentBulk = BaseState<EmarketingSentBulkResponse>;

export type EmarketingSentState = {
  summary: EmarketingSentSummary;
  details: EmarketingSentDetails;
  delete: EmarketingSentDelete;
  bulk: EmarketingSentBulk;
};

export const initialState = {
  summary: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
};

declare module 'src/state/ducks/models' {
  interface StateAll {
    // match to a duck folder
    'emarketing/sent': EmarketingSentState;
  }
}
