import { GiftcardReportResponse } from 'src/state/api-models/giftcard-report';
import { BaseState } from '../models';

export type ReportDetails = BaseState<GiftcardReportResponse>;

export type ReportState = {
  details: ReportDetails;
};

export type ReportStateAll = ReportState;

export const initialReportState: ReportState = {
  details: { loading: false, response: undefined },
};

export const initialStateAll: ReportStateAll = initialReportState;

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    giftcardReport: ReportStateAll;
  }
}
