import { createAsyncAction, createAction } from 'typesafe-actions';
import { EmployeeInOutTimeRequest, EmployeeInOutTimeResponse } from 'src/state/api-models/employee-in-out-time';
import * as types from './types';
import {
  WalkinListRequest,
  WalkinCustomerRequest,
  WalkinCustomerResponse,
  WalkinServiceRequest,
  WalkinServiceResponse,
  WalkinListGroupByEmployeeResponse,
  WalkinListResponse,
} from '../../api-models/walkin';
import { WalkinRegister } from '../../models/walkin';

// Create the set of async actions
export const getWalkinList = createAsyncAction(
  types.WALK_IN_LIST_REQUEST,
  types.WALK_IN_LIST_SUCCESS,
  types.WALK_IN_LIST_FAILED
)<WalkinListRequest, WalkinListResponse, any>();

export const getWalkinListGroupByEmployee = createAsyncAction(
  types.WALK_IN_LIST_GROUP_BY_EMPLOYEE_REQUEST,
  types.WALK_IN_LIST_GROUP_BY_EMPLOYEE_SUCCESS,
  types.WALK_IN_LIST_GROUP_BY_EMPLOYEE_FAILED
)<WalkinListRequest, WalkinListGroupByEmployeeResponse, any>();

// Create the set of async actions
export const getWalkinEmloyeeList = createAsyncAction(
  types.ENABLE_WALIK_EMPLOYEES,
  types.ENABLE_WALIK_EMPLOYEES_SUCCESS,
  types.ENABLE_WALIK_EMPLOYEES_FAILED
)<EmployeeInOutTimeRequest, EmployeeInOutTimeResponse, any>();

export const checkinCustomer = createAsyncAction(
  types.WALK_IN_CUSTOMERS_REQUEST,
  types.WALK_IN_CUSTOMERS_SUCCESS,
  types.WALK_IN_CUSTOMERS_FAILED
)<WalkinCustomerRequest, WalkinCustomerResponse, any>();

export const getServicesOfEmployee = createAsyncAction(
  types.WALK_IN_SERVICES_REQUEST,
  types.WALK_IN_SERVICES_SUCCESS,
  types.WALK_IN_SERVICES_FAILED
)<WalkinServiceRequest, WalkinServiceResponse, any>();

export const refreshCurrentTime = createAction(types.REFERSH_CURRENT_TIME)<string | Date>();

export const setWalkinRegister = createAction(types.WALK_IN_REGISTER)<WalkinRegister>();
export const setWalkInSuccess = createAction(types.SET_WALKIN_SUCCESS)<any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
