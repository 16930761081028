export const AUTH = {
  Login: '/login',
  LoginToken: '/login-token',
  Logout: '/logout',
  Profile: '/profile',
  CheckPinCode: '/role-by-code',
  ChangePass: '/change-pass',
  InitialSettings: '/initial-settings',
  InitialGeneralSettings: '/initial-settings/general',
  InitialStoreSettings: '/initial-settings/store/{store_id}',
};
