import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';
import {
  ServiceProductReportListRequest,
  ServiceProductReportListResponse,
  ServiceProductReportDetailRequest,
  ServiceProductReportDetailResponse
} from '../../api-models/service-product';

export const getServiceProductReportList = createAsyncAction(
  types.FETCH_LIST,
  types.FETCH_LIST_SUCCESS,
  types.FETCH_LIST_FAILED
) <ServiceProductReportListRequest, ServiceProductReportListResponse, any>();

export const getServiceProductReportDetails = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<ServiceProductReportDetailRequest, ServiceProductReportDetailResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
