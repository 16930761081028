import { BaseState } from '../models';
import {
  EmployeeInOutTimeResponse,
  ClockInOutTimeResponse,
  EmployeeInOutTimesInDateDeleteResponse,
  EmployeeInOutTimesInDateDetailResponse,
  EmployeeInOutTimesInDateUpdateResponse,
  EmployeeInOutTimesSummaryResponse,
  ReportEmployeeInOutTimesSummaryResponse,
} from '../../api-models/employee-in-out-time';

export type EmployeeInOutTime = BaseState<EmployeeInOutTimeResponse> & { reloadedAt?: Date };
export type ClockInOutTime = BaseState<ClockInOutTimeResponse>;
export type SummaryEmployeeInOutList = BaseState<EmployeeInOutTimesSummaryResponse>;
export type ReportSummaryEmployeeInOutList = BaseState<ReportEmployeeInOutTimesSummaryResponse>;
export type DetailEmployeeInOutList = BaseState<EmployeeInOutTimesInDateDetailResponse>;
export type DeleteReportEmployeeInOut = BaseState<EmployeeInOutTimesInDateDeleteResponse>;
export type EmployeeInOutTimesInDateDetail = BaseState<EmployeeInOutTimesInDateDetailResponse>;
export type UpdateReportEmployeeInOut = BaseState<EmployeeInOutTimesInDateUpdateResponse>;
export type ClockInOutState = {
  checkedInEmployee: EmployeeInOutTime;
  clockInOutTime: ClockInOutTime;
  summary: SummaryEmployeeInOutList;
  reportSummary: ReportSummaryEmployeeInOutList;
  delete: DeleteReportEmployeeInOut;
  details: EmployeeInOutTimesInDateDetail;
  update: UpdateReportEmployeeInOut;
};

export const initialClockInOutState: ClockInOutState = {
  checkedInEmployee: { loading: false, response: undefined },
  clockInOutTime: { loading: false, response: undefined },
  summary: { loading: false, response: undefined },
  reportSummary: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    // match to reducer name, each reducer will be one field in state
    clockInOut: ClockInOutState;
  }
}
