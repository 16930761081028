export const API_ENDPOINTS = {
  CATEGORIES: {
    List: '/categories',
    Create: '/categories',
    Details: '/categories/{id}',
    Update: '/categories/{id}',
    Delete: '/categories/{id}',
    Bulk: '/categories/bulk',
  },
};
