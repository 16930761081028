import produce, { Draft } from 'immer';
import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { CheckPinCodeState, initialAuthState, LoginState, ProfileState, InitialSettingsState } from './model';

const login = produce((draft: Draft<LoginState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.loginToken.request):
    case getType(actions.login.request):
      draft.loading = true;
      return draft;

    case getType(actions.loginToken.success):
    case getType(actions.login.success):
      draft.loading = false;
      draft.response = action.payload;
      return draft;

    case getType(actions.loginToken.failure):
    case getType(actions.login.failure):
      draft.loading = false;
      draft.errors = action.payload;
      return draft;

    case getType(actions.logout.request): {
      return draft;
    }

    case getType(actions.updateInitialSettings): {
      if (draft.response && draft.response.data) {
        draft.response.data.initial_settings = action.payload;
      }
      return draft;
    }

    case getType(actions.updateProfile.success): {
      if (draft.response && draft.response.data && draft.response.data.user) {
        draft.response.data.user = { ...draft.response.data.user, ...action.payload.data };
      }
      return draft;
    }

    case getType(actions.clearData): {
      draft = { loading: false, response: undefined };
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialAuthState.login);

const checkPinCode = produce((draft: Draft<CheckPinCodeState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.checkPinCode.request): {
      draft.loading = true;
      draft.response = undefined;
      draft.errors = undefined;
      return draft;
    }
    case getType(actions.checkPinCode.success): {
      draft.loading = true;
      draft.response = action.payload;

      return draft;
    }
    case getType(actions.checkPinCode.failure): {
      draft.loading = false;
      draft.errors = action.payload;
      return draft;
    }
    case getType(actions.clearPinCodeData): {
      draft = initialAuthState.checkPinCode;
      return draft;
    }

    default: {
      return draft;
    }
  }
}, initialAuthState.checkPinCode);

const initialSettings = produce((draft: Draft<InitialSettingsState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getInitialSettings.request): {
      draft.loading = true;
      draft.response = undefined;
      draft.errors = undefined;
      return draft;
    }
    case getType(actions.getInitialSettings.success): {
      draft.loading = true;
      draft.response = action.payload;

      return draft;
    }
    case getType(actions.getInitialSettings.failure): {
      draft.loading = false;
      draft.errors = action.payload;
      return draft;
    }

    default: {
      return draft;
    }
  }
}, initialAuthState.checkPinCode);

const profile = produce((draft: Draft<ProfileState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.updateProfile.request): {
      draft.loading = true;
      draft.response = undefined;
      draft.errors = undefined;
      return draft;
    }
    case getType(actions.updateProfile.success): {
      draft.loading = true;
      draft.response = action.payload;

      return draft;
    }
    case getType(actions.updateProfile.failure): {
      draft.loading = false;
      draft.errors = action.payload;
      return draft;
    }
    case getType(actions.clearProfileData): {
      draft = initialAuthState.profile;
      return draft;
    }

    default: {
      return draft;
    }
  }
}, initialAuthState.checkPinCode);

export default combineReducers({
  login,
  checkPinCode,
  initialSettings,
  profile,
});
