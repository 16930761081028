import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints/sms/template';
import { axiosInstance } from '../../../../utils/axios/axios-instance';
import * as actions from './actions';
import { removeCodeList } from 'src/utils/axios/axios-fecth';

// Handle request saga
function* getSmsTemplateList(action: ReturnType<typeof actions.getSmsTemplateList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.SMS_TEMPLATE.List, {
      params: action.payload,
    });

    yield put(actions.getSmsTemplateList.success((response as any).data));
  } catch (err) {
    yield put(actions.getSmsTemplateList.failure(err));
  }
}

function* getSmsTemplateDetails(action: ReturnType<typeof actions.getSmsTemplateDetails.request>): Generator {
  try {
    let response: any = {};
    if (action.payload)
      response = yield call(axiosInstance.get, API_ENDPOINTS.SMS_TEMPLATE.Details.replace('{id}', `${action.payload}`));

    yield put(actions.getSmsTemplateDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getSmsTemplateDetails.failure(err));
  }
}

function* createSmsTemplate(action: ReturnType<typeof actions.createSmsTemplate.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.SMS_TEMPLATE.Create, action.payload);

    yield put(actions.createSmsTemplate.success((response as any).data));
    yield removeCodeList('sms_templates');
  } catch (err) {
    yield put(actions.createSmsTemplate.failure(err));
  }
}

function* updateSmsTemplate(action: ReturnType<typeof actions.updateSmsTemplate.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.post,
      API_ENDPOINTS.SMS_TEMPLATE.Update.replace('{id}', `${action.payload.id}`),
      action.payload
    );

    yield put(actions.updateSmsTemplate.success((response as any).data));
    yield removeCodeList('sms_templates');
  } catch (err) {
    yield put(actions.updateSmsTemplate.failure(err));
  }
}

function* deleteSmsTemplate(action: ReturnType<typeof actions.deleteSmsTemplate.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.delete,
      API_ENDPOINTS.SMS_TEMPLATE.Delete.replace('{id}', `${action.payload}`)
    );

    yield put(actions.deleteSmsTemplate.success((response as any).data));
    yield removeCodeList('sms_templates');
  } catch (err) {
    yield put(actions.deleteSmsTemplate.failure(err));
  }
}

function* bulkSmsTemplate(action: ReturnType<typeof actions.bulkSmsTemplates.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.SMS_TEMPLATE.Bulk, action.payload);

    yield put(actions.bulkSmsTemplates.success((response as any).data));
    yield removeCodeList('sms_templates');
  } catch (err) {
    yield put(actions.bulkSmsTemplates.failure(err));
  }
}

// Main saga
export default function* smsSaga() {
  yield all([
    takeLatest(actions.getSmsTemplateList.request, getSmsTemplateList),
    takeLatest(actions.getSmsTemplateDetails.request, getSmsTemplateDetails),
    takeLatest(actions.createSmsTemplate.request, createSmsTemplate),
    takeLatest(actions.updateSmsTemplate.request, updateSmsTemplate),
    takeLatest(actions.deleteSmsTemplate.request, deleteSmsTemplate),
    takeLatest(actions.bulkSmsTemplates.request, bulkSmsTemplate),
  ]);
}
