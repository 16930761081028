import { TaxSetting } from 'src/state/models/common';
import { CalculateData, CalculateType } from 'src/types/calculate';
import { DiscountData, DiscountSetting } from 'src/types/discount';
import { toNumber } from './converter';

export const roundValue = (value: number, decimalPlaces = 2) => {
  const p = Math.pow(10, decimalPlaces);
  return Math.round(value * p) / p;
};

export const calculateAmount = (price: number | string, adjust?: CalculateData) => {
  let amount = toNumber(price);
  if (adjust) {
    if (String(adjust.type) === CalculateType.Percent) {
      amount *= 1 + toNumber(adjust.value) / 100;
    } else if (String(adjust.type) === CalculateType.Amount) {
      amount += toNumber(adjust.value);
    }
  }

  return roundValue(amount);
};

export const calculateAmountWithDiscount = (price: number | string, discount?: DiscountData) => {
  let amount = toNumber(price);
  if (discount) {
    if (String(discount.type) === CalculateType.Percent) {
      amount *= 1 - toNumber(discount.value) / 100;
    } else if (String(discount.type) === CalculateType.Amount) {
      amount = Math.max(amount - toNumber(discount.value), 0);
    }
  }

  return roundValue(amount);
};

export const calculateAmountWithMultiDiscounts = (price: number | string, discounts?: DiscountSetting[]) => {
  const amount = toNumber(price);
  if (amount === 0) return 0;

  let calulatorAmount = amount;
  if (discounts) {
    discounts.forEach((discountItem: DiscountSetting) => {
      calulatorAmount = calculateAmountWithDiscount(calulatorAmount, discountItem.discount);
    });
  }

  return calulatorAmount;
};

export const calculateTipAmount = (price: number | string, tipData?: CalculateData) => {
  let amount = toNumber(price);
  if (tipData) {
    if (String(tipData.type) === CalculateType.Percent) {
      amount = (amount * toNumber(tipData.value)) / 100;
    } else if (String(tipData.type) === CalculateType.Amount) {
      amount = toNumber(tipData.value);
    }
  }

  return roundValue(amount);
};

export const calculateDiscountAmount = (price: number | string, discount?: DiscountData) => {
  let amount = toNumber(price);
  if (discount) {
    if (String(discount.type) === CalculateType.Percent) {
      amount = (amount * toNumber(discount.value)) / 100;
    } else if (String(discount.type) === CalculateType.Amount) {
      amount = Math.min(amount, toNumber(discount.value));
    }
  }

  return roundValue(amount);
};

export const calculateCommissionAmount = (price: number | string, commission?: CalculateData) => {
  let amount = toNumber(price);
  if (commission) {
    if (String(commission.type) === CalculateType.Percent) {
      amount = (amount * toNumber(commission.value)) / 100;
    } else if (String(commission.type) === CalculateType.Amount) {
      amount = Math.min(amount, toNumber(commission.value));
    }
  }

  return roundValue(amount);
};

export const calculatePointToAmount = (point: number | string, pointPerAmount = 100) => {
  const pointNumber = toNumber(point);
  const pointAmount = pointNumber / pointPerAmount;
  return pointAmount;
};

export const toCalcTaxSetting = (taxSetting?: TaxSetting) => {
  if (taxSetting) {
    // convert string "0,5,8" to array [0, 5, 8]
    const { taxOnTaxes, taxes, indianTax } = taxSetting;
    let taxesList = [];
    if (taxes) {
      taxesList = taxes.split(',').map((item) => toNumber(item));
    }
    return { taxOnTaxes, indianTax, taxes: taxesList };
  }
};

/**
 * calculte tax
 * Amount: 100
 * Taxes: [3,5,8]
 *
 * On Taxes 0:
 *    $calulatorAmountTax = Amount
 *    $tax = ($calulatorAmountTax/100)*3 + ($calulatorAmountTax/100)*5 + ($calulatorAmountTax/100)*8
 *
 * On Taxes 1:
 *    $calulatorAmountTax = Amount + (Amount/100)*3;
 *    $tax = (Amount/100)*3 + ($calulatorAmountTax/100) * 5 + ($calulatorAmountTax/100)*8
 *
 * On Taxes 2:
 *    $calulatorAmountTax = Amount + (Amount/100)*5;
 *    $tax = (Amount/100)*3 + (Amount/100)*5 + ($calulatorAmountTax/100)*8
 */
export const calculateTaxAmount = (price: number | string, taxSetting?: TaxSetting) => {
  const amount = toNumber(price);

  if (amount === 0) return 0;

  const calcTaxSetting = toCalcTaxSetting(taxSetting);

  let taxAmount = 0;

  if (calcTaxSetting) {
    const { taxOnTaxes, taxes, indianTax } = calcTaxSetting;

    if (taxes && taxes.length > 0) {
      if (indianTax === '1') {
        // in case of indian tax, only calculate taxes[0]
        taxAmount = (taxes[0] / 100) * amount;
        return taxAmount;
      }

      const reducer = (accumulator, taxItem, taxIndex) => {
        if (taxOnTaxes === 0 || taxIndex < taxOnTaxes) {
          return accumulator + (taxItem / 100) * amount;
        }
        return accumulator + (taxItem / 100) * accumulator;
      };
      const totalAmount = taxes.reduce(reducer, amount);

      taxAmount = totalAmount - amount;
    }
  }

  return taxAmount;
};

/**
 * calculte amount with tax
 * amount: 100
 * taxOnTaxes: 0, taxes: [3,5,8]
 *    tax: 100x3% + 100x5% + 100x8% = 3 + 5 + 8
 * taxOnTaxes: 1, taxes: [3,5,8]
 *    tax: 100x3% + (100+100x3%) x 5% + (100+100x3%)x8% = 3+ 103x5% + 103x8%
 */
export const calculateAmountWithTax = (price: number | string, taxSetting?: TaxSetting) => {
  const amount = toNumber(price);
  const tax = calculateTaxAmount(amount, taxSetting);
  return amount + tax;
};


