export const API_ENDPOINTS = {
  CUSTOMERGROUP: {
    List: '/customer-groups',
    Create: '/customer-groups',
    Details: '/customer-groups/{id}',
    Update: '/customer-groups/{id}',
    Delete: '/customer-groups/{id}',
    Bulk: '/customer-groups/bulk',
    CheckHasCustomer: '/customer-groups/{id}/check-has-customer',
  },
};
