export const FETCH_SUMMARY = 'daily-report/summary';
export const FETCH_SUMMARY_SUCCESS = 'daily-report/summary_success';
export const FETCH_SUMMARY_FAILED = 'daily-report/summary_failed';

export const FETCH_PRINT_SUMMARY = 'daily-report/print_summary';
export const FETCH_PRINT_SUMMARY_SUCCESS = 'daily-report/print_summary_success';
export const FETCH_PRINT_SUMMARY_FAILED = 'daily-report/print_summary_failed';

export const FETCH_DETAILS = 'daily-report/details_summary';
export const FETCH_DETAILS_SUCCESS = 'daily-report/details_summary_success';
export const FETCH_DETAILS_FAILED = 'daily-report/details_summary_failed';

export const FETCH_PRINT_DETAILS = 'daily-report/print_details_summary';
export const FETCH_PRINT_DETAILS_SUCCESS = 'daily-report/print_details_summary_success';
export const FETCH_PRINT_DETAILS_FAILED = 'daily-report/print_details_summary_failed';

export const CLEAR_DATA = 'daily-report/clear_data';

export type IncomeAction =
  | typeof FETCH_SUMMARY
  | typeof FETCH_SUMMARY_FAILED
  | typeof FETCH_SUMMARY_SUCCESS
  | typeof FETCH_DETAILS
  | typeof FETCH_DETAILS_FAILED
  | typeof FETCH_DETAILS_SUCCESS
  | typeof CLEAR_DATA;
