import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { ExpenseState, initialExpenseState } from './model';

const expense = produce((draft: Draft<ExpenseState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getExpenseList.request): {
      draft.list.loading = true;
      return draft;
    }
    case getType(actions.getExpenseList.success): {
      draft.list.loading = true;
      (draft.list.response as any) = action.payload;
      return draft;
    }
    case getType(actions.getExpenseList.failure): {
      draft.list.loading = false;
      draft.list.errors = action.payload;
      return draft;
    }
    case getType(actions.createExpense.request): {
      draft.details.loading = true;
      draft.details.response = undefined;
      return draft;
    }
    case getType(actions.createExpense.success): {
      draft.create.loading = false;
      draft.create.response = action.payload;
      return draft;
    }
    case getType(actions.createExpense.failure): {
      draft.create.loading = false;
      draft.create.errors = action.payload;
      return draft;
    }
    case getType(actions.getExpenseDetails.request): {
      draft.details.loading = true;
      draft.details.response = undefined;
      draft.details.errors = undefined;
      return draft;
    }
    case getType(actions.getExpenseDetails.success): {
      draft.details.loading = true;
      draft.details.response = action.payload;
      return draft;
    }
    case getType(actions.getExpenseDetails.failure): {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    case getType(actions.updateExpense.request): {
      draft.update.loading = true;
      draft.update.response = undefined;
      return draft;
    }
    case getType(actions.updateExpense.success): {
      draft.update.loading = false;
      draft.update.response = action.payload;
      return draft;
    }
    case getType(actions.updateExpense.failure): {
      draft.update.loading = false;
      draft.update.errors = action.payload;
      return draft;
    }
    case getType(actions.deleteExpense.request): {
      draft.delete.loading = true;
      draft.delete.response = undefined;
      return draft;
    }
    case getType(actions.deleteExpense.success): {
      draft.delete.loading = false;
      draft.delete.response = action.payload;
      return draft;
    }
    case getType(actions.deleteExpense.failure): {
      draft.delete.loading = false;
      draft.delete.errors = action.payload;
      return draft;
    }
    case getType(actions.bulkExpense.request): {
      draft.bulk.loading = true;
      draft.bulk.response = undefined;
      return draft;
    }
    case getType(actions.bulkExpense.success): {
      draft.bulk.loading = false;
      draft.bulk.response = action.payload;
      return draft;
    }
    case getType(actions.bulkExpense.failure): {
      draft.bulk.loading = false;
      draft.bulk.errors = action.payload;
      return draft;
    }
    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialExpenseState;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialExpenseState);

export default expense;
