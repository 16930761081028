export const API_ENDPOINTS = {
  UNITS: {
    List: '/units',
    Create: '/units',
    Details: '/units/{id}',
    Update: '/units/{id}',
    Delete: '/units/{id}',
    Bulk: '/units/bulk',
  },
};
