export const FETCH_ORDERING = 'support/ordering';
export const FETCH_ORDERING_SUCCESS = 'support/ordering_success';
export const FETCH_ORDERING_FAILED = 'support/ordering_failed';

export const CLEAR_DATA = 'support/clear_data';

export type SupportAction =
  | typeof FETCH_ORDERING
  | typeof FETCH_ORDERING_SUCCESS
  | typeof FETCH_ORDERING_FAILED
  | typeof CLEAR_DATA;
