import { put, call, all, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';
import { API_ENDPOINTS } from '../../../config/api-endpoints';

function* getIncomeSummary(action: ReturnType<typeof actions.getIncomeSummary.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.DASHBOARD_REPORT.IncomeSummary, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getIncomeSummary.success((response as any).data));
  } catch (err) {
    yield put(actions.getIncomeSummary.failure(err));
  }
}

function* getIncomeDetails(action: ReturnType<typeof actions.getIncomeDetails.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.DASHBOARD_REPORT.IncomeDetails, {
      params: action.payload,
    });

    yield put(actions.getIncomeDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getIncomeDetails.failure(err));
  }
}

function* getSummaryPayments(action: ReturnType<typeof actions.getSummaryPayments.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.DASHBOARD_REPORT.PaymentSummary, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getSummaryPayments.success((response as any).data));
  } catch (err) {
    yield put(actions.getSummaryPayments.failure(err));
  }
}

function* getBirthdayCustomers(action: ReturnType<typeof actions.getBirthdayCustomers.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.CUSTOMER_REPORT.BrithdayCustomers, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getBirthdayCustomers.success((response as any).data));
  } catch (err) {
    yield put(actions.getBirthdayCustomers.failure(err));
  }
}

function* getTotalCustomers(action: ReturnType<typeof actions.getTotalCustomers.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.CUSTOMER_REPORT.TotalCustomers, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getTotalCustomers.success((response as any).data));
  } catch (err) {
    yield put(actions.getTotalCustomers.failure(err));
  }
}

// Main saga
export default function* incomeSaga() {
  yield all([
    takeLatest(actions.getIncomeSummary.request, getIncomeSummary),
    takeLatest(actions.getIncomeDetails.request, getIncomeDetails),
    takeLatest(actions.getSummaryPayments.request, getSummaryPayments),
    takeLatest(actions.getBirthdayCustomers.request, getBirthdayCustomers),
    takeLatest(actions.getTotalCustomers.request, getTotalCustomers),
  ]);
}
