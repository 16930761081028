import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  PaymentBulkRequest,
  PaymentBulkResponse,
  PaymentSaleResponse,
  PaymentVoidResponse,
  PaymentStatusResponse,
  PaymentListRequest,
  PaymentListResponse,
  PaymentReturnResponse,
  PaymentSaleRequest,
  PaymentReturnRequest,
  PaymentVoidRequest,
  PaymentTipAdjustResponse,
  PaymentTipAdjustRequest,
  PaymentStatusRequest,
  PaymentMomoRequest,
  PaymentMomoResponse,
  PaymentVietQrRequest,
  PaymentVietQrResponse,
} from '../../api-models/sale-payment';
import * as types from './types';

// Create the set of async actions
export const getPaymentList = createAsyncAction(types.FETCH_LIST, types.FETCH_LIST_SUCCESS, types.FETCH_LIST_FAILED)<
  PaymentListRequest,
  PaymentListResponse,
  any
>();

// Create the set of async actions
export const getPaymentStatus = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<PaymentStatusRequest, PaymentStatusResponse, any>();

// Create the set of async actions
export const salePayment = createAsyncAction(types.SALE_PAYMENT, types.SALE_PAYMENT_SUCCESS, types.SALE_PAYMENT_FAILED)<
  PaymentSaleRequest,
  PaymentSaleResponse,
  any
>();

// Create the set of async actions
export const tipAdjustPayment = createAsyncAction(
  types.RETURN_TIP_ADJUST,
  types.RETURN_TIP_ADJUST_SUCCESS,
  types.RETURN_TIP_ADJUST_FAILED
)<PaymentTipAdjustRequest, PaymentTipAdjustResponse, any>();

// Create the set of async actions
export const refundPayment = createAsyncAction(
  types.RETURN_PAYMENT,
  types.RETURN_PAYMENT_SUCCESS,
  types.RETURN_PAYMENT_FAILED
)<PaymentReturnRequest, PaymentReturnResponse, any>();

// Create the set of async actions
export const voidPayment = createAsyncAction(types.VOID_PAYMENT, types.VOID_PAYMENT_SUCCESS, types.VOID_PAYMENT_FAILED)<
  PaymentVoidRequest,
  PaymentVoidResponse,
  any
>();

export const bulkPayments = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  PaymentBulkRequest,
  PaymentBulkResponse,
  any
>();

export const paymentMomo = createAsyncAction(types.PAYMENT_MOMO, types.PAYMENT_MOMO_SUCCESS, types.PAYMENT_MOMO_FAILED)<
  PaymentMomoRequest,
  PaymentMomoResponse,
  any
>();

export const paymentVietQr = createAsyncAction(types.PAYMENT_VIET_QR, types.PAYMENT_VIET_QR_SUCCESS, types.PAYMENT_VIET_QR_FAILED)<
  PaymentVietQrRequest,
  PaymentVietQrResponse,
  any
>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
