import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';
import { GiftcardReportRequest, GiftcardReportResponse } from 'src/state/api-models/giftcard-report';

// Create the set of async actions
export const getDetailList = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<GiftcardReportRequest, GiftcardReportResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
