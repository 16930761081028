export const API_ENDPOINTS = {
  EMARKETING_TEMPLATE: {
    List: '/emarketing/templates',
    Create: '/emarketing/templates',
    Details: '/emarketing/templates/{id}',
    Update: '/emarketing/templates/{id}',
    Delete: '/emarketing/templates/{id}',
    Bulk: '/emarketing/templates/bulk',
  },
};
