export const FETCH_LIST = 'report/services-products/list';
export const FETCH_LIST_SUCCESS = 'report/services-products/list_success';
export const FETCH_LIST_FAILED = 'report/services-products/list_failed';

export const FETCH_DETAILS = 'report/services-products/details';
export const FETCH_DETAILS_SUCCESS = 'report/services-products/details_success';
export const FETCH_DETAILS_FAILED = 'report/services-products/details_failed';

export const CLEAR_DATA = 'report/services-products/clear_data';

export type ProductAction =
  | typeof FETCH_DETAILS
  | typeof FETCH_DETAILS_FAILED
  | typeof FETCH_DETAILS_SUCCESS
  | typeof FETCH_LIST
  | typeof FETCH_LIST_FAILED
  | typeof FETCH_LIST_SUCCESS
  | typeof CLEAR_DATA;
