import { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { authActions } from 'src/state/ducks/common/auth';
import { loadingActions } from 'src/state/ducks/common/loading';
import { getTokenValue } from 'src/state/ducks/common/auth/storage';
import { showToastMessage } from 'src/components/admin/message/toast-message';
import { uuidv4 } from '../generator';
import { debounce } from '../debounce-throttle';

export const requestAuthHandler = (store) => (request: AxiosRequestConfig) => {
  // Modify request here
  const token = getTokenValue();
  if (token) {
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
};

export const requestConvertFormDataHandler = (store) => (request: AxiosRequestConfig) => {
  if (request.data && !(request.data instanceof FormData)) {
    request.data = qs.stringify(request.data);
  }

  return request;
};

export const requestPaginationHandler = (store) => (request: AxiosRequestConfig) => {
  // modify pagination params ?page=1&per_page=10

  if (request.params && request.params.pagination) {
    Object.keys(request.params.pagination).forEach((key) => {
      request.params[key] = request.params.pagination[key];
    });

    request.params.pagination = undefined;
  }
  // if (request.params && request.params.filter) {
  //   Object.keys(request.params.filter).forEach((key) => {
  //     request.params[key] = request.params.filter[key];
  //   });
  //   request.params.filter = undefined;
  // }

  return request;
};

export const requestLoadingHandler = (store) => (request: AxiosRequestConfig) => {
  if (!request.skipLoadingHandler) {
    const apiRequestId = uuidv4();
    request.apiRequestId = apiRequestId;

    store.dispatch(loadingActions.addLoadingApi(apiRequestId));
  }

  return request;
};

export const responseLoadingHandler = (store) => (response: AxiosResponse) => {
  if (!response.config.skipLoadingHandler) {
    const { apiRequestId } = response.config;

    store.dispatch(loadingActions.removeLoadingApi(apiRequestId));
  }

  return response;
};

export const errorLoadingHandler = (store) => (error: any) => {
  if (!error.response) {
    store.dispatch(loadingActions.clearData());
  } else if (!error.response.config.skipLoadingHandler) {
    const { apiRequestId } = error.response.config;

    store.dispatch(loadingActions.removeLoadingApi(apiRequestId));
  }

  return error;
};

export const errorHandler = (store) => (error: any) => {
  // Handle errors

  if (typeof error !== 'undefined') {
    // Setup Error Message
  }

  if (error.response) {
    // Setup Generic Response Messages
    if (error.response.status === 401) {
      // 'UnAuthorized';
      store.dispatch(authActions.clearData());
    } else if (error.response.status === 403) {
      // Forbidden
    } else if (error.response.status === 404) {
      // 'API Route is Missing or Undefined';
    } else if (error.response.status === 405) {
      // 'API Route Method Not Allowed';
    } else if (error.response.status === 422) {
      // Validation Message
    } else if (error.response.status >= 500) {
      // 'Server Error';
    }

    // Try to Use the Response Message
  } else {
    // network error
    debounce(
      1000,
      showToastMessage,
      'Unable to connect to server. Please check your Internet connection and try again',
      'error'
    );
  }
  return Promise.reject(error);
};

// Handle responses
export const successHandler = (store) => (response: AxiosResponse) => response;
