import produce, { Draft } from 'immer';

import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { CategoryState, initialCategoryState } from './model';

const category = produce((draft: Draft<CategoryState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getCategoryList.request): {
      draft.list.loading = true;
      return draft;
    }
    case getType(actions.getCategoryList.success): {
      draft.list.loading = true;
      (draft.list.response as any) = action.payload;
      return draft;
    }
    case getType(actions.getCategoryList.failure): {
      draft.list.loading = false;
      draft.list.errors = action.payload;
      return draft;
    }
    case getType(actions.getCategoryDetails.request): {
      draft.details.loading = true;
      draft.details.response = undefined;
      draft.details.errors = undefined;
      return draft;
    }
    case getType(actions.getCategoryDetails.success): {
      draft.details.loading = true;
      draft.details.response = action.payload;
      return draft;
    }
    case getType(actions.getCategoryDetails.failure): {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    case getType(actions.createCategory.request): {
      draft.details.loading = true;
      draft.details.response = undefined;
      return draft;
    }
    case getType(actions.createCategory.success): {
      draft.create.loading = false;
      draft.create.response = action.payload;
      return draft;
    }
    case getType(actions.createCategory.failure): {
      draft.create.loading = false;
      draft.create.errors = action.payload;
      return draft;
    }
    case getType(actions.updateCategory.request): {
      draft.update.loading = true;
      draft.update.response = undefined;
      return draft;
    }
    case getType(actions.updateCategory.success): {
      draft.update.loading = false;
      draft.update.response = action.payload;
      return draft;
    }
    case getType(actions.updateCategory.failure): {
      draft.update.loading = false;
      draft.update.errors = action.payload;
      return draft;
    }
    case getType(actions.deleteCategory.request): {
      draft.delete.loading = true;
      draft.delete.response = undefined;
      return draft;
    }
    case getType(actions.deleteCategory.success): {
      draft.delete.loading = false;
      draft.delete.response = action.payload;
      return draft;
    }
    case getType(actions.deleteCategory.failure): {
      draft.delete.loading = false;
      draft.delete.errors = action.payload;
      return draft;
    }
    case getType(actions.bulkCategory.request): {
      draft.bulk.loading = true;
      draft.bulk.response = undefined;
      return draft;
    }
    case getType(actions.bulkCategory.success): {
      draft.bulk.loading = false;
      draft.bulk.response = action.payload;
      return draft;
    }
    case getType(actions.bulkCategory.failure): {
      draft.bulk.loading = false;
      draft.bulk.errors = action.payload;
      return draft;
    }
    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialCategoryState;
      }
      return draft;
    }

    default: {
      return draft;
    }
  }
}, initialCategoryState);

export default category;
