import { AnyAction, combineReducers } from 'redux';

import codeReducers from './common/code/reducers';
import initialSettingReducers from './common/setting-initial/reducers';
import storeSettingReducers from './common/setting-store/reducers';
import settingReducers from './common/setting/reducers';
import authReducers from './common/auth/reducers';
import loadingReducers from './common/loading/reducers';
import supportReducers from './common/support/reducers';
import globalReducers from './common/global/reducers';

import saleMgntReducers from './sale-mgnt/reducers';
import saleReducers from './sale-pos/reducers';
import salePaymentReducers from './sale-payment/reducers';
import waitingSaleReducers from './waiting-sale/reducers';

import dashboardReducers from './dashboard/reducers';
import storeReportReducers from './store-report/reducers';
import dailyReportReducers from './daily-report/reducers';
import incomeReducers from './income-report/reducers';
import incomeWorkingTimeReducers from './income-working-time-report/reducers';
import customerReportReducers from './customer-report/reducers';
import giftcardReportReducers from './giftcard-report/reducers';
import serviceProductReportReducers from './service-product-report/reducers';

import payrollReducers from './payroll/reducers';

import employeeReducers from './employee/reducers';
import serviceReducers from './service/reducers';
import productReducers from './product/reducers';

import productKitReducers from './product-kit/reducers';

import customerReduers from './customer/reducers';
import customerGroupReduers from './customerGroup/reducers';

import membershipReducers from './membership/reducers';
import supplierReducers from './supplier/reducers';
import storeReducers from './store/reducers';
import giftcardReducers from './giftcard/reducers';
import walkinReducers from './walkin/reducers';
import bookingApptReducers from './bookingAppt/reducers';
import clockInOutReducers from './clockInOut/reducers';

import smsTemplateReducers from './sms/template/reducers';
import smsReminderReducers from './sms/reminder/reducers';
import smsLogsReducers from './sms/logs/reducers';

import emarketingTemplateReducers from './emarketing/template/reducers';
import emarketingCampaignReducers from './emarketing/campaign/reducers';
import emarketingSentReducers from './emarketing/sent/reducers';

import categoryReducers from './category/reducers';
import unitsReducers from './unit/reducers';
import couponReducers from './coupon/reducers';

import turnSystemReducers from './turn-system/reducers';
import expenseReducers from './expense/reducers';

import * as rootActions from './actions';
import { getType } from 'typesafe-actions';

const appReducer = combineReducers({
  'common/code': codeReducers,
  'common/setting': settingReducers,
  'common/initialSetting': initialSettingReducers,
  'common/storeSetting': storeSettingReducers,
  'common/auth': authReducers,
  'common/loading': loadingReducers,
  'common/support': supportReducers,
  'common/global': globalReducers,

  saleMgnt: saleMgntReducers,
  sale: saleReducers,
  salePayment: salePaymentReducers,
  waitingSale: waitingSaleReducers,

  dashboard: dashboardReducers,
  storeReport: storeReportReducers,
  dailyReport: dailyReportReducers,
  income: incomeReducers,
  incomeWorkingTime: incomeWorkingTimeReducers,
  customerReport: customerReportReducers,
  giftcardReport: giftcardReportReducers,
  serviceProductReport: serviceProductReportReducers,

  payroll: payrollReducers,

  employee: employeeReducers,
  service: serviceReducers,
  customer: customerReduers,
  customerGroup: customerGroupReduers,
  membership: membershipReducers,
  supplier: supplierReducers,
  store: storeReducers,
  giftcard: giftcardReducers,
  walkin: walkinReducers,
  clockInOut: clockInOutReducers,
  bookingAppt: bookingApptReducers,
  productKit: productKitReducers,

  'sms/template': smsTemplateReducers,
  'sms/reminder': smsReminderReducers,
  'sms/logs': smsLogsReducers,
  'emarketing/template': emarketingTemplateReducers,
  'emarketing/campaign': emarketingCampaignReducers,
  'emarketing/sent': emarketingSentReducers,

  category: categoryReducers,
  product: productReducers,
  coupon: couponReducers,

  turnSystem: turnSystemReducers,
  expense: expenseReducers,

  // session,
  unit: unitsReducers,
});

export const rootReducers = (
  state: ReturnType<typeof appReducer>,
  action: AnyAction
) => {

  if (action.type === getType(rootActions.clearAllData)) {
    return {};
  }

  return appReducer(state, action);
};
