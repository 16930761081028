import {
  SettingData,
  SettingListResponse,
  SettingUpdateManyResponse,
  SettingUpdateResponse,
  SettingConnectPaymentResponse,
} from 'src/state/api-models/setting';
import { BaseState } from '../../models';

export type SettingListState = BaseState<SettingListResponse>;
export type SettingUpdateState = BaseState<SettingUpdateResponse> & {};
export type SettingUpdateManyState = BaseState<SettingUpdateManyResponse>;
export type SettingConnectPaymentState = BaseState<SettingConnectPaymentResponse>;

export type SettingDataState = { updatedAt?: string } & { [storeId: string]: SettingData };
export type SettingState = {
  data: SettingDataState;
  list: SettingListState;
  update: SettingUpdateState;
  updateMany: SettingUpdateManyState;
  connectPayment: SettingConnectPaymentState;
};

export const initialSettingState: SettingState = {
  data: {},
  list: { loading: false },
  update: { loading: false },
  updateMany: { loading: false },
  connectPayment: { loading: true },
};

declare module './../../models' {
  interface StateAll {
    // match to a duck folder
    'common/storeSetting': SettingState;
  }
}
