import { BaseState } from '../models';
import {
  ProductKitListResponse,
  ProductKitDetailResponse,
  ProductKitDeleteResponse,
  ProductKitUpdateResponse,
  ProductKitCreateResponse,
  ProductKitBulkResponse,
  ProductKitImportResponse,
} from '../../api-models/product-kit';

export type ProductKitDetails = BaseState<ProductKitDetailResponse>;
export type ProductKitList = BaseState<ProductKitListResponse>;
export type ProductKitCreate = BaseState<ProductKitCreateResponse>;
export type ProductKitUpdate = BaseState<ProductKitUpdateResponse>;
export type ProductKitDelete = BaseState<ProductKitDeleteResponse>;
export type ProductKitBulk = BaseState<ProductKitBulkResponse>;
export type ProductKitImport = BaseState<ProductKitImportResponse>;

export type ProductKitState = {
  list: ProductKitList;
  details: ProductKitDetails;
  create: ProductKitCreate;
  update: ProductKitUpdate;
  delete: ProductKitDelete;
  bulk: ProductKitBulk;
  import: ProductKitImport;
};

export const initialProductKitState: ProductKitState = {
  list: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
  import: { loading: false, response: undefined },
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    // match to reducer name, each reducer will be one field in state
    productKit: ProductKitState;
  }
}
