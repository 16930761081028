export enum OrderStatusId {
  Sale = '1',
  Refund = '2',
  Void = '3',
  Draft = '4',
  Trash = '9',
}

export enum PaymentType {
  Cash = 1,
  Credit =2,
  Giftcard =3,
  Check= 4,
  Point=5,
  Coupon=6,
  Voucher=7,
  Venmo=8,
  Momo=9,
  VnPay=10,
  Others=11,
  VietQr=12,
}


export enum SaleEvent {
  None,

  PrintReport,
  PrintFullReport,
  PrintDetailReport,
  PrintSaleReceipt,
  Invoice,
  Payroll,
  PrintPayrollReport,

  ClearAllTrashed,
  ClearTrash,
  ClearTrashConfirm,

  DataFetchRequested,

  Edit,
  Tip,
  ChangeOrderAt,
  ChangeEmployee,
  Payment,
  Refund,
  Remove,
  RemoveConfirm,
  Reprint,
  Restore,
  Sale,

  SaveChange,
  SaveAllChanges,
}

export type ChangeTipAmount = {
  tip_amount?: number;
  total_amount?: number;
};

export type TipAmountFormValues = {
  tip_amount: {
    [paymentId: string]: number;
  };
  total_amount: {
    [paymentId: string]: number;
  };
};

export const INITIAL_TIP_AMOUNT_VALUES: TipAmountFormValues = {
  tip_amount: {},
  total_amount: {},
};
