import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';
import {
  ProductKitListRequest,
  ProductKitListResponse,
  ProductKitDeleteResponse,
  ProductKitUpdateResponse,
  ProductKitCreateResponse,
  ProductKitDetailResponse,
  ProductKitBulkRequest,
  ProductKitBulkResponse,
  ProductKitImportRequest,
  ProductKitImportResponse,
  ProductKitItemListRequest,
  ProductKitItemListResponse,
} from '../../api-models/product-kit';
import { ProductKit } from '../../models/productkit';

// Create the set of async actions
export const getProductKitList = createAsyncAction(types.FETCH_LIST, types.FETCH_LIST_SUCCESS, types.FETCH_LIST_FAILED)<
  ProductKitListRequest,
  ProductKitListResponse,
  any
>();

// Create the set of async actions
export const getProductKitDetails = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<string, ProductKitDetailResponse, any>();

// Create the set of async actions
export const createProductKit = createAsyncAction(types.CREATE, types.CREATE_SUCCESS, types.CREATE_FAILED)<
  ProductKit,
  ProductKitCreateResponse,
  any
>();

// Create the set of async actions
export const updateProductKit = createAsyncAction(types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILED)<
  ProductKit,
  ProductKitUpdateResponse,
  any
>();

// Create the set of async actions
export const deleteProductKit = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  string,
  ProductKitDeleteResponse,
  any
>();

// Create the set of async actions
export const bulkProductKits = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  ProductKitBulkRequest,
  ProductKitBulkResponse,
  any
>();

// Create the set of async actions
export const importProductKit = createAsyncAction(
  types.IMPORT_EXCEL,
  types.IMPORT_EXCEL_SUCCESS,
  types.IMPORT_EXCEL_FAILED
)<ProductKitImportRequest, ProductKitImportResponse, any>();

// Create the set of async actions
export const getProductKitItemList = createAsyncAction(
  types.FETCH_LISTITEM,
  types.FETCH_LISTITEM_SUCCESS,
  types.FETCH_LISTITEM_FAILED
)<ProductKitItemListRequest, ProductKitItemListResponse, any>();

// // Create the set of async actions
// export const createProductKitItem = createAsyncAction(
//   types.CREATE_ITEM,
//   types.CREATE_ITEM_SUCCESS,
//   types.CREATE_ITEM_FAILED
// )<ProductKitItem, ProductKitItemCreateResponse, any>();

// // Create the set of async actions
// export const updateProductKitItem = createAsyncAction(
//   types.UPDATE_ITEM,
//   types.UPDATE_ITEM_SUCCESS,
//   types.UPDATE_ITEM_FAILED
// )<ProductKitItem, ProductKitItemUpdateResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
