import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { ClockInOutState, initialClockInOutState } from './model';

const clockInOut = produce((draft: Draft<ClockInOutState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getCheckedInEmployeeList.request): {
      draft.checkedInEmployee.loading = true;
      return draft;
    }
    case getType(actions.getCheckedInEmployeeList.success): {
      draft.checkedInEmployee.loading = false;
      draft.checkedInEmployee.response = action.payload;
      return draft;
    }
    case getType(actions.getCheckedInEmployeeList.failure): {
      draft.checkedInEmployee.loading = false;
      draft.checkedInEmployee.errors = action.payload;
      return draft;
    }
    case getType(actions.reloadCheckedInEmployeeList): {
      draft.checkedInEmployee.reloadedAt = new Date();
      return draft;
    }

    case getType(actions.clockInOutEmployee.request): {
      draft.clockInOutTime.loading = true;
      return draft;
    }
    case getType(actions.clockInOutEmployee.success): {
      draft.clockInOutTime.loading = false;
      draft.clockInOutTime.response = action.payload;
      return draft;
    }
    case getType(actions.clockInOutEmployee.failure): {
      draft.clockInOutTime.loading = false;
      draft.clockInOutTime.errors = action.payload;
      return draft;
    }

    case getType(actions.getSummaryEmployeeInOutList.request): {
      draft.summary.loading = true;
      return draft;
    }
    case getType(actions.getSummaryEmployeeInOutList.success): {
      draft.summary.loading = false;
      draft.summary.response = action.payload;
      return draft;
    }
    case getType(actions.getSummaryEmployeeInOutList.failure): {
      draft.summary.loading = false;
      draft.summary.errors = action.payload;
      return draft;
    }

    case getType(actions.getReportSummaryEmployeeInOutList.request): {
      draft.reportSummary.loading = true;
      draft.reportSummary.response = undefined;
      return draft;
    }
    case getType(actions.getReportSummaryEmployeeInOutList.success): {
      draft.reportSummary.loading = false;
      draft.reportSummary.response = action.payload;
      return draft;
    }
    case getType(actions.getReportSummaryEmployeeInOutList.failure): {
      draft.reportSummary.loading = false;
      draft.reportSummary.errors = action.payload;
      return draft;
    }

    case getType(actions.getEmployeeInOutTimesInDateDetail.request): {
      draft.summary.loading = true;
      return draft;
    }
    case getType(actions.getEmployeeInOutTimesInDateDetail.success): {
      draft.details.loading = false;
      draft.details.response = action.payload;
      return draft;
    }
    case getType(actions.getEmployeeInOutTimesInDateDetail.failure): {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }

    case getType(actions.deleteEmployeeInOutTimesInDate.request): {
      draft.delete.loading = true;
      return draft;
    }
    case getType(actions.deleteEmployeeInOutTimesInDate.success): {
      draft.delete.loading = false;
      draft.delete.response = action.payload;
      return draft;
    }
    case getType(actions.deleteEmployeeInOutTimesInDate.failure): {
      draft.delete.loading = false;
      draft.delete.errors = action.payload;
      return draft;
    }

    case getType(actions.updateEmployeeInOutTimesInDate.request): {
      draft.update.loading = true;
      return draft;
    }
    case getType(actions.updateEmployeeInOutTimesInDate.success): {
      draft.update.loading = false;
      draft.update.response = action.payload;
      return draft;
    }
    case getType(actions.updateEmployeeInOutTimesInDate.failure): {
      draft.update.loading = false;
      draft.update.errors = action.payload;
      return draft;
    }

    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialClockInOutState;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialClockInOutState);

export default clockInOut;
