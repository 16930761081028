import { put, call, all, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';
import { API_ENDPOINTS } from '../../../config/api-endpoints';

function* getSummaryList(action: ReturnType<typeof actions.getSummaryList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.CUSTOMER_REPORT.Summary, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getSummaryList.success((response as any).data));
  } catch (err) {
    yield put(actions.getSummaryList.failure(err));
  }
}

function* getSummaryDetailList(action: ReturnType<typeof actions.getSummaryDetailList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.CUSTOMER_REPORT.Details, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getSummaryDetailList.success((response as any).data));
  } catch (err) {
    yield put(actions.getSummaryDetailList.failure(err));
  }
}

// Main saga
export default function* customerReportSaga() {
  yield all([
    takeLatest(actions.getSummaryList.request, getSummaryList),
    takeLatest(actions.getSummaryDetailList.request, getSummaryDetailList),
  ]);
}
