import { OrderingResponse } from 'src/state/api-models/support';
import { BaseState } from '../../models';

export type OrderingState = BaseState<OrderingResponse>;

export type SupportState = {
  ordering: OrderingState;
};

export const initialSupportState = {
  ordering: { loading: false, response: undefined },
};

declare module './../../models' {
  interface StateAll {
    // match to a duck folder
    'common/support': SupportState;
  }
}
