import { put, call, all, takeLatest } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { convertToFormData } from 'src/utils/converter';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import { generateSingleKey } from 'src/utils/axios/axios-fecth';
import * as actions from './actions';

// Handle request saga
function* getEmployeeList(action: ReturnType<typeof actions.getEmployeeList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;

    let key_axios = generateSingleKey(API_ENDPOINTS.EMPLOYEE.ENABLE_BOOKING_LIST, {
      store_id: filter.store_id || '',
    });

    let responseData = null;
    let cacheObject = window.localStorage.getItem(key_axios);
    if (cacheObject) {
      responseData = JSON.parse(cacheObject);
    } else {
      const response = yield call(axiosInstance.get, API_ENDPOINTS.EMPLOYEE.ENABLE_BOOKING_LIST, {
        params: { sort, ...filter, ...pagination },
      });

      responseData = (response as any).data;
      if (responseData.success) {
        window.localStorage.setItem(key_axios, JSON.stringify(responseData));
      }
    }

    yield put(actions.getEmployeeList.success(responseData));
  } catch (err) {
    yield put(actions.getEmployeeList.failure(err));
  }
}

// Handle request saga
function* getBookingApptList(action: ReturnType<typeof actions.getBookingApptList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.BOOKINGAPPT.List, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getBookingApptList.success((response as any).data));
  } catch (err) {
    yield put(actions.getBookingApptList.failure(err));
  }
}

// Handle request saga
function* getUnassignedBookingApptList(
  action: ReturnType<typeof actions.getUnassignedBookingApptList.request>
): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.BOOKINGAPPT.UnassignedList, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getUnassignedBookingApptList.success((response as any).data));
  } catch (err) {
    yield put(actions.getUnassignedBookingApptList.failure(err));
  }
}

// Handle request saga
function* getNumberOfBookingsInDays(action: ReturnType<typeof actions.getNumberOfBookingsInDays.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.BOOKINGAPPT.CountInDays, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getNumberOfBookingsInDays.success((response as any).data));
  } catch (err) {
    yield put(actions.getNumberOfBookingsInDays.failure(err));
  }
}

function* getTopBookingServices(action: ReturnType<typeof actions.getTopBookingServices.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.BOOKINGAPPT.TopServices, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getTopBookingServices.success((response as any).data));
  } catch (err) {
    yield put(actions.getTopBookingServices.failure(err));
  }
}

function* assingBookingAppt(action: ReturnType<typeof actions.assingBookingAppt.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.BOOKINGAPPT.Assign, action.payload);

    yield put(actions.assingBookingAppt.success((response as any).data));
  } catch (err) {
    yield put(actions.assingBookingAppt.failure(err));
  }
}

function* getBookingApptDetails(action: ReturnType<typeof actions.getBookingApptDetails.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.BOOKINGAPPT.Details.replace('{id}', action.payload));

    yield put(actions.getBookingApptDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getBookingApptDetails.failure(err));
  }
}

function* createBookingAppt(action: ReturnType<typeof actions.createBookingAppt.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.BOOKINGAPPT.Create, action.payload, config));

    yield put(actions.createBookingAppt.success((response as any).data));
  } catch (err) {
    yield put(actions.createBookingAppt.failure(err));
  }
}

function* updateBookingAppt(action: ReturnType<typeof actions.updateBookingAppt.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.BOOKINGAPPT.Update.replace('{id}', action.payload.id), action.payload, config)
    );

    yield put(actions.updateBookingAppt.success((response as any).data));
  } catch (err) {
    yield put(actions.updateBookingAppt.failure(err));
  }
}

function* updateBookingApptStatus(action: ReturnType<typeof actions.updateBookingApptStatus.request>): Generator {
  try {
    const { id } = action.payload;

    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.BOOKINGAPPT.UpdateStatus.replace('{id}', id), action.payload)
    );

    yield put(actions.updateBookingApptStatus.success((response as any).data));
  } catch (err) {
    yield put(actions.updateBookingApptStatus.failure(err));
  }
}

function* deleteBookingAppt(action: ReturnType<typeof actions.deleteBookingAppt.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.delete(API_ENDPOINTS.BOOKINGAPPT.Delete.replace('{id}', action.payload))
    );

    yield put(actions.deleteBookingAppt.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteBookingAppt.failure(err));
  }
}

function* bulkBookingAppt(action: ReturnType<typeof actions.bulkBookingAppt.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.BOOKINGAPPT.Bulk, action.payload));

    yield put(actions.bulkBookingAppt.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkBookingAppt.failure(err));
  }
}

function* bookingApptSendSms(action: ReturnType<typeof actions.bookingApptSendSms.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.BOOKINGAPPT.SendSms, action.payload));
    yield put(actions.bookingApptSendSms.success((response as any).data));
  } catch (err) {
    yield put(actions.bookingApptSendSms.failure(err));
  }
}

function* updateBookingApptColumn(action: ReturnType<typeof actions.updateBookingApptColumn.request>): Generator {
  try {
    const { id } = action.payload;

    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.BOOKINGAPPT.UpdateColumn.replace('{id}', id), action.payload)
    );

    yield put(actions.updateBookingApptColumn.success((response as any).data));
  } catch (err) {
    yield put(actions.updateBookingApptColumn.failure(err));
  }
}

// Main saga
export default function* BookingApptSaga() {
  yield all([
    takeLatest(actions.getEmployeeList.request, getEmployeeList),
    takeLatest(actions.getBookingApptList.request, getBookingApptList),
    takeLatest(actions.getUnassignedBookingApptList.request, getUnassignedBookingApptList),
    takeLatest(actions.getNumberOfBookingsInDays.request, getNumberOfBookingsInDays),
    takeLatest(actions.getTopBookingServices.request, getTopBookingServices),
    takeLatest(actions.assingBookingAppt.request, assingBookingAppt),
    takeLatest(actions.getBookingApptDetails.request, getBookingApptDetails),
    takeLatest(actions.createBookingAppt.request, createBookingAppt),
    takeLatest(actions.updateBookingAppt.request, updateBookingAppt),
    takeLatest(actions.updateBookingApptStatus.request, updateBookingApptStatus),
    takeLatest(actions.deleteBookingAppt.request, deleteBookingAppt),
    takeLatest(actions.bulkBookingAppt.request, bulkBookingAppt),
    takeLatest(actions.bookingApptSendSms.request, bookingApptSendSms),
    takeLatest(actions.updateBookingApptColumn.request, updateBookingApptColumn),
  ]);
}
