export const BULK_SALE = 'saleMgnt/save_all_changes_sale';
export const BULK_SALE_SUCCESS = 'saleMgnt/save_all_changes_sale_success';
export const BULK_SALE_FAILED = 'saleMgnt/save_all_changes_sale_failed';

export const SAVE_ALL_CHANGES_SALE = 'saleMgnt/bulk_sale';
export const SAVE_ALL_CHANGES_SALE_SUCCESS = 'saleMgnt/bulk_sale_success';
export const SAVE_ALL_CHANGES_SALE_FAILED = 'saleMgnt/bulk_sale_failed';

export const GET_SALE_DETAIL = 'saleMgnt/get_sale_detail';
export const GET_SALE_DETAIL_SUCCESS = 'saleMgnt/get_sale_detail_success';
export const GET_SALE_DETAIL_FAILED = 'saleMgnt/get_sale_detail_faild';

export const GET_SALE_INVOICE = 'saleMgnt/get_sale_invoice';
export const GET_SALE_INVOICE_SUCCESS = 'saleMgnt/get_sale_invoice_success';
export const GET_SALE_INVOICE_FAILED = 'saleMgnt/get_sale_invoice_faild';

export const GET_SALE_REFUND = 'saleMgnt/get_sale_refund';
export const GET_SALE_REFUND_SUCCESS = 'saleMgnt/get_sale_refund_success';
export const GET_SALE_REFUND_FAILED = 'saleMgnt/get_sale_refund_faild';

export const UPDATE_SALE_STATUS = 'saleMgnt/update_sale_status';
export const UPDATE_SALE_STATUS_SUCCESS = 'saleMgnt/update_sale_status_success';
export const UPDATE_SALE_STATUS_FAILED = 'saleMgnt/update_sale_status_faild';

export const UPDATE_SALE_PAYMENT = 'saleMgnt/update_sale_payment';
export const UPDATE_SALE_PAYMENT_SUCCESS = 'saleMgnt/update_sale_payment_success';
export const UPDATE_SALE_PAYMENT_FAILED = 'saleMgnt/update_sale_payment_faild';

export const UPDATE_SALE_TIP = 'saleMgnt/update_sale_tip';
export const UPDATE_SALE_TIP_SUCCESS = 'saleMgnt/update_sale_tip_success';
export const UPDATE_SALE_TIP_FAILED = 'saleMgnt/update_sale_tip_faild';

export const CHANGE_ORDER_AT = 'saleMgnt/change_order_at';
export const CHANGE_ORDER_AT_SUCCESS = 'saleMgnt/change_order_at_success';
export const CHANGE_ORDER_AT_FAILED = 'saleMgnt/change_order_at_faild';

export const CHANGE_EMPLOYEE = 'saleMgnt/change_employee';
export const CHANGE_EMPLOYEE_SUCCESS = 'saleMgnt/change_employee_success';
export const CHANGE_EMPLOYEE_FAILED = 'saleMgnt/change_employee_faild';

export const REFUND_SALE = 'saleMgnt/refund_sale';
export const REFUND_SALE_SUCCESS = 'saleMgnt/refund_sale_success';
export const REFUND_SALE_FAILED = 'saleMgnt/urefund_sale_faild';

export const VOID_SALE = 'saleMgnt/void_sale';
export const VOID_SALE_SUCCESS = 'saleMgnt/void_sale_success';
export const VOID_SALE_FAILED = 'saleMgnt/void_sale_faild';

export const DELETE_SALE = 'saleMgnt/detele_sale';
export const DELETE_SALE_SUCCESS = 'saleMgnt/detele_sale_success';
export const DELETE_SALE_FAILED = 'saleMgnt/detele_sale_failed';

export const RESTORE_SALE = 'saleMgnt/restore_sale';
export const RESTORE_SALE_SUCCESS = 'saleMgnt/restore_sale_success';
export const RESTORE_SALE_FAILED = 'saleMgnt/restore_sale_failed';

export const CLEAR_TRASHED_SALE = 'saleMgnt/clear_trashed_sale';
export const CLEAR_TRASHED_SALE_SUCCESS = 'saleMgnt/clear_trashed_sale_success';
export const CLEAR_TRASHED_SALE_FAILED = 'saleMgnt/clear_trashed_sale_failed';

export const CLEAR_ALL_TRASHED_SALE = 'saleMgnt/clear_all_trashed_salesale';
export const CLEAR_ALL_TRASHED_SALE_SUCCESS = 'saleMgnt/clear_all_trashed_sale_success';
export const CLEAR_ALL_TRASHED_SALE_FAILED = 'saleMgnt/clear_all_trashed_sale_failed';

export const CLEAR_DATA = 'saleMgnt/clear_data';

export type IncomeAction =
  | typeof UPDATE_SALE_TIP
  | typeof UPDATE_SALE_TIP_FAILED
  | typeof UPDATE_SALE_TIP_SUCCESS
  | typeof UPDATE_SALE_PAYMENT
  | typeof UPDATE_SALE_PAYMENT_FAILED
  | typeof UPDATE_SALE_PAYMENT_SUCCESS
  | typeof UPDATE_SALE_STATUS
  | typeof UPDATE_SALE_STATUS_FAILED
  | typeof UPDATE_SALE_STATUS_SUCCESS
  | typeof DELETE_SALE
  | typeof DELETE_SALE_FAILED
  | typeof DELETE_SALE_SUCCESS
  | typeof RESTORE_SALE
  | typeof RESTORE_SALE_FAILED
  | typeof RESTORE_SALE_SUCCESS
  | typeof CLEAR_TRASHED_SALE
  | typeof CLEAR_TRASHED_SALE_FAILED
  | typeof CLEAR_TRASHED_SALE_SUCCESS
  | typeof CLEAR_ALL_TRASHED_SALE
  | typeof CLEAR_ALL_TRASHED_SALE_FAILED
  | typeof CLEAR_ALL_TRASHED_SALE_SUCCESS
  | typeof BULK_SALE
  | typeof BULK_SALE_FAILED
  | typeof BULK_SALE_SUCCESS
  | typeof CLEAR_DATA;
