import { put, call, all, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';
import { API_ENDPOINTS } from '../../../config/api-endpoints/units';

// Handle request saga
function* getUnitsList(action: ReturnType<typeof actions.getUnitsList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.UNITS.List, {
      params: action.payload,
    });

    yield put(actions.getUnitsList.success((response as any).data));
  } catch (err) {
    yield put(actions.getUnitsList.failure(err));
  }
}

function* getUnitsDetails(action: ReturnType<typeof actions.getUnitsDetails.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.UNITS.Details.replace('{id}', action.payload));

    yield put(actions.getUnitsDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getUnitsDetails.failure(err));
  }
}

function* createUnits(action: ReturnType<typeof actions.createUnits.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.UNITS.Create, action.payload));

    yield put(actions.createUnits.success((response as any).data));
  } catch (err) {
    yield put(actions.createUnits.failure(err));
  }
}

function* updateUnits(action: ReturnType<typeof actions.updateUnits.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.UNITS.Update.replace('{id}', action.payload.id), action.payload)
    );

    yield put(actions.updateUnits.success((response as any).data));
  } catch (err) {
    yield put(actions.updateUnits.failure(err));
  }
}

function* deleteUnits(action: ReturnType<typeof actions.deleteUnits.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.delete(API_ENDPOINTS.UNITS.Delete.replace('{id}', action.payload)));

    yield put(actions.deleteUnits.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteUnits.failure(err));
  }
}

function* bulkUnits(action: ReturnType<typeof actions.bulkUnits.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.UNITS.Bulk, action.payload);

    yield put(actions.bulkUnits.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkUnits.failure(err));
  }
}

// Main saga
export default function* unitsSaga() {
  yield all([
    takeLatest(actions.getUnitsList.request, getUnitsList),
    takeLatest(actions.getUnitsDetails.request, getUnitsDetails),
    takeLatest(actions.createUnits.request, createUnits),
    takeLatest(actions.updateUnits.request, updateUnits),
    takeLatest(actions.deleteUnits.request, deleteUnits),
    takeLatest(actions.bulkUnits.request, bulkUnits),
  ]);
}
