import { BaseState } from '../models';
import {
  CustomerGroupListResponse,
  CustomerGroupDetailResponse,
  CustomerGroupDeleteResponse,
  CustomerGroupUpdateResponse,
  CustomerGroupCreateResponse,
  CustomerGroupBulkResponse,
  CustomerGroupCheckHasCustomerResponse,
} from '../../api-models/customerGroup';

export type CustomerGroupDetails = BaseState<CustomerGroupDetailResponse> & {};
export type CustomerGroupList = BaseState<CustomerGroupListResponse> & {};
export type CustomerGroupCreate = BaseState<CustomerGroupCreateResponse> & {};
export type CustomerGroupUpdate = BaseState<CustomerGroupUpdateResponse> & {};
export type CustomerGroupDelete = BaseState<CustomerGroupDeleteResponse> & {};
export type CustomerGroupBulk = BaseState<CustomerGroupBulkResponse> & {};
export type CustomerGroupCheckHasCustomer = BaseState<CustomerGroupCheckHasCustomerResponse> & {};
export type CustomerGroupState = {
  list: CustomerGroupList;
  details: CustomerGroupDetails;
  create: CustomerGroupCreate;
  update: CustomerGroupUpdate;
  delete: CustomerGroupDelete;
  bulk: CustomerGroupBulk;
  checkHasCustomer: CustomerGroupCheckHasCustomer;
};

export const initialCustomerGroupState: CustomerGroupState = {
  list: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
  checkHasCustomer: { loading: false, response: undefined },
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    // match to reducer name, each reducer will be one field in state
    customerGroup: CustomerGroupState;
  }
}
