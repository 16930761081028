import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';
import {
  CouponListRequest,
  CouponListResponse,
  CouponDetailResponse,
  CouponCreateResponse,
  CouponUpdateResponse,
  CouponDeleteResponse,
  CouponBulkRequest,
  CouponBulkResponse,
  CouponHistoriesRequest,
  CouponHistoriesResponse,
} from '../../api-models/coupon';
import { Coupon } from 'src/state/models/coupon';

// Create the set of async actions
export const getCouponList = createAsyncAction(types.FETCH_LIST, types.FETCH_LIST_SUCCESS, types.FETCH_LIST_FAILED)<
  CouponListRequest,
  CouponListResponse,
  any
>();

// Create the set of async actions
export const getCouponDetails = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<string, CouponDetailResponse, any>();

// Create the set of async actions
export const createCoupon = createAsyncAction(types.CREATE, types.CREATE_SUCCESS, types.CREATE_FAILED)<
  Coupon,
  CouponCreateResponse,
  any
>();

// Create the set of async actions
export const updateCoupon = createAsyncAction(types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILED)<
  Coupon,
  CouponUpdateResponse,
  any
>();

// Create the set of async actions
export const deleteCoupon = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  string,
  CouponDeleteResponse,
  any
>();

// Create the set of async actions
export const bulkCoupons = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  CouponBulkRequest,
  CouponBulkResponse,
  any
>();

// Create the set of async actions
export const getGiftcardNumberGiftcards = createAsyncAction(
  types.GET_COUPON_CODE,
  types.GET_COUPON_CODE_SUCCESS,
  types.GET_COUPON_CODE_FAILED
)<CouponBulkRequest, CouponBulkResponse, any>();

// Create the set of async actions
export const getHistories = createAsyncAction(types.HISTORY, types.HISTORY_SUCCESS, types.HISTORY_FAILED)<
  CouponHistoriesRequest,
  CouponHistoriesResponse,
  any
>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
