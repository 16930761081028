import { LOCALES } from 'src/config/locales';
import { getInitialSettings } from 'src/state/ducks/common/auth/storage';
import { LocaleSettings } from 'src/types/locale';

let notInList = ['listingPrinters', 'printer_demand', 'app_version', 'rememberUser', 'loglevel', 'user_uuid'];

export const checkVersion = () => {
  const rootEle = document.getElementById('root');
  if (rootEle) {
    const currentVersion = rootEle.getAttribute('data-version');
    const appVersion = window.localStorage.getItem('app_version');
    if (appVersion === null) {
      window.localStorage.setItem('app_version', currentVersion);
    } else {
      if (appVersion !== currentVersion) {
        Object.entries(window.localStorage).forEach(([cKey, v]) => {
          if (notInList.indexOf(cKey) === -1) {
            window.localStorage.removeItem(cKey);
          }
        });
        //set is new app version
        window.localStorage.setItem('app_version', currentVersion);
      }
    }
  }
};

export const getLocaleSettings = (countryCode: string = undefined): LocaleSettings => {
  //check version and reset caching files
  checkVersion();

  if (countryCode) {
    return LOCALES[countryCode.toLocaleUpperCase()] || LOCALES.DEFAULT;
  }
  const initialSettings = getInitialSettings();
  return (
    (initialSettings &&
      initialSettings.generalSettings &&
      initialSettings.generalSettings.country_code &&
      LOCALES[initialSettings.generalSettings.country_code.toLocaleUpperCase()]) ||
    LOCALES.DEFAULT
  );
};
