import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { initialLoadingState, LoadingData } from './model';

const loading = produce((draft: Draft<LoadingData>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.addLoadingApi): {
      draft.push(action.payload);
      return draft;
    }
    case getType(actions.removeLoadingApi): {
      const index = draft.indexOf(action.payload);
      if (index >= 0) draft.splice(index, 1);
      return draft;
    }

    case getType(actions.clearData): {
      draft = initialLoadingState.data;
      return draft;
    }
    default:
      return draft;
  }
}, initialLoadingState.data);

export default loading;
