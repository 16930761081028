export const SALE = {
  ServiceProductSetting: 'pos/sales/service-product-setting',
  Request: 'pos/sales/request',
  Get: 'pos/sales/{id}',
  Detail: 'pos/sales/{id}',
  Invoice: 'pos/sales/{id}/invoice',
  Create: 'pos/sales',
  Update: 'pos/sales/{id}',
  UpdateStatus: 'pos/sales/{id}/status',
  UpdatePayment: 'pos/sales/{id}/payment',
  UpdateTip: 'pos/sales/{id}/tip',
  ChangeEmployee: 'pos/sales/{id}/employee',
  ChangeOrderAt: 'pos/sales/{id}/order-at',
  Refund: 'pos/sales/{id}/refund',
  Void: 'pos/sales/{id}/void',
  Restore: 'pos/sales/{id}/restore',
  Delete: 'pos/sales/{id}',
  Bulk: 'pos/sales',
  ClearTrashed: 'pos/sales/clear-trashed/{id}',
  ClearAllTrashed: 'pos/sales/clear-trashed/all',
  SaveAllChanges: 'pos/sales/save-all-changes',
};
