import { BaseState } from '../models';
import {
  CouponListResponse,
  CouponDetailResponse,
  CouponDeleteResponse,
  CouponUpdateResponse,
  CouponCreateResponse,
  CouponBulkResponse,
  CouponHistoriesResponse,
} from '../../api-models/coupon';

export type CouponDetails = BaseState<CouponDetailResponse>;
export type CouponList = BaseState<CouponListResponse>;
export type CouponCreate = BaseState<CouponCreateResponse>;
export type CouponUpdate = BaseState<CouponUpdateResponse>;
export type CouponDelete = BaseState<CouponDeleteResponse>;
export type CouponBulk = BaseState<CouponBulkResponse>;
export type CouponHistory = BaseState<CouponHistoriesResponse>;

export type CouponState = {
  list: CouponList;
  details: CouponDetails;
  create: CouponCreate;
  update: CouponUpdate;
  delete: CouponDelete;
  bulk: CouponBulk;
  history: CouponHistory;
};

export const initialCouponState: CouponState = {
  list: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
  history: { loading: false, response: undefined },
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    // match to reducer name, each reducer will be one field in state
    coupon: CouponState;
  }
}
