let toThrottle = false;
export function throttle(delay: number, func: Function, ...args) {
  if (!toThrottle) {
    toThrottle = true;
    func.apply(this, args);
    setTimeout(function () {
      toThrottle = false;
    }, delay);
  }
}

let timer;
export function debounce(delay: number, func: Function, ...args) {
  if (timer) clearTimeout(timer);
  timer = setTimeout(function () {
    timer = null;
    func.apply(this, args);
  }, delay);
}
