import { BaseState } from '../models';
import {
  IncomeListResponse,
  IncomeDetailResponse,
  ReportSummaryResponse,
  ReportDetailsResponse,
} from '../../api-models/income';

export type IncomeList = BaseState<IncomeListResponse>;
export type IncomeDetails = BaseState<IncomeDetailResponse>;

export type ReportSummary = BaseState<ReportSummaryResponse>;
export type ReportDetails = BaseState<ReportDetailsResponse>;

export type IncomeStateAll = {
  summary: IncomeList;
  details: IncomeList;

  reportSummary: ReportSummary;
  reportDetails: ReportDetails;
};

export const initialStateAll: IncomeStateAll = {
  summary: { loading: false, response: undefined },
  details: { loading: false, response: undefined },

  reportSummary: { loading: false, response: undefined },
  reportDetails: { loading: false, response: undefined },
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    storeReport: IncomeStateAll;
  }
}
