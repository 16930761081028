import { BookingStatus } from 'src/types/schedule';
import { BookingType } from 'src/types';

export type WalkinEmployee = {
  id: string;
  full_name: string;
  avatar: string;
  booking_appt: Walkin[];
};

export type Walkin = {
  id: string;
  status?: number;
  store_id?: string;
  user_id?: string;
  full_name?: string;
  customer_id: string;
  customer_name: string;
  telephone: string;
  booking_type: number;
  effective_start: string;
  effective_end: string;
  time_in?: string;
  started_at?: string;
  address: string;
  email: string;
  updated_at?: string;
  ticket_no?: string;
  order_id?: string;
  notes?: string;
  guest?: number;
  booking_appt_service: BookingApptService[];
};

export type BookingApptService = {
  id: string;
  name: string;
  booking_id: string;
  service_id: string;
  unit_price: number;
  finish_time: number;
};

export type WalkinService = {
  id: string;
  category_id?: number;
  name: string;
  supply_price?: number;
  unit_cost?: number;
  unit_price?: number;
  bg_color: string;
  font_color: string;
  finish_time?: number;
};

export type WalkinCustomer = {
  id: string;
  full_name: string;
  cellphone: string;
  point_balance: number;
  email: string;
  membership_number: string;
  avatar_image: string;
  notes: string;
};

export type CustomerInfo = {
  first_name: string;
  last_name: string;
  email?: string;
  birthday?: string;
  telehone?: string;
  action?: string;
};

export const initCustomerInfo: CustomerInfo = {
  first_name: '',
  last_name: '',
  email: '',
  birthday: '',
  telehone: '',
  action: 'register',
};

export const initWalkinService: WalkinService = {
  id: '',
  name: '',

  bg_color: '',
  font_color: '',
};

export const initWalkinCustomer: WalkinCustomer = {
  id: '',
  full_name: '',
  cellphone: '',
  point_balance: 0,
  email: '',
  membership_number: '',
  avatar_image: '',
  notes: '',
};

export const initWalkinEmployee: WalkinEmployee = {
  id: '',
  full_name: '',
  avatar: '',
  booking_appt: [],
};

export const initWalkin: Walkin = {
  id: '',
  user_id: '',
  customer_id: '',
  booking_type: 0,
  effective_start: '',
  effective_end: '',
  customer_name: '',
  telephone: '',
  address: '',
  email: '',
  booking_appt_service: [],
};

export type WalkinRegister = {
  user_id: string;
  title: string;
  customer_name: string;
  customer_id: string;
  telephone: string;
  notes: string;
  effective_start: string;
  effective_end: string;
  booking_type?: BookingType;
  status?: BookingStatus;
  point_balance?: string;
  booking_appt_service: BookingApptService[];
  customer_info?: CustomerInfo;
};

export const initWalkinRegister: WalkinRegister = {
  user_id: '',
  title: '',
  customer_name: '',
  customer_id: '',
  telephone: '',
  notes: '',
  effective_start: '',
  effective_end: '',
  // status: '',
  point_balance: '',
  booking_appt_service: [],
  customer_info: initCustomerInfo,
};
