import { put, call, all, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';
import { API_ENDPOINTS } from '../../../config/api-endpoints/customerGroup';
import { removeCodeList } from 'src/utils/axios/axios-fecth';

// Handle request saga
function* getCustomerGroupList(action: ReturnType<typeof actions.getCustomerGroupList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.CUSTOMERGROUP.List, {
      params: action.payload,
    });

    yield put(actions.getCustomerGroupList.success((response as any).data));
  } catch (err) {
    yield put(actions.getCustomerGroupList.failure(err));
  }
}

function* getCustomerGroupDetails(action: ReturnType<typeof actions.getCustomerGroupDetails.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.CUSTOMERGROUP.Details.replace('{id}', action.payload));

    yield put(actions.getCustomerGroupDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getCustomerGroupDetails.failure(err));
  }
}

function* createCustomerGroup(action: ReturnType<typeof actions.createCustomerGroup.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.CUSTOMERGROUP.Create, action.payload));

    yield put(actions.createCustomerGroup.success((response as any).data));
    yield removeCodeList('customer_groups');
  } catch (err) {
    yield put(actions.createCustomerGroup.failure(err));
  }
}

function* updateCustomerGroup(action: ReturnType<typeof actions.updateCustomerGroup.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.CUSTOMERGROUP.Update.replace('{id}', action.payload.id), action.payload)
    );

    yield put(actions.updateCustomerGroup.success((response as any).data));
    yield removeCodeList('customer_groups');
  } catch (err) {
    yield put(actions.updateCustomerGroup.failure(err));
  }
}

function* deleteCustomerGroup(action: ReturnType<typeof actions.deleteCustomerGroup.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.delete(API_ENDPOINTS.CUSTOMERGROUP.Delete.replace('{id}', action.payload))
    );

    yield put(actions.deleteCustomerGroup.success((response as any).data));
    yield removeCodeList('customer_groups');
  } catch (err) {
    yield put(actions.deleteCustomerGroup.failure(err));
  }
}

function* bulkCustomerGroup(action: ReturnType<typeof actions.bulkCustomerGroup.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.CUSTOMERGROUP.Bulk, action.payload);

    yield put(actions.bulkCustomerGroup.success((response as any).data));
    yield removeCodeList('customer_groups');
  } catch (err) {
    yield put(actions.bulkCustomerGroup.failure(err));
  }
}

function* customerGroupCheckHasCustomer(
  action: ReturnType<typeof actions.customerGroupCheckHasCustomer.request>
): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.CUSTOMERGROUP.CheckHasCustomer.replace('{id}', action.payload))
    );

    yield put(actions.customerGroupCheckHasCustomer.success((response as any).data));
  } catch (err) {
    yield put(actions.customerGroupCheckHasCustomer.failure(err));
  }
}

// Main saga
export default function* CustomerGroupSaga() {
  yield all([
    takeLatest(actions.getCustomerGroupList.request, getCustomerGroupList),
    takeLatest(actions.getCustomerGroupDetails.request, getCustomerGroupDetails),
    takeLatest(actions.createCustomerGroup.request, createCustomerGroup),
    takeLatest(actions.updateCustomerGroup.request, updateCustomerGroup),
    takeLatest(actions.deleteCustomerGroup.request, deleteCustomerGroup),
    takeLatest(actions.bulkCustomerGroup.request, bulkCustomerGroup),
    takeLatest(actions.customerGroupCheckHasCustomer.request, customerGroupCheckHasCustomer),
  ]);
}
