import {
  CheckPinCodeResponse,
  GetInitialSettingsResponse,
  LoginResponse,
  UpdateProfleResponse,
} from 'src/state/api-models/auth';
import { BaseState } from '../../models';
import { getCurrentUserData } from './storage';

export type LoginState = BaseState<LoginResponse>;
export type CheckPinCodeState = BaseState<CheckPinCodeResponse>;
export type InitialSettingsState = BaseState<GetInitialSettingsResponse>;
export type ProfileState = BaseState<UpdateProfleResponse>;

export type AuthState = {
  login: LoginState;
  checkPinCode: CheckPinCodeState;
  initialSettings: InitialSettingsState;
  profile: ProfileState;
};

// get login user from local storage
const data = getCurrentUserData();
export const initialAuthState: AuthState = {
  login: { loading: false, response: { data, success: !!data } },
  checkPinCode: { loading: false, response: undefined },
  initialSettings: { loading: false, response: undefined },
  profile: { loading: false, response: undefined },
};

declare module './../../models' {
  interface StateAll {
    // match to a duck folder
    'common/auth': AuthState;
  }
}
