import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';
import {
  UnitsListRequest,
  UnitsListResponse,
  UnitsDeleteResponse,
  UnitsUpdateResponse,
  UnitsCreateResponse,
  UnitsDetailResponse,
  UnitsBulkRequest,
  UnitsBulkResponse,
  UnitsUpdateRequest,
} from '../../api-models/units';
import { Unit } from '../../models/unit';

// Create the set of async actions
export const getUnitsList = createAsyncAction(types.FETCH_LIST, types.FETCH_LIST_SUCCESS, types.FETCH_LIST_FAILED)<
  UnitsListRequest,
  UnitsListResponse,
  any
>();

// Create the set of async actions
export const getUnitsDetails = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<string, UnitsDetailResponse, any>();

// Create the set of async actions
export const createUnits = createAsyncAction(types.CREATE, types.CREATE_SUCCESS, types.CREATE_FAILED)<
  Unit,
  UnitsCreateResponse,
  any
>();

// Create the set of async actions
export const updateUnits = createAsyncAction(types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILED)<
  UnitsUpdateRequest,
  UnitsUpdateResponse,
  any
>();

// Create the set of async actions
export const deleteUnits = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  string,
  UnitsDeleteResponse,
  any
>();

// Create the set of async actions
export const bulkUnits = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  UnitsBulkRequest,
  UnitsBulkResponse,
  any
>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
