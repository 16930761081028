export const EMPLOYEE_WORKING_TIME = {
  SUMMARY: '/report/working-times',
  EXPORT_SUMMARY: '/report/working-times/export',
  REPORT_SUMMARY: '/report/working-times/report-summary',

  DETAIL_WKT_IN_DATE: '/report/working-times/details-in-date',
  UPDATE_WKT_IN_DATE: '/report/working-times/details-in-date',
  DELETE_WKT_IN_DATE: '/report/working-times/details-in-date/{user_id}/{date_work}',

  DETAIL: '/report/working-times/{id}',
  DELETE: '/report/working-times/{id}',
  UPDATE: '/report/working-times/{id}',

  WORKING_TIME_PAYROLL: '/report/working-times/payroll',
  SAVE_WORKING_TIME_PAYROLL: '/report/working-times/payroll',
  PREVIEW_WORKING_TIME_PAYROLL: '/report/working-times/payroll-preview',
};
