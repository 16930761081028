import { BaseState } from '../models';
import {
  UnitsListResponse,
  UnitsDetailResponse,
  UnitsDeleteResponse,
  UnitsUpdateResponse,
  UnitsCreateResponse,
  UnitsBulkResponse,
} from '../../api-models/units';

export type UnitsDetails = BaseState<UnitsDetailResponse>;
export type UnitsList = BaseState<UnitsListResponse>;
export type UnitsCreate = BaseState<UnitsCreateResponse>;
export type UnitsUpdate = BaseState<UnitsUpdateResponse>;
export type UnitsDelete = BaseState<UnitsDeleteResponse>;
export type UnitsBulk = BaseState<UnitsBulkResponse>;

export type UnitsState = {
  list: UnitsList;
  details: UnitsDetails;
  create: UnitsCreate;
  update: UnitsUpdate;
  delete: UnitsDelete;
  bulk: UnitsBulk;
};

export const initialUnitsState = {
  list: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    unit: UnitsState;
  }
}
