import { BaseState } from '../models';
import {
  IncomeListResponse,
  IncomeDetailResponse,
  ReportFullDetailsResponse,
  ReportSummaryResponse,
  ReportDetailsResponse,
} from '../../api-models/income';

export type IncomeDetails = BaseState<IncomeDetailResponse>;
export type IncomeList = BaseState<IncomeListResponse>;

export type ReportSummary = BaseState<ReportSummaryResponse>;
export type ReportDetails = BaseState<ReportDetailsResponse>;
export type ReportFullDetailsRequest = BaseState<ReportFullDetailsResponse>;

export type IncomeStateAll = {
  summary: IncomeList;
  summaryDetails: IncomeList;

  incomeSummary: IncomeList;
  incomeDetails: IncomeList;

  reportSummary: ReportSummary;
  reportDetails: ReportDetails;
  reportFullDetails: ReportFullDetailsRequest;

};

export const initialStateAll: IncomeStateAll = {
  summary: { loading: false, response: undefined },
  summaryDetails: { loading: false, response: undefined },
  incomeSummary: { loading: false, response: undefined },
  incomeDetails: { loading: false, response: undefined },
  reportSummary: { loading: false, response: undefined },
  reportDetails: { loading: false, response: undefined },
  reportFullDetails: { loading: false, response: undefined },
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    income: IncomeStateAll;
  }
}
