import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { WalkinState, initialWalkinState } from './model';

const walkin = produce((draft: Draft<WalkinState>, action: ActionType<typeof actions>) => {
  // Walkin list
  switch (action.type) {
    case getType(actions.getWalkinList.request): {
      draft.list.loading = true;
      return draft;
    }
    case getType(actions.getWalkinList.success): {
      draft.list.response = action.payload;
      draft.list.loading = false;
      return draft;
    }
    case getType(actions.getWalkinList.failure): {
      draft.list.errors = action.payload;
      draft.list.loading = false;
      return draft;
    }

    case getType(actions.getWalkinListGroupByEmployee.request): {
      draft.listGroupByEmployee.loading = true;
      return draft;
    }
    case getType(actions.getWalkinListGroupByEmployee.success): {
      draft.listGroupByEmployee.response = action.payload;
      draft.listGroupByEmployee.loading = false;
      return draft;
    }
    case getType(actions.getWalkinListGroupByEmployee.failure): {
      draft.listGroupByEmployee.errors = action.payload;
      draft.listGroupByEmployee.loading = false;
      return draft;
    }

    // Emloyees list
    case getType(actions.getWalkinEmloyeeList.request): {
      draft.employees.loading = true;
      return draft;
    }
    case getType(actions.getWalkinEmloyeeList.success): {
      draft.employees.response = action.payload;
      draft.employees.loading = false;
      return draft;
    }
    case getType(actions.getWalkinEmloyeeList.failure): {
      draft.employees.errors = action.payload;
      draft.employees.loading = false;
      return draft;
    }

    // Customer list
    case getType(actions.checkinCustomer.request): {
      draft.checkinCustomer.loading = true;
      return draft;
    }
    case getType(actions.checkinCustomer.success): {
      draft.checkinCustomer.response = action.payload;
      draft.checkinCustomer.loading = false;
      return draft;
    }
    case getType(actions.checkinCustomer.failure): {
      draft.checkinCustomer.errors = action.payload;
      draft.checkinCustomer.loading = false;
      return draft;
    }

    // Service list
    case getType(actions.getServicesOfEmployee.request): {
      draft.services.loading = true;
      return draft;
    }
    case getType(actions.getServicesOfEmployee.success): {
      draft.services.response = action.payload;
      draft.services.loading = false;
      return draft;
    }
    case getType(actions.getServicesOfEmployee.failure): {
      draft.services.errors = action.payload;
      draft.services.loading = false;
      return draft;
    }

    case getType(actions.refreshCurrentTime): {
      draft.currentTime = action.payload;
      return draft;
    }

    // Walkin Register
    case getType(actions.setWalkinRegister): {
      draft.walkinRegister = action.payload;
      return draft;
    }

    case getType(actions.setWalkInSuccess): {
      draft.walkinSuccess.status = action.payload;
      return draft;
    }

    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialWalkinState;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialWalkinState);

export default walkin;
