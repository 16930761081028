import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { ProductKitState, initialProductKitState } from './model';

const productKit = produce((draft: Draft<ProductKitState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getProductKitList.request): {
      draft.list.loading = true;
      return draft;
    }
    case getType(actions.getProductKitList.success): {
      draft.list.loading = true;
      draft.list.response = action.payload;
      return draft;
    }
    case getType(actions.getProductKitList.failure): {
      draft.list.loading = false;
      draft.list.errors = action.payload;
      return draft;
    }
    case getType(actions.getProductKitDetails.request): {
      draft.details.loading = true;
      draft.details.response = undefined;
      draft.details.errors = undefined;
      return draft;
    }
    case getType(actions.getProductKitDetails.success): {
      draft.details.loading = true;
      draft.details.response = action.payload;

      return draft;
    }
    case getType(actions.getProductKitDetails.failure): {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    case getType(actions.createProductKit.request): {
      draft.details.loading = true;
      return draft;
    }
    case getType(actions.createProductKit.success): {
      draft.create.loading = false;
      draft.create.response = action.payload;
      return draft;
    }
    case getType(actions.createProductKit.failure): {
      draft.create.loading = false;
      draft.create.errors = action.payload;
      return draft;
    }
    case getType(actions.updateProductKit.request): {
      draft.update.loading = true;
      return draft;
    }
    case getType(actions.updateProductKit.success): {
      draft.update.loading = false;
      draft.update.response = action.payload;
      return draft;
    }
    case getType(actions.updateProductKit.failure): {
      draft.update.loading = false;
      draft.update.errors = action.payload;
      return draft;
    }
    case getType(actions.deleteProductKit.request): {
      draft.delete.loading = true;
      return draft;
    }
    case getType(actions.deleteProductKit.success): {
      draft.delete.loading = false;
      draft.delete.response = action.payload;
      return draft;
    }
    case getType(actions.deleteProductKit.failure): {
      draft.delete.loading = false;
      draft.delete.errors = action.payload;
      return draft;
    }
    case getType(actions.bulkProductKits.request): {
      draft.bulk.loading = true;
      return draft;
    }
    case getType(actions.bulkProductKits.success): {
      draft.bulk.loading = false;
      draft.bulk.response = action.payload;
      return draft;
    }
    case getType(actions.bulkProductKits.failure): {
      draft.bulk.loading = false;
      draft.bulk.errors = action.payload;
      return draft;
    }
    case getType(actions.importProductKit.success): {
      draft.import.loading = false;
      draft.import.response = action.payload;
      return draft;
    }
    case getType(actions.importProductKit.failure): {
      draft.import.loading = false;
      draft.import.errors = action.payload;
      return draft;
    }
    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialProductKitState;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialProductKitState);

export default productKit;
