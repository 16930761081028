import { createAction, createAsyncAction } from 'typesafe-actions';
import * as types from './types';
import {
  GetTurnListRequest,
  GetTurnListResponse,
  CreateTurnDetailRequest,
  CreateTurnDetailReponse,
  GetTurnDetailRequest,
  GetTurnDetailResponse,
  UpdateTurnDetailRequest,
  UpdateTurnDetailResponse,
  DeleteTurnDetailRequest,
  DeleteTurnDetailResponse,
  GetTurnServicesResponse,
  GetTurnServicesRequest,
  GetReorderListRequest,
  GetReorderListResponse,
  ChangeReorderRequest,
  ChangeReorderResponse,
} from '../../api-models/employee-turn-system';

export const getTurnServices = createAsyncAction(
  types.FETCH_SERVICES_LIST,
  types.FETCH_SERVICES_LIST_SUCCESS,
  types.FETCH_SERVICES_LIST_FAILED
)<GetTurnServicesRequest, GetTurnServicesResponse, any>();

export const getTurnList = createAsyncAction(
  types.FETCH_TURN_LIST,
  types.FETCH_TURN_LIST_SUCCESS,
  types.FETCH_TURN_LIST_FAILED
)<GetTurnListRequest, GetTurnListResponse, any>();

export const createTurnDetail = createAsyncAction(types.CREATE, types.CREATE_SUCCESS, types.CREATE_FAILED)<
  CreateTurnDetailRequest,
  CreateTurnDetailReponse,
  any
>();

export const getTurnDetail = createAsyncAction(
  types.FETCH_TURN_DETAIL,
  types.FETCH_TURN_DETAIL_SUCCESS,
  types.FETCH_TURN_DETAIL_FAILED
)<GetTurnDetailRequest, GetTurnDetailResponse, any>();

export const updateTurnDetail = createAsyncAction(types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILED)<
  UpdateTurnDetailRequest,
  UpdateTurnDetailResponse,
  any
>();

export const deleteTurnDetail = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  DeleteTurnDetailRequest,
  DeleteTurnDetailResponse,
  any
>();

export const getReorderList = createAsyncAction(
  types.FETCH_REORDER_LIST,
  types.FETCH_REORDER_LIST_SUCCESS,
  types.FETCH_REORDER_LIST_FAILED
)<GetReorderListRequest, GetReorderListResponse, any>();

export const changeReorder = createAsyncAction(
  types.CHANGE_REORDER,
  types.CHANGE_REORDER_SUCCESS,
  types.CHANGE_REORDER_FAILED
)<ChangeReorderRequest, ChangeReorderResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
