export const FETCH_SERVICES_LIST = 'turn_system/get_services_list';
export const FETCH_SERVICES_LIST_SUCCESS = 'turn_system/get_services_list_success';
export const FETCH_SERVICES_LIST_FAILED = 'turn_system/get_services_list_failed';

export const FETCH_TURN_LIST = 'turn_system/get_list_turn';
export const FETCH_TURN_LIST_SUCCESS = 'turn_system/get_list_turn_success';
export const FETCH_TURN_LIST_FAILED = 'turn_system/get_list_turn_failed';

export const CREATE = 'turn_system/create';
export const CREATE_SUCCESS = 'turn_system/create_success';
export const CREATE_FAILED = 'turn_system/create_failed';

export const FETCH_TURN_DETAIL = 'turn-system/detail';
export const FETCH_TURN_DETAIL_SUCCESS = 'turn-system/detail_success';
export const FETCH_TURN_DETAIL_FAILED = 'turn-system/detail_failed';

export const UPDATE = 'turn_system/update';
export const UPDATE_SUCCESS = 'turn_system/update_success';
export const UPDATE_FAILED = 'turn_system/update_failed';

export const DELETE = 'turn_system/delete';
export const DELETE_SUCCESS = 'turn_system/delete_success';
export const DELETE_FAILED = 'turn_system/delete_failed';

export const FETCH_REORDER_LIST = 'turn-system/reorder_list';
export const FETCH_REORDER_LIST_SUCCESS = 'turn-system/reorder_list_success';
export const FETCH_REORDER_LIST_FAILED = 'turn-system/reorder_list_failed';

export const CHANGE_REORDER = 'turn-system/change_reorder';
export const CHANGE_REORDER_SUCCESS = 'turn-system/change_reorder_success';
export const CHANGE_REORDER_FAILED = 'turn-system/change_reorder_failed';

export const CLEAR_DATA = 'turn_system/clear_data';

export type TurnSystemAction =
  | typeof FETCH_TURN_LIST
  | typeof FETCH_TURN_LIST_SUCCESS
  | typeof FETCH_TURN_LIST_FAILED
  | typeof CREATE
  | typeof CREATE_SUCCESS
  | typeof CREATE_FAILED
  | typeof FETCH_TURN_DETAIL
  | typeof FETCH_TURN_DETAIL_SUCCESS
  | typeof FETCH_TURN_DETAIL_FAILED
  | typeof UPDATE
  | typeof UPDATE_SUCCESS
  | typeof UPDATE_FAILED
  | typeof DELETE
  | typeof DELETE_SUCCESS
  | typeof DELETE_FAILED
  | typeof CLEAR_DATA
  | typeof FETCH_REORDER_LIST
  | typeof FETCH_REORDER_LIST_SUCCESS
  | typeof FETCH_REORDER_LIST_FAILED
  | typeof CHANGE_REORDER
  | typeof CHANGE_REORDER_SUCCESS
  | typeof CHANGE_REORDER_FAILED;
