import React from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { OverlayLoading } from 'src/components/common/loading/overlay-loading';
import { AppRouteProps } from './types';
import { useAppRoutes } from './use-app-routes';
import { getRouteDefaultHomePage } from 'src/state/ducks/common/auth/storage';
import { getPageUrl } from 'src/guards/utils';

// Containers
const DefaultLayout = React.lazy(() => import('../components/layout/DefaultLayout'));

const FrontLayout = React.lazy(() => import('../components/layout/FrontLayout'));

export const BaseRoute = ({
  component: Component,
  layout,
  screen,
  module,
  requireAuth,
  auth,
  ...rest
}: AppRouteProps) => (
  <>
    <Route
      {...rest}
      render={(props) => (
        <>
          {layout === 'default' || layout === 'pos' ? (
            <DefaultLayout
              layout={layout}
              module={module}
              screen={screen}
              requireAuth={requireAuth}
              auth={auth}
              {...props}
            >
              <Component {...props} />
            </DefaultLayout>
          ) : (
            <FrontLayout
              layout={layout}
              module={module}
              screen={screen}
              requireAuth={requireAuth}
              auth={auth}
              {...props}
            >
              <div className={layout ? `screen_${layout}` : layout}>
                <Component {...props} />
              </div>
            </FrontLayout>
          )}
        </>
      )}
    />
  </>
);

export const AppRoute = () => {
  const { appRoutes } = useAppRoutes();
  const homePage = getRouteDefaultHomePage();
  const homePageUrl = getPageUrl(homePage);

  return (
    <HashRouter>
      <React.Suspense fallback={<OverlayLoading />}>
        {/* <Switch>
          <Route
            path="/"
            name="Home"
            render={(props: RouteComponentProps) => (
              <>
                <DefaultLayout {...props}>
              </>
            )}
          />
        </Switch> */}
        <Switch>
          {appRoutes.map((route, idx) => (
            <BaseRoute key={idx} {...route} />
          ))}
          <Redirect from="/" to={homePageUrl} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};
