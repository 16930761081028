import produce, { Draft } from 'immer';
import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';

import { initialSupportState, OrderingState } from './model';

const ordering = produce((draft: Draft<OrderingState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getOrdering.request): {
      draft.loading = true;
      return draft;
    }
    case getType(actions.getOrdering.success): {
      draft.loading = true;
      draft.response = action.payload;
      return draft;
    }
    case getType(actions.getOrdering.failure): {
      draft.loading = false;
      draft.response = action.payload;
      return draft;
    }
    case getType(actions.clearData): {
      draft = initialSupportState.ordering;
      return draft;
    }
    default:
      return draft;
  }
}, initialSupportState.ordering);

export default combineReducers({
  ordering,
});
