export const LOGIN = 'auth/login';
export const LOGIN_SUCCESS = 'auth/login_success';
export const LOGIN_FAILED = 'auth/login_failed';

export const LOGIN_TOKEN = 'auth/login_token';
export const LOGIN_TOKEN_SUCCESS = 'auth/login_token_success';
export const LOGIN_TOKEN_FAILED = 'auth/login_token_failed';

export const LOGOUT = 'auth/logout';
export const LOGOUT_SUCCESS = 'auth/logout_success';
export const LOGOUT_FAILED = 'auth/logout_failed';

export const CHECK_PIN_CODE_ROLE = 'auth/check_pin_code';
export const CHECK_PIN_CODE_ROLE_SUCCESS = 'auth/check_pin_code_success';
export const CHECK_PIN_CODE_ROLE_FAILED = 'auth/check_pin_code_failed';

export const GET_INITIAL_SETTINGS = 'auth/get_required_setting';
export const GET_INITIAL_SETTINGS_SUCCESS = 'auth/get_required_setting_success';
export const GET_INITIAL_SETTINGS_FAILED = 'auth/get_required_setting_failed';

export const UPDATE_INITIAL_SETTINGS = 'auth/update_required_setting';
export const UPDATE_PROFILE = 'auth/update_profile';
export const UPDATE_PROFILE_SUCCESS = 'auth/update_profile_success';
export const UPDATE_PROFILE_FAILED = 'auth/update_profile_failed';

export const CLEAR_DATA = 'auth/clear_data';
export const CLEAR_DATA_CHECK_PIN_CODE = 'auth/clear_data_check_pin_code';
export const CLEAR_DATA_PROFILE = 'auth/clear_data_profile';

export type AuthAction =
  | typeof LOGIN
  | typeof LOGIN_FAILED
  | typeof LOGIN_SUCCESS
  | typeof LOGOUT
  | typeof LOGOUT_FAILED
  | typeof LOGIN_SUCCESS
  | typeof CHECK_PIN_CODE_ROLE
  | typeof CHECK_PIN_CODE_ROLE_SUCCESS
  | typeof CHECK_PIN_CODE_ROLE_FAILED
  | typeof GET_INITIAL_SETTINGS
  | typeof GET_INITIAL_SETTINGS_SUCCESS
  | typeof GET_INITIAL_SETTINGS_FAILED
  | typeof UPDATE_INITIAL_SETTINGS
  | typeof CLEAR_DATA_CHECK_PIN_CODE
  | typeof CLEAR_DATA;
