import { BaseState } from '../models';
import {
  ExpenseListResponse,
  ExpenseCreateResponse,
  ExpenseDetailResponse,
  ExpenseUpdateResponse,
  ExpenseDeleteResponse,
  ExpenseBulkResponse,
} from '../../api-models/expense';

export type ExpenseList = BaseState<ExpenseListResponse>;
export type ExpenseCreate = BaseState<ExpenseCreateResponse>;
export type ExpenseDetails = BaseState<ExpenseDetailResponse>;
export type ExpenseUpdate = BaseState<ExpenseUpdateResponse>;
export type ExpenseDelete = BaseState<ExpenseDeleteResponse>;
export type ExpenseBulk = BaseState<ExpenseBulkResponse>;

export type ExpenseState = {
  list: ExpenseList;
  create: ExpenseCreate;
  details: ExpenseDetails;
  update: ExpenseUpdate;
  delete: ExpenseDelete;
  bulk: ExpenseBulk;
};

export const initialExpenseState = {
  list: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    expense: ExpenseState;
  }
}
