import { put, call, all, takeLatest } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';

// Handle request saga
function* getMembershipList(action: ReturnType<typeof actions.getMembershipList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.MEMBERSHIP.List, {
      params: action.payload,
    });

    yield put(actions.getMembershipList.success((response as any).data));
  } catch (err) {
    yield put(actions.getMembershipList.failure(err));
  }
}

function* createMembership(action: ReturnType<typeof actions.createMembership.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.MEMBERSHIP.Create, action.payload));

    yield put(actions.createMembership.success((response as any).data));
  } catch (err) {
    yield put(actions.createMembership.failure(err));
  }
}

function* updateMembership(action: ReturnType<typeof actions.updateMembership.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.MEMBERSHIP.Update.replace('{id}', action.payload.id), action.payload)
    );

    yield put(actions.updateMembership.success((response as any).data));
  } catch (err) {
    yield put(actions.updateMembership.failure(err));
  }
}

function* detailMembership(action: ReturnType<typeof actions.detailMembership.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.get(API_ENDPOINTS.MEMBERSHIP.Details.replace('{id}', action.payload.id))
    );

    yield put(actions.detailMembership.success((response as any).data));
  } catch (err) {
    yield put(actions.detailMembership.failure(err));
  }
}

function* deleteMembership(action: ReturnType<typeof actions.deleteMembership.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.delete(API_ENDPOINTS.MEMBERSHIP.Delete.replace('{id}', action.payload.id))
    );

    yield put(actions.deleteMembership.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteMembership.failure(err));
  }
}

function* historyMembership(action: ReturnType<typeof actions.historyMembership.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.get(API_ENDPOINTS.MEMBERSHIP.History.replace('{id}', action.payload.id))
    );

    yield put(actions.historyMembership.success((response as any).data));
  } catch (err) {
    yield put(actions.historyMembership.failure(err));
  }
}

function* bulkMembership(action: ReturnType<typeof actions.bulkMembership.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.MEMBERSHIP.Bulk, action.payload);

    yield put(actions.bulkMembership.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkMembership.failure(err));
  }
}

// Main saga
export default function* membershipSaga() {
  yield all([
    takeLatest(actions.getMembershipList.request, getMembershipList),
    takeLatest(actions.createMembership.request, createMembership),
    takeLatest(actions.updateMembership.request, updateMembership),
    takeLatest(actions.detailMembership.request, detailMembership),
    takeLatest(actions.deleteMembership.request, deleteMembership),
    takeLatest(actions.bulkMembership.request, bulkMembership),
    takeLatest(actions.historyMembership.request, historyMembership),
  ]);
}
