import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints/sms/logs';
import { axiosInstance } from 'src/utils/axios/axios-instance';
import * as actions from './actions';

// Handle request saga
function* smsLogSummary(action: ReturnType<typeof actions.smsLogSummary.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.SMS_LOGS.Summary, {
      params: action.payload,
    });
    yield put(actions.smsLogSummary.success((response as any).data));
  } catch (err) {
    yield put(actions.smsLogSummary.failure(err));
  }
}

function* smsLogDetails(action: ReturnType<typeof actions.smsLogDetails.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.SMS_LOGS.Detail, {
      params: action.payload,
    });

    yield put(actions.smsLogDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.smsLogDetails.failure(err));
  }
}

function* deleteSmsLog(action: ReturnType<typeof actions.deleteSmsLog.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.delete,
      API_ENDPOINTS.SMS_LOGS.Delete.replace('{id}', `${action.payload}`)
    );

    yield put(actions.deleteSmsLog.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteSmsLog.failure(err));
  }
}

function* bulkSmsLog(action: ReturnType<typeof actions.bulkSmsLog.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.SMS_LOGS.Bulk, action.payload);

    yield put(actions.bulkSmsLog.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkSmsLog.failure(err));
  }
}

// Main saga
export default function* smsSaga() {
  yield all([
    takeLatest(actions.smsLogSummary.request, smsLogSummary),
    takeLatest(actions.smsLogDetails.request, smsLogDetails),
    takeLatest(actions.deleteSmsLog.request, deleteSmsLog),
    takeLatest(actions.bulkSmsLog.request, bulkSmsLog),
  ]);
}
