import produce, { Draft } from 'immer';
import * as types from './types';
import { CustomerGroupState, initialCustomerGroupState } from './model';

const customerGroup = produce((draft: Draft<CustomerGroupState>, action) => {
  switch (action.type) {
    case types.FETCH_LIST: {
      draft.list.loading = true;
      return draft;
    }
    case types.FETCH_LIST_SUCCESS: {
      draft.list.loading = true;
      draft.list.response = action.payload;
      return draft;
    }
    case types.FETCH_LIST_FAILED: {
      draft.list.loading = false;
      draft.list.errors = action.payload;
      return draft;
    }
    case types.FETCH_DETAILS: {
      draft.details.loading = true;
      draft.details.response = undefined;
      draft.details.errors = undefined;
      return draft;
    }
    case types.FETCH_DETAILS_SUCCESS: {
      draft.details.loading = true;
      draft.details.response = action.payload;

      return draft;
    }
    case types.FETCH_DETAILS_FAILED: {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    case types.CREATE: {
      draft.details.loading = true;
      return draft;
    }
    case types.CREATE_SUCCESS: {
      draft.create.loading = false;
      draft.create.response = action.payload;
      return draft;
    }
    case types.CREATE_FAILED: {
      draft.create.loading = false;
      draft.create.errors = action.payload;
      return draft;
    }
    case types.UPDATE: {
      draft.update.loading = true;
      return draft;
    }
    case types.UPDATE_SUCCESS: {
      draft.update.loading = false;
      draft.update.response = action.payload;
      return draft;
    }
    case types.UPDATE_FAILED: {
      draft.update.loading = false;
      draft.update.errors = action.payload;
      return draft;
    }
    case types.DELETE: {
      draft.delete.loading = true;
      return draft;
    }
    case types.DELETE_SUCCESS: {
      draft.delete.loading = false;
      draft.delete.response = action.payload;
      return draft;
    }
    case types.DELETE_FAILED: {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    case types.BULK: {
      draft.bulk.loading = true;
      return draft;
    }
    case types.BULK_SUCCESS: {
      draft.bulk.loading = false;
      draft.bulk.response = action.payload;
      return draft;
    }
    case types.BULK_FAILED: {
      draft.bulk.loading = false;
      draft.bulk.errors = action.payload;
      return draft;
    }
    case types.CHECK_HAS_CUSTOMER: {
      draft.checkHasCustomer.loading = true;
      draft.checkHasCustomer.response = null;
      return draft;
    }
    case types.CHECK_HAS_CUSTOMER_SUCCESS: {
      draft.checkHasCustomer.loading = false;
      draft.checkHasCustomer.response = action.payload;
      return draft;
    }
    case types.CHECK_HAS_CUSTOMER_FAILED: {
      draft.checkHasCustomer.loading = false;
      draft.checkHasCustomer.errors = action.payload;
      return draft;
    }

    case types.CLEAR_DATA: {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialCustomerGroupState;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialCustomerGroupState);

export default customerGroup;
