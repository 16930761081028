export const FETCH_DETAILS = 'emarketing/campaign/details';
export const FETCH_DETAILS_SUCCESS = 'emarketing/campaign/details_success';
export const FETCH_DETAILS_FAILED = 'emarketing/campaign/details_failed';

export const FETCH_LIST = 'emarketing/campaign/list';
export const FETCH_LIST_SUCCESS = 'emarketing/campaign/list_success';
export const FETCH_LIST_FAILED = 'emarketing/campaign/list_failed';

export const CREATE = 'emarketing/campaign/create';
export const CREATE_SUCCESS = 'emarketing/campaign/create_success';
export const CREATE_FAILED = 'emarketing/campaign/create_failed';

export const UPDATE = 'emarketing/campaign/update';
export const UPDATE_SUCCESS = 'emarketing/campaign/update_success';
export const UPDATE_FAILED = 'emarketing/campaign/update_failed';

export const UPDATE_STATUS = 'emarketing/campaign/update_status';
export const UPDATE_STATUS_SUCCESS = 'emarketing/campaign/update_status_success';
export const UPDATE_STATUS_FAILED = 'emarketing/campaign/update_status_failed';

export const DELETE = 'emarketing/campaign/detele';
export const DELETE_SUCCESS = 'emarketing/campaign/detele_success';
export const DELETE_FAILED = 'emarketing/campaign/detele_failed';

export const BULK = 'emarketing/campaign/bulk';
export const BULK_SUCCESS = 'emarketing/campaign/bulk_success';
export const BULK_FAILED = 'emarketing/campaign/bulk_failed';

export const CLEAR_DATA = 'emarketing/campaign/clear_data';

export type CampaignAction =
  | typeof FETCH_DETAILS
  | typeof FETCH_DETAILS_FAILED
  | typeof FETCH_DETAILS_SUCCESS
  | typeof FETCH_LIST
  | typeof FETCH_LIST_FAILED
  | typeof FETCH_LIST_SUCCESS
  | typeof CREATE
  | typeof CREATE_FAILED
  | typeof CREATE_SUCCESS
  | typeof UPDATE
  | typeof UPDATE_FAILED
  | typeof UPDATE_SUCCESS
  | typeof DELETE
  | typeof DELETE_FAILED
  | typeof DELETE_SUCCESS
  | typeof BULK
  | typeof BULK_FAILED
  | typeof BULK_SUCCESS
  | typeof CLEAR_DATA;
