import {
  SettingData,
  SettingListRequest,
  SettingListResponse,
  SettingUpdateManyRequest,
  SettingUpdateManyResponse,
  SettingUpdateRequest,
  SettingUpdateResponse,
} from 'src/state/api-models/setting';
import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';

// Create the set of async actions
export const getSettingList = createAsyncAction(types.FETCH_LIST, types.FETCH_LIST_SUCCESS, types.FETCH_LIST_FAILED)<
  SettingListRequest,
  SettingListResponse,
  any
>();

// Create the set of async actions
export const updateSettings = createAsyncAction(types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILED)<
  SettingUpdateRequest,
  SettingUpdateResponse,
  any
>();

// Create the set of async actions
export const updateManySettings = createAsyncAction(
  types.UPDATE_MANY,
  types.UPDATE_MANY_SUCCESS,
  types.UPDATE_MANY_FAILED
)<SettingUpdateManyRequest, SettingUpdateManyResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)();

export const hasUpdatedData = createAction(types.HAS_UPDATED_DATA)();

export const clearGroupData = createAction(types.CLEAR_GROUP_DATA)<string | void>();
export const setData = createAction(types.SET_DATA)<SettingData>();
