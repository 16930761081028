import { useMemo, useCallback } from 'react';
import navigation from './navigation';
import { useTranslation } from 'react-i18next';
import routes from './routes';

export const useAppRoutes = () => {
  const { t } = useTranslation('navigation');

  const getNavigations = useCallback(
    (modules: string[], excludeScreens: string[] = []) => {
      const filterItems = [];
      navigation.items.forEach((item) => {
        if (modules.indexOf(item.module) >= 0 && excludeScreens.indexOf(item.screen) < 0) {
          if (item.children && item.children.length > 0) {
            const filterChildren = item.children
              .filter((child) => modules.indexOf(child.module) >= 0 && excludeScreens.indexOf(child.screen) < 0)
              .map((child) => ({
                ...child,
                name: t(`navigation:${child.name}.name`),
                badge: { ...child.badge, text: t(`navigation:${child.name}.badge`) },
              }));
            filterItems.push({
              ...item,
              name: t(`navigation:${item.name}.name`),
              badge: { ...item.badge, text: t(`navigation:${item.name}.badge`) },
              children: filterChildren,
            });
          } else {
            filterItems.push({
              ...item,
              name: t(`navigation:${item.name}.name`),
              badge: { ...item.badge, text: t(`navigation:${item.name}.badge`) },
            });
          }
        } else if (item.children && item.children.length > 0) {
          const filterChildren = item.children
            .filter((child) => modules.indexOf(child.module) >= 0 && excludeScreens.indexOf(child.screen) < 0)
            .map((child) => ({
              ...child,
              name: t(`navigation:${child.name}.name`),
              badge: { ...child.badge, text: t(`navigation:${child.name}.badge`) },
            }));
          if (filterChildren.length > 0) {
            filterItems.push({
              ...item,
              name: t(`navigation:${item.name}.name`),
              badge: { ...item.badge, text: t(`navigation:${item.name}.badge`) },
              children: filterChildren,
            });
          }
        }
      });

      return { items: filterItems };
    },
    [t]
  );

  const appRoutes = useMemo(() => {
    return routes.map((item) => ({
      ...item,
      name: t(`navigation:${item.name}.name`),
    }));
  }, [t]);

  return { getNavigations, appRoutes };
};
