export const CUSTOMER = {
  List: '/customers',
  Create: '/customers',
  Details: '/customers/{id}',
  Update: '/customers/{id}',
  Delete: '/customers/{id}',
  Bulk: '/customers/bulk',
  Import: '/customers/import',
  CheckMembership: '/customers/check-membership',
  ListHistoryPoint: '/customers/historyPoint',
  ListReviews: '/customers/reviews',
  Avatar: '/customers/avatar/{id}',

  PosActiveList: '/pos/customers',
  PostDetails: '/pos/customers/{id}',
  PosCreate: '/pos/customers',
  PosUpdate: '/pos/customers/{id}',
  Checkin: '/pos/customers/checkin',

  CreateReview: '/pos/reviews',
};
