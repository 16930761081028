import { CalculateData } from 'src/types/calculate';
import { PaymentType } from 'src/types/sale';
import { BookingAppt } from './bookingAppt';
import { Employee } from './employee';
import { Customer } from './customer';
import { DiscountSetting } from 'src/types/discount';

export type PaymentData = {
  payment_type: PaymentType;
  payment_amount: number;
  payment_info?: any;
};

export type CreditPaymentData = {
  payment_id: string;
  payment_amount: number;
  payment_info?: any;
};

export type PaymentAmounts = {
  cash: number;
  credit: number;
  giftCard: number;
  check: number;
  point: number;
};

export type TipData = {
  user_id: string;
  employee_name?: string;
  tip_amount: number;
};

export enum OrderItemType {
  Service = 1,
  Product = 2,
  ProductKit = 3,
  Giftcard = 90,
}

export type OrderItem = {
  id: string;
  name: string;
  sku?: string;
  giftcard_number?: string;
  unit_price?: number;
  category_id?: string;
  price?: number;
  taxes?: string;
  tax_on?: number;
  indian_tax?: '0' | '1';
  user_id?: string;
  employee_name?: string;
  order_status_id?: string;
  font_color?: string;
  bg_color?: string;
  info?: any;
};

export type OrderItemData = Omit<OrderItem, 'id'> & {
  item_id: string;
  item_code?: string;
  item_type: OrderItemType;
  quantity: number;
  total?: number;
  discounts?: DiscountSetting[];
  discount_emp?: number;
  discount_item?: number;
  discount_emp_info?: DiscountSetting;
  discount_item_info?: DiscountSetting;
};

export type OrderMeta = {
  id?: string;
  daily_ticket_no?: number;
  ticket_no?: number;
  invoice_no?: number;
  order_at?: string;
};

export type Order = {
  id: string;
  user_id: string;
  store_id: string;
  customer_id: string;
  order_status_id: number;
  daily_ticket_no?: number;
  ticket_no?: number;
  invoice_no?: number;
  invoice_prefix: string;
  order_at: string;
  comment: string;
  taxes: number;
  total_price: number;
  currency_id: string;
  currency_code: string;
  currency_value: number;
  items: OrderItemData[];
  total: OrderTotal;
  discount: OrderDiscount;
  payments: PaymentData[];
};

export type OrderDiscount = {
  id: string;
  order_id: string;
  discount_item: number;
  discount_employee: number;
  discount_ticket: number;
  discount_ticket_info?: CalculateData;
  discount_membership: number;
  discount_membership_info?: CalculateData;
  discount_manager: number;
  discount_manager_info?: CalculateData;
  discount_other: number;
  discount_other_info?: CalculateData;
};

export const initOrderDiscount: OrderDiscount = {
  id: '',
  order_id: '',
  discount_item: 0,
  discount_employee: 0,
  discount_ticket: 0,
  discount_membership: 0,
  discount_manager: 0,
  discount_other: 0,
};

export type OrderTotal = OrderDiscount & {
  id: string;
  order_id: string;
  total_cost: number;
  total_commission: number;
  total_deductible: number;
  creditcard_fee: number;
  taxes: number;
  discount_amount: number;
  tip_amount: number;
  refund_amount?: number;
  sub_total: number;
  total_price: number;
  paid_customer_total: number;
  paid_customer_change_amount_total: number;
  indian: boolean;
  // redeem: number;
};

export const initOrderTotal: OrderTotal = {
  id: '',
  order_id: '',
  total_cost: 0,
  total_commission: 0,
  total_deductible: 0,
  creditcard_fee: 0,
  taxes: 0,
  discount_amount: 0,
  tip_amount: 0,
  sub_total: 0,
  total_price: 0,
  paid_customer_total: 0,
  paid_customer_change_amount_total: 0,
  indian: false,
  // redeem: 0,
  ...initOrderDiscount,
};

export type EmployeeWithOrderItems = {
  employee: Employee;
  orderItems: OrderItemData[];
  tips?: TipData;
};

export type CreditPaymentAction = 'sale' | 'retry' | 'draft' | 'complete';

export type SaleOrderData = {
  id?: string;
  store_id?: string;
  workstation_id?: string;
  daily_ticket_no?: number;
  ticket_no?: number;
  invoice_no?: number;
  invoice_prefix?: string;
  order_at?: string;
  orderEmployees: EmployeeWithOrderItems[];
  discount?: OrderDiscount;
  payments: PaymentData[];
  employee?: Employee;
  customer?: Customer;
  customer_name?: string;
  customer_id?: string;
  booking?: BookingAppt;
  orderTotal?: OrderTotal;
  creditPaymentAction?: CreditPaymentAction;
};

export const initSaleOrderData: SaleOrderData = {
  orderEmployees: [],
  payments: [],
};

export type OrderTotalAmount = {
  totalAmount: number;
  totalTaxAmount: number;
  totalTipAmount: number;
  totalDiscountEmployee: number;
  totalDiscountItem: number;
};
