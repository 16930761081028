export const makeCode = (length = 10) => {
  let result = '';

  const characters = 'ABCDEFGHJKLMNOPQRSTUVWXUZ0123456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return `${result.substring(0, 3)}-${result.substring(3)}`;
};

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function client_id() {
  return Math.random().toString(36).slice(-6);
}

export function generateMposID({ tenant, store_code, workstationId }) {
  return `${String(tenant).toUpperCase()}-${String(store_code).padStart(4, '0')}-${String(workstationId).padStart(
    2,
    '0'
  )}`;
}
