export type GlobalKeys = 'selectedStoreWorkstationId';
export type GlobalState = {
  [key in GlobalKeys]?: any;
};

export const initialGlobalState = {};

declare module './../../models' {
  interface StateAll {
    // match to a duck folder
    'common/global': GlobalState;
  }
}
