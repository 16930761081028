import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';
import {
  ProductBulkRequest,
  ProductBulkResponse,
  ProductCreateResponse,
  ProductDeleteResponse,
  ProductDetailResponse,
  ProductListRequest,
  ProductListResponse,
  ProductUpdateResponse,
  ProductImportRequest,
  ProductImportResponse,
  InventoryResponse,
  InventoryRequest,
  InventoryCreateRequest,
  InventoryCreateResponse,
  ProductUpdateRequest,
} from '../../api-models/product';
import { Product } from 'src/state/models/product';

// Create the set of async actions
export const getProductList = createAsyncAction(types.FETCH_LIST, types.FETCH_LIST_SUCCESS, types.FETCH_LIST_FAILED)<
  ProductListRequest,
  ProductListResponse,
  any
>();

// Create the set of async actions
export const importProduct = createAsyncAction(
  types.IMPORT_EXCEL,
  types.IMPORT_EXCEL_SUCCESS,
  types.IMPORT_EXCEL_FAILED
)<ProductImportRequest, ProductImportResponse, any>();

// Create the set of async actions
export const getProductDetails = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<string, ProductDetailResponse, any>();

// Create the set of async actions
export const createProduct = createAsyncAction(types.CREATE, types.CREATE_SUCCESS, types.CREATE_FAILED)<
  Product,
  ProductCreateResponse,
  any
>();

// Create the set of async actions
export const updateProduct = createAsyncAction(types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILED)<
  Product,
  ProductUpdateResponse,
  any
>();

// Create the set of async actions
export const updateOrdering = createAsyncAction(
  types.UPDATE_ORDERING,
  types.UPDATE_ORDERING_SUCCESS,
  types.UPDATE_ORDERING_FAILED
)<ProductUpdateRequest, ProductUpdateResponse, any>();

// Create the set of async actions
export const deleteProduct = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  string,
  ProductDeleteResponse,
  any
>();

// Create the set of async actions
export const bulkProduct = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  ProductBulkRequest,
  ProductBulkResponse,
  any
>();

// Create the set of async actions
export const createInventory = createAsyncAction(types.CREATEINV, types.CREATEINV_SUCCESS, types.CREATEINV_FAILED)<
  InventoryCreateRequest,
  InventoryCreateResponse,
  any
>();

// Create the set of async actions
export const getInventory = createAsyncAction(
  types.FETCH_INVENTORY_DETAILS,
  types.FETCH_INVENTORY_DETAILS_SUCCESS,
  types.FETCH_INVENTORY_DETAILS_FAILED
)<InventoryRequest, InventoryResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
