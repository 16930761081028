export const ENABLE_WALIK_EMPLOYEES = 'walkin/enable_walik_employees';
export const ENABLE_WALIK_EMPLOYEES_SUCCESS = 'walkin/enable_walik_employees_success';
export const ENABLE_WALIK_EMPLOYEES_FAILED = 'walkin/enable_walik_employees_failed';

export const WALK_IN_LIST_REQUEST = 'walkin/walkin_list_request';
export const WALK_IN_LIST_SUCCESS = 'walkin/walkin_list_success';
export const WALK_IN_LIST_FAILED = 'walkin/walkin_list_failed';

export const WALK_IN_LIST_GROUP_BY_EMPLOYEE_REQUEST = 'walkin/list_group_by_employee_request';
export const WALK_IN_LIST_GROUP_BY_EMPLOYEE_SUCCESS = 'walkin/list_group_by_employee_success';
export const WALK_IN_LIST_GROUP_BY_EMPLOYEE_FAILED = 'walkin/list_group_by_employee_failed';

export const WALK_IN_CUSTOMERS_REQUEST = 'walkin/walkin_customers_request';
export const WALK_IN_CUSTOMERS_SUCCESS = 'walkin/walkin_customers_success';
export const WALK_IN_CUSTOMERS_FAILED = 'walkin/walkin_customers_failed';

export const WALK_IN_SERVICES_REQUEST = 'walkin/walkin_services_request';
export const WALK_IN_SERVICES_SUCCESS = 'walkin/walkin_services_success';
export const WALK_IN_SERVICES_FAILED = 'walkin/walkin_services_failed';

export const REFERSH_CURRENT_TIME = 'walkin/refersh_current_time';
export const WALK_IN_REGISTER = 'walkin/walkin_register';
export const SET_WALKIN_SUCCESS = 'walkin/set_sign_in_walkin_success';

export const CLEAR_DATA = 'walkin/clear_data';

export type WalkinAction =
  | typeof WALK_IN_LIST_REQUEST
  | typeof WALK_IN_LIST_SUCCESS
  | typeof WALK_IN_LIST_FAILED
  | typeof WALK_IN_CUSTOMERS_REQUEST
  | typeof WALK_IN_CUSTOMERS_SUCCESS
  | typeof WALK_IN_CUSTOMERS_FAILED
  | typeof WALK_IN_SERVICES_REQUEST
  | typeof WALK_IN_SERVICES_SUCCESS
  | typeof WALK_IN_SERVICES_FAILED
  | typeof WALK_IN_REGISTER
  | typeof CLEAR_DATA;
