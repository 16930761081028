import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  SaleBulkRequest,
  SaleBulkResponse,
  SaleChangeEmployeeRequest,
  SaleChangeEmployeeResponse,
  SaleChangeOrderAtRequest,
  SaleChangeOrderAtResponse,
  SaleDeleteResponse,
  SaleDetailResponse,
  SaleInvoiceResponse,
  SaleRefundDetailResponse,
  SaleRefundRequest,
  SaleRefundResponse,
  SaleSaveAllChangesRequest,
  SaleSaveAllChangesResponse,
  SaleUpdateStatusRequest,
  SaleUpdateStatusResponse,
  SaleUpdateTipRequest,
  SaleUpdateTipResponse,
  SaleVoidRequest,
  SaleVoidResponse,
} from 'src/state/api-models/sale';
import * as types from './types';

export const updateSaleTip = createAsyncAction(
  types.UPDATE_SALE_TIP,
  types.UPDATE_SALE_TIP_SUCCESS,
  types.UPDATE_SALE_TIP_FAILED
)<SaleUpdateTipRequest, SaleUpdateTipResponse, any>();

export const changeEmployee = createAsyncAction(
  types.CHANGE_EMPLOYEE,
  types.CHANGE_EMPLOYEE_SUCCESS,
  types.CHANGE_EMPLOYEE_FAILED
)<SaleChangeEmployeeRequest, SaleChangeEmployeeResponse, any>();

export const changeOrderAt = createAsyncAction(
  types.CHANGE_ORDER_AT,
  types.CHANGE_ORDER_AT_SUCCESS,
  types.CHANGE_ORDER_AT_FAILED
)<SaleChangeOrderAtRequest, SaleChangeOrderAtResponse, any>();

export const refundSale = createAsyncAction(types.REFUND_SALE, types.REFUND_SALE_SUCCESS, types.REFUND_SALE_FAILED)<
  SaleRefundRequest,
  SaleRefundResponse,
  any
>();

export const voidSale = createAsyncAction(types.VOID_SALE, types.VOID_SALE_SUCCESS, types.VOID_SALE_FAILED)<
  SaleVoidRequest,
  SaleVoidResponse,
  any
>();

export const saveAllChanges = createAsyncAction(
  types.SAVE_ALL_CHANGES_SALE,
  types.SAVE_ALL_CHANGES_SALE_SUCCESS,
  types.SAVE_ALL_CHANGES_SALE_FAILED
)<SaleSaveAllChangesRequest, SaleSaveAllChangesResponse, any>();

export const bulkSale = createAsyncAction(types.BULK_SALE, types.BULK_SALE_SUCCESS, types.BULK_SALE_FAILED)<
  SaleBulkRequest,
  SaleBulkResponse,
  any
>();

export const getSaleDetail = createAsyncAction(
  types.GET_SALE_DETAIL,
  types.GET_SALE_DETAIL_SUCCESS,
  types.GET_SALE_DETAIL_FAILED
)<string, SaleDetailResponse, any>();

export const getSaleInvoice = createAsyncAction(
  types.GET_SALE_INVOICE,
  types.GET_SALE_INVOICE_SUCCESS,
  types.GET_SALE_INVOICE_FAILED
)<string, SaleInvoiceResponse, any>();

export const getSaleRefund = createAsyncAction(
  types.GET_SALE_REFUND,
  types.GET_SALE_REFUND_SUCCESS,
  types.GET_SALE_REFUND_FAILED
)<string, SaleRefundDetailResponse, any>();

// Create the set of async actions
export const updateSaleStatus = createAsyncAction(
  types.UPDATE_SALE_STATUS,
  types.UPDATE_SALE_STATUS_SUCCESS,
  types.UPDATE_SALE_STATUS_FAILED
)<SaleUpdateStatusRequest, SaleUpdateStatusResponse, any>();

// Create the set of async actions
export const deleteSale = createAsyncAction(types.DELETE_SALE, types.DELETE_SALE_SUCCESS, types.DELETE_SALE_FAILED)<
  string,
  SaleDeleteResponse,
  any
>();

export const restoreSale = createAsyncAction(types.RESTORE_SALE, types.RESTORE_SALE_SUCCESS, types.RESTORE_SALE_FAILED)<
  string,
  SaleDeleteResponse,
  any
>();

// Create the set of async actions
export const clearTrashedSale = createAsyncAction(
  types.CLEAR_TRASHED_SALE,
  types.CLEAR_TRASHED_SALE_SUCCESS,
  types.CLEAR_TRASHED_SALE_FAILED
)<string, SaleDeleteResponse, any>();

// Create the set of async actions
export const clearAllTrashedSales = createAsyncAction(
  types.CLEAR_ALL_TRASHED_SALE,
  types.CLEAR_ALL_TRASHED_SALE_SUCCESS,
  types.CLEAR_ALL_TRASHED_SALE_FAILED
)<void, SaleDeleteResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
