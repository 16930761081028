export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL || 'https://espa247.com';
export const API_URL = process.env.REACT_APP_API_ENDPOINT_ADMIN || 'http://localhost/api';

export const DEMO_TENANT = process.env.REACT_APP_DEMO_TENANT || 'demo';
export const LOGIN_DEMO_ACCOUNT = process.env.REACT_APP_LOGIN_DEMO_ACCOUNT || 'owner@espa247.com';
export const LOGIN_DEMO_PASSWORD = process.env.REACT_APP_LOGIN_DEMO_PASSWORD || 'NailSalon123';
export const LOGIN_DEMO_OWNER_CODE = process.env.REACT_APP_LOGIN_DEMO_OWNER_CODE || '9000';

// specifies the number of milliseconds before the request times out.
// If the request takes longer than `timeout`, the request will be aborted.
// Default is `0` (no timeout), timeout: 1000 -> 1 second
export const API_TIMEOUT = Number(process.env.REACT_APP_API_TIME_OUT) || 0;
export const API_SALE_TIMEOUT = Number(process.env.REACT_APP_API_SALE_TIME_OUT) || 0;

export const USE_MEDIA_CDN = process.env.REACT_APP_USE_MEDIA_CDN || false;
export const MEDIA_CDN_ENDPOINT = process.env.REACT_APP_MEDIA_CDN_ENDPOINT || '';

export const ITEMS_PER_PAGE = Number(process.env.REACT_APP_ITEMS_PER_PAGE) || 15;

export const PASSWORD_MASKER = Number(process.env.REACT_APP_PASSWORD_MASKER) || '********';

export const PRINTER_SOCKET = process.env.REACT_APP_PRINTER_SOCKET_ENDPOINT || 'ws://127.0.0.1:7777/manage';
export const APP_CLOUD_SOCKET = process.env.REACT_APP_CLOUD_SOCKET_ENDPOINT || '';
export const APP_SCAN_QRCODE = process.env.REACT_APP_SCAN_QRCODE_ENDPOINT || '';
