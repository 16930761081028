import { call, all, takeLatest, put } from 'redux-saga/effects';
import { SaleOrderData } from 'src/state/models/order';
import * as actions from './actions';
import * as storage from './storage';

function* getWaitingSaleList(action: ReturnType<typeof actions.getWaitingSaleList.request>): Generator {
  try {
    const { store_id, workstation_id } = action.payload;
    const response = yield call(() => storage.getWaitingSaleTickets(store_id, workstation_id));

    yield put(actions.getWaitingSaleList.success(response as SaleOrderData[]));
  } catch (err) {
    yield put(actions.getWaitingSaleList.failure(err));
  }
}

function* saveWaitingSale(action: ReturnType<typeof actions.saveWaitingSale>): Generator {
  const { ticket, store_id, workstation_id } = action.payload;
  yield call(() => storage.saveWaitingSale(ticket, store_id, workstation_id));
}

function* deleteWaitingSale(action: ReturnType<typeof actions.deleteWaitingSale>): Generator {
  const { ticket_id, store_id, workstation_id } = action.payload;
  yield call(() => storage.delteWaitingSale(ticket_id, store_id, workstation_id));
}

function* mergeWaitingSale(action: ReturnType<typeof actions.mergeWaitingSale>): Generator {
  const { ticket_ids, store_id, workstation_id } = action.payload;
  yield call(() => storage.mergeWaitingSale(ticket_ids, store_id, workstation_id));
}

// Main saga
export default function* waitSaleSaga() {
  yield all([
    takeLatest(actions.getWaitingSaleList.request, getWaitingSaleList),
    takeLatest(actions.saveWaitingSale, saveWaitingSale),
    takeLatest(actions.deleteWaitingSale, deleteWaitingSale),
    takeLatest(actions.mergeWaitingSale, mergeWaitingSale),
  ]);
}
