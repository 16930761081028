export const RELOAD_CHECKED_IN_TIME = 'clockInOut/reload_checked_in_time';
export const CHECKED_IN_TIME = 'clockInOut/checked_in_time';
export const CHECKED_IN_TIME_SUCCESS = 'clockInOut/checked_in_time_success';
export const CHECKED_IN_TIME_FAILED = 'clockInOut/checked_in_time_failed';

export const CLOCK_IN_OUT_TIME = 'clockInOut/clock_in_out_time';
export const CLOCK_IN_OUT_TIME_SUCCESS = 'clockInOut/clock_in_out_time_success';
export const CLOCK_IN_OUT_TIME_FAILED = 'clockInOut/clock_in_out_time_failed';

export const SUMMARY_EMPLOYEE_IN_OUT_LIST = 'clockInOut/summary_employee_in_out_list';
export const SUMMARY_EMPLOYEE_IN_OUT_LIST_SUCCESS = 'clockInOut/summary_employee_in_out_list_success';
export const SUMMARY_EMPLOYEE_IN_OUT_LIST_FAILED = 'clockInOut/summary_employee_in_out_list_failed';

export const REPORT_SUMMARY_EMPLOYEE_IN_OUT_LIST = 'clockInOut/report_summary_employee_in_out_list';
export const REPORT_SUMMARY_EMPLOYEE_IN_OUT_LIST_SUCCESS = 'clockInOut/report_summary_employee_in_out_list_success';
export const REPORT_SUMMARY_EMPLOYEE_IN_OUT_LIST_FAILED = 'clockInOut/report_summary_employee_in_out_list_failed';

export const EMPLOYEE_IN_OUT_DETAIL = 'clockInOut/report_employee_in_out_detail';
export const EMPLOYEE_IN_OUT_DETAIL_SUCCESS = 'clockInOut/report_employee_in_out_detail_success';
export const EMPLOYEE_IN_OUT_DETAIL_FAILED = 'clockInOut/report_employee_in_out_detail_failed';

export const DELETE_EMPLOYEE_IN_OUT = 'clockInOut/detele_report_employee_in_out';
export const DELETE_EMPLOYEE_IN_OUT_SUCCESS = 'clockInOut/detele_report_employee_in_out_success';
export const DELETE_EMPLOYEE_IN_OUT_FAILED = 'clockInOut/detele_report_employee_in_out_failed';

export const UPDATE_EMPLOYEE_IN_OUT = 'clockInOut/update_report_employee_in_out';
export const UPDATE_EMPLOYEE_IN_OUT_SUCCESS = 'clockInOut/update_report_employee_in_out_success';
export const UPDATE_EMPLOYEE_IN_OUT_FAILED = 'clockInOut/update_report_employee_in_out_failed';

export const CLEAR_DATA = 'clockInOut/clear_data';

export type ClockInOutAction =
  | typeof RELOAD_CHECKED_IN_TIME
  | typeof CHECKED_IN_TIME
  | typeof CHECKED_IN_TIME_FAILED
  | typeof CHECKED_IN_TIME_SUCCESS
  | typeof CLOCK_IN_OUT_TIME
  | typeof CLOCK_IN_OUT_TIME_FAILED
  | typeof CLOCK_IN_OUT_TIME_SUCCESS
  | typeof REPORT_SUMMARY_EMPLOYEE_IN_OUT_LIST
  | typeof REPORT_SUMMARY_EMPLOYEE_IN_OUT_LIST_FAILED
  | typeof REPORT_SUMMARY_EMPLOYEE_IN_OUT_LIST_SUCCESS
  | typeof EMPLOYEE_IN_OUT_DETAIL
  | typeof EMPLOYEE_IN_OUT_DETAIL_FAILED
  | typeof EMPLOYEE_IN_OUT_DETAIL_SUCCESS
  | typeof DELETE_EMPLOYEE_IN_OUT
  | typeof DELETE_EMPLOYEE_IN_OUT_SUCCESS
  | typeof DELETE_EMPLOYEE_IN_OUT_FAILED
  | typeof UPDATE_EMPLOYEE_IN_OUT
  | typeof UPDATE_EMPLOYEE_IN_OUT_SUCCESS
  | typeof UPDATE_EMPLOYEE_IN_OUT_FAILED;
