import { createAction, createAsyncAction } from 'typesafe-actions';
import { GlobalKeys, GlobalState } from './model';
import * as types from './types';

export const clearData = createAction(types.CLEAR_DATA, (action) => {
  return () => action();
})();

export const getGlobalVariables = createAsyncAction(
  types.GET_GLOBAL_VARIABLES_REQUEST,
  types.GET_GLOBAL_VARIABLES_SUCCESS,
  types.GET_GLOBAL_VARIABLES_FAILED
)<{ store_id; workstation_id }, GlobalState, any>();

export const setItem = createAction(types.SET_ITEM, (key: GlobalKeys, value: any) => ({ key, value }))();

export const removeItem = createAction(types.REMOVE_ITEM, (key: GlobalKeys) => ({ key }))();
