import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  DashboardPaymentAnalysisRequest,
  DashboardPaymentAnalysisResponse,
} from 'src/state/api-models/payment-analysis';
import {
  CustomerListRequest,
  CustomerListResponse,
  CustomerTotalRequest,
  CustomerTotalResponse,
} from 'src/state/api-models/customer';
import * as types from './types';
import {
  DashboardDetailsRequest,
  DashboardDetailsResponse,
  DashboardSummaryRequest,
  DashboardSummaryResponse,
} from '../../api-models/dashboard';

export type searchConditionRequest = {
  user_id?: string;
  cashier_id?: string;
  time_type: string;
  effective_start?: string;
  effective_end?: string;
  timezone?: string;
};

export const setTimeType = createAction(types.SET_TIME_TYPE)<searchConditionRequest>();

// Create the set of async actions
export const getIncomeSummary = createAsyncAction(
  types.FETCH_SUMMARY,
  types.FETCH_SUMMARY_SUCCESS,
  types.FETCH_SUMMARY_FAILED
)<DashboardSummaryRequest, DashboardSummaryResponse, any>();

// Create the set of async actions
export const getIncomeDetails = createAsyncAction(
  types.FETCH_SUMMARY_DETAILS,
  types.FETCH_SUMMARY_DETAILS_SUCCESS,
  types.FETCH_SUMMARY_DETAILS_FAILED
)<DashboardDetailsRequest, DashboardDetailsResponse, any>();

// Create the set of async actions
export const getSummaryPayments = createAsyncAction(
  types.FETCH_SUMMARY_PAYMENT,
  types.FETCH_SUMMARY_PAYMENT_SUCCESS,
  types.FETCH_SUMMARY_PAYMENT_FAILED
)<DashboardPaymentAnalysisRequest, DashboardPaymentAnalysisResponse, any>();

// Create the set of async actions
export const getBirthdayCustomers = createAsyncAction(
  types.FETCH_BIRTHDAY_CUSTOMERS,
  types.FETCH_BIRTHDAY_CUSTOMERS_SUCCESS,
  types.FETCH_BIRTHDAY_CUSTOMERS_FAILED
)<CustomerListRequest, CustomerListResponse, any>();

// Create the set of async actions
export const getTotalCustomers = createAsyncAction(
  types.FETCH_TOTAL_CUSTOMERS,
  types.FETCH_TOTAL_CUSTOMERS_SUCCESS,
  types.FETCH_TOTAL_CUSTOMERS_FAILED
)<CustomerTotalRequest, CustomerTotalResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
