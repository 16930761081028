import { BaseState } from 'src/state/ducks/models';
import {
  SmsReminderListResponse,
  SmsReminderDetailResponse,
  SmsReminderDeleteResponse,
  SmsReminderUpdateResponse,
  SmsReminderCreateResponse,
  SmsReminderBulkResponse,
  SmsReminderUpdateStatusResponse,
} from 'src/state/api-models/sms-reminder';

export type SmsReminderDetails = BaseState<SmsReminderDetailResponse>;
export type SmsReminderList = BaseState<SmsReminderListResponse>;
export type SmsReminderCreate = BaseState<SmsReminderCreateResponse>;
export type SmsReminderUpdate = BaseState<SmsReminderUpdateResponse>;
export type SmsReminderUpdateStatus = BaseState<SmsReminderUpdateStatusResponse>;
export type SmsReminderDelete = BaseState<SmsReminderDeleteResponse>;
export type SmsReminderBulk = BaseState<SmsReminderBulkResponse>;

export type SmsReminderState = {
  list: SmsReminderList;
  details: SmsReminderDetails;
  create: SmsReminderCreate;
  update: SmsReminderUpdate;
  updateStatus: SmsReminderUpdateStatus;
  delete: SmsReminderDelete;
  bulk: SmsReminderBulk;
};

export const initialSmsReminderState = {
  list: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  updateStatus: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
};

declare module 'src/state/ducks/models' {
  interface StateAll {
    // match to a duck folder
    'sms/reminder': SmsReminderState;
  }
}
