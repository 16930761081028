import { put, call, all, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';
import { API_ENDPOINTS } from '../../../config/api-endpoints/categories';
import { convertToFormData } from 'src/utils/converter';
import { removeInitialProducts } from 'src/utils/axios/axios-fecth';

// Handle request saga
function* getCategoryList(action: ReturnType<typeof actions.getCategoryList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.CATEGORIES.List, {
      params: action.payload,
    });

    yield put(actions.getCategoryList.success((response as any).data));
  } catch (err) {
    yield put(actions.getCategoryList.failure(err));
  }
}

function* getCategoryDetails(action: ReturnType<typeof actions.getCategoryDetails.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.CATEGORIES.Details.replace('{id}', action.payload));

    yield put(actions.getCategoryDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getCategoryDetails.failure(err));
  }
}

function* createCategory(action: ReturnType<typeof actions.createCategory.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.CATEGORIES.Create, action.payload, config));

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.createCategory.success((response as any).data));
  } catch (err) {
    yield put(actions.createCategory.failure(err));
  }
}

function* updateCategory(action: ReturnType<typeof actions.updateCategory.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const id = action.payload.id;
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.CATEGORIES.Update.replace('{id}', id), action.payload, config)
    );

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.updateCategory.success((response as any).data));
  } catch (err) {
    yield put(actions.updateCategory.failure(err));
  }
}

function* deleteCategory(action: ReturnType<typeof actions.deleteCategory.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.delete(API_ENDPOINTS.CATEGORIES.Delete.replace('{id}', action.payload))
    );

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.deleteCategory.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteCategory.failure(err));
  }
}

function* bulkCategory(action: ReturnType<typeof actions.bulkCategory.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.CATEGORIES.Bulk, action.payload);

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.bulkCategory.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkCategory.failure(err));
  }
}

// Main saga
export default function* categorySaga() {
  yield all([
    takeLatest(actions.getCategoryList.request, getCategoryList),
    takeLatest(actions.getCategoryDetails.request, getCategoryDetails),
    takeLatest(actions.createCategory.request, createCategory),
    takeLatest(actions.updateCategory.request, updateCategory),
    takeLatest(actions.deleteCategory.request, deleteCategory),
    takeLatest(actions.bulkCategory.request, bulkCategory),
  ]);
}
