import Axios from 'axios';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { getStoreId, getCurrentUserData } from 'src/state/ducks/common/auth/storage';

export const getDomain = () => {
  return window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
};

export const fetchData = async (url, setKey = null) => {
  let cachedData = null;
  let subdomain = getDomain();

  try {
    let key_axios = setKey ? setKey : subdomain + '_' + url;
    cachedData = window.localStorage.getItem(key_axios);

    if (cachedData) {
      return JSON.parse(cachedData);
    }

    const response = await Axios.get(url);
    const data = response.data;
    if (data) {
      window.localStorage.setItem(key_axios, JSON.stringify(data));
    }

    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
    // If an error occurs, return the cached data (if available)
    return cachedData ? JSON.parse(cachedData) : null;
  }
};

export const joinParams = (params = null) => {
  var i = 0;
  let private_key = '';
  Object.keys(params).forEach((key) => {
    if (Array.isArray(params[key])) {
      params[key].forEach(function (item) {
        if (typeof item === 'object' && item !== null) {
          Object.keys(item).forEach((k) => {
            private_key += item[k] !== '' && item[k] ? '_' + item[k] : '';
          });
        } else {
          private_key += item !== '' && item ? '_' + item : '';
        }
      });
    } else {
      private_key += params[key] !== '' && params[key] ? (i !== 0 ? '_' : '') + params[key] : '';
    }
    i++;
  });
  return private_key;
};

export const generateSingleKey = (url, params = null) => {
  let private_key = '';
  let domain = getDomain() + url;
  if (params) {
    private_key += joinParams(params);
  }

  return domain + ':' + private_key;
};

export const deleteKey = (private_key) => {
  window.localStorage.removeItem(private_key);
};

export const clearStorage = () => {
  window.localStorage.clear();
};

export const removeCacheConfig = (store_id, code, url, workstation = '0') => {
  let private_key = getDomain();

  switch (code) {
    case 'profile':
    case 'tax':
    case 'walkin':
    case 'schedule':
    case 'take_turn':
    case 'print':
    case 'payment':
    case 'payroll':
      private_key += url + ':' + code + '_' + store_id;
      break;
    case 'sms':
    case 'reviews':
    case 'reward_point':
    case 'others':
      private_key += url + ':' + code;
      break;
    case 'initial_store':
      private_key += API_ENDPOINTS.STORE_SETTING.Initial + ':';
      if (store_id && workstation !== '') {
        private_key += store_id + '_' + workstation;
      }
      break;
    case 'initial':
      private_key += API_ENDPOINTS.SETTING.Initial + ':';
      if (store_id) {
        private_key += store_id;
      }
      break;
    default:
      private_key += API_ENDPOINTS.SETTING.Initial + ':';
      if (store_id && workstation !== '') {
        private_key += store_id + '_' + workstation;
      }
      break;
  }

  deleteKey(private_key);

  let setting_products = ['profile', 'payment', 'tax'];
  if (setting_products.indexOf(code) >= 0) {
    removeInitialProducts();
  }
  return true;
};

export const removeCacheEmployees = (stores) => {
  let domain = getDomain();
  const keysModule = [
    domain + API_ENDPOINTS.EMPLOYEE.CHECKED_IN_LIST + ':{store_id}',
    domain + API_ENDPOINTS.EMPLOYEE.ENABLE_BOOKING_LIST + ':{store_id}',
    domain + API_ENDPOINTS.EMPLOYEE.ENABLE_WALKIN_LIST + ':{store_id}',
    domain + API_ENDPOINTS.CODE.List + ':users_users7_role_id_7_{store_id}',
    domain + API_ENDPOINTS.CODE.List + ':users_users6_role_id_6_{store_id}',
    domain + API_ENDPOINTS.CODE.List + ':users_users4_role_id_4_{store_id}',
  ];
  if (stores !== null && stores.length > 0) {
    for (var num = 0; num < stores.length; num++) {
      for (var y = 0; y < keysModule.length; y++) {
        const private_key = keysModule[y].replace('{store_id}', stores[num]);
        deleteKey(private_key);
      }
    }
  } else {
    let store_id = getStoreId();
    for (var ny = 0; ny < keysModule.length; ny++) {
      const private_key = keysModule[ny].replace('{store_id}', store_id);
      deleteKey(private_key);
    }
  }
  return true;
};

export const removeCacheSale = (store_id) => {
  let domain = getDomain();
  const keysModule = [
    domain + API_ENDPOINTS.EMPLOYEE.CHECKED_IN_LIST + ':{store_id}',
    domain + API_ENDPOINTS.EMPLOYEE.ENABLE_BOOKING_LIST + ':{store_id}',
    domain + API_ENDPOINTS.EMPLOYEE.ENABLE_WALKIN_LIST + ':{store_id}',
  ];
  for (var ny = 0; ny < keysModule.length; ny++) {
    const private_key = keysModule[ny].replace('{store_id}', store_id);
    deleteKey(private_key);
  }
  return true;
};

export const removeInitialProducts = () => {
  //delete for code listing
  let auth = getCurrentUserData();
  let key_axios = getDomain() + 'pos/sales/service-product-setting:' + auth.store_id + '_' + auth.workstation_id;
  return deleteKey(key_axios);
};

export const removeCodeList = (code) => {
  //delete for code listing
  let auth = getCurrentUserData();
  let listCode = {
    sms_templates: 'sms_templates_sms_templates_',
    customer_groups: 'customer_groups_customer_groups_',
    emarketing_event: 'emarketing_event_emarketing_event_',
    emarketing_templates: 'emarketing_templates_emarketing_templates_',
  };
  let code_private_key = listCode[code] || '';
  let key_axios = getDomain() + API_ENDPOINTS.CODE.List + ':' + code_private_key + '_' + auth.workstation_id;
  return deleteKey(key_axios);
};
