export const FETCH_DETAILS = 'productKit/details';
export const FETCH_DETAILS_SUCCESS = 'productKit/details_success';
export const FETCH_DETAILS_FAILED = 'productKit/details_failed';

export const FETCH_LIST = 'productKit/list';
export const FETCH_LIST_SUCCESS = 'productKit/list_success';
export const FETCH_LIST_FAILED = 'productKit/list_failed';

export const CREATE = 'productKit/create';
export const CREATE_SUCCESS = 'productKit/create_success';
export const CREATE_FAILED = 'productKit/create_failed';

export const UPDATE = 'productKit/update';
export const UPDATE_SUCCESS = 'productKit/update_success';
export const UPDATE_FAILED = 'productKit/update_failed';

export const DELETE = 'productKit/detele';
export const DELETE_SUCCESS = 'productKit/detele_success';
export const DELETE_FAILED = 'productKit/detele_failed';

export const BULK = 'productKit/bulk';
export const BULK_SUCCESS = 'productKit/bulk_success';
export const BULK_FAILED = 'productKit/bulk_failed';

export const IMPORT_EXCEL = 'productKit/import';
export const IMPORT_EXCEL_SUCCESS = 'productKit/import_success';
export const IMPORT_EXCEL_FAILED = 'productKit/import_failed';

export const FETCH_LISTITEM = 'productKitItem/list';
export const FETCH_LISTITEM_SUCCESS = 'productKitItem/list_success';
export const FETCH_LISTITEM_FAILED = 'productKitItem/list_failed';

export const CLEAR_DATA = 'productKitItem/clear_data';

export type ProductKitAction =
  | typeof FETCH_DETAILS
  | typeof FETCH_DETAILS_FAILED
  | typeof FETCH_DETAILS_SUCCESS
  | typeof FETCH_LIST
  | typeof FETCH_LIST_FAILED
  | typeof FETCH_LIST_SUCCESS
  | typeof CREATE
  | typeof CREATE_FAILED
  | typeof CREATE_SUCCESS
  | typeof UPDATE
  | typeof UPDATE_FAILED
  | typeof UPDATE_SUCCESS
  | typeof DELETE
  | typeof DELETE_FAILED
  | typeof DELETE_SUCCESS
  | typeof BULK
  | typeof BULK_FAILED
  | typeof BULK_SUCCESS
  | typeof IMPORT_EXCEL
  | typeof IMPORT_EXCEL_FAILED
  | typeof IMPORT_EXCEL_SUCCESS
  | typeof FETCH_LISTITEM
  | typeof FETCH_LISTITEM_FAILED
  | typeof FETCH_LISTITEM_SUCCESS
  | typeof CLEAR_DATA;
