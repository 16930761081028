export const FETCH_DETAILS = 'customerGroup/details';
export const FETCH_DETAILS_SUCCESS = 'customerGroup/details_success';
export const FETCH_DETAILS_FAILED = 'customerGroup/details_failed';

export const FETCH_LIST = 'customerGroup/list';
export const FETCH_LIST_SUCCESS = 'customerGroup/list_success';
export const FETCH_LIST_FAILED = 'customerGroup/list_failed';

export const CREATE = 'customerGroup/create';
export const CREATE_SUCCESS = 'customerGroup/create_success';
export const CREATE_FAILED = 'customerGroup/create_failed';

export const UPDATE = 'customerGroup/update';
export const UPDATE_SUCCESS = 'customerGroup/update_success';
export const UPDATE_FAILED = 'customerGroup/update_failed';

export const DELETE = 'customerGroup/detele';
export const DELETE_SUCCESS = 'customerGroup/detele_success';
export const DELETE_FAILED = 'customerGroup/detele_failed';

export const BULK = 'customerGroup/bulk';
export const BULK_SUCCESS = 'customerGroup/bulk_success';
export const BULK_FAILED = 'customerGroup/bulk_failed';

export const CLEAR_DATA = 'customerGroup/clear_data';

export const CHECK_HAS_CUSTOMER = 'customerGroup/check_has_customer';
export const CHECK_HAS_CUSTOMER_SUCCESS = 'customerGroup/check_has_customer_success';
export const CHECK_HAS_CUSTOMER_FAILED = 'customerGroup/check_has_customer_failed';
