import { put, call, all, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';
import { API_ENDPOINTS } from '../../../config/api-endpoints/report-service-product';

function* getServiceProductReportList(action: ReturnType<typeof actions.getServiceProductReportList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.List, {
      params: action.payload,
    });

    yield put(actions.getServiceProductReportList.success((response as any).data));
  } catch (err) {
    yield put(actions.getServiceProductReportList.failure(err));
  }
}

function* getServiceProductReportDetails(action: ReturnType<typeof actions.getServiceProductReportDetails.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.Details, {
      params: action.payload,
    });

    yield put(actions.getServiceProductReportDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getServiceProductReportDetails.failure(err));
  }
}

export default function* productSaga() {
  yield all([
    takeLatest(actions.getServiceProductReportList.request, getServiceProductReportList),
    takeLatest(actions.getServiceProductReportDetails.request, getServiceProductReportDetails),
  ])
}
