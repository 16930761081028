import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';
import {
  CustomerGroupListRequest,
  CustomerGroupListResponse,
  CustomerGroupDeleteResponse,
  CustomerGroupUpdateRequest,
  CustomerGroupUpdateResponse,
  CustomerGroupCreateResponse,
  CustomerGroupDetailResponse,
  CustomerGroupBulkResponse,
  CustomerGroupBulkRequest,
  CustomerGroupCheckHasCustomerResponse,
} from '../../api-models/customerGroup';
import { CustomerGroup } from '../../models/customerGroup';

// Create the set of async actions
export const getCustomerGroupList = createAsyncAction(
  types.FETCH_LIST,
  types.FETCH_LIST_SUCCESS,
  types.FETCH_LIST_FAILED
)<CustomerGroupListRequest, CustomerGroupListResponse, any>();

// Create the set of async actions
export const getCustomerGroupDetails = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<string, CustomerGroupDetailResponse, any>();

// Create the set of async actions
export const createCustomerGroup = createAsyncAction(types.CREATE, types.CREATE_SUCCESS, types.CREATE_FAILED)<
  CustomerGroup,
  CustomerGroupCreateResponse,
  any
>();

// Create the set of async actions
export const updateCustomerGroup = createAsyncAction(types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILED)<
  CustomerGroupUpdateRequest,
  CustomerGroupUpdateResponse,
  any
>();

// Create the set of async actions
export const deleteCustomerGroup = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  string,
  CustomerGroupDeleteResponse,
  any
>();

// Create the set of async actions
export const bulkCustomerGroup = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  CustomerGroupBulkRequest,
  CustomerGroupBulkResponse,
  any
>();

// Create the set of async actions
export const customerGroupCheckHasCustomer = createAsyncAction(
  types.CHECK_HAS_CUSTOMER,
  types.CHECK_HAS_CUSTOMER_SUCCESS,
  types.CHECK_HAS_CUSTOMER_FAILED
)<string, CustomerGroupCheckHasCustomerResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
