import { createAsyncAction, createAction } from 'typesafe-actions';
import { EmarketingTemplate } from 'src/state/models/emarketing';
import {
  EmarketingTemplateListResponse,
  EmarketingTemplateDetailResponse,
  EmarketingTemplateDeleteResponse,
  EmarketingTemplateUpdateResponse,
  EmarketingTemplateCreateResponse,
  EmarketingTemplateBulkResponse,
  EmarketingTemplateListRequest,
  EmarketingTemplateBulkRequest,
} from 'src/state/api-models/emarketing-template';
import * as types from './types';

// Create the set of async actions
export const getList = createAsyncAction(types.FETCH_LIST, types.FETCH_LIST_SUCCESS, types.FETCH_LIST_FAILED)<
  EmarketingTemplateListRequest,
  EmarketingTemplateListResponse,
  any
>();

// Create the set of async actions
export const getDetails = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<EmarketingTemplate['id'] | undefined, EmarketingTemplateDetailResponse, any>();

// Create the set of async actions
export const createData = createAsyncAction(types.CREATE, types.CREATE_SUCCESS, types.CREATE_FAILED)<
  EmarketingTemplate,
  EmarketingTemplateCreateResponse,
  any
>();

// Create the set of async actions
export const updateData = createAsyncAction(types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILED)<
  EmarketingTemplate,
  EmarketingTemplateUpdateResponse,
  any
>();

// Create the set of async actions
export const deleteData = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  EmarketingTemplate['id'],
  EmarketingTemplateDeleteResponse,
  any
>();

export const bulkDatas = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  EmarketingTemplateBulkRequest,
  EmarketingTemplateBulkResponse,
  any
>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
