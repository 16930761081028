export type LoadingApi = string;

export type LoadingData = LoadingApi[];
export type LoadingState = LoadingData;

export const initialLoadingState = {
  data: [],
};

declare module './../../models' {
  interface StateAll {
    // match to a duck folder
    'common/loading': LoadingState;
  }
}
