export const FETCH_DETAILS = 'bookingAppt/details';
export const FETCH_DETAILS_SUCCESS = 'bookingAppt/details_success';
export const FETCH_DETAILS_FAILED = 'bookingAppt/details_failed';

export const FETCH_LIST = 'bookingAppt/list';
export const FETCH_LIST_SUCCESS = 'bookingAppt/list_success';
export const FETCH_LIST_FAILED = 'bookingAppt/list_failed';

export const FETCH_LIST_UNASSIGNED = 'bookingAppt/list_unassigned';
export const FETCH_LIST_UNASSIGNED_SUCCESS = 'bookingAppt/list_unassigned_success';
export const FETCH_LIST_UNASSIGNED_FAILED = 'bookingAppt/list_unassigned_failed';

export const FETCH_NUMBER_OF_BOOKINGS_IN_DAYS = 'bookingAppt/number_of_bookings_in_days';
export const FETCH_NUMBER_OF_BOOKINGS_IN_DAYS_SUCCESS = 'bookingAppt/number_of_bookings_in_days_success';
export const FETCH_NUMBER_OF_BOOKINGS_IN_DAYS_FAILED = 'bookingAppt/number_of_bookings_in_days_failed';

export const FETCH_TOP_BOOKING_SERVICES = 'bookingAppt/top_booking_services';
export const FETCH_TOP_BOOKING_SERVICES_SUCCESS = 'bookingAppt/top_booking_services_success';
export const FETCH_TOP_BOOKING_SERVICES_FAILED = 'bookingAppt/top_booking_services_failed';

export const ASSIGN = 'bookingAppt/assign';
export const ASSIGN_SUCCESS = 'bookingAppt/assign_success';
export const ASSIGN_FAILED = 'bookingAppt/assign_failed';

export const CREATE = 'bookingAppt/create';
export const CREATE_SUCCESS = 'bookingAppt/create_success';
export const CREATE_FAILED = 'bookingAppt/create_failed';

export const UPDATE = 'bookingAppt/update';
export const UPDATE_SUCCESS = 'bookingAppt/update_success';
export const UPDATE_FAILED = 'bookingAppt/update_failed';

export const UPDATE_STATUS = 'bookingAppt/update_status';
export const UPDATE_STATUS_SUCCESS = 'bookingAppt/update_status_success';
export const UPDATE_STATUS_FAILED = 'bookingAppt/update_status_failed';

export const UPDATE_COLUMN = 'bookingAppt/update_column';
export const UPDATE_COLUMN_SUCCESS = 'bookingAppt/update_column_success';
export const UPDATE_COLUMN_FAILED = 'bookingAppt/update_column_failed';

export const DELETE = 'bookingAppt/detele';
export const DELETE_SUCCESS = 'bookingAppt/detele_success';
export const DELETE_FAILED = 'bookingAppt/detele_failed';

export const BULK = 'bookingAppt/bulk';
export const BULK_SUCCESS = 'bookingAppt/bulk_success';
export const BULK_FAILED = 'bookingAppt/bulk_failed';

export const IMPORT = 'bookingAppt/import';
export const IMPORT_SUCCESS = 'bookingAppt/import_success';
export const IMPORT_FAILED = 'bookingAppt/import_failed';

export const INPUTSEARCH = 'bookingAppt/search';
export const SET_SERVICEBOOKING = 'bookingAppt/setServiceBooking';
export const REMOVE_SERVICEBOOKING = 'bookingAppt/removeServiceBooking';

export const BOOKINGAPPT_SERVICE = 'bookingAppt/bookingApptService';
export const BOOKINGAPPT_SERVICE_SUCCESS = 'bookingAppt/bookingApptService_success';
export const BOOKINGAPPT_SERVICE_FAILED = 'bookingAppt/bookingApptService_failed';

export const FETCH_EMPLOYEE_LIST = 'bookingAppt/list_employee';
export const FETCH_EMPLOYEE_LIST_SUCCESS = 'bookingAppt/list_employee_success';
export const FETCH_EMPLOYEE_LIST_FAILED = 'bookingAppt/list_employee_failed';

export const BOOKINGAPPT_SEND_SMS = 'bookingAppt/send_message';
export const BOOKINGAPPT_SEND_SMS_SUCCESS = 'bookingAppt/send_message_success';
export const BOOKINGAPPT_SEND_SMS_FAILED = 'bookingAppt/send_message_failed';

export const CLEAR_DATA = 'bookingAppt/clear_data';
