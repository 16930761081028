import { BaseState } from 'src/state/ducks/models';
import {
  EmarketingCampaignListResponse,
  EmarketingCampaignDetailResponse,
  EmarketingCampaignDeleteResponse,
  EmarketingCampaignUpdateResponse,
  EmarketingCampaignCreateResponse,
  EmarketingCampaignBulkResponse,
  EmarketingCampaignUpdateStatusResponse,
} from 'src/state/api-models/emarketing-campaign';

export type EmarketingCampaignDetails = BaseState<EmarketingCampaignDetailResponse>;
export type EmarketingCampaignList = BaseState<EmarketingCampaignListResponse>;
export type EmarketingCampaignCreate = BaseState<EmarketingCampaignCreateResponse>;
export type EmarketingCampaignUpdate = BaseState<EmarketingCampaignUpdateResponse>;
export type EmarketingCampaignUpdateStatus = BaseState<EmarketingCampaignUpdateStatusResponse>;
export type EmarketingCampaignDelete = BaseState<EmarketingCampaignDeleteResponse>;
export type EmarketingCampaignBulk = BaseState<EmarketingCampaignBulkResponse>;

export type EmarketingCampaignState = {
  list: EmarketingCampaignList;
  details: EmarketingCampaignDetails;
  create: EmarketingCampaignCreate;
  update: EmarketingCampaignUpdate;
  updateStatus: EmarketingCampaignUpdateStatus;
  delete: EmarketingCampaignDelete;
  bulk: EmarketingCampaignBulk;
};

export const initialState: EmarketingCampaignState = {
  list: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  updateStatus: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
};

declare module 'src/state/ducks/models' {
  interface StateAll {
    // match to a duck folder
    'emarketing/campaign': EmarketingCampaignState;
  }
}
