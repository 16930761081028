import { BaseState } from '../models';
import {
  PaymentListResponse,
  PaymentStatusResponse,
  PaymentVoidResponse,
  PaymentReturnResponse,
  PaymentSaleResponse,
  PaymentBulkResponse,
  PaymentTipAdjustResponse,
  PaymentMomoResponse,
  PaymentVietQrResponse,
} from '../../api-models/sale-payment';

export type PaymentStatus = BaseState<PaymentStatusResponse>;
export type PaymentList = BaseState<PaymentListResponse>;
export type PaymentSale = BaseState<PaymentSaleResponse>;
export type PaymentUpdate = BaseState<PaymentReturnResponse>;
export type PaymentVoid = BaseState<PaymentVoidResponse>;
export type PaymentReturn = BaseState<PaymentReturnResponse>;
export type PaymentTipAdjust = BaseState<PaymentTipAdjustResponse>;
export type PaymentBulk = BaseState<PaymentBulkResponse>;
export type PaymentMomo = BaseState<PaymentMomoResponse>;
export type PaymentVietQr = BaseState<PaymentVietQrResponse>;

export type PaymentState = {
  list: PaymentList;
  status: PaymentStatus;
  sale: PaymentSale;
  return: PaymentUpdate;
  void: PaymentVoid;
  tipAdjust: PaymentTipAdjust;
  bulk: PaymentBulk;
  momo: PaymentMomo;
  vietqr: PaymentVietQr;
};

export const initialPaymentState: PaymentState = {
  list: { loading: false, response: undefined },
  status: { loading: false, response: undefined },
  sale: { loading: false, response: undefined },
  return: { loading: false, response: undefined },
  void: { loading: false, response: undefined },
  tipAdjust: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
  momo: { loading: false, response: undefined },
  vietqr: { loading: false, response: undefined },
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    salePayment: PaymentState;
  }
}
