import { EmployeeInOutTimeResponse } from 'src/state/api-models/employee-in-out-time';
import { BaseState } from '../models';
import {
  WalkinCustomerResponse,
  WalkinServiceResponse,
  WalkinListResponse,
  WalkinListGroupByEmployeeResponse,
} from '../../api-models/walkin';
import { WalkinRegister, initWalkinRegister } from '../../models/walkin';

export type EmployeeInOutTime = BaseState<EmployeeInOutTimeResponse>;
export type WalkInSignInState = { status: boolean };

export type WalkinState = {
  list: BaseState<WalkinListResponse>;
  listGroupByEmployee: BaseState<WalkinListGroupByEmployeeResponse>;
  employees: EmployeeInOutTime;
  checkinCustomer: BaseState<WalkinCustomerResponse>;
  services: BaseState<WalkinServiceResponse>;
  cat_services: BaseState<WalkinServiceResponse>;
  walkinRegister: WalkinRegister;
  walkinSuccess: WalkInSignInState;
  currentTime: string | Date;
};

export const initialWalkinState: WalkinState = {
  list: { loading: false, response: undefined },
  listGroupByEmployee: { loading: false, response: undefined },
  employees: { loading: false, response: undefined },
  checkinCustomer: { loading: false, response: undefined },
  services: { loading: false, response: undefined },
  cat_services: { loading: false, response: undefined },
  walkinRegister: initWalkinRegister,
  walkinSuccess: { status: false },
  currentTime: new Date(),
};

declare module './../models' {
  interface StateAll {
    walkin: WalkinState;
  }
}
