export const API_ENDPOINTS = {
  SMS_TEMPLATE: {
    List: '/text-message/templates',
    Create: '/text-message/templates',
    Details: '/text-message/templates/{id}',
    Update: '/text-message/templates/{id}',
    Delete: '/text-message/templates/{id}',
    Bulk: '/text-message/templates/bulk',
  },
};
