export const SET_TIME_TYPE = 'dashboard/set_time_type';
export const SET_FILTER = 'dashboard/set_filter';

export const FETCH_SUMMARY = 'dashboard/summary';
export const FETCH_SUMMARY_SUCCESS = 'dashboard/summary_success';
export const FETCH_SUMMARY_FAILED = 'dashboard/summary_failed';

export const FETCH_SUMMARY_DETAILS = 'dashboard/details_summary';
export const FETCH_SUMMARY_DETAILS_SUCCESS = 'dashboard/details_summary_success';
export const FETCH_SUMMARY_DETAILS_FAILED = 'dashboard/details_summary_failed';

export const FETCH_SUMMARY_PAYMENT = 'dashboard/summary_payment';
export const FETCH_SUMMARY_PAYMENT_SUCCESS = 'dashboard/summary_payment_success';
export const FETCH_SUMMARY_PAYMENT_FAILED = 'dashboard/summary_payment_failed';

export const FETCH_BIRTHDAY_CUSTOMERS = 'dashboard/birthday_customers';
export const FETCH_BIRTHDAY_CUSTOMERS_SUCCESS = 'dashboard/birthday_customers_success';
export const FETCH_BIRTHDAY_CUSTOMERS_FAILED = 'dashboard/birthday_customers_failed';

export const FETCH_TOTAL_CUSTOMERS = 'dashboard/totaly_customers';
export const FETCH_TOTAL_CUSTOMERS_SUCCESS = 'dashboard/totaly_customers_success';
export const FETCH_TOTAL_CUSTOMERS_FAILED = 'dashboard/totaly_customers_failed';

export const CLEAR_DATA = 'dashboard/clear_data';

export type IncomeAction =
  | typeof FETCH_SUMMARY
  | typeof FETCH_SUMMARY_FAILED
  | typeof FETCH_SUMMARY_SUCCESS
  | typeof FETCH_SUMMARY_DETAILS
  | typeof FETCH_SUMMARY_DETAILS_FAILED
  | typeof FETCH_SUMMARY_DETAILS_SUCCESS
  | typeof FETCH_SUMMARY_PAYMENT
  | typeof FETCH_SUMMARY_PAYMENT_FAILED
  | typeof FETCH_SUMMARY_PAYMENT_SUCCESS
  | typeof FETCH_BIRTHDAY_CUSTOMERS
  | typeof FETCH_BIRTHDAY_CUSTOMERS_FAILED
  | typeof FETCH_BIRTHDAY_CUSTOMERS_SUCCESS
  | typeof CLEAR_DATA;
