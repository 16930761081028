import { put, call, all, takeLatest } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';

function* getSummaryList(action: ReturnType<typeof actions.getSummaryList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.STORE_REPORT.Summary, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getSummaryList.success((response as any).data));
  } catch (err) {
    yield put(actions.getSummaryList.failure(err));
  }
}

function* getDetailList(action: ReturnType<typeof actions.getDetailList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.STORE_REPORT.Details, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getDetailList.success((response as any).data));
  } catch (err) {
    yield put(actions.getDetailList.failure(err));
  }
}

function* getReportSummary(action: ReturnType<typeof actions.getReportSummary.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.STORE_REPORT.ExportSummary, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getReportSummary.success((response as any).data));
  } catch (err) {
    yield put(actions.getReportSummary.failure(err));
  }
}

function* getReportDetails(action: ReturnType<typeof actions.getReportDetails.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.STORE_REPORT.ExportDetails, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getReportDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getReportDetails.failure(err));
  }
}

// Main saga
export default function* storeReportSaga() {
  yield all([
    takeLatest(actions.getSummaryList.request, getSummaryList),
    takeLatest(actions.getDetailList.request, getDetailList),

    takeLatest(actions.getReportSummary.request, getReportSummary),
    takeLatest(actions.getReportDetails.request, getReportDetails),
  ]);
}
