import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { PayrollState, initialPayrollState } from './model';

const payroll = produce((draft: Draft<PayrollState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getPayrollList.request): {
      draft.list.loading = true;
      return draft;
    }
    case getType(actions.getPayrollList.success): {
      draft.list.loading = true;
      draft.list.response = action.payload;
      return draft;
    }
    case getType(actions.getPayrollList.failure): {
      draft.list.loading = false;
      draft.list.errors = action.payload;
      return draft;
    }
    case getType(actions.getPayrollDetails.request): {
      draft.details.loading = true;
      draft.details.response = undefined;
      draft.details.errors = undefined;
      return draft;
    }
    case getType(actions.getPayrollDetails.success): {
      draft.details.loading = true;
      draft.details.response = action.payload;

      return draft;
    }
    case getType(actions.getPayrollDetails.failure): {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    case getType(actions.calculatePayoll.request): {
      draft.calculate.loading = true;
      draft.calculate.response = undefined;
      draft.calculate.errors = undefined;
      return draft;
    }
    case getType(actions.calculatePayoll.success): {
      draft.calculate.loading = false;
      draft.calculate.response = action.payload;
      return draft;
    }
    case getType(actions.calculatePayoll.failure): {
      draft.calculate.loading = false;
      draft.calculate.errors = action.payload;
      return draft;
    }
    case getType(actions.createPayroll.request): {
      draft.create.loading = true;
      return draft;
    }
    case getType(actions.createPayroll.success): {
      draft.create.loading = false;
      draft.create.response = action.payload;
      return draft;
    }
    case getType(actions.createPayroll.failure): {
      draft.create.loading = false;
      draft.create.errors = action.payload;
      return draft;
    }
    case getType(actions.updatePayroll.request): {
      draft.update.loading = true;
      return draft;
    }
    case getType(actions.updatePayroll.success): {
      draft.update.loading = false;
      draft.update.response = action.payload;
      return draft;
    }
    case getType(actions.updatePayroll.failure): {
      draft.update.loading = false;
      draft.update.errors = action.payload;
      return draft;
    }

    case getType(actions.deletePayroll.request): {
      draft.delete.loading = true;
      return draft;
    }
    case getType(actions.deletePayroll.success): {
      draft.delete.loading = false;
      draft.delete.response = action.payload;
      return draft;
    }
    case getType(actions.deletePayroll.failure): {
      draft.delete.loading = false;
      draft.delete.errors = action.payload;
      return draft;
    }
    case getType(actions.bulkPayrolls.request): {
      draft.bulk.loading = true;
      draft.bulk.response = undefined;
      return draft;
    }
    case getType(actions.bulkPayrolls.success): {
      draft.bulk.loading = false;
      draft.bulk.response = action.payload;
      return draft;
    }
    case getType(actions.bulkPayrolls.failure): {
      draft.bulk.loading = false;
      draft.bulk.errors = action.payload;
      return draft;
    }
    case getType(actions.getPayrollCommissionReport.request): {
      draft.report.loading = true;
      draft.report.response = undefined;
      draft.report.errors = undefined;
      return draft;
    }
    case getType(actions.getPayrollCommissionReport.success): {
      draft.report.loading = true;
      draft.report.response = action.payload;

      return draft;
    }
    case getType(actions.getPayrollCommissionReport.failure): {
      draft.report.loading = false;
      draft.report.errors = action.payload;
      return draft;
    }
    case getType(actions.getPayrollCommissionSummary.request): {
      draft.reportSummary.loading = true;
      return draft;
    }
    case getType(actions.getPayrollCommissionSummary.success): {
      draft.reportSummary.loading = true;
      draft.reportSummary.response = action.payload;
      return draft;
    }
    case getType(actions.getPayrollCommissionSummary.failure): {
      draft.reportSummary.loading = false;
      draft.reportSummary.errors = action.payload;
      return draft;
    }
    case getType(actions.getPayrollCommissionDetails.request): {
      draft.reportDetails.loading = true;
      return draft;
    }
    case getType(actions.getPayrollCommissionDetails.success): {
      draft.reportDetails.loading = true;
      draft.reportDetails.response = action.payload;

      return draft;
    }
    case getType(actions.getPayrollCommissionDetails.failure): {
      draft.reportDetails.loading = false;
      draft.reportDetails.errors = action.payload;
      return draft;
    }

    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialPayrollState;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialPayrollState);

export default payroll;
