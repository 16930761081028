import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints/emarketing/sent';
import { axiosInstance } from 'src/utils/axios/axios-instance';
import * as actions from './actions';

function* emarketingSummary(action: ReturnType<typeof actions.emarketingSentSummary.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.EMARKETING_SENT.Summary, {
      params: action.payload,
    });
    yield put(actions.emarketingSentSummary.success((response as any).data));
  } catch (err) {
    yield put(actions.emarketingSentSummary.failure(err));
  }
}

function* emarketingDetails(action: ReturnType<typeof actions.emarketingSentDetail.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.EMARKETING_SENT.Details, {
      params: action.payload,
    });
    yield put(actions.emarketingSentDetail.success((response as any).data));
  } catch (err) {
    yield put(actions.emarketingSentDetail.failure(err));
  }
}

function* deleteEmarketingSent(action: ReturnType<typeof actions.deleteEmarketingSent.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.delete,
      API_ENDPOINTS.EMARKETING_SENT.Delete.replace('{id}', `${action.payload}`)
    );

    yield put(actions.deleteEmarketingSent.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteEmarketingSent.failure(err));
  }
}

function* bulkEmarketingSent(action: ReturnType<typeof actions.bulkEmarketingSents.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.EMARKETING_SENT.Bulk, action.payload);

    yield put(actions.bulkEmarketingSents.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkEmarketingSents.failure(err));
  }
}

// Main saga
export default function* emarketingSaga() {
  yield all([
    takeLatest(actions.emarketingSentSummary.request, emarketingSummary),
    takeLatest(actions.emarketingSentDetail.request, emarketingDetails),
    takeLatest(actions.deleteEmarketingSent.request, deleteEmarketingSent),
    takeLatest(actions.bulkEmarketingSents.request, bulkEmarketingSent),
  ]);
}
