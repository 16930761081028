export type ModuleKeys =
  | 'dashboard'
  | 'sale'
  | 'walkin'
  | 'schedule'
  | 'turn_system'
  | 'emarketing'
  | 'sms'
  | 'customer'
  | 'customer_group'
  | 'membership'
  | 'giftcard'
  | 'coupon'
  | 'report'
  | 'back_office'
  | 'member'
  | 'supplier'
  | 'category'
  | 'unit'
  | 'service'
  | 'product'
  | 'package'
  | 'expense'
  | 'setting';

export enum Module {
  Dashboard = 1,
  Sale,
  Walkin,
  Schedule,
  TurnSystem,
  Emarketing,
  Sms,
  Customer,
  Membership,
  Giftcard,
  Coupon,
  Report,
  BackOffice,
  Setting,
}

export const Modules = [
  {
    value: String(Module.Dashboard),
    label: 'Dashboard',
    key: 'dashboard',
  },
  {
    value: String(Module.Sale),
    label: 'Sale',
    key: 'sale',
  },
  {
    value: String(Module.Walkin),
    label: 'Walkin',
    key: 'walkin',
  },
  {
    value: String(Module.Schedule),
    label: 'Schedule',
    key: 'schedule',
  },
  {
    value: String(Module.TurnSystem),
    label: 'Turn System',
    key: 'turn_system',
  },
  {
    value: String(Module.Emarketing),
    label: 'eMarketing',
    key: 'emarketing',
  },
  {
    value: String(Module.Sms),
    label: 'SMS',
    key: 'sms',
  },
  {
    value: String(Module.Giftcard),
    label: 'Gift Card',
    key: 'giftcard',
  },
  {
    value: String(Module.Coupon),
    label: 'Coupon',
    key: 'coupon',
  },
  {
    value: String(Module.Customer),
    label: 'Customer',
    key: 'customer',
  },
  {
    value: String(Module.Membership),
    label: 'Membership',
    key: 'membership',
  },
  {
    value: String(Module.Report),
    label: 'Report',
    key: 'report',
  },
  {
    value: String(Module.BackOffice),
    label: 'Back Office',
    key: 'back_office',
  },
  {
    value: String(Module.Setting),
    label: 'Setting',
    key: 'setting',
  },
];
