export const FETCH_SUMMARY = 'sms/logs/summary';
export const FETCH_SUMMARY_SUCCESS = 'sms/logs/summary_success';
export const FETCH_SUMMARY_FAILED = 'sms/logs/summary_failed';

export const FETCH_DETAILS = 'sms/logs/details';
export const FETCH_DETAILS_SUCCESS = 'sms/logs/details_success';
export const FETCH_DETAILS_FAILED = 'sms/logs/details_failed';

export const DELETE = 'sms/logs/delete';
export const DELETE_SUCCESS = 'sms/logs/delete_success';
export const DELETE_FAILED = 'sms/logs/delete_failed';

export const BULK = 'sms/logs/bulk';
export const BULK_SUCCESS = 'sms/logs/bulk_success';
export const BULK_FAILED = 'sms/logs/bulk_failed';

export const CLEAR_DATA = 'sms/logs/clear_data';

export type SmsLogAction =
  | typeof FETCH_SUMMARY
  | typeof FETCH_SUMMARY_SUCCESS
  | typeof FETCH_SUMMARY_FAILED
  | typeof FETCH_DETAILS
  | typeof FETCH_DETAILS_SUCCESS
  | typeof FETCH_DETAILS_FAILED
  | typeof DELETE
  | typeof DELETE_FAILED
  | typeof DELETE_SUCCESS
  | typeof BULK
  | typeof BULK_FAILED
  | typeof BULK_SUCCESS
  | typeof CLEAR_DATA;
