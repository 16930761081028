import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { TurnSystemState, initialState } from './model';

const turnSystem = produce((draft: Draft<TurnSystemState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    // list
    case getType(actions.getTurnServices.request): {
      draft.services.loading = true;
      return draft;
    }
    case getType(actions.getTurnServices.success): {
      draft.services.loading = true;
      draft.services.response = action.payload;
      return draft;
    }
    case getType(actions.getTurnServices.failure): {
      draft.services.loading = false;
      draft.services.errors = action.payload;
      return draft;
    }
    // list
    case getType(actions.getTurnList.request): {
      draft.list.loading = true;
      return draft;
    }
    case getType(actions.getTurnList.success): {
      draft.list.loading = true;
      draft.list.response = action.payload;
      return draft;
    }
    case getType(actions.getTurnList.failure): {
      draft.list.loading = false;
      draft.list.errors = action.payload;
      return draft;
    }
    // create
    case getType(actions.createTurnDetail.request): {
      draft.create.loading = true;
      return draft;
    }
    case getType(actions.createTurnDetail.success): {
      draft.create.loading = true;
      draft.create.response = action.payload;
      return draft;
    }
    case getType(actions.createTurnDetail.failure): {
      draft.create.loading = false;
      draft.create.errors = action.payload;
      return draft;
    }
    // detail
    case getType(actions.getTurnDetail.request): {
      draft.details.loading = true;
      return draft;
    }
    case getType(actions.getTurnDetail.success): {
      draft.details.loading = true;
      draft.details.response = action.payload;
      return draft;
    }
    case getType(actions.getTurnDetail.failure): {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    // update
    case getType(actions.updateTurnDetail.request): {
      draft.update.loading = true;
      return draft;
    }
    case getType(actions.updateTurnDetail.success): {
      draft.update.loading = true;
      draft.update.response = action.payload;
      return draft;
    }
    case getType(actions.updateTurnDetail.failure): {
      draft.update.loading = false;
      draft.update.errors = action.payload;
      return draft;
    }
    case getType(actions.deleteTurnDetail.request): {
      draft.delete.loading = true;
      return draft;
    }
    case getType(actions.deleteTurnDetail.success): {
      draft.delete.loading = true;
      draft.delete.response = action.payload;
      return draft;
    }
    case getType(actions.deleteTurnDetail.failure): {
      draft.delete.loading = false;
      draft.delete.errors = action.payload;
      return draft;
    }
    case getType(actions.getReorderList.request): {
      draft.reorderList.loading = true;
      return draft;
    }
    case getType(actions.getReorderList.success): {
      draft.reorderList.loading = true;
      draft.reorderList.response = action.payload;
      return draft;
    }
    case getType(actions.getReorderList.failure): {
      draft.reorderList.loading = false;
      draft.reorderList.errors = action.payload;
      return draft;
    }
    case getType(actions.changeReorder.request): {
      draft.changeReorder.loading = true;
      return draft;
    }
    case getType(actions.changeReorder.success): {
      draft.changeReorder.loading = true;
      draft.changeReorder.response = action.payload;
      return draft;
    }
    case getType(actions.changeReorder.failure): {
      draft.changeReorder.loading = false;
      draft.changeReorder.errors = action.payload;
      return draft;
    }
    // clear data
    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialState;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialState);

export default turnSystem;
