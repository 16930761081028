export const FETCH_SUMMARY = 'income/summary';
export const FETCH_SUMMARY_SUCCESS = 'income/summary_success';
export const FETCH_SUMMARY_FAILED = 'income/summary_failed';

export const FETCH_SUMMARY_DETAILS = 'income/details_summary';
export const FETCH_SUMMARY_DETAILS_SUCCESS = 'income/details_summary_success';
export const FETCH_SUMMARY_DETAILS_FAILED = 'income/details_summary_failed';

export const FETCH_INCOME_SUMMARY = 'income/summary_incomes';
export const FETCH_INCOME_SUMMARY_SUCCESS = 'income/summary_incomes_success';
export const FETCH_INCOME_SUMMARY_FAILED = 'income/summary_incomes_failed';

export const FETCH_INCOME_DETAILS = 'income/details_incomes';
export const FETCH_INCOME_DETAILS_SUCCESS = 'income/details_incomes_success';
export const FETCH_INCOME_DETAILS_FAILED = 'income/details_incomes_failed';

export const FETCH_REPORT_SUMMARY = 'income/report_summary';
export const FETCH_REPORT_SUMMARY_SUCCESS = 'income/report_summary_success';
export const FETCH_REPORT_SUMMARY_FAILED = 'income/report_summary_failed';

export const FETCH_REPORT_DETAILS = 'income/report_details';
export const FETCH_REPORT_DETAILS_SUCCESS = 'income/report_details_success';
export const FETCH_REPORT_DETAILS_FAILED = 'income/report_details_failed';

export const FETCH_REPORT_FULL_DETAILS = 'income/report_full_details';
export const FETCH_REPORT_FULL_DETAILS_SUCCESS = 'income/report_full_details_success';
export const FETCH_REPORT_FULL_DETAILS_FAILED = 'income/report_full_details_failed';



export const CLEAR_DATA = 'income/clear_data';

export type IncomeAction =
  | typeof FETCH_SUMMARY
  | typeof FETCH_SUMMARY_FAILED
  | typeof FETCH_SUMMARY_SUCCESS
  | typeof FETCH_SUMMARY_DETAILS
  | typeof FETCH_SUMMARY_DETAILS_FAILED
  | typeof FETCH_SUMMARY_DETAILS_SUCCESS
  | typeof FETCH_INCOME_SUMMARY
  | typeof FETCH_INCOME_SUMMARY_FAILED
  | typeof FETCH_INCOME_SUMMARY_SUCCESS
  | typeof FETCH_INCOME_DETAILS
  | typeof FETCH_INCOME_DETAILS_FAILED
  | typeof FETCH_INCOME_DETAILS_SUCCESS
  | typeof FETCH_REPORT_SUMMARY
  | typeof FETCH_REPORT_SUMMARY_SUCCESS
  | typeof FETCH_REPORT_SUMMARY_FAILED
  | typeof FETCH_REPORT_DETAILS
  | typeof FETCH_REPORT_DETAILS_SUCCESS
  | typeof FETCH_REPORT_DETAILS_FAILED
  | typeof FETCH_REPORT_FULL_DETAILS
  | typeof FETCH_REPORT_FULL_DETAILS_SUCCESS
  | typeof FETCH_REPORT_FULL_DETAILS_FAILED
  | typeof CLEAR_DATA;
