import { fork, all } from 'redux-saga/effects';

import saleMgntSaga from './sale-mgnt/sagas';
import saleSaga from './sale-pos/sagas';
import salePaymentSaga from './sale-payment/sagas';
import waitingSaleSaga from './waiting-sale/sagas';

import dashboardSaga from './dashboard/sagas';
import storeReportSaga from './store-report/sagas';
import dailyReportSaga from './daily-report/sagas';
import incomeSaga from './income-report/sagas';
import incomeWorkingTimeSaga from './income-working-time-report/sagas';
import customerReportSaga from './customer-report/sagas';
import giftcardReportSaga from './giftcard-report/sagas';
import serviceProductReportSaga from './service-product-report/sagas';

import payrollSaga from './payroll/sagas';

import employeeSaga from './employee/sagas';
import serviceSaga from './service/sagas';
import productSaga from './product/sagas';
import customerSaga from './customer/sagas';
import customerGroupSaga from './customerGroup/sagas';
import membershipSaga from './membership/sagas';
import supplierSaga from './supplier/sagas';
import storeSaga from './store/sagas';
import giftcardSaga from './giftcard/sagas';
import walkinSaga from './walkin/sagas';
import clockInOutSaga from './clockInOut/sagas';
import codeSaga from './common/code/sagas';
import initialSettingSaga from './common/setting-initial/sagas';
import storeSettingSaga from './common/setting-store/sagas';
import settingSaga from './common/setting/sagas';
import authSaga from './common/auth/sagas';
import bookingApptSaga from './bookingAppt/sagas';

import smsTemplateSaga from './sms/template/sagas';
import smsReminderSaga from './sms/reminder/sagas';
import smsLogsSaga from './sms/logs/sagas';

import emarketingTemplateSaga from './emarketing/template/sagas';
import emarketingCampaignSaga from './emarketing/campaign/sagas';
import emarketingSentSaga from './emarketing/sent/sagas';

import productKitSaga from './product-kit/sagas';
import unitsSaga from './unit/sagas';
import couponSaga from './coupon/sagas';
import supportSaga from './common/support/sagas';

import categorySaga from './category/sagas';

import turnSystemSaga from './turn-system/sagas';
import expenseSaga from './expense/sagas';
// We `fork()` these tasks so they execute in the background.
export function* rootSagas() {
  yield all([
    fork(authSaga),
    fork(codeSaga),
    fork(initialSettingSaga),
    fork(storeSettingSaga),
    fork(settingSaga),
    fork(supportSaga),

    fork(saleSaga),
    fork(saleMgntSaga),
    fork(salePaymentSaga),
    fork(waitingSaleSaga),

    fork(dashboardSaga),
    fork(storeReportSaga),
    fork(dailyReportSaga),
    fork(incomeSaga),
    fork(incomeWorkingTimeSaga),
    fork(customerReportSaga),
    fork(giftcardReportSaga),
    fork(serviceProductReportSaga),

    fork(payrollSaga),

    fork(employeeSaga),
    fork(customerSaga),
    fork(customerGroupSaga),
    fork(membershipSaga),
    fork(supplierSaga),

    fork(storeSaga),
    fork(giftcardSaga),
    fork(walkinSaga),
    fork(clockInOutSaga),
    fork(bookingApptSaga),

    fork(smsTemplateSaga),
    fork(smsReminderSaga),
    fork(smsLogsSaga),

    fork(emarketingTemplateSaga),
    fork(emarketingCampaignSaga),
    fork(emarketingSentSaga),

    fork(categorySaga),
    fork(unitsSaga),
    fork(serviceSaga),
    fork(productKitSaga),
    fork(productSaga),
    fork(couponSaga),

    fork(turnSystemSaga),
    fork(expenseSaga),

    // fork(teamsSaga),
    // `fork()` any other store sagas down here...
  ]);
}
