export const FETCH_DETAILS = 'payment/status';
export const FETCH_DETAILS_SUCCESS = 'payment/status_success';
export const FETCH_DETAILS_FAILED = 'payment/status_failed';

export const FETCH_LIST = 'payment/list';
export const FETCH_LIST_SUCCESS = 'payment/list_success';
export const FETCH_LIST_FAILED = 'payment/list_failed';

export const SALE_PAYMENT = 'payment/sale';
export const SALE_PAYMENT_SUCCESS = 'payment/sale_success';
export const SALE_PAYMENT_FAILED = 'payment/sale_failed';

export const RETURN_TIP_ADJUST = 'payment/tipAdjust';
export const RETURN_TIP_ADJUST_SUCCESS = 'payment/tipAdjust_success';
export const RETURN_TIP_ADJUST_FAILED = 'payment/tipAdjust_failed';

export const RETURN_PAYMENT = 'payment/return';
export const RETURN_PAYMENT_SUCCESS = 'payment/return_success';
export const RETURN_PAYMENT_FAILED = 'payment/return_failed';

export const VOID_PAYMENT = 'payment/void';
export const VOID_PAYMENT_SUCCESS = 'payment/void_success';
export const VOID_PAYMENT_FAILED = 'payment/void_failed';

export const BULK = 'payment/bulk';
export const BULK_SUCCESS = 'payment/bulk_success';
export const BULK_FAILED = 'payment/bulk_failed';

export const PAYMENT_MOMO = 'payment/momo';
export const PAYMENT_MOMO_SUCCESS = 'payment/momo_success';
export const PAYMENT_MOMO_FAILED = 'payment/momo_failed';

export const PAYMENT_MOMO_STATUS = 'payment/momo_status';
export const PAYMENT_MOMO_STATUS_SUCCESS = 'payment/momo_status_success';
export const PAYMENT_MOMO_STATUS_FAILED = 'payment/momo_status_failed';

export const PAYMENT_MOMO_CONFIRM = 'payment/momo_confirm';
export const PAYMENT_MOMO_CONFIRM_SUCCESS = 'payment/momo_confirm_success';
export const PAYMENT_MOMO_CONFIRM_FAILED = 'payment/momo_confirm_failed';

export const PAYMENT_VIET_QR = 'payment/vietqr';
export const PAYMENT_VIET_QR_SUCCESS = 'payment/vietqr_success';
export const PAYMENT_VIET_QR_FAILED = 'payment/vietqr_failed';

export const CLEAR_DATA = 'payment/clear_data';

export type PaymentAction =
  | typeof FETCH_DETAILS
  | typeof FETCH_DETAILS_FAILED
  | typeof FETCH_DETAILS_SUCCESS
  | typeof FETCH_LIST
  | typeof FETCH_LIST_FAILED
  | typeof FETCH_LIST_SUCCESS
  | typeof SALE_PAYMENT
  | typeof SALE_PAYMENT_FAILED
  | typeof SALE_PAYMENT_SUCCESS
  | typeof RETURN_PAYMENT
  | typeof RETURN_PAYMENT_FAILED
  | typeof RETURN_PAYMENT_SUCCESS
  | typeof VOID_PAYMENT
  | typeof VOID_PAYMENT_FAILED
  | typeof VOID_PAYMENT_SUCCESS
  | typeof BULK
  | typeof BULK_FAILED
  | typeof BULK_SUCCESS
  | typeof PAYMENT_MOMO
  | typeof PAYMENT_MOMO_SUCCESS
  | typeof PAYMENT_MOMO_FAILED
  | typeof PAYMENT_MOMO_STATUS
  | typeof PAYMENT_MOMO_STATUS_SUCCESS
  | typeof PAYMENT_MOMO_STATUS_FAILED
  | typeof PAYMENT_MOMO_CONFIRM
  | typeof PAYMENT_MOMO_CONFIRM_SUCCESS
  | typeof PAYMENT_MOMO_CONFIRM_FAILED
  | typeof PAYMENT_VIET_QR
  | typeof PAYMENT_VIET_QR_SUCCESS
  | typeof PAYMENT_VIET_QR_FAILED
  | typeof CLEAR_DATA;
