import { BaseState } from '../models';
import {
  ServiceProductReportListResponse,
  ServiceProductReportDetailResponse
} from '../../api-models/service-product';

export type ServiceProductReportList = BaseState<ServiceProductReportListResponse>;
export type ServiceProductReportDetails = BaseState<ServiceProductReportDetailResponse>;


export type ServiceProductReportState = {
  list: ServiceProductReportList;
  details: ServiceProductReportDetails;
};

export const initialServiceProductReportState = {
  list: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    // match to reducer name, each reducer will be one field in state
    serviceProductReport: ServiceProductReportState;
  }
}
