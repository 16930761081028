import produce, { Draft } from 'immer';
import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { CodeListState, initialCodeState } from './model';

const codeList = produce((draft: Draft<CodeListState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getCodeList.request): {
      const key = action.payload.keyCodeName || action.payload.codeName;
      draft.codeData[key] = { loading: true };
      return draft;
    }
    case getType(actions.getCodeList.success): {
      const key = action.payload.keyCodeName || action.payload.codeName;
      draft.codeData[key] = {
        loading: false,
        response: action.payload.responseData,
      };
      return draft;
    }
    case getType(actions.getCodeList.failure): {
      const key = action.payload.keyCodeName || action.payload.codeName;
      draft.codeData[key] = {
        loading: false,
        errors: action.payload.errors,
      };
      return draft;
    }
    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialCodeState.codeList as any;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialCodeState.codeList);

export default combineReducers({
  codeList,
});
