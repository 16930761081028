export const API_ENDPOINTS = {
  SMS_REMINDER: {
    List: '/text-message/reminders',
    Create: '/text-message/reminders',
    Details: '/text-message/reminders/{id}',
    Update: '/text-message/reminders/{id}',
    UpdateStatus: '/text-message/reminders/{id}/status',
    Delete: '/text-message/reminders/{id}',
    Bulk: '/text-message/reminders/bulk',
  },
};
