export const GET_WAITING_SALE_LIST_REQUEST = 'waitingSale/get_waiting_sale_list_request';
export const GET_WAITING_SALE_LIST_SUCCESS = 'waitingSale/get_waiting_sale_list_success';
export const GET_WAITING_SALE_LIST_FAILED = 'waitingSale/get_waiting_sale_list_falied';

export const SAVE_WAITING_SALE = 'waitingSale/save_waiting_sale';

export const DELETE_WAITING_SALE = 'waitingSale/delete_waiting_sale';

export const MERGE_WAITING_SALE = 'waitingSale/merge_waiting_sale';

export const CLEAR_DATA = 'waitingSale/clear_data';

export type WaitingSaleAction =
  | typeof SAVE_WAITING_SALE
  | typeof DELETE_WAITING_SALE
  | typeof MERGE_WAITING_SALE
  | typeof CLEAR_DATA;
