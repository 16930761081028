import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints/emarketing/campaign';
import { axiosInstance } from 'src/utils/axios/axios-instance';
import * as actions from './actions';
import { removeCodeList } from 'src/utils/axios/axios-fecth';

// Handle request saga
function* getList(action: ReturnType<typeof actions.getList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.EMARKETING_CAMPAIGN.List, {
      params: action.payload,
    });

    yield put(actions.getList.success((response as any).data));
  } catch (err) {
    yield put(actions.getList.failure(err));
  }
}

function* getDetails(action: ReturnType<typeof actions.getDetails.request>): Generator {
  try {
    let response: any = {};
    if (action.payload)
      response = yield call(
        axiosInstance.get,
        API_ENDPOINTS.EMARKETING_CAMPAIGN.Details.replace('{id}', `${action.payload}`)
      );

    yield put(actions.getDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getDetails.failure(err));
  }
}

function* createData(action: ReturnType<typeof actions.createData.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.EMARKETING_CAMPAIGN.Create, action.payload);

    yield put(actions.createData.success((response as any).data));

    yield removeCodeList('emarketing_event');
  } catch (err) {
    yield put(actions.createData.failure(err));
  }
}

function* updateData(action: ReturnType<typeof actions.updateData.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.post,
      API_ENDPOINTS.EMARKETING_CAMPAIGN.Update.replace('{id}', `${action.payload.id}`),
      action.payload
    );

    yield put(actions.updateData.success((response as any).data));
    yield removeCodeList('emarketing_event');
  } catch (err) {
    yield put(actions.updateData.failure(err));
  }
}

function* updateStatus(action: ReturnType<typeof actions.updateStatus.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.post,
      API_ENDPOINTS.EMARKETING_CAMPAIGN.UpdateStatus.replace('{id}', `${action.payload.id}`),
      action.payload
    );

    yield put(actions.updateStatus.success((response as any).data));
    yield removeCodeList('emarketing_event');
  } catch (err) {
    yield put(actions.updateStatus.failure(err));
  }
}

function* deleteData(action: ReturnType<typeof actions.deleteData.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.delete,
      API_ENDPOINTS.EMARKETING_CAMPAIGN.Delete.replace('{id}', `${action.payload}`)
    );

    yield put(actions.deleteData.success((response as any).data));
    yield removeCodeList('emarketing_event');
  } catch (err) {
    yield put(actions.deleteData.failure(err));
  }
}

function* bulkData(action: ReturnType<typeof actions.bulkDatas.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.EMARKETING_CAMPAIGN.Bulk, action.payload);

    yield put(actions.bulkDatas.success((response as any).data));
    yield removeCodeList('emarketing_event');
  } catch (err) {
    yield put(actions.bulkDatas.failure(err));
  }
}

// Main saga
export default function* emarketingSaga() {
  yield all([
    takeLatest(actions.getList.request, getList),
    takeLatest(actions.getDetails.request, getDetails),
    takeLatest(actions.createData.request, createData),
    takeLatest(actions.updateData.request, updateData),
    takeLatest(actions.updateStatus.request, updateStatus),
    takeLatest(actions.deleteData.request, deleteData),
    takeLatest(actions.bulkDatas.request, bulkData),
  ]);
}
