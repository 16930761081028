import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  EmarketingSentSummaryRequest,
  EmarketingSentSummaryResponse,
  EmarketingSentDetailRequest,
  EmarketingSentDetailResponse,
  EmarketingSentDeleteRequest,
  EmarketingSentDeleteResponse,
  EmarketingSentBulkRequest,
  EmarketingSentBulkResponse,
} from 'src/state/api-models/emarketing-sent';
import * as types from './types';

export const emarketingSentSummary = createAsyncAction(
  types.FETCH_SUMMARY,
  types.FETCH_SUMMARY_SUCCESS,
  types.FETCH_SUMMARY_FAILED
)<EmarketingSentSummaryRequest, EmarketingSentSummaryResponse, any>();

export const emarketingSentDetail = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<EmarketingSentDetailRequest, EmarketingSentDetailResponse, any>();

export const deleteEmarketingSent = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  EmarketingSentDeleteRequest['id'],
  EmarketingSentDeleteResponse,
  any
>();

export const bulkEmarketingSents = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  EmarketingSentBulkRequest,
  EmarketingSentBulkResponse,
  any
>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
