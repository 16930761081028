import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { SmsReminderState, initialSmsReminderState } from './model';

const smsReminder = produce((draft: Draft<SmsReminderState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getSmsReminderList.request): {
      draft.list.loading = true;
      return draft;
    }
    case getType(actions.getSmsReminderList.success): {
      draft.list.loading = true;
      draft.list.response = action.payload;
      return draft;
    }
    case getType(actions.getSmsReminderList.failure): {
      draft.list.loading = false;
      draft.list.errors = action.payload;
      return draft;
    }
    case getType(actions.getSmsReminderDetails.request): {
      draft.details.loading = true;
      draft.details.response = undefined;
      draft.details.errors = undefined;
      return draft;
    }
    case getType(actions.getSmsReminderDetails.success): {
      draft.details.loading = true;
      draft.details.response = action.payload;
      return draft;
    }
    case getType(actions.getSmsReminderDetails.failure): {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    case getType(actions.createSmsReminder.request): {
      draft.create.loading = true;
      return draft;
    }
    case getType(actions.createSmsReminder.success): {
      draft.create.loading = false;
      draft.create.response = action.payload;
      return draft;
    }
    case getType(actions.createSmsReminder.failure): {
      draft.create.loading = false;
      draft.create.errors = action.payload;
      return draft;
    }
    case getType(actions.updateSmsReminder.request): {
      draft.update.loading = true;
      return draft;
    }
    case getType(actions.updateSmsReminder.success): {
      draft.update.loading = false;
      draft.update.response = action.payload;
      return draft;
    }
    case getType(actions.updateSmsReminder.failure): {
      draft.update.loading = false;
      draft.update.errors = action.payload;
      return draft;
    }
    case getType(actions.updateStatus.request): {
      draft.updateStatus.loading = true;
      return draft;
    }
    case getType(actions.updateStatus.success): {
      draft.updateStatus.loading = false;
      draft.updateStatus.response = action.payload;
      return draft;
    }
    case getType(actions.updateStatus.failure): {
      draft.updateStatus.loading = false;
      draft.updateStatus.errors = action.payload;
      return draft;
    }
    case getType(actions.deleteSmsReminder.request): {
      draft.delete.loading = true;
      return draft;
    }
    case getType(actions.deleteSmsReminder.success): {
      draft.delete.loading = false;
      draft.delete.response = action.payload;
      return draft;
    }
    case getType(actions.deleteSmsReminder.failure): {
      draft.delete.loading = false;
      draft.delete.errors = action.payload;
      return draft;
    }
    case getType(actions.bulkSmsReminder.request): {
      draft.bulk.loading = true;
      return draft;
    }
    case getType(actions.bulkSmsReminder.success): {
      draft.bulk.loading = false;
      draft.bulk.response = action.payload;
      return draft;
    }
    case getType(actions.bulkSmsReminder.failure): {
      draft.bulk.loading = false;
      draft.bulk.errors = action.payload;
      return draft;
    }
    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialSmsReminderState;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialSmsReminderState);

export default smsReminder;
