import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints/turn-system';
import { axiosInstance } from 'src/utils/axios/axios-instance';
import * as actions from './actions';

function* getTurnServices(action: ReturnType<typeof actions.getTurnServices.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.TURN_SYSTEM.GetTurnServices, {
      params: action.payload,
    });
    yield put(actions.getTurnServices.success((response as any).data));
  } catch (err) {
    yield put(actions.getTurnServices.failure(err));
  }
}

function* getTurnList(action: ReturnType<typeof actions.getTurnList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.TURN_SYSTEM.GetTurnList, {
      params: action.payload,
    });
    yield put(actions.getTurnList.success((response as any).data));
  } catch (err) {
    yield put(actions.getTurnList.failure(err));
  }
}

function* createTurnDetail(action: ReturnType<typeof actions.createTurnDetail.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.TURN_SYSTEM.CreateTurnDetail, action.payload);
    yield put(actions.createTurnDetail.success((response as any).data));
  } catch (err) {
    yield put(actions.createTurnDetail.failure(err));
  }
}

function* getTurnDetail(action: ReturnType<typeof actions.getTurnDetail.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.get,
      API_ENDPOINTS.TURN_SYSTEM.GetTurnDetail.replace('{id}', action.payload.id)
    );
    yield put(actions.getTurnDetail.success((response as any).data));
  } catch (err) {
    yield put(actions.getTurnDetail.failure(err));
  }
}

function* updateTurnDetail(action: ReturnType<typeof actions.updateTurnDetail.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.post,
      API_ENDPOINTS.TURN_SYSTEM.UpdateTurnDetail.replace('{id}', action.payload.id),
      action.payload
    );
    yield put(actions.updateTurnDetail.success((response as any).data));
  } catch (err) {
    yield put(actions.updateTurnDetail.failure(err));
  }
}

function* deleteTurnDetail(action: ReturnType<typeof actions.deleteTurnDetail.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.delete,
      API_ENDPOINTS.TURN_SYSTEM.DeleteTurnDetail.replace('{id}', action.payload.id)
    );
    yield put(actions.deleteTurnDetail.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteTurnDetail.failure(err));
  }
}

function* getReorderList(action: ReturnType<typeof actions.getReorderList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.TURN_SYSTEM.GetReorderList, {
      params: { sort, ...filter, ...pagination },
    });
    yield put(actions.getReorderList.success((response as any).data));
  } catch (err) {
    yield put(actions.getReorderList.failure(err));
  }
}

function* changeReorder(action: ReturnType<typeof actions.changeReorder.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.TURN_SYSTEM.ChangeReorder, action.payload);
    yield put(actions.changeReorder.success((response as any).data));
  } catch (err) {
    yield put(actions.changeReorder.failure(err));
  }
}

// Main saga
export default function* turnSystemSaga() {
  yield all([
    takeLatest(actions.getTurnList.request, getTurnList),
    takeLatest(actions.getTurnServices.request, getTurnServices),
    takeLatest(actions.createTurnDetail.request, createTurnDetail),
    takeLatest(actions.getTurnDetail.request, getTurnDetail),
    takeLatest(actions.updateTurnDetail.request, updateTurnDetail),
    takeLatest(actions.deleteTurnDetail.request, deleteTurnDetail),
    takeLatest(actions.getReorderList.request, getReorderList),
    takeLatest(actions.changeReorder.request, changeReorder),
  ]);
}
