export const USER = {
  List: '/users',
  Total: '/users/total',
  Create: '/users',
  Details: '/users/{id}',
  Update: '/users/{id}',
  Delete: '/users/{id}',
  ChangePass: '/users/{id}/change-pass',
  Bulk: '/users/bulk',
  Import: '/users/import',
};
