import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { ReportState, initialReportState } from './model';

const dashboard = produce((draft: Draft<ReportState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.setTimeType): {
      draft.searchCondition = action.payload;
      return draft;
    }

    case getType(actions.getIncomeSummary.request): {
      draft.summary.loading = true;
      return draft;
    }
    case getType(actions.getIncomeSummary.success): {
      draft.summary.loading = true;
      draft.summary.response = action.payload;
      return draft;
    }
    case getType(actions.getIncomeSummary.failure): {
      draft.summary.loading = false;
      draft.summary.errors = action.payload;
      return draft;
    }
    case getType(actions.getIncomeDetails.request): {
      draft.summaryDetails.loading = true;
      return draft;
    }
    case getType(actions.getIncomeDetails.success): {
      draft.summaryDetails.loading = true;
      draft.summaryDetails.response = action.payload;

      return draft;
    }
    case getType(actions.getIncomeDetails.failure): {
      draft.summaryDetails.loading = false;
      draft.summaryDetails.errors = action.payload;
      return draft;
    }

    case getType(actions.getSummaryPayments.request): {
      draft.paymentAnalysis.loading = true;
      return draft;
    }
    case getType(actions.getSummaryPayments.success): {
      draft.paymentAnalysis.loading = true;
      draft.paymentAnalysis.response = action.payload;

      return draft;
    }
    case getType(actions.getSummaryPayments.failure): {
      draft.paymentAnalysis.loading = false;
      draft.paymentAnalysis.errors = action.payload;
      return draft;
    }

    case getType(actions.getBirthdayCustomers.request): {
      draft.birthayCustomers.loading = true;
      return draft;
    }
    case getType(actions.getBirthdayCustomers.success): {
      draft.birthayCustomers.loading = true;
      draft.birthayCustomers.response = action.payload;

      return draft;
    }
    case getType(actions.getBirthdayCustomers.failure): {
      draft.birthayCustomers.loading = false;
      draft.birthayCustomers.errors = action.payload;
      return draft;
    }

    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialReportState;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialReportState);

export default dashboard;
