import { BaseState } from 'src/state/ducks/models';
import {
  GetTurnListResponse,
  CreateTurnDetailReponse,
  GetTurnDetailResponse,
  UpdateTurnDetailResponse,
  DeleteTurnDetailResponse,
  GetTurnServicesResponse,
  GetReorderListResponse,
  ChangeReorderResponse,
} from '../../api-models/employee-turn-system';

export type TurnServices = BaseState<GetTurnServicesResponse>;
export type TurnList = BaseState<GetTurnListResponse>;
export type CreateTurnDetail = BaseState<CreateTurnDetailReponse>;
export type GetTurnDetail = BaseState<GetTurnDetailResponse>;
export type UpdateTurnDetail = BaseState<UpdateTurnDetailResponse>;
export type DeleteTurnDetail = BaseState<DeleteTurnDetailResponse>;
export type GetReorderList = BaseState<GetReorderListResponse>;
export type ChangeReorder = BaseState<ChangeReorderResponse>;

export type TurnSystemState = {
  services: TurnServices;
  list: TurnList;
  create: CreateTurnDetail;
  details: GetTurnDetail;
  update: UpdateTurnDetail;
  delete: DeleteTurnDetail;
  reorderList: GetReorderList;
  changeReorder: ChangeReorder;
};

export const initialState: TurnSystemState = {
  services: { loading: false, response: undefined },
  list: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  reorderList: { loading: false, response: undefined },
  changeReorder: { loading: false, response: undefined },
};

declare module 'src/state/ducks/models' {
  interface StateAll {
    turnSystem: TurnSystemState;
  }
}
