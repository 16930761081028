import { EmployeeListResponse } from 'src/state/api-models/employee';
import { BaseState } from '../models';
import {
  BookingApptListResponse,
  BookingApptDetailResponse,
  BookingApptDeleteResponse,
  BookingApptUpdateResponse,
  BookingApptCreateResponse,
  BookingApptBulkResponse,
  BookingApptServiceResponse,
  BookingApptAssignResponse,
  BookingApptUpdateStatusResponse,
  BookingApptTopServicesResponse,
  BookingApptSendSmsResponse,
  CountBookingApptInDaysResponse,
  BookingApptUpdateColumnResponse,
} from '../../api-models/bookingAppt';
import { BookingApptServiceItem, InputSearchBookingAppt } from '../../models/bookingAppt';

export type EmployeeList = BaseState<EmployeeListResponse>;

export type EmployeeState = {
  list: EmployeeList;
};

export const initialEmployeeState: EmployeeState = {
  list: { loading: false, response: undefined },
};

export type BookingApptDetails = BaseState<BookingApptDetailResponse> & {};
export type BookingApptList = BaseState<BookingApptListResponse> & {};
export type BookingApptCount = BaseState<CountBookingApptInDaysResponse> & {};
export type BookingApptAssign = BaseState<BookingApptAssignResponse> & {};
export type BookingApptTopServices = BaseState<BookingApptTopServicesResponse> & {};

export type BookingApptCreate = BaseState<BookingApptCreateResponse> & {};
export type BookingApptUpdate = BaseState<BookingApptUpdateResponse> & {};
export type BookingApptUpdateStatus = BaseState<BookingApptUpdateStatusResponse> & {};
export type BookingApptUpdateColumn = BaseState<BookingApptUpdateColumnResponse> & {};
export type BookingApptDelete = BaseState<BookingApptDeleteResponse> & {};
export type BookingApptBulk = BaseState<BookingApptBulkResponse> & {};
export type BookingApptInputSearch = InputSearchBookingAppt;
export type ServiceBookingAppt = BookingApptServiceItem;
export type BookingApptsService = BaseState<BookingApptServiceResponse> & {};
export type BookingApptSendSms = BaseState<BookingApptSendSmsResponse> & {};
export type BookingApptState = {
  list: BookingApptList;
  countInDays: BookingApptCount;
  unassigedList: BookingApptList;
  topServices: BookingApptTopServices;
  details: BookingApptDetails;
  assign: BookingApptAssign;
  create: BookingApptCreate;
  update: BookingApptUpdate;
  updateStatus: BookingApptUpdateStatus;
  updateColumn: BookingApptUpdateColumn;
  delete: BookingApptDelete;
  bulk: BookingApptBulk;
  paraSearch: BookingApptInputSearch;
  serviceBooking: ServiceBookingAppt[];
  listService: BookingApptsService;
  sendSms: BookingApptSendSms;
};

export const initialBookingApptState: BookingApptState = {
  list: { loading: false, response: undefined },
  countInDays: { loading: false, response: undefined },
  unassigedList: { loading: false, response: undefined },
  topServices: { loading: false, response: undefined },
  assign: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  updateStatus: { loading: false, response: undefined },
  updateColumn: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
  paraSearch: {},
  serviceBooking: [],
  listService: { loading: false, response: undefined },
  sendSms: { loading: false, response: undefined },
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    bookingAppt: {
      // match to reducer name, each reducer will be one field in state
      bookingAppt: BookingApptState;
      employee: EmployeeState;
    };
  }
}
