import { OrderingRequest, OrderingResponse } from 'src/state/api-models/support';
import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';

// Create the set of async actions
export const getOrdering = createAsyncAction(
  types.FETCH_ORDERING,
  types.FETCH_ORDERING_SUCCESS,
  types.FETCH_ORDERING_FAILED
)<OrderingRequest, OrderingResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
