import { BaseState } from '../models';
import {
  CategoryListResponse,
  CategoryDetailResponse,
  CategoryDeleteResponse,
  CategoryUpdateResponse,
  CategoryCreateResponse,
  CategoryBulkResponse,
} from '../../api-models/category';

export type CategoryDetails = BaseState<CategoryDetailResponse>;
export type CategoryList = BaseState<CategoryListResponse>;
export type CategoryCreate = BaseState<CategoryCreateResponse>;
export type CategoryUpdate = BaseState<CategoryUpdateResponse>;
export type CategoryDelete = BaseState<CategoryDeleteResponse>;
export type CategoryBulk = BaseState<CategoryBulkResponse>;

export type CategoryState = {
  list: CategoryList;
  details: CategoryDetails;
  create: CategoryCreate;
  update: CategoryUpdate;
  delete: CategoryDelete;
  bulk: CategoryBulk;
};

export const initialCategoryState = {
  list: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    // match to reducer name, each reducer will be one field in state
    category: CategoryState;
  }
}
