import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  SmsLogSummaryRequest,
  SmsLogSummaryResponse,
  SmsLogDetailRequest,
  SmsLogDetailResponse,
  SmsLogDeleteRequest,
  SmsLogDeleteResponse,
  SmsLogBulkRequest,
  SmsLogBulkResponse,
} from 'src/state/api-models/sms-logs';
import * as types from './types';

// Create the set of async actions
export const smsLogSummary = createAsyncAction(
  types.FETCH_SUMMARY,
  types.FETCH_SUMMARY_SUCCESS,
  types.FETCH_SUMMARY_FAILED
)<SmsLogSummaryRequest, SmsLogSummaryResponse, any>();

// Create the set of async actions
export const smsLogDetails = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<SmsLogDetailRequest, SmsLogDetailResponse, any>();

// Create the set of async actions
export const deleteSmsLog = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  SmsLogDeleteRequest['id'],
  SmsLogDeleteResponse,
  any
>();

export const bulkSmsLog = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  SmsLogBulkRequest,
  SmsLogBulkResponse,
  any
>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
