import { LocaleSettings } from 'src/types/locale';

export const LOCALES: { [key: string]: LocaleSettings } = {
  DEFAULT: {
    locale: 'en-US',
    language: 'en',
    country: 'US',
    barcode: 'UPC',
    currency: 'USD',
    currencySymbol: '$',
    symbolShortly: {
      thousand: '',
    },
    thousandSeparator: ',',
    decimalSeparator: '.',
    fractionDigits: 2,
    dateTimeFormat: {
      date: 'MM/DD/YYYY',
      shortDate: 'MM/DD/YY',
      longDate: 'ddd, MM/DD/YYYY',
      month: 'MM/YYYY',
      shortMonth: 'MM/YY',
      longMonth: 'MMMM YYYY',
      year: 'YYYY',
      dateTime: 'hh:mm A, MM/DD/YYYY',
      time: 'hh:mm A',
      shortTime: 'LTS',
      longtime: 'LTS',
    },
  },
  US: {
    locale: 'en-US',
    language: 'en',
    country: 'US',
    barcode: 'UPC',
    currency: 'USD',
    currencySymbol: '$',
    symbolShortly: {
      thousand: '',
    },
    thousandSeparator: ',',
    decimalSeparator: '.',
    fractionDigits: 2,
    dateTimeFormat: {
      date: 'MM/DD/YYYY',
      shortDate: 'MM/DD/YY',
      longDate: 'ddd, MM/DD/YYYY',
      month: 'MM/YYYY',
      shortMonth: 'MM/YY',
      longMonth: 'MMMM YYYY',
      year: 'YYYY',
      dateTime: 'hh:mm A, DD/MM/YYYY',
      time: 'hh:mm A',
      shortTime: 'LTS',
      longtime: 'LTS',
    },
  },
  CA: {
    locale: 'en-CA',
    language: 'en',
    country: 'CA',
    barcode: 'UPC',
    currency: 'CAD',
    currencySymbol: '$',
    symbolShortly: {
      thousand: '',
    },
    thousandSeparator: ',',
    decimalSeparator: '.',
    fractionDigits: 2,
    dateTimeFormat: {
      date: 'MM/DD/YYYY',
      shortDate: 'MM/DD/YY',
      longDate: 'ddd, MM/DD/YYYY',
      month: 'MM/YYYY',
      shortMonth: 'MM/YY',
      longMonth: 'MMMM YYYY',
      year: 'YYYY',
      dateTime: 'hh:mm A, DD/MM/YYYY',
      time: 'hh:mm A',
      shortTime: 'LTS',
      longtime: 'LTS',
    },
  },
  VN: {
    locale: 'vi-VN',
    language: 'vi',
    country: 'VN',
    barcode: 'EAN13',
    currency: 'VND',
    currencySymbol: '₫',
    symbolShortly: {
      thousand: 'k',
    },
    thousandSeparator: '.',
    decimalSeparator: ',',
    fractionDigits: 0,
    dateTimeFormat: {
      date: 'DD/MM/YYYY',
      shortDate: 'DD/MM/YY',
      longDate: 'ddd, DD/MM/YYYY',
      month: 'MM/YYYY',
      shortMonth: 'MM/YY',
      longMonth: 'MMMM YYYY',
      year: 'YYYY',
      dateTime: 'hh:mm A, MM/DD/YYYY',
      time: 'hh:mm A',
      shortTime: 'LT',
      longtime: 'LTS',
    },
  },
};
