import { put, call, all, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';
import { API_ENDPOINTS } from '../../../config/api-endpoints';

function* getDetailList(action: ReturnType<typeof actions.getDetailList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.GIFTCARD_REPORT.Report, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getDetailList.success((response as any).data));
  } catch (err) {
    yield put(actions.getDetailList.failure(err));
  }
}

// Main saga
export default function* dailySaga() {
  yield all([takeLatest(actions.getDetailList.request, getDetailList)]);
}
