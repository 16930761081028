import { put, call, all, takeLatest } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';

function* getIncomeSummaryList(action: ReturnType<typeof actions.getIncomeSummaryList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.INCOME_WORKING_TIME_REPORT.IncomeSummary, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getIncomeSummaryList.success((response as any).data));
  } catch (err) {
    yield put(actions.getIncomeSummaryList.failure(err));
  }
}

function* getIncomeDetailList(action: ReturnType<typeof actions.getIncomeDetailList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.INCOME_WORKING_TIME_REPORT.IncomeDetails, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getIncomeDetailList.success((response as any).data));
  } catch (err) {
    yield put(actions.getIncomeDetailList.failure(err));
  }
}

function* getReportSummary(action: ReturnType<typeof actions.getReportSummary.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.INCOME_WORKING_TIME_REPORT.ReportSummary, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getReportSummary.success((response as any).data));
  } catch (err) {
    yield put(actions.getReportSummary.failure(err));
  }
}





// Main saga
export default function* wkIncomeSaga() {
  yield all([
    takeLatest(actions.getIncomeSummaryList.request, getIncomeSummaryList),
    takeLatest(actions.getIncomeDetailList.request, getIncomeDetailList),
    takeLatest(actions.getReportSummary.request, getReportSummary),
  ]);
}
