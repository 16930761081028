
import { ReportWorkingTimencomeResponse } from 'src/state/api-models/income-working-time';
import { BaseState } from '../models';
import { IncomeListResponse } from '../../api-models/income';

export type IncomeList = BaseState<IncomeListResponse>;
export type ReportSummary = BaseState<ReportWorkingTimencomeResponse>;



export type IncomeStateAll = {
  incomeSummary: IncomeList;

  reportSummary: ReportSummary;

};

export const initialStateAll: IncomeStateAll = {
  incomeSummary: { loading: false, response: undefined },
  reportSummary: { loading: false, response: undefined },

};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    incomeWorkingTime: IncomeStateAll;
  }
}
