import {
  CreatePayrollResponse,
  DeletePayrollResponse,
  PayrollListResponse,
  PayrollCommissionDetailListResponse,
  PayrollDetailResponse,
  PayrollCommissionSummaryResponse,
  CalculatePayrollResponse,
  UpdatePayrollResponse,
  BulkPayrollResponse,
} from 'src/state/api-models/payroll';
import { BaseState } from '../models';


export type ReportPayrollState = BaseState<PayrollCommissionSummaryResponse>;
export type ReportPayrollSummaryState = BaseState<PayrollCommissionSummaryResponse>;
export type ReportPayrollDetailState = BaseState<PayrollCommissionDetailListResponse>;

export type ListPayroll = BaseState<PayrollListResponse>;
export type CalculatePayroll = BaseState<CalculatePayrollResponse>;
export type PayrollDetail = BaseState<PayrollDetailResponse>;
export type CreatePayroll = BaseState<CreatePayrollResponse>;
export type UpdatePayroll = BaseState<UpdatePayrollResponse>;
export type DeletePayroll = BaseState<DeletePayrollResponse>;
export type BulkPayroll = BaseState<BulkPayrollResponse>;

export type PayrollState = {
  report: ReportPayrollState;
  reportSummary: ReportPayrollSummaryState;
  reportDetails: ReportPayrollDetailState;

  list: ListPayroll;
  calculate: CalculatePayroll;
  details: PayrollDetail;
  update: UpdatePayroll;
  create: CreatePayroll;
  delete: DeletePayroll;
  bulk: BulkPayroll;
};

export const initialPayrollState: PayrollState = {
  report: { loading: false, response: undefined },
  reportSummary: { loading: false, response: undefined },
  reportDetails: { loading: false, response: undefined },
  list: { loading: false, response: undefined },
  calculate: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    // match to reducer name, each reducer will be one field in state
    payroll: PayrollState;
  }
}
