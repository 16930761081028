export const FETCH_SUMMARY = 'storeReport/summary';
export const FETCH_SUMMARY_SUCCESS = 'storeReport/summary_success';
export const FETCH_SUMMARY_FAILED = 'storeReport/summary_failed';

export const FETCH_DETAILS = 'storeReport/details';
export const FETCH_DETAILS_SUCCESS = 'storeReport/details_success';
export const FETCH_DETAILS_FAILED = 'storeReport/details_failed';

export const FETCH_REPORT_DETAILS = 'storeReport/report_details';
export const FETCH_REPORT_DETAILS_SUCCESS = 'storeReport/report_details_success';
export const FETCH_REPORT_DETAILS_FAILED = 'storeReport/report_details_failed';

export const FETCH_REPORT_SUMMARY = 'storeReport/report_summary_details';
export const FETCH_REPORT_SUMMARY_SUCCESS = 'storeReport/report_summary_details_success';
export const FETCH_REPORT_SUMMARY_FAILED = 'storeReport/report_summary_details_failed';

export const CLEAR_DATA = 'storeReport/clear_data';

export type IncomeAction =
  | typeof FETCH_SUMMARY
  | typeof FETCH_SUMMARY_FAILED
  | typeof FETCH_SUMMARY_SUCCESS
  | typeof FETCH_DETAILS
  | typeof FETCH_DETAILS_FAILED
  | typeof FETCH_DETAILS_SUCCESS
  | typeof FETCH_REPORT_DETAILS
  | typeof FETCH_REPORT_DETAILS_SUCCESS
  | typeof FETCH_REPORT_DETAILS_FAILED
  | typeof FETCH_REPORT_SUMMARY
  | typeof FETCH_REPORT_SUMMARY_SUCCESS
  | typeof FETCH_REPORT_SUMMARY_FAILED
  | typeof CLEAR_DATA;
