import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';
import {
  CategoryListRequest,
  CategoryListResponse,
  CategoryDeleteResponse,
  CategoryUpdateResponse,
  CategoryCreateResponse,
  CategoryDetailResponse,
  CategoryBulkRequest,
  CategoryBulkResponse,
} from '../../api-models/category';
import { Category } from 'src/state/models/category';

// Create the set of async actions
export const getCategoryList = createAsyncAction(types.FETCH_LIST, types.FETCH_LIST_SUCCESS, types.FETCH_LIST_FAILED)<
  CategoryListRequest,
  CategoryListResponse,
  any
>();

// Create the set of async actions
export const getCategoryDetails = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<string, CategoryDetailResponse, any>();

// Create the set of async actions
export const createCategory = createAsyncAction(types.CREATE, types.CREATE_SUCCESS, types.CREATE_FAILED)<
  Category,
  CategoryCreateResponse,
  any
>();

// Create the set of async actions
export const updateCategory = createAsyncAction(types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILED)<
  Category,
  CategoryUpdateResponse,
  any
>();

// Create the set of async actions
export const deleteCategory = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  string,
  CategoryDeleteResponse,
  any
>();

// Create the set of async actions
export const bulkCategory = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  CategoryBulkRequest,
  CategoryBulkResponse,
  any
>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
