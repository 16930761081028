import {
  SaleBulkResponse,
  SaleChangeEmployeeResponse,
  SaleChangeOrderAtResponse,
  SaleClearTrashedResponse,
  SaleDeleteResponse,
  SaleDetailResponse,
  SaleInvoiceResponse,
  SaleRefundDetailResponse,
  SaleRefundResponse,
  SaleRestoreResponse,
  SaleSaveAllChangesResponse,
  SaleUpdateStatusResponse,
  SaleUpdateTipResponse,
  SaleVoidResponse,
} from 'src/state/api-models/sale';

import { BaseState } from '../models';

export type SaleDetail = BaseState<SaleDetailResponse>;
export type SaleInvoiceState = BaseState<SaleInvoiceResponse>;
export type SaleUpdateStatus = BaseState<SaleUpdateStatusResponse>;
export type SaleDelete = BaseState<SaleDeleteResponse>;
export type SaleBulk = BaseState<SaleBulkResponse>;
export type SaleSaveAllChanges = BaseState<SaleSaveAllChangesResponse>;
export type SaleTip = BaseState<SaleUpdateTipResponse>;
export type SaleChangeEmployee = BaseState<SaleChangeEmployeeResponse>;
export type SaleChangeOrderAt = BaseState<SaleChangeOrderAtResponse>;
export type SaleRefund = BaseState<SaleRefundResponse>;
export type SaleRefundDetail = BaseState<SaleRefundDetailResponse>;
export type SaleVoid = BaseState<SaleVoidResponse>;
export type SaleRestore = BaseState<SaleRestoreResponse>;
export type SaleClearTrashed = BaseState<SaleClearTrashedResponse>;
export type SaleClearAllTrashed = BaseState<SaleClearTrashedResponse>;

export type SaleMgntStateAll = {
  details: SaleDetail;
  invoice: SaleInvoiceState;

  status: SaleUpdateStatus;
  refundDetail: SaleRefundDetail;
  refund: SaleRefund;
  void: SaleVoid;
  delete: SaleDelete;
  bulk: SaleBulk;
  saveAllChanges: SaleSaveAllChanges;
  tip: SaleTip;
  changeEmployee: SaleChangeEmployee;
  changeOrderAt: SaleChangeOrderAt;
  restore: SaleRestore;
  clearTrashed: SaleClearTrashed;
  clearAllTrashed: SaleClearAllTrashed;
};

export const initialSaleState: SaleMgntStateAll = {
  details: { loading: false, response: undefined },
  invoice: { loading: false, response: undefined },
  status: { loading: false, response: undefined },
  refund: { loading: false, response: undefined },
  void: { loading: false, response: undefined },
  refundDetail: { loading: false, response: undefined },
  tip: { loading: false, response: undefined },
  changeEmployee: { loading: false, response: undefined },
  changeOrderAt: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
  saveAllChanges: { loading: false, response: undefined },
  restore: { loading: false, response: undefined },
  clearTrashed: { loading: false, response: undefined },
  clearAllTrashed: { loading: false, response: undefined },
};

export const initialStateAll: SaleMgntStateAll = initialSaleState;

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    saleMgnt: SaleMgntStateAll;
  }
}
