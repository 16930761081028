import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import parse from 'html-react-parser';
// import { Alert } from 'reactstrap';

export type MessageType = 'info' | 'success' | 'warning' | 'error' | 'default';
// const ALERT_COLOR_MAP = {
//   default: 'primary',
//   success: 'success',
//   error: 'danger',
//   info: 'info',
//   warning: 'warning',
// };

const TOAST_OPTIONS: ToastOptions = {
  autoClose: 5000,
  // position: 'bottom-center',
  // hideProgressBar: true,
};

// const TOAST_CONTENT_STYLE: React.CSSProperties = { textAlign: 'center' };

export const showToastMessage = (
  messageContent: string = '',
  messageType: MessageType = 'default',
  options?: ToastOptions
) => {
  const ToastContent = () => <>{parse(messageContent)}</>;
  // return <Alert color={ALERT_COLOR_MAP[messageType]}>{messageContent}</Alert>;
  // return <div style={TOAST_CONTENT_STYLE}>{messageContent}</div>;
  switch (messageType) {
    case 'success':
      toast.success(<ToastContent />, { ...TOAST_OPTIONS, ...options });
      break;
    case 'error':
      toast.error(<ToastContent />, { ...TOAST_OPTIONS, ...options });
      break;
    case 'info':
      toast.info(<ToastContent />, { ...TOAST_OPTIONS, ...options });
      break;
    case 'warning':
      toast.warn(<ToastContent />, { ...TOAST_OPTIONS, ...options });
      break;
    default:
      toast(<ToastContent />, { ...TOAST_OPTIONS, ...options });
  }
};

export const removeAllToasts = () =>
  // Remove all toasts !
  toast.dismiss();
