import { createAsyncAction, createAction } from 'typesafe-actions';
import { SmsTemplate } from 'src/state/models/sms';
import {
  SmsTemplateListResponse,
  SmsTemplateDetailResponse,
  SmsTemplateDeleteResponse,
  SmsTemplateUpdateResponse,
  SmsTemplateCreateResponse,
  SmsTemplateBulkResponse,
  SmsTemplateListRequest,
  SmsTemplateBulkRequest,
} from 'src/state/api-models/sms-template';
import * as types from './types';

// Create the set of async actions
export const getSmsTemplateList = createAsyncAction(
  types.FETCH_LIST,
  types.FETCH_LIST_SUCCESS,
  types.FETCH_LIST_FAILED
)<SmsTemplateListRequest, SmsTemplateListResponse, any>();

// Create the set of async actions
export const getSmsTemplateDetails = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<SmsTemplate['id'] | undefined, SmsTemplateDetailResponse, any>();

// Create the set of async actions
export const createSmsTemplate = createAsyncAction(types.CREATE, types.CREATE_SUCCESS, types.CREATE_FAILED)<
  SmsTemplate,
  SmsTemplateCreateResponse,
  any
>();

// Create the set of async actions
export const updateSmsTemplate = createAsyncAction(types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILED)<
  SmsTemplate,
  SmsTemplateUpdateResponse,
  any
>();

// Create the set of async actions
export const deleteSmsTemplate = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  SmsTemplate['id'],
  SmsTemplateDeleteResponse,
  any
>();

export const bulkSmsTemplates = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  SmsTemplateBulkRequest,
  SmsTemplateBulkResponse,
  any
>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
