import {
  CreatePayrollRequest,
  CreatePayrollResponse,
  DeletePayrollRequest,
  DeletePayrollResponse,
  PayrollListRequest,
  PayrollListResponse,
  PayrollDetailRequest,
  PayrollDetailResponse,
  CalculatePayrollRequest,
  CalculatePayrollResponse,
  UpdatePayrollRequest,
  UpdatePayrollResponse,
  BulkPayrollRequest,
  BulkPayrollResponse,
  PayrollCommissionSummaryRequest,
  PayrollCommissionSummaryResponse,
  PayrollCommissionDetailListRequest,
  PayrollCommissionDetailListResponse,
} from 'src/state/api-models/payroll';
import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';

export const getPayrollList = createAsyncAction(
  types.PAYROLL_LIST,
  types.PAYROLL_LIST_SUCCESS,
  types.PAYROLL_LIST_FAILED
)<PayrollListRequest, PayrollListResponse, any>();



export const getPayrollDetails = createAsyncAction(
  types.PAYROLL_DETAIL,
  types.PAYROLL_DETAIL_SUCCESS,
  types.PAYROLL_DETAIL_FAILED
)<PayrollDetailRequest, PayrollDetailResponse, any>();

export const calculatePayoll = createAsyncAction(
  types.CALCULATE_PAYOLL,
  types.CALCULATE_PAYOLL_SUCCESS,
  types.CALCULATE_PAYOLL_FAILED
)<CalculatePayrollRequest, CalculatePayrollResponse, any>();

export const createPayroll = createAsyncAction(
  types.CREATE_PAYROLL,
  types.CREATE_PAYROLL_SUCCESS,
  types.CREATE_PAYROLL_FAILED
)<CreatePayrollRequest, CreatePayrollResponse, any>();

export const updatePayroll = createAsyncAction(
  types.UPDATE_PAYROLL,
  types.UPDATE_PAYROLL_SUCCESS,
  types.UPDATE_PAYROLL_FAILED
)<UpdatePayrollRequest, UpdatePayrollResponse, any>();

export const deletePayroll = createAsyncAction(
  types.DELTE_PAYROLL,
  types.DELTE_PAYROLL_SUCCESS,
  types.DELTE_PAYROLL_FAILED
)<DeletePayrollRequest, DeletePayrollResponse, any>();

export const bulkPayrolls = createAsyncAction(
  types.BULK_PAYROLL,
  types.BULK_PAYROLL_SUCCESS,
  types.BULK_PAYROLL_FAILED
)<BulkPayrollRequest, BulkPayrollResponse, any>();

// Create the set of async actions
export const getPayrollCommissionReport = createAsyncAction(
  types.FETCH_PAYROLL_COMMISSION,
  types.FETCH_PAYROLL_COMMISSION_SUCCESS,
  types.FETCH_PAYROLL_COMMISSION_FAILED
)<PayrollCommissionSummaryRequest, PayrollCommissionSummaryResponse, any>();

// Create the set of async actions
export const getPayrollCommissionSummary = createAsyncAction(
  types.FETCH_PAYROLL_COMMISSION_SUMMARY,
  types.FETCH_PAYROLL_COMMISSION_SUMMARY_SUCCESS,
  types.FETCH_PAYROLL_COMMISSION_SUMMARY_FAILED
)<PayrollCommissionSummaryRequest, PayrollCommissionSummaryResponse, any>();

// Create the set of async actions
export const getPayrollCommissionDetails = createAsyncAction(
  types.FETCH_PAYROLL_COMMISSION_DETAILS,
  types.FETCH_PAYROLL_COMMISSION_DETAILS_SUCCESS,
  types.FETCH_PAYROLL_COMMISSION_DETAILS_FAILED
)<PayrollCommissionDetailListRequest, PayrollCommissionDetailListResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
