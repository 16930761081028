import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

//import Backend from 'i18next-http-backend';
//import LanguageDetector from 'i18next-browser-languagedetector';
import { getLocaleSettings } from 'src/utils/locale';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';

const localeSettings = getLocaleSettings();

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  //.use(Backend)

  //learn more: https://github.com/i18next/i18next-chained-backend
  .use(ChainedBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
      // learn more: https://github.com/i18next/i18next-localstorage-backend
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [
        {
          expirationTime: 1 * 24 * 60 * 60 * 1000, // 1 days
        },
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
      ],
    },
    load: 'languageOnly',
    lng: (localeSettings && localeSettings.language) || 'en',
    fallbackLng: 'en',
    debug: false,
    ns: ['common', 'navigation'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      prefix: '{{',
      suffix: '}}',
    },
  });

export default i18n;
