export const SERVICE = {
  List: '/services',
  Create: '/services',
  Details: '/services/{id}',
  Update: '/services/{id}',
  Ordering: '/services/{id}/ordering',
  Delete: '/services/{id}',
  Bulk: '/services/bulk',
  IMPORT: 'services/import',

  PosList: '/pos/services',
  PosWalkinServices: '/pos/services/walkin-services',
};
