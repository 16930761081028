import { BaseState } from 'src/state/ducks/models';
import {
  SmsTemplateListResponse,
  SmsTemplateDetailResponse,
  SmsTemplateDeleteResponse,
  SmsTemplateUpdateResponse,
  SmsTemplateCreateResponse,
  SmsTemplateBulkResponse,
} from 'src/state/api-models/sms-template';

export type SmsTemplateDetails = BaseState<SmsTemplateDetailResponse>;
export type SmsTemplateList = BaseState<SmsTemplateListResponse>;
export type SmsTemplateCreate = BaseState<SmsTemplateCreateResponse>;
export type SmsTemplateUpdate = BaseState<SmsTemplateUpdateResponse>;
export type SmsTemplateDelete = BaseState<SmsTemplateDeleteResponse>;
export type SmsTemplateBulk = BaseState<SmsTemplateBulkResponse>;

export type SmsTemplateState = {
  list: SmsTemplateList;
  details: SmsTemplateDetails;
  create: SmsTemplateCreate;
  update: SmsTemplateUpdate;
  delete: SmsTemplateDelete;
  bulk: SmsTemplateBulk;
};

export const initialSmsTemplateState = {
  list: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
};

declare module 'src/state/ducks/models' {
  interface StateAll {
    // match to a duck folder
    'sms/template': SmsTemplateState;
  }
}
