import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { initialProductState, ProductState } from './model';

const product = produce((draft: Draft<ProductState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getProductList.request): {
      draft.list.loading = true;
      return draft;
    }
    case getType(actions.getProductList.success): {
      draft.list.loading = true;
      draft.list.response = action.payload;
      return draft;
    }
    case getType(actions.getProductList.failure): {
      draft.list.loading = false;
      draft.list.errors = action.payload;
      return draft;
    }
    case getType(actions.getProductDetails.request): {
      draft.details.loading = true;
      draft.details.response = undefined;
      draft.details.errors = undefined;
      return draft;
    }
    case getType(actions.getProductDetails.success): {
      draft.details.loading = true;
      draft.details.response = action.payload;

      return draft;
    }
    case getType(actions.getProductDetails.failure): {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    case getType(actions.importProduct.request): {
      draft.import.loading = true;
      draft.import.response = undefined;
      draft.import.errors = undefined;
      return draft;
    }
    case getType(actions.importProduct.success): {
      draft.import.loading = false;
      draft.import.response = action.payload;
      return draft;
    }
    case getType(actions.importProduct.failure): {
      draft.import.loading = false;
      draft.import.errors = action.payload;
      return draft;
    }
    case getType(actions.createProduct.request): {
      draft.create.loading = true;
      return draft;
    }
    case getType(actions.createProduct.success): {
      draft.create.loading = false;
      draft.create.response = action.payload;
      return draft;
    }
    case getType(actions.createProduct.failure): {
      draft.create.loading = false;
      draft.create.errors = action.payload;
      return draft;
    }
    case getType(actions.updateProduct.request): {
      draft.update.loading = true;
      return draft;
    }
    case getType(actions.updateProduct.success): {
      draft.update.loading = false;
      draft.update.response = action.payload;
      return draft;
    }
    case getType(actions.updateProduct.failure): {
      draft.update.loading = false;
      draft.update.errors = action.payload;
      return draft;
    }
    case getType(actions.updateOrdering.request): {
      draft.ordering.loading = true;
      return draft;
    }
    case getType(actions.updateOrdering.success): {
      draft.ordering.loading = false;
      draft.ordering.response = action.payload;
      return draft;
    }
    case getType(actions.updateOrdering.failure): {
      draft.ordering.loading = false;
      draft.ordering.errors = action.payload;
      return draft;
    }
    case getType(actions.deleteProduct.request): {
      draft.delete.loading = true;
      return draft;
    }
    case getType(actions.deleteProduct.success): {
      draft.delete.loading = false;
      draft.delete.response = action.payload;
      return draft;
    }
    case getType(actions.deleteProduct.failure): {
      draft.delete.loading = false;
      draft.delete.errors = action.payload;
      return draft;
    }
    case getType(actions.bulkProduct.request): {
      draft.bulk.loading = true;
      return draft;
    }
    case getType(actions.bulkProduct.success): {
      draft.bulk.loading = false;
      draft.bulk.response = action.payload;
      return draft;
    }
    case getType(actions.bulkProduct.failure): {
      draft.bulk.loading = false;
      draft.bulk.errors = action.payload;
      return draft;
    }
    case getType(actions.createInventory.request): {
      draft.inventoryCreate.loading = true;
      return draft;
    }
    case getType(actions.createInventory.success): {
      draft.inventoryCreate.loading = false;
      draft.inventoryCreate.response = action.payload;
      return draft;
    }
    case getType(actions.createInventory.failure): {
      draft.inventoryCreate.loading = false;
      draft.inventoryCreate.errors = action.payload;
      return draft;
    }
    case getType(actions.getInventory.request): {
      draft.inventoryList.loading = true;
      return draft;
    }
    case getType(actions.getInventory.success): {
      draft.inventoryList.loading = false;
      draft.inventoryList.response = action.payload;
      return draft;
    }
    case getType(actions.getInventory.failure): {
      draft.inventoryList.loading = false;
      draft.inventoryList.errors = action.payload;
      return draft;
    }
    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialProductState;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialProductState);

export default product;
