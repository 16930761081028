export const FETCH_INCOME_SUMMARY = 'income_working_time/summary_income_working_times';
export const FETCH_INCOME_SUMMARY_SUCCESS = 'income_working_time/summary_income_working_times_success';
export const FETCH_INCOME_SUMMARY_FAILED = 'income_working_time/summary_income_working_times_failed';

export const FETCH_INCOME_DETAILS = 'income_working_time/details_income_working_times';
export const FETCH_INCOME_DETAILS_SUCCESS = 'income_working_time/details_income_working_times_success';
export const FETCH_INCOME_DETAILS_FAILED = 'income_working_time/details_income_working_times_failed';

export const FETCH_REPORT_SUMMARY = 'income_working_time/report_summary';
export const FETCH_REPORT_SUMMARY_SUCCESS = 'income_working_time/report_summary_success';
export const FETCH_REPORT_SUMMARY_FAILED = 'income_working_time/report_summary_failed';

export const FETCH_REPORT_DETAILS = 'income_working_time/report_details';
export const FETCH_REPORT_DETAILS_SUCCESS = 'income_working_time/report_details_success';
export const FETCH_REPORT_DETAILS_FAILED = 'income_working_time/report_details_failed';

export const FETCH_REPORT_FULL_DETAILS = 'income_working_time/report_full_details';
export const FETCH_REPORT_FULL_DETAILS_SUCCESS = 'income_working_time/report_full_details_success';
export const FETCH_REPORT_FULL_DETAILS_FAILED = 'income_working_time/report_full_details_failed';


export const CLEAR_DATA = 'income_working_time/clear_data';

export type IncomeAction =
  | typeof FETCH_INCOME_SUMMARY
  | typeof FETCH_INCOME_SUMMARY_FAILED
  | typeof FETCH_INCOME_SUMMARY_SUCCESS
  | typeof FETCH_INCOME_DETAILS
  | typeof FETCH_INCOME_DETAILS_FAILED
  | typeof FETCH_INCOME_DETAILS_SUCCESS
  | typeof FETCH_REPORT_SUMMARY
  | typeof FETCH_REPORT_SUMMARY_SUCCESS
  | typeof FETCH_REPORT_SUMMARY_FAILED
  | typeof FETCH_REPORT_DETAILS
  | typeof FETCH_REPORT_DETAILS_SUCCESS
  | typeof FETCH_REPORT_DETAILS_FAILED
  | typeof FETCH_REPORT_FULL_DETAILS
  | typeof FETCH_REPORT_FULL_DETAILS_SUCCESS
  | typeof FETCH_REPORT_FULL_DETAILS_FAILED
  | typeof CLEAR_DATA;
