import { BaseState } from 'src/state/ducks/models';
import {
  SmsLogSummaryResponse,
  SmsLogDetailResponse,
  SmsLogDeleteResponse,
  SmsLogBulkResponse,
} from 'src/state/api-models/sms-logs';

export type SmsLogSummary = BaseState<SmsLogSummaryResponse>;
export type SmsLogDetails = BaseState<SmsLogDetailResponse>;
export type SmsLogDelete = BaseState<SmsLogDeleteResponse>;
export type SmsLogBulk = BaseState<SmsLogBulkResponse>;

export type SmsLogsState = {
  summary: SmsLogSummary;
  details: SmsLogDetails;
  delete: SmsLogDelete;
  bulk: SmsLogBulk;
};

export const initialState = {
  summary: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
};

declare module 'src/state/ducks/models' {
  interface StateAll {
    // match to a duck folder
    'sms/logs': SmsLogsState;
  }
}
