import { createAsyncAction, createAction } from 'typesafe-actions';
import {
  ReportWorkingTimencomeRequest,
  ReportWorkingTimencomeResponse,
} from 'src/state/api-models/income-working-time';
import * as types from './types';
import { IncomeListRequest, IncomeListResponse } from '../../api-models/income';

// Create the set of async actions
export const getIncomeSummaryList = createAsyncAction(
  types.FETCH_INCOME_SUMMARY,
  types.FETCH_INCOME_SUMMARY_SUCCESS,
  types.FETCH_INCOME_SUMMARY_FAILED
)<IncomeListRequest, IncomeListResponse, any>();

// Create the set of async actions
export const getIncomeDetailList = createAsyncAction(
  types.FETCH_INCOME_DETAILS,
  types.FETCH_INCOME_DETAILS_SUCCESS,
  types.FETCH_INCOME_DETAILS_FAILED
)<IncomeListRequest, IncomeListResponse, any>();

// Create the set of async actions
export const getReportSummary = createAsyncAction(
  types.FETCH_REPORT_SUMMARY,
  types.FETCH_REPORT_SUMMARY_SUCCESS,
  types.FETCH_REPORT_SUMMARY_FAILED
)<ReportWorkingTimencomeRequest, ReportWorkingTimencomeResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
