import { createAsyncAction, createAction } from 'typesafe-actions';
import { EmarketingCampaign } from 'src/state/models/emarketing';
import {
  EmarketingCampaignListResponse,
  EmarketingCampaignDetailResponse,
  EmarketingCampaignDeleteResponse,
  EmarketingCampaignUpdateResponse,
  EmarketingCampaignCreateResponse,
  EmarketingCampaignBulkResponse,
  EmarketingCampaignListRequest,
  EmarketingCampaignBulkRequest,
  EmarketingCampaignUpdateRequest,
  EmarketingCampaignUpdateStatusRequest,
  EmarketingCampaignUpdateStatusResponse,
} from 'src/state/api-models/emarketing-campaign';
import * as types from './types';

// Create the set of async actions
export const getList = createAsyncAction(types.FETCH_LIST, types.FETCH_LIST_SUCCESS, types.FETCH_LIST_FAILED)<
  EmarketingCampaignListRequest,
  EmarketingCampaignListResponse,
  any
>();

// Create the set of async actions
export const getDetails = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<EmarketingCampaign['id'] | undefined, EmarketingCampaignDetailResponse, any>();

// Create the set of async actions
export const createData = createAsyncAction(types.CREATE, types.CREATE_SUCCESS, types.CREATE_FAILED)<
  EmarketingCampaign,
  EmarketingCampaignCreateResponse,
  any
>();

// Create the set of async actions
export const updateData = createAsyncAction(types.UPDATE, types.UPDATE_SUCCESS, types.UPDATE_FAILED)<
  EmarketingCampaignUpdateRequest,
  EmarketingCampaignUpdateResponse,
  any
>();

// Create the set of async actions
export const updateStatus = createAsyncAction(
  types.UPDATE_STATUS,
  types.UPDATE_STATUS_SUCCESS,
  types.UPDATE_STATUS_FAILED
)<EmarketingCampaignUpdateStatusRequest, EmarketingCampaignUpdateStatusResponse, any>();

// Create the set of async actions
export const deleteData = createAsyncAction(types.DELETE, types.DELETE_SUCCESS, types.DELETE_FAILED)<
  EmarketingCampaign['id'],
  EmarketingCampaignDeleteResponse,
  any
>();

export const bulkDatas = createAsyncAction(types.BULK, types.BULK_SUCCESS, types.BULK_FAILED)<
  EmarketingCampaignBulkRequest,
  EmarketingCampaignBulkResponse,
  any
>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
