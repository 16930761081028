import { createSelector } from 'reselect';
import { StateAll } from '../../models';
import { AuthState, initialAuthState, LoginState } from './model';

const rootSelector = (state: StateAll) => state['common/auth'] || initialAuthState;

const loginSelector = (state: StateAll) => {
  const authState = rootSelector(state);
  return authState.login;
};

export const getLoginResponse = createSelector(loginSelector, (state: LoginState) => state && state.response);

export const getUpdateProfileResponse = createSelector(
  rootSelector,
  (state: AuthState) => state && state.profile && state.profile.response
);

export const isAuthenticated = createSelector(
  getLoginResponse,
  (state) => state && state.success && state.data && state.data.token && state.data.user
);

export const getToken = createSelector(getLoginResponse, (state) => state && state.data && state.data.token);

export const getUser = createSelector(getLoginResponse, (state) => state && state.data && state.data.user);

export const getLoginStoreId = createSelector(
  getLoginResponse,
  (state) => state && state.data && state.data.user && state.data.user.login_store_id
);
export const getLoginWorkstationId = createSelector(
  getLoginResponse,
  (state) => state && state.data && state.data.user && state.data.user.login_workstation_id
);

export const getLoginModules = createSelector(getLoginResponse, (state) => {
  const data = state && state.data;

  if (data && data.user && data.permissions) {
    return data.permissions.modules || [];
    // const { user, owner, modules } = data;

    // const loginEnableModules = getRoleModules(user.role_id);

    // if (owner) {
    //   const planDisableModules = getDisableModulesByOwnerPlan(owner);
    //   return loginEnableModules.filter((item) => planDisableModules.indexOf(item) < 0);
    // }

    // return loginEnableModules;
  }

  return [];
});

export const getPermissions = createSelector(getLoginResponse, (state) => {
  const data = state && state.data;

  return (data && data.user && data.permissions) || {};
});

export const getLoginMaximumEmployees = createSelector(getLoginResponse, (state) => {
  const data = state && state.data;

  if (data && data.user && data.permissions) {
    return data.permissions.maximumEmployees;
    //return getMaximumEmployees(data.owner);
  }

  return 0;
});

export const getCheckPinCodeResponse = createSelector(rootSelector, (state: AuthState) => state && state.checkPinCode);
