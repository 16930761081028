import { put, call, all, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from 'src/utils/axios/axios-instance';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import * as actions from './actions';
import { convertToFormData } from 'src/utils/converter';
import { removeInitialProducts } from 'src/utils/axios/axios-fecth';

// Handle request saga
function* getServiceList(action: ReturnType<typeof actions.getServiceList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.SERVICE.List, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getServiceList.success((response as any).data));
  } catch (err) {
    yield put(actions.getServiceList.failure(err));
  }
}

function* getActiveServiceList(action: ReturnType<typeof actions.getActiveServiceList.request>): Generator {
  try {
    const { filter, sort, pagination } = action.payload;
    const response = yield call(axiosInstance.get, API_ENDPOINTS.SERVICE.PosList, {
      params: { sort, ...filter, ...pagination },
    });

    yield put(actions.getActiveServiceList.success((response as any).data));
  } catch (err) {
    yield put(actions.getActiveServiceList.failure(err));
  }
}

function* getServiceDetails(action: ReturnType<typeof actions.getServiceDetails.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.SERVICE.Details.replace('{id}', action.payload));

    yield put(actions.getServiceDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getServiceDetails.failure(err));
  }
}

function* createService(action: ReturnType<typeof actions.createService.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.SERVICE.Create, action.payload, config));

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.createService.success((response as any).data));
  } catch (err) {
    yield put(actions.createService.failure(err));
  }
}

function* updateService(action: ReturnType<typeof actions.updateService.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const id = action.payload.id;
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.SERVICE.Update.replace('{id}', id), action.payload, config)
    );

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.updateService.success((response as any).data));
  } catch (err) {
    yield put(actions.updateService.failure(err));
  }
}

function* updateOrdering(action: ReturnType<typeof actions.updateOrdering.request>): Generator {
  try {
    const id = action.payload.id.toString();
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.SERVICE.Ordering.replace('{id}', id), action.payload)
    );

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.updateOrdering.success((response as any).data));
  } catch (err) {
    yield put(actions.updateOrdering.failure(err));
  }
}

function* deleteService(action: ReturnType<typeof actions.deleteService.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.delete(API_ENDPOINTS.SERVICE.Delete.replace('{id}', action.payload))
    );

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.deleteService.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteService.failure(err));
  }
}

function* importService(action: ReturnType<typeof actions.importService.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };

    const response = yield call(axiosInstance.post, API_ENDPOINTS.SERVICE.IMPORT, action.payload, config);
    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.importService.success((response as any).data));
  } catch (err) {
    yield put(actions.importService.failure(err));
  }
}

function* bulkService(action: ReturnType<typeof actions.bulkService.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.SERVICE.Bulk, action.payload);

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.bulkService.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkService.failure(err));
  }
}

// Main saga
export default function* serviceSaga() {
  yield all([
    takeLatest(actions.getServiceList.request, getServiceList),
    takeLatest(actions.getActiveServiceList.request, getActiveServiceList),
    takeLatest(actions.getServiceDetails.request, getServiceDetails),
    takeLatest(actions.createService.request, createService),
    takeLatest(actions.updateService.request, updateService),
    takeLatest(actions.updateOrdering.request, updateOrdering),
    takeLatest(actions.deleteService.request, deleteService),
    takeLatest(actions.importService.request, importService),
    takeLatest(actions.bulkService.request, bulkService),
  ]);
}
