import { BaseState } from '../models';
import {
  CustomerListResponse,
  CustomerDetailResponse,
  CustomerDeleteResponse,
  CustomerUpdateResponse,
  CustomerCreateResponse,
  CustomerBulkResponse,
  CustomerHistoryPointResponse,
  CustomerReviewListResponse,
  CustomerReviewResponse,
  CustomerImportResponse,
  CheckMembershipResponse,
} from '../../api-models/customer';
import { InputSearchCustomer } from '../../models/customer';

export type CustomerDetails = BaseState<CustomerDetailResponse> & {};
export type CustomerList = BaseState<CustomerListResponse> & {};
export type CustomerCreate = BaseState<CustomerCreateResponse> & {};
export type CustomerUpdate = BaseState<CustomerUpdateResponse> & {};
export type CustomerDelete = BaseState<CustomerDeleteResponse> & {};
export type CustomerBulk = BaseState<CustomerBulkResponse> & {};
export type CustomerImport = BaseState<CustomerImportResponse> & {};
export type CustomerInputSearch = InputSearchCustomer;
export type CustomersHistoryPoint = BaseState<CustomerHistoryPointResponse> & {};
export type CustomerReviewList = BaseState<CustomerReviewListResponse> & {};
export type CustomerReview = BaseState<CustomerReviewResponse> & {};
export type CheckMembership = BaseState<CheckMembershipResponse> & {};

export type CustomerState = {
  list: CustomerList;
  details: CustomerDetails;
  create: CustomerCreate;
  createInPos: CustomerCreate;
  update: CustomerUpdate;
  delete: CustomerDelete;
  bulk: CustomerBulk;
  import: CustomerImport;
  paraSearch: CustomerInputSearch;
  listHistoryPoint: CustomersHistoryPoint;
  review: CustomerReview;
  listReviews: CustomerReviewList;
  checkMembership: CheckMembership;
};

export const initialCustomerState: CustomerState = {
  list: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  createInPos: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  delete: { loading: false, response: undefined },
  bulk: { loading: false, response: undefined },
  import: { loading: false, response: undefined },
  paraSearch: {},
  listHistoryPoint: { loading: false, response: undefined },
  review: { loading: false, response: undefined },
  listReviews: { loading: false, response: undefined },
  checkMembership: { loading: false, response: undefined },
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    // match to reducer name, each reducer will be one field in state
    customer: CustomerState;
  }
}
