export const FETCH_DETAILS = 'coupon/details';
export const FETCH_DETAILS_SUCCESS = 'coupon/details_success';
export const FETCH_DETAILS_FAILED = 'coupon/details_failed';

export const FETCH_LIST = 'coupon/list';
export const FETCH_LIST_SUCCESS = 'coupon/list_success';
export const FETCH_LIST_FAILED = 'coupon/list_failed';

export const CREATE = 'coupon/create';
export const CREATE_SUCCESS = 'coupon/create_success';
export const CREATE_FAILED = 'coupon/create_failed';

export const UPDATE = 'coupon/update';
export const UPDATE_SUCCESS = 'coupon/update_success';
export const UPDATE_FAILED = 'coupon/update_failed';

export const DELETE = 'coupon/detele';
export const DELETE_SUCCESS = 'coupon/detele_success';
export const DELETE_FAILED = 'coupon/detele_failed';

export const BULK = 'coupon/bulk';
export const BULK_SUCCESS = 'coupon/bulk_success';
export const BULK_FAILED = 'coupon/bulk_failed';

export const GET_COUPON_CODE = 'coupon/get_coupon_code';
export const GET_COUPON_CODE_SUCCESS = 'coupon/get_coupon_code_success';
export const GET_COUPON_CODE_FAILED = 'coupon/get_coupon_code_failed';

export const HISTORY = 'coupon/history';
export const HISTORY_SUCCESS = 'coupon/history_success';
export const HISTORY_FAILED = 'coupon/history_failed';

export const CLEAR_DATA = 'coupon/clear_data';

export type CouponAction =
  | typeof FETCH_DETAILS
  | typeof FETCH_DETAILS_FAILED
  | typeof FETCH_DETAILS_SUCCESS
  | typeof FETCH_LIST
  | typeof FETCH_LIST_FAILED
  | typeof FETCH_LIST_SUCCESS
  | typeof CREATE
  | typeof CREATE_FAILED
  | typeof CREATE_SUCCESS
  | typeof UPDATE
  | typeof UPDATE_FAILED
  | typeof UPDATE_SUCCESS
  | typeof DELETE
  | typeof DELETE_FAILED
  | typeof DELETE_SUCCESS
  | typeof BULK
  | typeof BULK_FAILED
  | typeof BULK_SUCCESS
  | typeof CLEAR_DATA;
