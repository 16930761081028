import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';
import { IncomeListRequest, IncomeListResponse } from '../../api-models/income';

// Create the set of async actions
export const getSummaryList = createAsyncAction(
  types.FETCH_SUMMARY,
  types.FETCH_SUMMARY_SUCCESS,
  types.FETCH_SUMMARY_FAILED
)<IncomeListRequest, IncomeListResponse, any>();

export const printSummaryList = createAsyncAction(
  types.FETCH_PRINT_SUMMARY,
  types.FETCH_PRINT_SUMMARY_SUCCESS,
  types.FETCH_PRINT_SUMMARY_FAILED
)<IncomeListRequest, IncomeListResponse, any>();

// Create the set of async actions
export const getDetailList = createAsyncAction(
  types.FETCH_DETAILS,
  types.FETCH_DETAILS_SUCCESS,
  types.FETCH_DETAILS_FAILED
)<IncomeListRequest, IncomeListResponse, any>();

// Create the set of async actions
export const printDetailList = createAsyncAction(
  types.FETCH_PRINT_DETAILS,
  types.FETCH_PRINT_DETAILS_SUCCESS,
  types.FETCH_PRINT_DETAILS_FAILED
)<IncomeListRequest, IncomeListResponse, any>();

export const clearData = createAction(types.CLEAR_DATA)<string | void>();
