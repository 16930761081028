import { put, call, all, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';
import { API_ENDPOINTS } from '../../../config/api-endpoints/product';
import { convertToFormData } from 'src/utils/converter';
import { removeInitialProducts } from 'src/utils/axios/axios-fecth';

// Handle request saga
function* getProductList(action: ReturnType<typeof actions.getProductList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.PRODUCT.List, {
      params: action.payload,
    });

    yield put(actions.getProductList.success((response as any).data));
  } catch (err) {
    yield put(actions.getProductList.failure(err));
  }
}

function* getProductDetails(action: ReturnType<typeof actions.getProductDetails.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.PRODUCT.Details.replace('{id}', action.payload));

    yield put(actions.getProductDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getProductDetails.failure(err));
  }
}

function* createProduct(action: ReturnType<typeof actions.createProduct.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.PRODUCT.Create, action.payload, config));
    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.createProduct.success((response as any).data));
  } catch (err) {
    yield put(actions.createProduct.failure(err));
  }
}

function* updateProduct(action: ReturnType<typeof actions.updateProduct.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const id = action.payload.id;
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.PRODUCT.Update.replace('{id}', id), action.payload, config)
    );

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.updateProduct.success((response as any).data));
  } catch (err) {
    yield put(actions.updateProduct.failure(err));
  }
}

function* updateOrdering(action: ReturnType<typeof actions.updateOrdering.request>): Generator {
  try {
    const id = action.payload.id.toString();
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.PRODUCT.Ordering.replace('{id}', id), action.payload)
    );

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.updateOrdering.success((response as any).data));
  } catch (err) {
    yield put(actions.updateOrdering.failure(err));
  }
}

function* deleteProduct(action: ReturnType<typeof actions.deleteProduct.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.delete(API_ENDPOINTS.PRODUCT.Delete.replace('{id}', action.payload))
    );

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.deleteProduct.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteProduct.failure(err));
  }
}

function* importProduct(action: ReturnType<typeof actions.importProduct.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const response = yield call(axiosInstance.post, API_ENDPOINTS.PRODUCT.IMPORT, action.payload, config);

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.importProduct.success((response as any).data));
  } catch (err) {
    yield put(actions.importProduct.failure(err));
  }
}

function* bulkProduct(action: ReturnType<typeof actions.bulkProduct.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.PRODUCT.Bulk, action.payload);

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.bulkProduct.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkProduct.failure(err));
  }
}

function* getInventoryList(action: ReturnType<typeof actions.getInventory.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.PRODUCT.inventoriesDetail, {
      params: action.payload,
    });

    yield put(actions.getInventory.success((response as any).data));
  } catch (err) {
    yield put(actions.getInventory.failure(err));
  }
}

function* createInventory(action: ReturnType<typeof actions.createInventory.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.PRODUCT.CreateInventory, action.payload));

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.createInventory.success((response as any).data));
  } catch (err) {
    yield put(actions.createInventory.failure(err));
  }
}

// Main saga
export default function* productSaga() {
  yield all([
    takeLatest(actions.getProductList.request, getProductList),
    takeLatest(actions.getProductDetails.request, getProductDetails),
    takeLatest(actions.createProduct.request, createProduct),
    takeLatest(actions.updateProduct.request, updateProduct),
    takeLatest(actions.updateOrdering.request, updateOrdering),
    takeLatest(actions.deleteProduct.request, deleteProduct),
    takeLatest(actions.importProduct.request, importProduct),
    takeLatest(actions.bulkProduct.request, bulkProduct),
    takeLatest(actions.getInventory.request, getInventoryList),
    takeLatest(actions.createInventory.request, createInventory),
  ]);
}
