import moment, { Moment } from 'moment';

export const toDateTime = (value?: string | Date | Moment) => moment(value).format('YYYY-MM-DDTHH:mm:ss');

export const toUTCDateTime = (value?: string | Date | Moment) => moment(value).utc().format('YYYY-MM-DDTHH:mm:ss');

export const toDateTime2 = (value?: string | Date | Moment) => moment(value).format('YYYY-MM-DD HH:mm:ss');

export const toUTCDateTime2 = (value?: string | Date | Moment) => moment(value).utc().format('YYYY-MM-DD HH:mm:ss');

export const toDate = (value?: string | Date | Moment) => moment(value).format('YYYY-MM-DD');

export const toUTCDate = (value?: string | Date | Moment) => moment(value).utc().format('YYYY-MM-DD');

export const toTime = (value?: string | Date | Moment) => moment(value).format('HH:mm:ss');

export const toUTCTime = (value?: string | Date | Moment) => moment(value).utc().format('HH:mm:ss');
