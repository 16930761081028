import { put, call, all, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';
import { API_ENDPOINTS } from '../../../config/api-endpoints/productKit';
import { convertToFormData } from 'src/utils/converter';
import { removeInitialProducts } from 'src/utils/axios/axios-fecth';

// Handle request saga
function* getProductKitList(action: ReturnType<typeof actions.getProductKitList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.PRODUCTKIT.List, {
      params: action.payload,
    });

    yield put(actions.getProductKitList.success((response as any).data));
  } catch (err) {
    yield put(actions.getProductKitList.failure(err));
  }
}

function* getProductKitDetails(action: ReturnType<typeof actions.getProductKitDetails.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.PRODUCTKIT.Details.replace('{id}', action.payload));

    yield put(actions.getProductKitDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getProductKitDetails.failure(err));
  }
}

function* createProductKit(action: ReturnType<typeof actions.createProductKit.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.PRODUCTKIT.Create, action.payload));

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.createProductKit.success((response as any).data));
  } catch (err) {
    yield put(actions.createProductKit.failure(err));
  }
}

function* updateProductKit(action: ReturnType<typeof actions.updateProductKit.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.PRODUCTKIT.Update.replace('{id}', action.payload.id), action.payload)
    );

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.updateProductKit.success((response as any).data));
  } catch (err) {
    yield put(actions.updateProductKit.failure(err));
  }
}

function* deleteProductKit(action: ReturnType<typeof actions.deleteProductKit.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.delete(API_ENDPOINTS.PRODUCTKIT.Delete.replace('{id}', action.payload))
    );

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.deleteProductKit.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteProductKit.failure(err));
  }
}

function* bulkProductKits(action: ReturnType<typeof actions.bulkProductKits.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.PRODUCTKIT.Bulk, action.payload);

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.bulkProductKits.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkProductKits.failure(err));
  }
}

function* importProductKit(action: ReturnType<typeof actions.importProductKit.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };

    const response = yield call(axiosInstance.post, API_ENDPOINTS.PRODUCTKIT.Import, action, config);

    //remove caching local storage
    yield removeInitialProducts();

    yield put(actions.importProductKit.success((response as any).data));
  } catch (err) {
    yield put(actions.importProductKit.failure(err));
  }
}

// Main saga
export default function* productKitSaga() {
  yield all([
    takeLatest(actions.getProductKitList.request, getProductKitList),
    takeLatest(actions.getProductKitDetails.request, getProductKitDetails),
    takeLatest(actions.createProductKit.request, createProductKit),
    takeLatest(actions.updateProductKit.request, updateProductKit),
    takeLatest(actions.deleteProductKit.request, deleteProductKit),
    takeLatest(actions.bulkProductKits.request, bulkProductKits),
    takeLatest(actions.importProductKit.request, importProductKit),
  ]);
}
