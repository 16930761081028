import produce, { Draft } from 'immer';
import { combineReducers } from 'redux';
import * as types from './types';
import { BookingApptState, EmployeeState, initialBookingApptState, initialEmployeeState } from './model';

const bookingAppt = produce((draft: Draft<BookingApptState>, action) => {
  switch (action.type) {
    case types.FETCH_LIST: {
      draft.list.loading = true;
      return draft;
    }
    case types.FETCH_LIST_SUCCESS: {
      draft.list.loading = true;
      draft.list.response = action.payload;
      return draft;
    }
    case types.FETCH_LIST_FAILED: {
      draft.list.loading = false;
      draft.list.errors = action.payload;
      return draft;
    }
    case types.FETCH_LIST_UNASSIGNED: {
      draft.unassigedList.loading = true;
      return draft;
    }
    case types.FETCH_LIST_UNASSIGNED_SUCCESS: {
      draft.unassigedList.loading = true;
      draft.unassigedList.response = action.payload;
      return draft;
    }
    case types.FETCH_LIST_UNASSIGNED_FAILED: {
      draft.unassigedList.loading = false;
      draft.unassigedList.errors = action.payload;
      return draft;
    }
    case types.FETCH_NUMBER_OF_BOOKINGS_IN_DAYS: {
      draft.countInDays.loading = true;
      draft.countInDays.response = undefined;
      draft.countInDays.errors = undefined;
      return draft;
    }
    case types.FETCH_NUMBER_OF_BOOKINGS_IN_DAYS_SUCCESS: {
      draft.countInDays.loading = true;
      draft.countInDays.response = action.payload;
      return draft;
    }
    case types.FETCH_NUMBER_OF_BOOKINGS_IN_DAYS_FAILED: {
      draft.countInDays.loading = false;
      draft.countInDays.errors = action.payload;
      return draft;
    }
    case types.FETCH_TOP_BOOKING_SERVICES: {
      draft.topServices.loading = true;
      return draft;
    }
    case types.FETCH_TOP_BOOKING_SERVICES_SUCCESS: {
      draft.topServices.loading = true;
      draft.topServices.response = action.payload;
      return draft;
    }
    case types.FETCH_TOP_BOOKING_SERVICES_FAILED: {
      draft.topServices.loading = false;
      draft.topServices.errors = action.payload;
      return draft;
    }
    case types.ASSIGN: {
      draft.assign.loading = true;
      return draft;
    }
    case types.ASSIGN_SUCCESS: {
      draft.assign.loading = true;
      draft.assign.response = action.payload;
      return draft;
    }
    case types.ASSIGN_FAILED: {
      draft.assign.loading = false;
      draft.assign.errors = action.payload;
      return draft;
    }
    case types.FETCH_DETAILS: {
      draft.details.loading = true;
      draft.details.response = undefined;
      draft.details.errors = undefined;
      return draft;
    }
    case types.FETCH_DETAILS_SUCCESS: {
      draft.details.loading = true;
      draft.details.response = action.payload;

      return draft;
    }
    case types.FETCH_DETAILS_FAILED: {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    case types.CREATE: {
      draft.details.loading = true;
      return draft;
    }
    case types.CREATE_SUCCESS: {
      draft.create.loading = false;
      draft.create.response = action.payload;
      return draft;
    }
    case types.CREATE_FAILED: {
      draft.create.loading = false;
      draft.create.errors = action.payload;
      return draft;
    }
    case types.UPDATE: {
      draft.update.loading = true;
      return draft;
    }
    case types.UPDATE_SUCCESS: {
      draft.update.loading = false;
      draft.update.response = action.payload;
      return draft;
    }
    case types.UPDATE_FAILED: {
      draft.update.loading = false;
      draft.update.errors = action.payload;
      return draft;
    }
    case types.UPDATE_STATUS: {
      draft.updateStatus.loading = true;
      return draft;
    }
    case types.UPDATE_STATUS_SUCCESS: {
      draft.updateStatus.loading = false;
      draft.updateStatus.response = action.payload;
      return draft;
    }
    case types.UPDATE_STATUS_FAILED: {
      draft.updateStatus.loading = false;
      draft.updateStatus.errors = action.payload;
      return draft;
    }
    case types.UPDATE_COLUMN: {
      draft.updateColumn.loading = true;
      return draft;
    }
    case types.UPDATE_COLUMN_SUCCESS: {
      draft.updateColumn.loading = false;
      draft.updateColumn.response = action.payload;
      return draft;
    }
    case types.UPDATE_COLUMN_FAILED: {
      draft.updateColumn.loading = false;
      draft.updateColumn.errors = action.payload;
      return draft;
    }
    case types.DELETE: {
      draft.delete.loading = true;
      return draft;
    }
    case types.DELETE_SUCCESS: {
      draft.delete.loading = false;
      draft.delete.response = action.payload;
      return draft;
    }
    case types.DELETE_FAILED: {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    case types.BULK: {
      draft.bulk.loading = true;
      draft.bulk.response = undefined;
      return draft;
    }
    case types.BULK_SUCCESS: {
      draft.bulk.loading = false;
      draft.bulk.response = action.payload;
      return draft;
    }
    case types.BULK_FAILED: {
      draft.bulk.loading = false;
      draft.bulk.errors = action.payload;
      return draft;
    }

    case types.INPUTSEARCH: {
      draft.paraSearch = action.payload;
      return draft;
    }
    case types.SET_SERVICEBOOKING: {
      draft.serviceBooking = action.payload;
      return draft;
    }
    case types.REMOVE_SERVICEBOOKING: {
      const index = draft.serviceBooking.findIndex((item) => item.service_id === action.payload.service_id);
      if (index >= 0) {
        draft.serviceBooking.splice(index, 1);
      }
      return draft;
    }
    case types.BOOKINGAPPT_SERVICE: {
      draft.listService.loading = true;
      return draft;
    }
    case types.BOOKINGAPPT_SERVICE_SUCCESS: {
      draft.listService.loading = false;
      draft.listService.response = action.payload;
      return draft;
    }
    case types.BOOKINGAPPT_SERVICE_FAILED: {
      draft.listService.loading = false;
      draft.listService.errors = action.payload;
      return draft;
    }
    case types.BOOKINGAPPT_SEND_SMS: {
      draft.sendSms.loading = true;
      return draft;
    }
    case types.BOOKINGAPPT_SEND_SMS_SUCCESS: {
      draft.sendSms.loading = false;
      draft.sendSms.response = action.payload;
      return draft;
    }
    case types.BOOKINGAPPT_SEND_SMS_FAILED: {
      draft.sendSms.loading = false;
      draft.sendSms.errors = action.payload;
      return draft;
    }
    case types.CLEAR_DATA: {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialBookingApptState;
      }
      return draft;
    }

    default: {
      return draft;
    }
  }
}, initialBookingApptState);

const employee = produce((draft: Draft<EmployeeState>, action) => {
  switch (action.type) {
    case types.FETCH_EMPLOYEE_LIST: {
      draft.list.loading = true;
      return draft;
    }
    case types.FETCH_EMPLOYEE_LIST_SUCCESS: {
      draft.list.loading = true;
      draft.list.response = action.payload;
      return draft;
    }
    case types.FETCH_EMPLOYEE_LIST_FAILED: {
      draft.list.loading = false;
      draft.list.errors = action.payload;
      return draft;
    }

    case types.CLEAR_DATA: {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialEmployeeState;
      }
      return draft;
    }

    default: {
      return draft;
    }
  }
}, initialEmployeeState);

export default combineReducers({
  bookingAppt,
  employee,
});
