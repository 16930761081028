import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { axiosInstance } from 'src/utils/axios/axios-instance';
import * as actions from './actions';

// Handle request saga
function* getPaymentList(action: ReturnType<typeof actions.getPaymentList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.PAYMENT.List, {
      params: action.payload,
    });

    yield put(actions.getPaymentList.success((response as any).data));
  } catch (err) {
    yield put(actions.getPaymentList.failure(err));
  }
}

function* getPaymentStatus(action: ReturnType<typeof actions.getPaymentStatus.request>): Generator {
  try {
    let response: any = {};
    if (action.payload) response = yield call(axiosInstance.post, API_ENDPOINTS.PAYMENT.Status, action.payload);

    yield put(actions.getPaymentStatus.success((response as any).data));
  } catch (err) {
    yield put(actions.getPaymentStatus.failure(err));
  }
}

function* salePayment(action: ReturnType<typeof actions.salePayment.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.PAYMENT.Sale, action.payload);

    yield put(actions.salePayment.success((response as any).data));
  } catch (err) {
    yield put(actions.salePayment.failure(err));
  }
}

function* refundPayment(action: ReturnType<typeof actions.refundPayment.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.PAYMENT.Refund, action.payload);

    yield put(actions.refundPayment.success((response as any).data));
  } catch (err) {
    yield put(actions.refundPayment.failure(err));
  }
}

function* voidPayment(action: ReturnType<typeof actions.voidPayment.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.PAYMENT.Void, action.payload);

    yield put(actions.voidPayment.success((response as any).data));
  } catch (err) {
    yield put(actions.voidPayment.failure(err));
  }
}

function* tipAdjustPayment(action: ReturnType<typeof actions.tipAdjustPayment.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.PAYMENT.TipAdjust, action.payload);

    yield put(actions.tipAdjustPayment.success((response as any).data));
  } catch (err) {
    yield put(actions.tipAdjustPayment.failure(err));
  }
}

function* bulkPayments(action: ReturnType<typeof actions.bulkPayments.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.PAYMENT.Bulk, action.payload);

    yield put(actions.bulkPayments.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkPayments.failure(err));
  }
}

function* paymentMomo(action: ReturnType<typeof actions.paymentMomo.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.PAYMENT.Momo, action.payload);

    yield put(actions.paymentMomo.success((response as any).data));
  } catch (err) {
    yield put(actions.paymentMomo.failure(err));
  }
}

function* paymentVietQr(action: ReturnType<typeof actions.paymentVietQr.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.PAYMENT.VietQr, action.payload);

    yield put(actions.paymentVietQr.success((response as any).data));
  } catch (err) {
    yield put(actions.paymentVietQr.failure(err));
  }
}

// Main saga
export default function* paymentSaga() {
  yield all([
    takeLatest(actions.getPaymentList.request, getPaymentList),
    takeLatest(actions.getPaymentStatus.request, getPaymentStatus),
    takeLatest(actions.salePayment.request, salePayment),
    takeLatest(actions.refundPayment.request, refundPayment),
    takeLatest(actions.voidPayment.request, voidPayment),
    takeLatest(actions.tipAdjustPayment.request, tipAdjustPayment),
    takeLatest(actions.bulkPayments.request, bulkPayments),
    takeLatest(actions.paymentMomo.request, paymentMomo),
    takeLatest(actions.paymentVietQr.request, paymentVietQr),
  ]);
}
