import { createAsyncAction, createAction } from 'typesafe-actions';
import * as types from './types';
import {
  IncomeListRequest,
  IncomeListResponse,
  ReportFullDetailsResponse,
  ReportFullDetailsRequest,
  ReportSummaryRequest,
  ReportSummaryResponse,
  ReportDetailsRequest,
  ReportDetailsResponse,
} from '../../api-models/income';

// Create the set of async actions
export const getSummaryList = createAsyncAction(
  types.FETCH_SUMMARY,
  types.FETCH_SUMMARY_SUCCESS,
  types.FETCH_SUMMARY_FAILED
)<IncomeListRequest, IncomeListResponse, any>();

// Create the set of async actions
export const getSummaryDetailList = createAsyncAction(
  types.FETCH_SUMMARY_DETAILS,
  types.FETCH_SUMMARY_DETAILS_SUCCESS,
  types.FETCH_SUMMARY_DETAILS_FAILED
)<IncomeListRequest, IncomeListResponse, any>();

// Create the set of async actions
export const getIncomeSummaryList = createAsyncAction(
  types.FETCH_INCOME_SUMMARY,
  types.FETCH_INCOME_SUMMARY_SUCCESS,
  types.FETCH_INCOME_SUMMARY_FAILED
)<IncomeListRequest, IncomeListResponse, any>();

// Create the set of async actions
export const getIncomeDetailList = createAsyncAction(
  types.FETCH_INCOME_DETAILS,
  types.FETCH_INCOME_DETAILS_SUCCESS,
  types.FETCH_INCOME_DETAILS_FAILED
)<IncomeListRequest, IncomeListResponse, any>();

// Create the set of async actions
export const getReportSummary = createAsyncAction(
  types.FETCH_REPORT_SUMMARY,
  types.FETCH_REPORT_SUMMARY_SUCCESS,
  types.FETCH_REPORT_SUMMARY_FAILED
)<ReportSummaryRequest, ReportSummaryResponse, any>();

// Create the set of async actions
export const getReportFullDetails = createAsyncAction(
  types.FETCH_REPORT_FULL_DETAILS,
  types.FETCH_REPORT_FULL_DETAILS_SUCCESS,
  types.FETCH_REPORT_FULL_DETAILS_FAILED
)<ReportFullDetailsRequest, ReportFullDetailsResponse, any>();

// Create the set of async actions
export const getReportDetails = createAsyncAction(
  types.FETCH_REPORT_DETAILS,
  types.FETCH_REPORT_DETAILS_SUCCESS,
  types.FETCH_REPORT_DETAILS_FAILED
)<ReportDetailsRequest, ReportDetailsResponse, any>();


export const clearData = createAction(types.CLEAR_DATA)<string | void>();
