export const FETCH_GENERAL_SETTING = 'initialSetting/general_setting';
export const FETCH_GENERAL_SETTING_SUCCESS = 'initialSetting/general_setting_success';
export const FETCH_GENERAL_SETTING_FAILED = 'initialSetting/general_setting_failed';

export const FETCH_STORE_SETTING = 'initialSetting/store_setting';
export const FETCH_STORE_SETTING_SUCCESS = 'initialSetting/store_setting_success';
export const FETCH_STORE_SETTING_FAILED = 'initialSetting/store_setting_failed';

export const SET_GENERAL_DATA = 'initialSetting/set_general_data';
export const SET_STORE_DATA = 'initialSetting/set_store_data';

export const CLEAR_GROUP_DATA = 'initialSetting/clear_group_data';
export const CLEAR_DATA = 'initialSetting/clear_data';

export type SettingAction =
  | typeof FETCH_GENERAL_SETTING
  | typeof FETCH_GENERAL_SETTING_FAILED
  | typeof FETCH_GENERAL_SETTING_SUCCESS
  | typeof FETCH_STORE_SETTING
  | typeof FETCH_STORE_SETTING_FAILED
  | typeof FETCH_STORE_SETTING_SUCCESS
  | typeof SET_GENERAL_DATA
  | typeof SET_STORE_DATA
  | typeof CLEAR_DATA;
