import { STORE } from './store';
import { SERVICE } from './service';
import { USER } from './user';
import { GIFTCARD } from './giftcard';
import { MEMBERSHIP } from './membership';
import { SALE } from './sale';
import { EMPLOYEE } from './employee';
import { EMPLOYEE_WORKING_TIME } from './employee-working-time';
import { CUSTOMER } from './customer';
import { BOOKINGAPPT } from './bookingAppt';
import { WALKIN } from './walkin';

import { DASHBOARD_REPORT } from './dashboard';
import { INCOME_REPORT } from './report-income';
import { SUMMARY_REPORT } from './report-summary';
import { STORE_REPORT } from './report-store';
import { INCOME_WORKING_TIME_REPORT } from './report-income-working-time';
import { CUSTOMER_REPORT } from './report-customer';
import { DAILY_REPORT } from './report-daily';
import { GIFTCARD_REPORT } from './report-giftcard';
import { PAYROLL } from './report-payroll';

import { PAYMENT } from './sale-payment';

import { AUTH } from './auth';
import { CODE } from './code';
import { MEDIA } from './media';

import { STORE_SETTING } from './store-setting';
import { SETTING } from './setting';

export const API_ENDPOINTS = {
  AUTH,
  CODE,
  MEDIA,

  STORE_SETTING,
  SETTING,

  PAYMENT,
  SALE,

  STORE,
  SERVICE,
  USER,
  GIFTCARD,
  EMPLOYEE,
  EMPLOYEE_WORKING_TIME,
  CUSTOMER,
  MEMBERSHIP,

  BOOKINGAPPT,
  WALKIN,

  DASHBOARD_REPORT,
  INCOME_REPORT,
  INCOME_WORKING_TIME_REPORT,
  SUMMARY_REPORT,
  STORE_REPORT,
  CUSTOMER_REPORT,
  DAILY_REPORT,
  GIFTCARD_REPORT,
  PAYROLL,
};
