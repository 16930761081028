export const PAGE_PATH = {
  Error_403: '/403',
  Error_404: '/404',
  Error_500: '/500',
  Login: '/login',
  Logout: '/logout',

  Plan: '/plan',

  Dashboard: '/dashboard',

  Admin: '/admin',
  Members: '/admin/members',
  CategoryList: '/admin/categories',
  UnitsList: '/admin/units',
  ServiceList: '/admin/services',
  CustomerList: '/admin/customers',
  CustomerGroup: '/admin/customer-groups',
  Memberships: '/admin/memberships',
  Suppliers: '/admin/suppliers',
  EmarketingCampaign: '/admin/emarketing/campaigns',
  EmarketingTemplate: '/admin/emarketing/templates',
  EmarketingSentSummary: '/admin/emarketing/report/summary',
  EmarketingSentDetails: '/admin/emarketing/report/details',
  SmsReminder: '/admin/sms/reminder',
  SmsTemplate: '/admin/sms/templates',
  SmsReportSummary: '/admin/sms/report/summary',
  SmsReportDetails: '/admin/sms/report/details',
  Giftcardlist: '/admin/giftcards',
  Couponlist: '/admin/coupons',
  Settings: '/admin/settings',
  PackageList: '/admin/item_kits',
  ProductList: '/admin/product',
  ExpenseList: '/admin/expenses',

  EmployeeSales: '/pos/sales',
  EmployeeSalesParams: '/pos/sales/:id',
  ReceiptById: '/pos/receipts/:id',
  TurnSystem: '/pos/turn-system', // '/pos/turn-system'
  TurnReOrder: '/pos/turn-re-order',
  CashDrawer: '/pos/cash-drawer',

  PayrollReport: '/pos/report/payroll',

  IncomeReport: '/pos/report/income',
  IncomeReportDetail: '/pos/report/income/details',
  IncomeWorkingTimeReport: '/pos/report/income-wkt',
  SummaryReport: '/pos/report/summary',
  SummaryReportDetail: '/pos/report/summary/details',
  StoreReport: '/pos/report/store',
  StoreReportDetail: '/pos/report/store/details',
  CustomerReport: '/pos/report/customer',
  CustomerReportDetail: '/pos/report/customer/details',
  DailyReport: '/pos/report/daily',
  DailyReportDetail: '/pos/report/daily/details',
  GiftcardReport: '/pos/report/giftcard',
  GiftcardReportDetail: '/pos/report/giftcard/details',
  EmployeeCheckInOutReport: '/pos/report/check-in-out',
  AnalysisCustomer: 'pos/report/analysis-customer',
  AnalysisPayment: 'pos/report/analysis-payment',
  ServiceProductReport: '/pos/report/services-products',
  ServiceProductReportDetail: '/pos/report/services-products/details',

  Schedule: '/pos/booking-appt',
  EmployeeCheckIn: '/pos/employee-check-in',
  Walkins: '/pos/walk-in',
  WalkinsTv: '/pos/walk-in/tv/:storeId',
  WalkinSignIn: '/pos/walk-in/sign-in/:storeId/:isEmp/:isCat/:isService',
  WalkinSignUp: '/pos/walk-in/sign-up/:storeId/:isEmp/:isCat/:isService',
  WalkinQuickEmployees: '/pos/walk-in/sign-in/:storeId/quick-employees/:isEmp/:isCat/:isService',
  WalkinQuickServices: '/pos/walk-in/sign-in/:storeId/quick-services/:isEmp/:isCat/:isService',
  CustomerReview: '/pos/walk-in/review/:storeId',
};
