export const PAYROLL = {
  List: '/report/payrolls',
  Create: '/report/payrolls',
  Details: '/report/payrolls/{id}',
  Update: '/report/payrolls/{id}',
  Delete: '/report/payrolls/{id}',
  Bulk: '/report/payrolls/bulk',
  Calculate: '/report/payrolls/calculate',
  Preview: '/report/payrolls/preview',
  Report: '/report/payrolls/{id}/report',
  PayrollWorkingTimeSummary: '/report/payrolls/wk-summary',
  PayrollWorkingTimeDetails: '/report/payrolls/wk-details',
  PayrollCommissionReport: '/report/payrolls/commission-report',
  PayrollCommissionSummary: '/report/payrolls/commission-summary',
  PayrollCommissionDetails: '/report/payrolls/commission-details',
};
