export const FETCH_DETAILS = 'product/details';
export const FETCH_DETAILS_SUCCESS = 'product/details_success';
export const FETCH_DETAILS_FAILED = 'product/details_failed';

export const FETCH_LIST = 'product/list';
export const FETCH_LIST_SUCCESS = 'product/list_success';
export const FETCH_LIST_FAILED = 'product/list_failed';

export const CREATE = 'product/create';
export const CREATE_SUCCESS = 'product/create_success';
export const CREATE_FAILED = 'product/create_failed';

export const UPDATE = 'product/update';
export const UPDATE_SUCCESS = 'product/update_success';
export const UPDATE_FAILED = 'product/update_failed';

export const UPDATE_ORDERING = 'product/update_ordering';
export const UPDATE_ORDERING_SUCCESS = 'product/update_ordering_success';
export const UPDATE_ORDERING_FAILED = 'product/update_ordering_failed';

export const DELETE = 'product/detele';
export const DELETE_SUCCESS = 'product/detele_success';
export const DELETE_FAILED = 'product/detele_failed';

export const IMPORT_EXCEL = 'product/import';
export const IMPORT_EXCEL_SUCCESS = 'product/import_success';
export const IMPORT_EXCEL_FAILED = 'product/import_failed';

export const BULK = 'product/bulk';
export const BULK_SUCCESS = 'product/bulk_success';
export const BULK_FAILED = 'product/bulk_failed';

export const CREATEINV = 'inventory/create';
export const CREATEINV_SUCCESS = 'inventory/create_success';
export const CREATEINV_FAILED = 'inventory/create_failed';

export const FETCH_INVENTORY_DETAILS = 'inventory/details';
export const FETCH_INVENTORY_DETAILS_SUCCESS = 'inventory/details_success';
export const FETCH_INVENTORY_DETAILS_FAILED = 'inventory/details_failed';

export const CLEAR_DATA = 'product/clear_data';

export type ProductAction =
  | typeof FETCH_DETAILS
  | typeof FETCH_DETAILS_FAILED
  | typeof FETCH_DETAILS_SUCCESS
  | typeof FETCH_LIST
  | typeof FETCH_LIST_FAILED
  | typeof FETCH_LIST_SUCCESS
  | typeof CREATE
  | typeof CREATE_FAILED
  | typeof CREATE_SUCCESS
  | typeof UPDATE
  | typeof UPDATE_FAILED
  | typeof UPDATE_SUCCESS
  | typeof DELETE
  | typeof DELETE_FAILED
  | typeof DELETE_SUCCESS
  | typeof BULK
  | typeof BULK_FAILED
  | typeof BULK_SUCCESS
  | typeof CREATEINV
  | typeof CREATEINV_SUCCESS
  | typeof CREATEINV_FAILED
  | typeof FETCH_INVENTORY_DETAILS
  | typeof FETCH_INVENTORY_DETAILS_SUCCESS
  | typeof FETCH_INVENTORY_DETAILS_FAILED
  | typeof CLEAR_DATA;
