import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { SmsLogsState, initialState } from './model';

const smsLogs = produce((draft: Draft<SmsLogsState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.smsLogSummary.request): {
      draft.summary.loading = true;
      return draft;
    }
    case getType(actions.smsLogSummary.success): {
      draft.summary.loading = true;
      draft.summary.response = action.payload;
      return draft;
    }
    case getType(actions.smsLogSummary.failure): {
      draft.summary.loading = false;
      draft.summary.errors = action.payload;
      return draft;
    }
    case getType(actions.smsLogDetails.request): {
      draft.details.loading = true;
      draft.details.response = undefined;
      draft.details.errors = undefined;
      return draft;
    }
    case getType(actions.smsLogDetails.success): {
      draft.details.loading = true;
      draft.details.response = action.payload;
      return draft;
    }
    case getType(actions.smsLogDetails.failure): {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    case getType(actions.deleteSmsLog.request): {
      draft.delete.loading = true;
      return draft;
    }
    case getType(actions.deleteSmsLog.success): {
      draft.delete.loading = false;
      draft.delete.response = action.payload;
      return draft;
    }
    case getType(actions.deleteSmsLog.failure): {
      draft.delete.loading = false;
      draft.delete.errors = action.payload;
      return draft;
    }
    case getType(actions.bulkSmsLog.request): {
      draft.bulk.loading = true;
      return draft;
    }
    case getType(actions.bulkSmsLog.success): {
      draft.bulk.loading = false;
      draft.bulk.response = action.payload;
      return draft;
    }
    case getType(actions.bulkSmsLog.failure): {
      draft.bulk.loading = false;
      draft.bulk.errors = action.payload;
      return draft;
    }
    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      }
      return initialState;
    }
    default: {
      return draft;
    }
  }
}, initialState);

export default smsLogs;
