export const FETCH_LIST = 'storeSetting/list';
export const FETCH_LIST_SUCCESS = 'storeSetting/list_success';
export const FETCH_LIST_FAILED = 'storeSetting/list_failed';

export const FETCH_CONNECT_PAYMENT = 'storeSetting/connect_payment';
export const FETCH_CONNECT_PAYMENT_SUCCESS = 'storeSetting/connect_payment_success';
export const FETCH_CONNECT_PAYMENT_FAILED = 'storeSetting/connect_payment_failed';

export const UPDATE = 'storeSetting/update';
export const UPDATE_SUCCESS = 'storeSetting/update_success';
export const UPDATE_FAILED = 'storeSetting/update_failed';

export const UPDATE_MANY = 'storeSetting/update_many';
export const UPDATE_MANY_SUCCESS = 'storeSetting/update_many_success';
export const UPDATE_MANY_FAILED = 'storeSetting/update_many_failed';

export const HAS_UPDATED_DATA = 'storeSetting/has_updated_data';
export const SET_DATA = 'storeSetting/set_data';
export const CLEAR_GROUP_DATA = 'storeSetting/clear_group_data';
export const CLEAR_DATA = 'storeSetting/clear_data';

export type SettingAction =
  | typeof FETCH_LIST
  | typeof FETCH_LIST_FAILED
  | typeof FETCH_LIST_SUCCESS
  | typeof FETCH_CONNECT_PAYMENT
  | typeof FETCH_CONNECT_PAYMENT_FAILED
  | typeof FETCH_CONNECT_PAYMENT_SUCCESS
  | typeof UPDATE
  | typeof UPDATE_FAILED
  | typeof UPDATE_SUCCESS
  | typeof UPDATE_MANY
  | typeof UPDATE_MANY_FAILED
  | typeof UPDATE_MANY_SUCCESS
  | typeof HAS_UPDATED_DATA
  | typeof SET_DATA
  | typeof CLEAR_DATA;
