import { SaleOrderData } from 'src/state/models/order';
//import * as storage from './storage';

export type WaitingSaleState = SaleOrderData[];

export type WaitingSaleStateAll = WaitingSaleState;
// TODO: how to get global store_id, workstation_id
//export const initialWaitingSaleState: WaitingSaleState = storage.getWaitingSaleTickets('', '');

export const initialWaitingSaleState: WaitingSaleState = [];

export const initialStateAll: WaitingSaleStateAll = initialWaitingSaleState;

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    waitingSale: WaitingSaleStateAll;
  }
}
