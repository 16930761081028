import { put, call, all, takeLatest } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { convertToFormData } from 'src/utils/converter';
import { axiosInstance } from '../../../utils/axios/axios-instance';
import * as actions from './actions';

// Handle request saga
function* getCustomerList(action: ReturnType<typeof actions.getCustomerList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.CUSTOMER.List, {
      params: action.payload,
    });

    yield put(actions.getCustomerList.success((response as any).data));
  } catch (err) {
    yield put(actions.getCustomerList.failure(err));
  }
}

function* getCustomerDetails(action: ReturnType<typeof actions.getCustomerDetails.request>): Generator {
  try {
    const response = yield call(
      axiosInstance.get,
      API_ENDPOINTS.CUSTOMER.Details.replace('{id}', (action.payload as any).id),
      {
        params: action.payload,
      }
    );

    yield put(actions.getCustomerDetails.success((response as any).data));
  } catch (err) {
    yield put(actions.getCustomerDetails.failure(err));
  }
}

function* createCustomer(action: ReturnType<typeof actions.createCustomer.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.CUSTOMER.Create, action.payload, config));

    yield put(actions.createCustomer.success((response as any).data));
  } catch (err) {
    yield put(actions.createCustomer.failure(err));
  }
}

function* createCustomerInPos(action: ReturnType<typeof actions.createCustomerInPos.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.CUSTOMER.PosCreate, action.payload, config));

    yield put(actions.createCustomerInPos.success((response as any).data));
  } catch (err) {
    yield put(actions.createCustomerInPos.failure(err));
  }
}

function* updateCustomer(action: ReturnType<typeof actions.updateCustomer.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };
    const id = action.payload.id;
    const response = yield call(() =>
      axiosInstance.post(API_ENDPOINTS.CUSTOMER.Update.replace('{id}', id), action.payload, config)
    );

    yield put(actions.updateCustomer.success((response as any).data));
  } catch (err) {
    yield put(actions.updateCustomer.failure(err));
  }
}

function* deleteCustomer(action: ReturnType<typeof actions.deleteCustomer.request>): Generator {
  try {
    const response = yield call(() =>
      axiosInstance.delete(API_ENDPOINTS.CUSTOMER.Delete.replace('{id}', action.payload))
    );

    yield put(actions.deleteCustomer.success((response as any).data));
  } catch (err) {
    yield put(actions.deleteCustomer.failure(err));
  }
}

function* bulkCustomer(action: ReturnType<typeof actions.bulkCustomer.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.CUSTOMER.Bulk, action.payload));

    yield put(actions.bulkCustomer.success((response as any).data));
  } catch (err) {
    yield put(actions.bulkCustomer.failure(err));
  }
}

function* importCustomer(action: ReturnType<typeof actions.importCustomer.request>): Generator {
  try {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    };

    const response = yield call(axiosInstance.post, API_ENDPOINTS.CUSTOMER.Import, action.payload, config);

    yield put(actions.importCustomer.success((response as any).data));
  } catch (err) {
    yield put(actions.importCustomer.failure(err));
  }
}

function* getHistoryPointSaga(action: ReturnType<typeof actions.historyPointAction.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.CUSTOMER.ListHistoryPoint, {
      params: action.payload,
    });
    yield put(actions.historyPointAction.success((response as any).data));
  } catch (err) {
    yield put(actions.historyPointAction.failure(err));
  }
}

function* customerReviewList(action: ReturnType<typeof actions.customerReviewList.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.CUSTOMER.ListReviews, {
      params: action.payload,
    });

    yield put(actions.customerReviewList.success((response as any).data));
  } catch (err) {
    yield put(actions.customerReviewList.failure(err));
  }
}

function* customerReview(action: ReturnType<typeof actions.customerReview.request>): Generator {
  try {
    const response = yield call(() => axiosInstance.post(API_ENDPOINTS.CUSTOMER.ListReviews, action.payload));

    yield put(actions.customerReview.success((response as any).data));
  } catch (err) {
    yield put(actions.customerReview.failure(err));
  }
}

function* checkMembership(action: ReturnType<typeof actions.checkMembership.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.CUSTOMER.CheckMembership, {
      params: action.payload,
    });

    yield put(actions.checkMembership.success((response as any).data));
  } catch (err) {
    yield put(actions.checkMembership.failure(err));
  }
}

// Main saga
export default function* CustomerSaga() {
  yield all([
    takeLatest(actions.getCustomerList.request, getCustomerList),
    takeLatest(actions.getCustomerDetails.request, getCustomerDetails),
    takeLatest(actions.createCustomer.request, createCustomer),
    takeLatest(actions.createCustomerInPos.request, createCustomerInPos),
    takeLatest(actions.updateCustomer.request, updateCustomer),
    takeLatest(actions.deleteCustomer.request, deleteCustomer),
    takeLatest(actions.bulkCustomer.request, bulkCustomer),
    takeLatest(actions.importCustomer.request, importCustomer),
    takeLatest(actions.historyPointAction.request, getHistoryPointSaga),
    takeLatest(actions.customerReviewList.request, customerReviewList),
    takeLatest(actions.customerReview.request, customerReview),
    takeLatest(actions.checkMembership.request, checkMembership),
  ]);
}
