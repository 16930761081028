import produce, { Draft } from 'immer';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { IncomeStateAll, initialStateAll } from './model';

const income = produce((draft: Draft<IncomeStateAll>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getSummaryList.request): {
      draft.summary.loading = true;
      return draft;
    }
    case getType(actions.getSummaryList.success): {
      draft.summary.loading = true;
      draft.summary.response = action.payload;
      return draft;
    }
    case getType(actions.getSummaryList.failure): {
      draft.summary.loading = false;
      draft.summary.errors = action.payload;
      return draft;
    }
    case getType(actions.getSummaryDetailList.request): {
      draft.summaryDetails.loading = true;
      return draft;
    }
    case getType(actions.getSummaryDetailList.success): {
      draft.summaryDetails.loading = true;
      draft.summaryDetails.response = action.payload;

      return draft;
    }
    case getType(actions.getSummaryDetailList.failure): {
      draft.summaryDetails.loading = false;
      draft.summaryDetails.errors = action.payload;
      return draft;
    }
    case getType(actions.getIncomeSummaryList.request): {
      draft.incomeSummary.loading = true;
      return draft;
    }
    case getType(actions.getIncomeSummaryList.success): {
      draft.incomeSummary.loading = true;
      draft.incomeSummary.response = action.payload;
      return draft;
    }
    case getType(actions.getIncomeSummaryList.failure): {
      draft.incomeSummary.loading = false;
      draft.incomeSummary.errors = action.payload;
      return draft;
    }
    case getType(actions.getIncomeDetailList.request): {
      draft.incomeDetails.loading = true;
      return draft;
    }
    case getType(actions.getIncomeDetailList.success): {
      draft.incomeDetails.loading = true;
      draft.incomeDetails.response = action.payload;

      return draft;
    }
    case getType(actions.getIncomeDetailList.failure): {
      draft.incomeDetails.loading = false;
      draft.incomeDetails.errors = action.payload;
      return draft;
    }
    case getType(actions.getReportSummary.request): {
      draft.reportSummary.loading = true;
      draft.reportSummary.response = undefined;
      draft.reportSummary.errors = undefined;
      return draft;
    }
    case getType(actions.getReportSummary.success): {
      draft.reportSummary.loading = true;
      draft.reportSummary.response = action.payload;

      return draft;
    }
    case getType(actions.getReportSummary.failure): {
      draft.reportSummary.loading = false;
      draft.reportSummary.errors = action.payload;
      return draft;
    }
    case getType(actions.getReportDetails.request): {
      draft.reportDetails.loading = true;
      draft.reportDetails.response = undefined;
      draft.reportDetails.errors = undefined;
      return draft;
    }
    case getType(actions.getReportDetails.success): {
      draft.reportDetails.loading = true;
      draft.reportDetails.response = action.payload;

      return draft;
    }
    case getType(actions.getReportDetails.failure): {
      draft.reportDetails.loading = false;
      draft.reportDetails.errors = action.payload;
      return draft;
    }
    case getType(actions.getReportFullDetails.request): {
      draft.reportFullDetails.loading = true;
      draft.reportFullDetails.response = undefined;
      draft.reportFullDetails.errors = undefined;
      return draft;
    }
    case getType(actions.getReportFullDetails.success): {
      draft.reportFullDetails.loading = true;
      draft.reportFullDetails.response = action.payload;

      return draft;
    }
    case getType(actions.getReportFullDetails.failure): {
      draft.reportFullDetails.loading = false;
      draft.reportFullDetails.errors = action.payload;
      return draft;
    }

    case getType(actions.clearData): {
      if (action.payload) {
        draft[action.payload] = {};
      } else {
        draft = initialStateAll;
      }
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialStateAll);

export default income;
