export const FETCH_DETAILS = 'category/details';
export const FETCH_DETAILS_SUCCESS = 'category/details_success';
export const FETCH_DETAILS_FAILED = 'category/details_failed';

export const FETCH_LIST = 'category/list';
export const FETCH_LIST_SUCCESS = 'category/list_success';
export const FETCH_LIST_FAILED = 'category/list_failed';

export const FETCH_ACTIVE_LIST = 'category/active_list';
export const FETCH_ACTIVE_LIST_SUCCESS = 'category/active_list_success';
export const FETCH_ACTIVE_LIST_FAILED = 'category/active_list_failed';

export const CREATE = 'category/create';
export const CREATE_SUCCESS = 'category/create_success';
export const CREATE_FAILED = 'category/create_failed';

export const UPDATE = 'category/update';
export const UPDATE_SUCCESS = 'category/update_success';
export const UPDATE_FAILED = 'category/update_failed';

export const DELETE = 'category/detele';
export const DELETE_SUCCESS = 'category/detele_success';
export const DELETE_FAILED = 'category/detele_failed';

export const BULK = 'category/bulk';
export const BULK_SUCCESS = 'category/bulk_success';
export const BULK_FAILED = 'category/bulk_failed';

export const GET_GIFTCARD_NUMBER = 'category/get_categories_number';
export const GET_GIFTCARD_NUMBER_SUCCESS = 'category/get_categories_number_success';
export const GET_GIFTCARD_NUMBER_FAILED = 'category/get_categories_number_failed';

export const CLEAR_DATA = 'category/clear_data';

export type CategoryAction =
  | typeof FETCH_DETAILS
  | typeof FETCH_DETAILS_FAILED
  | typeof FETCH_DETAILS_SUCCESS
  | typeof FETCH_LIST
  | typeof FETCH_LIST_FAILED
  | typeof FETCH_LIST_SUCCESS
  | typeof CREATE
  | typeof CREATE_FAILED
  | typeof CREATE_SUCCESS
  | typeof UPDATE
  | typeof UPDATE_FAILED
  | typeof UPDATE_SUCCESS
  | typeof DELETE
  | typeof DELETE_FAILED
  | typeof DELETE_SUCCESS
  | typeof BULK
  | typeof BULK_FAILED
  | typeof BULK_SUCCESS;
