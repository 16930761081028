export const toCodeValue = (value) => {
  const code = value ? value.replace(/[^0-9a-zA-Z]+/gi, '') : '';

  return code;
};

export const toNumber = (value: string | number): number => {
  let numberVal = value || 0;
  numberVal = typeof numberVal === 'number' ? numberVal : parseFloat(numberVal.replace(/,/g, ''));
  return numberVal;
};

export const convertUuIdtoNumber = (uuid: string, N = 256) => {
  const INIT_NUMBER = 1;
  const x = uuid.split('-').reduce((a, b) => a ^ Number.parseInt(b, 16), INIT_NUMBER);
  return Math.abs(x) % N;
};

export const convertNewLinetoHtmlBr = (value: string) => {
  const regex = /\n|\\n|\r|\\r|\n\r|\\n\\r|\r\n|\\r\\n/g;

  return value.replace(regex, '<br/>');
};

export const removeNonAlphanumericCharacters = (value: string) => {
  return value.replace(/[^0-9a-zA-Z-]/g, '');
};

export const convertPhoneNumber = (value: string) => value.replace(/-| |\(|\)/g, '');

export const cloneObject = (a: any, b: any) => {
  if (a && b && typeof a === 'object' && typeof b === 'object') {
    for (const prop in a) {
      if (a.hasOwnProperty(prop) && a.hasOwnProperty(prop)) {
        // if the value is a nested object, recursively copy all it's properties
        if (isObject(a[prop])) {
          b[prop] = cloneObject(a[prop], b[prop]);
        } else {
          b[prop] = a[prop];
        }
      }
    }
    const c = { ...b };
    return c;
  }
  const c = { ...b };
  return c;
};

export const isObject = (obj: any) => {
  const type = typeof obj;
  return type === 'function' || (type === 'object' && !!obj);
};

export const convertBlankToUndefined = (value: any) => (value === '' ? undefined : value);

export const replaceAt = (value: string, index: number, replacement: string) =>
  value.substring(0, index) + replacement + value.substring(index + replacement.length);

function buildFormData(formData: FormData, data: any, parentKey?: string) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    if (data !== undefined && data !== null) {
      formData.append(parentKey, data);
    }
  }
}

export const convertToFormData = (object) => {
  const formData = new FormData();

  buildFormData(formData, object);

  return formData;
};
