import produce, { Draft } from 'immer';
import { combineReducers } from 'redux';

import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import {
  SaleOrderState,
  SaleFirstData,
  GiftcardState,
  SaleState,
  EmployeeState,
  initialOrderState,
  initialFirstDataState,
  initialSaleState,
  initGiftcardState,
  initEmployeeState,
  CustomerState,
  initCustomerState,
  SaleSettingData,
  initialSaleSettingData,
} from './model';
import {
  setOrderData,
  addEmployee,
  changeEmployee,
  addOrderItem,
  addPayment,
  addTipEmployee,
  deleteOrderItem,
  discountOrder,
  discountOrderItem,
  updateOrderByBooking,
  updateOrderByTurn,
  updateOrderItem,
  removeDiscountOrder,
  updateQtyOrderItem,
} from './producers';

const settingData = produce((draft: Draft<SaleSettingData>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getSaleServiceProductSetting.request): {
      draft.loading = true;
      return draft;
    }
    case getType(actions.getSaleServiceProductSetting.success): {
      draft.loading = true;
      draft.response = action.payload;
      return draft;
    }
    case getType(actions.getSaleServiceProductSetting.failure): {
      draft.loading = false;
      draft.errors = action.payload;
      return draft;
    }

    default: {
      return draft;
    }
  }
}, initialSaleSettingData);

const firstData = produce((draft: Draft<SaleFirstData>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getSaleInit.request): {
      draft.loading = true;
      return draft;
    }
    case getType(actions.getSaleInit.success): {
      draft.loading = true;
      draft.response = action.payload;
      return draft;
    }
    case getType(actions.getSaleInit.failure): {
      draft.loading = false;
      draft.errors = action.payload;
      return draft;
    }

    default: {
      return draft;
    }
  }
}, initialFirstDataState);

const giftcard = produce((draft: Draft<GiftcardState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getGiftcardDetails.request): {
      draft.details.loading = true;
      draft.details.response = undefined;
      draft.details.errors = undefined;
      return draft;
    }
    case getType(actions.getGiftcardDetails.success): {
      draft.details.loading = true;
      draft.details.response = action.payload;
      return draft;
    }
    case getType(actions.getGiftcardDetails.failure): {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    case getType(actions.getGiftcardHistories.request): {
      draft.histories.loading = true;
      draft.histories.response = undefined;
      draft.histories.errors = undefined;
      return draft;
    }
    case getType(actions.getGiftcardHistories.success): {
      draft.histories.loading = true;
      draft.histories.response = action.payload;
      return draft;
    }
    case getType(actions.getGiftcardHistories.failure): {
      draft.histories.loading = false;
      draft.histories.errors = action.payload;
      return draft;
    }
    case getType(actions.clearGiftcard): {
      draft.details.loading = false;
      draft.details.response = undefined;
      draft.details.errors = undefined;
      return draft;
    }

    default: {
      return draft;
    }
  }
}, initGiftcardState);

const customer = produce((draft: Draft<CustomerState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getCustomerList.request): {
      draft.list.loading = true;
      draft.list.response = undefined;
      draft.list.errors = undefined;
      return draft;
    }
    case getType(actions.getCustomerList.success): {
      draft.list.loading = true;
      draft.list.response = action.payload;
      return draft;
    }
    case getType(actions.getCustomerList.failure): {
      draft.list.loading = false;
      draft.list.errors = action.payload;
      return draft;
    }
    case getType(actions.getCustomerDetails.request): {
      draft.details.loading = true;
      draft.details.response = undefined;
      draft.details.errors = undefined;
      return draft;
    }
    case getType(actions.getCustomerDetails.success): {
      draft.details.loading = true;
      draft.details.response = action.payload;
      return draft;
    }
    case getType(actions.getCustomerDetails.failure): {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    case getType(actions.createCustomer.request): {
      draft.create.loading = true;
      draft.create.response = undefined;
      draft.create.errors = undefined;
      return draft;
    }
    case getType(actions.createCustomer.success): {
      draft.create.loading = true;
      draft.create.response = action.payload;
      return draft;
    }
    case getType(actions.createCustomer.failure): {
      draft.create.loading = false;
      draft.create.errors = action.payload;
      return draft;
    }

    case getType(actions.updateCustomer.request): {
      draft.update.loading = true;
      draft.update.response = undefined;
      draft.update.errors = undefined;
      return draft;
    }
    case getType(actions.updateCustomer.success): {
      draft.update.loading = true;
      draft.update.response = action.payload;
      return draft;
    }
    case getType(actions.updateCustomer.failure): {
      draft.update.loading = false;
      draft.update.errors = action.payload;
      return draft;
    }

    case getType(actions.clearCustomer): {
      draft = initCustomerState;
      return draft;
    }

    default: {
      return draft;
    }
  }
}, initCustomerState);

const employee = produce((draft: Draft<EmployeeState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getEmployeeList.request): {
      draft.list.loading = true;
      draft.list.response = undefined;
      draft.list.errors = undefined;
      return draft;
    }
    case getType(actions.getEmployeeList.success): {
      draft.list.loading = true;
      draft.list.response = action.payload;
      return draft;
    }
    case getType(actions.getEmployeeList.failure): {
      draft.list.loading = false;
      draft.list.errors = action.payload;
      return draft;
    }
    case getType(actions.getCheckedInEmployeeList.request): {
      draft.checkedInEmployee.loading = true;
      return draft;
    }
    case getType(actions.getCheckedInEmployeeList.success): {
      draft.checkedInEmployee.loading = false;
      draft.checkedInEmployee.response = action.payload;
      return draft;
    }
    case getType(actions.getCheckedInEmployeeList.failure): {
      draft.checkedInEmployee.loading = false;
      draft.checkedInEmployee.errors = action.payload;
      return draft;
    }

    default: {
      return draft;
    }
  }
}, initEmployeeState);

const sale = produce((draft: Draft<SaleState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.createSale.request): {
      draft.create.loading = true;
      return draft;
    }
    case getType(actions.createSale.success): {
      draft.create.loading = false;
      draft.create.response = action.payload;
      return draft;
    }
    case getType(actions.createSale.failure): {
      draft.create.loading = false;
      draft.create.errors = action.payload;
      return draft;
    }
    case getType(actions.updateSale.request): {
      draft.update.loading = true;
      return draft;
    }
    case getType(actions.updateSale.success): {
      draft.update.loading = false;
      draft.update.response = action.payload;
      return draft;
    }
    case getType(actions.updateSale.failure): {
      draft.update.loading = false;
      draft.update.errors = action.payload;
      return draft;
    }
    case getType(actions.getSale.request): {
      draft.details.loading = true;
      return draft;
    }
    case getType(actions.getSale.success): {
      draft.details.loading = false;
      draft.details.response = action.payload;
      return draft;
    }
    case getType(actions.getSale.failure): {
      draft.details.loading = false;
      draft.details.errors = action.payload;
      return draft;
    }
    case getType(actions.clearSaleData): {
      draft.create.loading = false;
      draft.create.response = undefined;
      draft.create.errors = undefined;
      draft.update.loading = false;
      draft.update.response = undefined;
      draft.update.errors = undefined;
      draft.details.loading = false;
      draft.details.response = undefined;
      draft.details.errors = undefined;
      return draft;
    }

    default: {
      return draft;
    }
  }
}, initialSaleState);

const order = produce((draft: Draft<SaleOrderState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.setSettings): {
      draft.settings = action.payload;
      return draft;
    }
    case getType(actions.setActiveCategory): {
      draft.activeCategory = action.payload;
      return draft;
    }
    case getType(actions.addEmployee): {
      draft = addEmployee(draft, action.payload);
      return draft;
    }
    case getType(actions.changeEmployee): {
      draft = changeEmployee(draft, action.payload);
      return draft;
    }
    case getType(actions.addOrderItem): {
      draft = addOrderItem(draft, action.payload);
      // draft = calcOrderTotal(draft);
      return draft;
    }
    case getType(actions.removeDiscountOrder): {
      draft = removeDiscountOrder(draft, action.payload);
      // draft = calcOrderTotal(draft);
      return draft;
    }
    case getType(actions.deleteOrderItem): {
      draft = deleteOrderItem(draft, action.payload);
      // draft = calcDiscountTotal(draft);
      // draft = calcOrderTotal(draft);
      return draft;
    }

    case getType(actions.updateOrderItem): {
      draft = updateOrderItem(draft, action.payload);
      // draft = calcDiscountTotal(draft);
      // draft = calcOrderTotal(draft);
      return draft;
    }
    case getType(actions.updateQtyOrderItem): {
      draft = updateQtyOrderItem(draft, action.payload);
      // draft = calcDiscountTotal(draft);
      // draft = calcOrderTotal(draft);
      return draft;
    }
    case getType(actions.selectOrderItem): {
      draft.activeEmployeeIndex = action.payload.employeeIndex;
      draft.activeOrderItemIndex = action.payload.orderItemIndex;
      return draft;
    }
    case getType(actions.discountOrderItem): {
      draft = discountOrderItem(draft, action.payload);
      // draft = calcDiscountTotal(draft);
      // draft = calcOrderTotal(draft);
      return draft;
    }
    case getType(actions.discountOrder): {
      draft = discountOrder(draft, action.payload);
      // draft = calcDiscountTotal(draft);
      // draft = calcOrderTotal(draft);
      return draft;
    }

    case getType(actions.addPayment): {
      draft = addPayment(draft, action.payload);
      // draft = calcPaymentTotal(draft);
      // draft = calcOrderTotal(draft);
      return draft;
    }
    case getType(actions.addCustomer): {
      draft.customer = action.payload;
      return draft;
    }
    case getType(actions.addBooking): {
      draft.booking = action.payload;
      draft = updateOrderByBooking(draft, action.payload);
      return draft;
    }
    case getType(actions.addTurn): {
      draft.turn = action.payload;
      draft = updateOrderByTurn(draft, action.payload);
      return draft;
    }
    case getType(actions.setOrderMetaData): {
      const { id, daily_ticket_no, ticket_no, invoice_no, order_at } = action.payload;
      draft.id = id;
      draft.ticket_no = ticket_no;
      draft.daily_ticket_no = daily_ticket_no;
      draft.invoice_no = invoice_no;
      draft.order_at = order_at;
      return draft;
    }
    case getType(actions.setOrderData): {
      draft = setOrderData(draft, action.payload);
      return draft;
    }
    case getType(actions.clearOrderData): {
      if (action.payload.clearAll) {
        draft.employee = undefined;
        draft.orderEmployees = [];
        draft.activeEmployeeIndex = -1;
      } else if (draft.orderEmployees && draft.orderEmployees.length > 0) {
        draft.orderEmployees = [{ employee: draft.orderEmployees[0].employee, orderItems: [] }];
        draft.employee = draft.orderEmployees[0].employee;
        draft.activeEmployeeIndex = 0;
      } else if (draft.employee) {
        draft.orderEmployees = [{ employee: draft.employee, orderItems: [] }];
        draft.activeEmployeeIndex = 0;
      }

      draft.id = undefined;
      draft.ticket_no = 0;
      draft.daily_ticket_no = 0;
      draft.invoice_no = 0;
      draft.order_at = undefined;

      draft.activeOrderItemIndex = -1;
      draft.discount = initialOrderState.discount;
      draft.payments = initialOrderState.payments;

      // not clear customer info
      if (action.payload.clearAll) {
        draft.customer = undefined;
      }
      return draft;
    }
    case getType(actions.addTip): {
      draft = addTipEmployee(draft, action.payload);
      // draft = calcOrderTotal(draft);
      return draft;
    }
    default: {
      return draft;
    }
  }
}, initialOrderState);

export default combineReducers({
  settingData,
  firstData,
  sale,
  order,
  giftcard,
  employee,
  customer,
});
