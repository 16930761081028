import {
  initOrderDiscount,
  initOrderTotal,
  OrderDiscount,
  OrderTotal,
  PaymentData,
  SaleOrderData,
} from 'src/state/models/order';
import { Customer } from 'src/state/models/customer';
import { GiftcardDetailResponse, GiftcardHistoriesResponse } from 'src/state/api-models/giftcard';
import { EmployeeListResponse } from 'src/state/api-models/employee';
import { CustomerCreateResponse, CustomerListResponse } from 'src/state/api-models/customer';
import { SettingGroup } from 'src/state/api-models/setting';
import { Category } from 'src/state/models/category';
import { BookingAppt } from 'src/state/models/bookingAppt';
import { BaseState } from '../models';
import {
  SaleFirstDataResponse,
  SaleCreateResponse,
  SaleDetailResponse,
  SaleUpdateResponse,
  SaleServiceProductSettingResponse,
} from '../../api-models/sale';
import { CustomerDetails, CustomerUpdate } from '../customer/model';
import { EmployeeTurnSystem } from 'src/state/models/employee-turn-system';
import { EmployeeInOutTimeResponse } from 'src/state/api-models/employee-in-out-time';

export type SaleSettingData = BaseState<SaleServiceProductSettingResponse>;
export type SaleFirstData = BaseState<SaleFirstDataResponse>;
export type SaleDetails = BaseState<SaleDetailResponse>;
export type SaleCreate = BaseState<SaleCreateResponse>;
export type SaleUpdate = BaseState<SaleUpdateResponse>;

export type SaleState = {
  create: SaleCreate;
  update: SaleUpdate;
  details: SaleDetails;
};

export type GiftcardState = {
  details: BaseState<GiftcardDetailResponse>;
  histories: BaseState<GiftcardHistoriesResponse>;
};

export type EmployeeState = {
  list: BaseState<EmployeeListResponse>;
  checkedInEmployee: BaseState<EmployeeInOutTimeResponse> & { reloadedAt?: Date };
};

export type CustomerState = {
  create: BaseState<CustomerCreateResponse>;
  details: CustomerDetails;
  update: CustomerUpdate;
  list: BaseState<CustomerListResponse>;
};

export type SaleOrderState = SaleOrderData & {
  settings: SettingGroup;
  activeCategory?: Category;
  activeEmployeeIndex?: number;
  activeOrderItemIndex?: number;
  orderTotal?: OrderTotal;
  discount?: OrderDiscount;
  payments: PaymentData[];
  customer?: Customer;
  booking?: BookingAppt;
  turn?: EmployeeTurnSystem;
  order_at?: string;
  daily_ticket_no?: number;
  ticket_no?: number;
  invoice_no?: number;
  id?: string;
};

export type SaleStateAll = {
  // match to reducer name, each reducer will be one field in state
  settingData: SaleSettingData;
  firstData: SaleFirstData;
  sale: SaleState;
  order: SaleOrderState;
  giftcard: GiftcardState;
  employee: EmployeeState;
  customer: CustomerState;
};

export const initialSaleState: SaleState = {
  details: { loading: false, response: undefined },
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
};

export const initGiftcardState: GiftcardState = {
  details: { loading: false, response: undefined },
  histories: { loading: false, response: undefined },
};

export const initEmployeeState: EmployeeState = {
  list: { loading: false, response: undefined },
  checkedInEmployee: { loading: false, response: undefined },
};

export const initCustomerState: CustomerState = {
  create: { loading: false, response: undefined },
  update: { loading: false, response: undefined },
  details: { loading: false, response: undefined },
  list: { loading: false, response: undefined },
};

export const initialSaleSettingData: SaleSettingData = {
  loading: false,
  response: undefined,
};

export const initialFirstDataState: SaleFirstData = {
  loading: false,
  response: undefined,
};

export const initialOrderState: SaleOrderState = {
  settings: {},
  orderEmployees: [],
  orderTotal: initOrderTotal,
  discount: initOrderDiscount,
  payments: [],
};

export const initialStateAll: SaleStateAll = {
  settingData: initialSaleSettingData,
  firstData: initialFirstDataState,
  sale: initialSaleState,
  order: initialOrderState,
  giftcard: initGiftcardState,
  employee: initEmployeeState,
  customer: initCustomerState,
};

declare module './../models' {
  interface StateAll {
    // match to a duck folder
    sale: SaleStateAll;
  }
}
