export const API_ENDPOINTS = {
  COUPON: {
    List: '/coupons',
    Create: '/coupons',
    Details: '/coupons/{id}',
    Histories: '/coupons/{id}/histories',
    Update: '/coupons/{id}',
    Delete: '/coupons/{id}',
    Bulk: '/coupons/bulk',
    POS: {
      Details: '/pos/coupons/{id}',
    },
  },
};
